import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { workforcesDto } from '../admin/_models/workforcesDto';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DataService } from '../data.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DialogConfirmation } from '../_shared/dialog-confirmation/dialog-confirmation';


@Component({
  selector: 'app-manage-roles-list',
  templateUrl: './manage-roles-list.component.html',
  styleUrls: ['./manage-roles-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ManageRolesListComponent implements OnInit {

  isTableExpanded = false;

  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  expandedElement: any;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<any>;
  rolesList: any[] = [];
  roleName: string = '';
  loading = false;
  checkedRoles: any[];
  filterRolesList;
  constructor(private router: Router, private dataService: DataService, private _dialog: MatDialog) { }

  // isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');
  // expandedElement: any;

  displayedColumns = ['index', 'roleName', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.checkedRoles = [];
    this.getRolesList();
  }

  getRolesList() {
    this.loading = true;
    // pageCount/limit/roleName
    this.dataService
      .getOperationsRolesBy(this.currentPage, this.pageSize, this.roleName)
      .subscribe((res) => {
        this.rolesList = res.payload.content;
        this.totalRows = res.payload.totalElements;
        this.dataSource = new MatTableDataSource<any>(this.rolesList);
        this.filterRolesList = this.rolesList.map(({ roleName }) => ({
          name: roleName,

        }));

        console.log("filterRolesList ======= ", this.filterRolesList);
        this.loading = false;
      },
        error => {
          this.loading = false;
          console.log("err", error);
        }
      );
  }

  createNewRole() {
    this.router.navigateByUrl('/roles-form');
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    if (filterValue.length > 0) {
      this.dataService.getOperationsRolesBy(0, this.pageSize, filterValue.trim().toLowerCase()).pipe(first()).subscribe(res => {
        this.rolesList = res.payload.content;
        this.totalRows = res.payload.totalElements;
        this.dataSource = new MatTableDataSource<any>(this.rolesList);
      },
        error => {
          console.log("err", error);
        });
    } else {
      this.getRolesList();
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.dataSource.filter = this.searchKey;
    this.getRolesList()
  }

  onView(row) {
    row.isExpanded = !row.isExpanded;
  }

  onEdit(row) {
    this.router.navigateByUrl(`/roles-form/edit/${row.id}`);
  }

  onDelete(row) {
    console.log('delete', row);
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      data: {
        title: { title: "Are you sure?", color: "#D93636" },
        body: `You’re are going to delete role
        “${row.roleName}”, please confirm`,
        btnConfirmation: { title: "Delete", color: "#D93636" },
        btnClose: { title: "Cancel" },
        comment: false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dataService.deleteRoles(row.roleName).subscribe(
          (res) => {
            if (res.success) this.getRolesList();
          },
          (error) => {
            if (error.erorr.code == 400) return;
          }
        );
      }
    });


  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getRolesList();
  }

  onFilterRole(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  clearSelected() {
    console.log("parent clearSelected fired");

    // this.roles.value = null
  }

  // onFilter(event) {
  //   console.log("parent onFilter event",event.value)
  //   this.checkedRoles = event.value
  //   // console.log(this.checkedRoles); 
  // }

  onApplyMultipleFilter(event) {
    console.log("parent onFilter event", event.value)
    // console.log('onApplyMultipleFilter selected elements',this.checkedRoles);
  }


}
