import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CitationList } from '../_models/citationList';

import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { ReplaySubject, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

import { FormControl } from '@angular/forms';

import { CacheFiltersCitationService, CitationReportListService, CitationService } from '../_services';

import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';

import { ngxCsv } from 'ngx-csv/ngx-csv';
import { AuthorizationService } from 'src/app/_services';
import { ShowCitationDetailsDialogComponent } from './citation-details-dialog/show-citation-details-dialog.component';
import { ShowImagesDialog } from './show-images-dialog/show-images-dialog';

interface SelectDropdownIdName {
  id: any;
  name: string;
}

@Component({
  selector: 'app-list',
  templateUrl: './citation-list.component.html',
  styleUrls: ['./citation-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class CitationListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('violationSelect', { static: true }) violationSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  filteredColumns = [
    // { id: 'citationDateTime', name: "Citation date time" },
    { id: 'id', name: 'Citation number', value: true },
    { id: 'plateNumberEn', name: 'Car Plate', value: true },
    { id: 'enforcerName', name: 'Enforcer Name', value: true },
    // { id: 'citationDescription', name: "Citation description", value: true },
    { id: 'operationArea', name: 'Operation area', value: true },
    { id: 'feesAmount', name: 'Fees Amount', value: true },
    // { id: 5, name: "License plate", value: true },
    { id: 'country', name: 'Country ', value: true },
    { id: 'createdDate', name: 'Citation Date ', value: true },
    { id: 'settledDate', name: 'Settled Date ', value: true },
    // { id: 'street', name: "Street", value: true },
    { id: 'violationName', name: 'Violation Description', value: true },
    { id: 'violationCode', name: 'Violation Code', value: true },
    { id: 'status', name: 'Status', value: true },
    { id: 'actionBy', name: 'Settled By', value: true },
  ];
  loading: boolean = false;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<CitationList>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = [
    'index',
    'createdDate',
    'plateNumberEn',
    'status',
    'violationName',
    'violationCode',
    'id',
    'feesAmount',
    'operationArea',
    'enforcerName',
    'country',
    'settledDate',
    'actionBy',
    'latitude',
    'longitude',
    'actions',
  ];
  dataList: [{}];
  displayedColumnsShow: string[] = [
    'index',
    'id',
    'plateNumberEn',
    'violationName',
    'violationCode',
    'enforcerName',
    'operationArea',
    'feesAmount',
    'country',
    'createdDate',
    'settledDate',
    'status',
    'actionBy',
    'latitude',
    'longitude',
    'actions',
  ];
  sortBy: string = 'createdDate';
  sortDirection: string = 'desc';

  isListMultiSelect = false;

  // displayedColumnsMulti: string[] = ['select', 'index', 'id', 'plateNumberEn', 'violationName', 'status'];
  displayedColumnsMulti: string[] = [
    'select',
    'index',
    'createdDate',
    'plateNumberEn',
    'status',
    'violationName',
    'violationCode',
    'id',
    'feesAmount',
    'operationArea',
    'enforcerName',
    'country',
    'settledDate',
    'actionBy',
  ];
  dataSourceMulti: MatTableDataSource<CitationList>;

  dataListMulti: [] = [];
  // multi Select
  selection = new SelectionModel<CitationList>(true, []);

  // filterStatusList = [{ id: 'Opened', name: 'Opened' }, { id: 'Settled', name: 'Settled' }, { id: 'Voided', name: 'Voided' }];
  filterStatusList = [
    { id: 'All', name: 'All' },
    { id: 'Opened', name: 'Opened' },
    { id: 'Settled', name: 'Settled' },
    { id: 'Voided', name: 'Voided' },
  ];
  selectedfilterStatusId: string[] = [];

  // filterStatusList = ['Opened', 'Settled', 'Voided'];
  /* filters*/
  today = new Date();
  firstDayOfMonth = new Date(
    this.today.getFullYear(),
    this.today.getMonth(),
    1
  );
  selectedDateRange: any[] = [this.firstDayOfMonth, this.today]; // initialize with first day of current month to today

  filterFromDate;
  filterToDate;

  operations: SelectDropdownIdName[] = [];
  selectedOperationId: any;

  countries: any = [];
  selectedCountryId: any;

  enforcerName: any;
  citationNumber: string = '';
  street: string = '';
  plateNumber: string = '';
  violationId: string = '';
  // enforcer name  //

  /** control for the selected enforcerName */
  filtersFormCtrlEnforcerName = new FormControl();

  /** control for the MatSelect filter keyword */
  enforcerNameFilterSearchCtrl: FormControl = new FormControl();

  filteredEnforcerData: any[] = [];
  /** list of banks filtered by search keyword */
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // end enforcer name  //

  // violation selection filter //

  /** control for the selected enforcerName */
  filtersFormCtrlViolation = new FormControl();

  /** control for the MatSelect filter keyword */
  violationFilterSearchCtrl: FormControl = new FormControl();
  filteredViolationData: any[] = [];
  /** list of banks filtered by search keyword */
  filteredViolationOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  // end enforcer name  //

  // End violation selection filter //

  // violationList = [];
  // filtersFormCtrlViolation = new FormControl();
  // filteredViolationOptions: Observable<string[]>;

  ///////////
  workforceOpts = [
    { id: '', name: 'All' },
    { id: 'actionBy', name: 'Settled By' },
    { id: 'enforcerName', name: 'Issued By' },
  ];
  selectedWorkforceOptId: string = '';
  citationPlateNumber?: string;
  isExpanded: boolean = false;
  constructor(
    private _router: Router,
    private _citationService: CitationService,
    public authorizationService: AuthorizationService,
    public dialog: MatDialog,
    private _dialog: MatDialog,
    private _citationReportListService: CitationReportListService,
    private _cacheService: CacheFiltersCitationService,
    private changeDetector: ChangeDetectorRef,
    private _activatedRoute: ActivatedRoute
  ) {
    this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
    this.citationPlateNumber = this._activatedRoute.snapshot.params.plateNumber;
    console.log(this.citationPlateNumber);
  }

  ngOnInit(): void {
    if (this.citationPlateNumber) {
      this.plateNumber = this.citationPlateNumber;
      this.selectedfilterStatusId = ['Opened'];
      this.isExpanded = true;
      this.isListMultiSelect = true;
      this.loadOpenedCitationWithPlateNumber(this.citationPlateNumber);
    }
    // load cache
    else {
      const cachedFilters = this._cacheService.cachedValue;
      this._loadCachedToFilters(cachedFilters);
    }

    this._loadOperationList();
    this._loadVehicleCountriesList();

    this.enforcerNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map((search) => {
          return this.filteredEnforcerData.filter(
            (item) =>
              item.name
                .toLowerCase()
                .indexOf(
                  this.enforcerNameFilterSearchCtrl.value.toLowerCase()
                ) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        (filtered) => {
          this.filteredEnforcerOptions.next(filtered);
        },
        (error) => {}
      );

    this.violationFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map((search) => {
          return this.filteredViolationData.filter(
            (item) =>
              item.arName
                .toLowerCase()
                .indexOf(this.violationFilterSearchCtrl.value.toLowerCase()) >
                -1 || item.id == this.violationFilterSearchCtrl.value
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(
        (filtered) => {
          this.filteredViolationOptions.next(filtered);
        },
        (error) => {}
      );
    this._loadGetEmployeesByNameOrUserNameOrId();
    this._loadGetViolationsList();
  }

  clearEnforcer() {
    this.singleSelect.options.forEach((data: MatOption) => data.deselect());
    this.enforcerName = '';
  }

  clearViolation() {
    this.violationSelect.options.forEach((data: MatOption) => data.deselect());
    this.violationId = '';
  }

  private _loadCitationList(
    offset: number,
    limit: number,
    sortBy: string,
    sortDirection: string,
    status = '',
    query: string = '',
    enforcerName: string = '',
    citationNumber: string = '',
    street: string = '',
    plateNumber: string = '',
    selectedOperationId: string = '',
    selectedCountryId: string = '',
    fromDate: string = '',
    toDate: string = '',
    violationId: string = '',
    selectedWorkforceOptId: string = ''
  ) {
    this.loading = true;

    this._citationService
      .getList(
        offset,
        limit,
        sortBy,
        sortDirection,
        status,
        query,
        enforcerName,
        citationNumber,
        street,
        plateNumber,
        selectedOperationId,
        selectedCountryId,
        fromDate,
        toDate,
        violationId,
        selectedWorkforceOptId
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          this.dataList = resp.payload.content;
          // console.log('resp.payload', resp.payload);
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<CitationList>(this.dataList);

          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    if (filterValue.length >= 1) {
      this.isListMultiSelect = true;
      this.loading = true;
      this._citationService
        .getList(
          0,
          this.pageSize,
          this.sortBy,
          this.sortDirection,
          '',
          filterValue
        )
        .pipe(first())
        .subscribe(
          (resp) => {
            this.dataListMulti = resp.payload.content;
            this.totalRows = resp.payload.totalElements;
            this.dataSourceMulti = new MatTableDataSource<CitationList>(
              this.dataListMulti
            );
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            console.log(' err ===== ', error);
          }
        );
    } else if (!filterValue.length) {
      this.isListMultiSelect = false;
      this.currentPage = 0;
      this._loadCitationList(0, this.pageSize, this.sortBy, this.sortDirection);
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.dataSource.filter = this.searchKey;
    this.isListMultiSelect = false;
    this.currentPage = 0;
    this._loadCitationList(0, this.pageSize, this.sortBy, this.sortDirection);
  }
  onControl(row) {
    // console.log('onControl(row)', row);
    // this._router.navigate(['/citation-control'], {
    //   state: row
    // });
    this._router.navigateByUrl(`/citation-control/${row.id}`);
  }

  onView(row) {
    this._router.navigateByUrl(`/citation-settled-view/${row.id}`);
  }
  onShowImage(row) {
    // console.log('onShowImage(row)', row);
    const dialogRef = this.dialog.open(ShowImagesDialog, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '960px',
      height: '700px',
      data: row.images,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
    });

    //this._router.navigateByUrl(`//${row.id}`);
  }

  onSettle(row) {
    // console.log('onSettle(row)', row);
    this._router.navigateByUrl(`/citation-settle/${row.id}`);
  }

  onVoid(row) {
    // console.log('onVoid(row)', row);
    this._router.navigateByUrl(`/citation-void/${row.id}`);
  }

  pageChanged(event: PageEvent) {
    console.log('pageChanged event', event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;

    console.log('pageChanged this.pageSize', this.pageSize);
    console.log('pageChanged this.currentPage', this.currentPage);
    //change here
    const statusList = this._getFilterStatusList();
    console.log('get status list pageChanged statusList', statusList);

    this._loadCitationList(
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDirection,
      statusList,
      '',
      this.enforcerName,
      this.citationNumber,
      '',
      this.plateNumber,
      this.selectedOperationId,
      this.selectedCountryId,
      this.filterFromDate,
      this.filterToDate,
      this.violationId,
      this.selectedWorkforceOptId
    );

    // this._loadCitationList(this.currentPage, this.pageSize, this.sortBy, this.sortDirection);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.

    //id  ,plateNumberEn, enName
    console.log('sortState', sortState);
    this.sortBy = sortState.active;
    // this.sortDirection = sortState.direction;
    this.sortDirection = this.sortDirection === 'desc' ? 'asc' : 'desc';

    // this._loadCitationList(this.currentPage, this.pageSize, this.sortBy, this.sortDirection);
    const statusList = this._getFilterStatusList();

    this._loadCitationList(
      this.currentPage,
      this.pageSize,
      this.sortBy,
      this.sortDirection,
      statusList,
      '',
      this.enforcerName,
      this.citationNumber,
      '',
      this.plateNumber,
      this.selectedOperationId,
      this.selectedCountryId,
      this.filterFromDate,
      this.filterToDate,
      this.violationId,
      this.selectedWorkforceOptId
    );
  }

  // multi Select

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (!this.dataSource) return false;
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceMulti ? this.dataSourceMulti.data.length : 0;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSourceMulti.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CitationList): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }

  onControlCitations(): void {
    if (!this.selection['_selected'].length) return;
    let ids = '';
    console.log('_selected list', this.selection['_selected']);
    this.selection['_selected'].map((item) => {
      if (item.status === 'Opened')
        ids.length ? (ids += `,${item.id}`) : (ids = `${item.id}`);
    });
    if (ids.length)
      this._router.navigateByUrl(`/citation-control-multi/${ids}`);
    else {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: 'Attention', color: '#D93636' },
          body: 'There is no opened citation selected',
          btnConfirmation: { hide: true, title: 'Confirm', color: '#D93636' },
          btnClose: { title: 'close' },
          comment: false,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }
  }
  onCancelSearch(): void {
    this.onSearchClear();
    console.log('onControlCitations');
  }

  onApplyMultipleFilter(event) {
    // console.log("parent onFilter event", event.value);
    let statusList;
    statusList = event.value.filter((item) => item == 0);
    if (statusList.length == 0) {
      statusList = event.value.reduce((previousValue, currentValue) => {
        return `${previousValue},${currentValue}`;
      });
    } else {
      statusList = 'Opened,Settled,Voided';
    }
    // console.log("statusList", statusList);
    this.currentPage = 0;
    this._loadCitationList(
      0,
      this.pageSize,
      this.sortBy,
      this.sortDirection,
      statusList
    );
  }

  onDisplayHideColumn(event) {
    // debugger
    this.displayedColumnsShow = event.value;
  }
  isHiddenColumn(column) {
    if (this.displayedColumnsShow) {
      const found = this.displayedColumnsShow.filter((item) => item == column);
      return found.length ? false : true;
    } else {
      console.log('isHiddenColumn => Any action');
    }
  }
  clearSelected() {
    console.log('parent clearSelected fired');

    // this.roles.value = null
  }
  clearSelectedColumns() {
    this.displayedColumnsShow = this.displayedColumns;
  }
  /** filters */
  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }

  private _dateFormat(d) {
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  onApplyFilters() {
    console.log(
      'onApplyFilters filtersFormCtrlEnforcerName',
      this.filtersFormCtrlEnforcerName.value
    );
    // console.log("onApplyFilters enforcerNameFilterSearchCtrl", this.enforcerNameFilterSearchCtrl.value);

    if (this.filtersFormCtrlEnforcerName.value)
      this.enforcerName = this.filtersFormCtrlEnforcerName.value;
    if (this.filtersFormCtrlViolation.value)
      this.violationId = this.filtersFormCtrlViolation.value;

    console.log('onApplyFilters this.violationId', this.violationId);

    const statusList = this._getFilterStatusList();
    console.log(
      'onApplyFilters selectedWorkforceOptId',
      this.selectedWorkforceOptId
    );

    this._setFiltersCache();
    this.currentPage = 0;
    this._loadCitationList(
      0,
      this.pageSize,
      this.sortBy,
      this.sortDirection,
      statusList,
      '',
      this.enforcerName,
      this.citationNumber,
      '',
      this.plateNumber,
      this.selectedOperationId,
      this.selectedCountryId,
      this.filterFromDate,
      this.filterToDate,
      this.violationId,
      this.selectedWorkforceOptId
    );
  }

  onClearFilters() {
    // reset filters
    this.filterFromDate = undefined;
    this.filterToDate = undefined;
    this.selectedDateRange = [];
    this.selectedOperationId = this.operations[0].id;
    if (this.countries.length) this.selectedCountryId = this.countries[0].id;

    this.filtersFormCtrlEnforcerName.setValue('');
    this.enforcerName = '';
    this.filteredEnforcerOptions.next([]);

    this.filtersFormCtrlViolation.setValue('');
    this.violationId = '';
    this.filteredViolationOptions.next([]);

    this.citationNumber = '';
    this.street = '';
    this.plateNumber = '';
    this.selectedfilterStatusId = [];
    this._cacheService.clear();
    this.currentPage = 0;
    // reset table data list to empty
    this.dataList = [] as any;
    this.totalRows = 0;
    this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
    // this._loadCitationList(
    //   0,
    //   this.pageSize,
    //   this.sortBy,
    //   this.sortDirection,
    //   '',
    //   '',
    //   this.enforcerName,
    //   this.citationNumber,
    //   '',
    //   this.plateNumber,
    //   this.selectedOperationId,
    //   this.selectedCountryId,
    //   this.filterFromDate,
    //   this.filterToDate,
    //   this.violationId,
    //   this.selectedWorkforceOptId
    // );
  }

  get validSearch() {
    // return (this.selectedOperationId ||
    //   (this.selectedDateRange[0] && this.selectedDateRange[1]) ||
    //   this.selectedCountryId ||
    //   this.enforcerName ||
    //   this.citationNumber ||
    //   this.street ||
    //   this.plateNumber
    // ) ? true : false;
    return true;
  }

  private _loadOperationList() {
    if (this.operations.length > 1) return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService
      .getOperationList()
      .pipe(first())
      .subscribe(
        (resp) => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  private _loadVehicleCountriesList() {
    if (this.countries.length > 1) return false;

    this.loading = true;
    this.countries = [{ id: 0, enName: 'All' }];

    this._citationService
      .getVehicleCountries()
      .pipe(first())
      .subscribe(
        (resp) => {
          this.countries = this.countries.concat(resp.payload.content);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(' err ===== ', error);
        }
      );
  }

  private _loadGetEmployeesByNameOrUserNameOrId(keyword: string = '') {
    if (this.filteredEnforcerData.length > 1) return false;

    this.loading = true;
    this._citationService
      .getEmployeesByNameOrUserNameOrId(keyword)
      .pipe(first())
      .subscribe(
        (resp) => {
          this.filteredEnforcerData = resp.payload;
          this.filteredEnforcerOptions.next(this.filteredEnforcerData);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  private _loadGetViolationsList(keyword: string = '') {
    if (this.filteredViolationData.length > 1) return false;

    this.loading = true;
    this._citationService
      .getViolationsList(keyword)
      .pipe(first())
      .subscribe(
        (resp) => {
          this.filteredViolationData = resp.payload.content;
          this.filteredViolationOptions.next(this.filteredViolationData);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  getActionBy(actionBy) {
    return actionBy ? actionBy : 'N/A';
  }

  // filters
  private _filter(value: string, type: string) {
    const filterValue = value; //.toLowerCase();
    console.log('type=', type, ',filterValue=', filterValue);
    if (filterValue.length >= 3) {
      switch (type) {
        case 'enforcerName':
          this._loadGetEmployeesByNameOrUserNameOrId(filterValue);
          break;
        case 'violation':
          this._loadGetViolationsList(filterValue);
          break;
      }
    }
    return;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // deprecated
  public downloadCSVOld() {
    const data = this.dataList.map((item) => {
      return {
        createdDate: this.formatDate(item['createdDate']),
        plateNumberEn: item['plateNumberEn'],
        status: item['status'],
        violationName: item['violationName'],
        violationCode: item['violationId'],
        id: item['id'],
        feesAmount: item['amount'],
        operationArea: item['operationName'],
        enforcerName: item['enforcerName'],
        country: item['country'] ? item['country'].enName : '',
        settledDate: this.formatDate(item['modifiedDate']),
        actionBy: item['actionBy'] ? item['actionBy'] : '',
      };
    });
    new ngxCsv(data, 'Citations', {
      headers: [
        'Citation Date',
        'Car Plate',
        'Status',
        'Violation Description',
        'Violation Code',
        'Citation Number',
        'Fees Amount',
        'Operation Area',
        'Issued By',
        'Country',
        'Settled Date',
        'Settled By',
      ],
    });
  }

  public downloadCSV() {
    if (this.totalRows > 40000) {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: 'Attention', color: '#D93636' },
          body: 'Too Much Records',
          btnConfirmation: { hide: true, title: 'Confirm', color: '#D93636' },
          btnClose: { title: 'close' },
          comment: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {});
      return false;
    }

    this.loading = true;
    const statusList = this._getFilterStatusList();
    this._citationService
      .downloadCSV(
        0,
        this.pageSize,
        this.sortBy,
        this.sortDirection,
        statusList,
        '',
        this.enforcerName,
        this.citationNumber,
        '',
        this.plateNumber,
        this.selectedOperationId,
        this.selectedCountryId,
        this.filterFromDate,
        this.filterToDate,
        this.violationId,
        this.selectedWorkforceOptId
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          this.loading = false;
          console.log('totalRows =========== ', this.totalRows);
          if (resp !== undefined) {
            const hiddenElement = document.createElement('a');
            // hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(resp);
            // hiddenElement.target = '_blank';
            // //provide the name for the CSV file to be downloaded
            // hiddenElement.download = 'citations.csv';

            hiddenElement.setAttribute(
              'href',
              `data:text/csv;charset=utf-8,%EF%BB%BF ${encodeURIComponent(
                resp
              )}`
            );
            hiddenElement.setAttribute('download', 'citations.csv');
            hiddenElement.style.visibility = 'hidden';
            hiddenElement.click();
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  private _setFiltersCache() {
    const cache = {
      selectedOperationId: this.selectedOperationId,
      filterFromDate: this.filterFromDate,
      filterToDate: this.filterToDate,
      selectedCountryId: this.selectedCountryId,
      enforcerName: this.enforcerName,
      citationNumber: this.citationNumber,
      plateNumber: this.plateNumber,
      violationId: this.violationId,
      statusList: this.selectedfilterStatusId,
      selectedWorkforceOptId: this.selectedWorkforceOptId,
    };
    console.log('set cache === ', cache);

    this._cacheService.set(cache);
  }

  private _loadCachedToFilters(cached) {
    if (!cached) {
      this.selectedOperationId = 0;
      this.selectedCountryId = 0;
      this.currentPage = 0;
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
      this._loadCitationList(
        0,
        this.pageSize,
        this.sortBy,
        this.sortDirection,
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        this.filterFromDate,
        this.filterToDate
      );
      return;
    }

    console.log('_load cached cached', cached);
    this.filtersFormCtrlEnforcerName.setValue(cached.enforcerName);
    this.filtersFormCtrlViolation.setValue(cached.violationId);

    if (cached.filterFromDate)
      this.selectedDateRange[0] = new Date(cached.filterFromDate);
    if (cached.filterToDate)
      this.selectedDateRange[1] = new Date(cached.filterToDate);

    this.selectedOperationId = cached.selectedOperationId;
    this.filterFromDate = cached.filterFromDate;
    this.filterToDate = cached.filterToDate;
    this.selectedCountryId = cached.selectedCountryId;
    this.enforcerName = cached.enforcerName;
    this.citationNumber = cached.citationNumber;
    this.plateNumber = cached.plateNumber;
    this.violationId = cached.violationId;
    this.selectedfilterStatusId = cached.statusList || [];
    console.log(this.selectedfilterStatusId);

    this.selectedWorkforceOptId = cached.selectedWorkforceOptId || '';

    this.onApplyFilters();
  }

  loadOpenedCitationWithPlateNumber(plateNumber: string) {
    // offset: number,
    // limit: number,
    // sortBy: string,
    // sortDirection: string,
    // status = '',
    // query: string = '',
    // enforcerName: string = '',
    // citationNumber: string = '',
    // street: string = '',
    // plateNumber: string = '',

    this.selectedOperationId = 0;
    this.selectedCountryId = 0;
    this.currentPage = 0;
    // this._loadCitationList(0, this.pageSize, this.sortBy, this.sortDirection, 'Opened', '', '', '', '', plateNumber);

    // const filterValue = (e.target as HTMLInputElement).value;
    // if (filterValue.length >= 1) {
    this.isListMultiSelect = true;
    this.loading = true;
    this._citationService
      .getList(
        0,
        this.pageSize,
        this.sortBy,
        this.sortDirection,
        'Opened',
        '',
        '',
        '',
        '',
        plateNumber
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          this.dataListMulti = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSourceMulti = new MatTableDataSource<CitationList>(
            this.dataListMulti
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(' err ===== ', error);
        }
      );
  }

  formatDate(d) {
    if (!d) return '';
    // const spDateTime = new Date(d).toISOString().split('T');
    // return `${spDateTime[0]} ${spDateTime[1].split('.')[0]}`
    d = new Date(d);
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const month =
      d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();

    const hours = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
    const minutes = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes();
    const seconds = d.getSeconds() < 10 ? `0${d.getSeconds()}` : d.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }
  onVoidVoid(row) {
    this._router.navigateByUrl(`/citation-void-void/${row.id}`);
  }
  onCitationDetails(row) {
    console.log('onShowImage(row)', row.id);
    const dialogRef = this.dialog.open(ShowCitationDetailsDialogComponent, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '1300px',
      height: '700px',
      data: row,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      this._router.navigateByUrl(`/enforcement-citations`);
    });
  }

  // ngAfterContentChecked(): void {
  //   this.changeDetector.detectChanges();
  // }

  private _getFilterStatusList() {
    let statusList = '';
    const all = this.selectedfilterStatusId.find((item) => item == 'All');
    if (all) return 'Opened,Settled,Voided';

    if (this.selectedfilterStatusId.length !== 0) {
      statusList = this.selectedfilterStatusId.reduce(
        (previousValue, currentValue) => {
          return `${previousValue},${currentValue}`;
        }
      );
    } else {
      statusList = 'Opened,Settled,Voided';
    }

    return statusList;
  }

  reloadDropdownFilter($event, load) {
    this[load]();
  }
}
