<section class="row g-0 align-items-center">
    <div class="col-12 row g-0">
        <div class="col-9">
            <h1 class="whitelist__title">Manage Whitelist</h1>
        </div>
        <div class="col-3 text-end">
            <button class="btn login__btn" *ngIf="authorizationService.check('Whitelist','Add')" (click)="onAddWhitelist()">Add a Vehicle to Whitelist</button>
        </div>
    </div>

    <div class="" *ngIf="successMsg">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>

  <div class="row text-center">

    <div class="col-2">
      <mat-form-field>
        <mat-label>Operation</mat-label>
        <mat-select [(value)]="selectedOperationId" formControlName="operation">
          <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div><!-- License Plate -->
    <div class="col-3">
    <button class="btn btn-primary" (click)="getWhitelist()">Search</button>
    </div>
  </div>

  <div class="row text-center">
    <!-- Manage Whitelist Search -->
    <mat-form-field appearance="fill" class="search-form-field manage-whitelist__search-bar">
      <input matInput placeholder="Search by vehicle user, and carplate" autocomplete="off" [(ngModel)]="searchKey" (keyup)="applyFilter()" />
      <mat-icon matPrefix>search</mat-icon>
      <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>

    <!-- Manage Whitelist Grid -->
    <div class="mat-elevation-z8 custom-table-card">
        <mat-table [dataSource]="dataSource" multiTemplateDataRows>
            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let element; let i = dataIndex">
                    {{element?.id}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="vehicleUser">
                <mat-header-cell *matHeaderCellDef>Vehicle User</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.whitelistDetails[0].userName }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="carPlate">
                <mat-header-cell *matHeaderCellDef>Car Plate</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.plateNumberEn }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="plateType">
                <mat-header-cell *matHeaderCellDef>Plate Type</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element?.type }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actions">
                <mat-header-cell class="justify-content-end" *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell class="justify-content-end" *matCellDef="let row">
                    <button mat-icon-button *ngIf="authorizationService.check('Whitelist','View')" (click)="onView(row)">
                        <!-- <mat-icon>remove_red_eye</mat-icon> -->
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12 9.5854C10.5462 9.5854 9.39198 10.676 9.39198 12.0045C9.39198 13.333 10.5462 14.4236 12 14.4236C13.4537 14.4236 14.608 13.333 14.608 12.0045C14.608 10.676 13.4537 9.5854 12 9.5854ZM7.85352 12.0045C7.85352 9.85263 9.71799 8.13086 12 8.13086C14.282 8.13086 16.1464 9.85263 16.1464 12.0045C16.1464 14.1564 14.282 15.8782 12 15.8782C9.71799 15.8782 7.85352 14.1564 7.85352 12.0045Z"
                                fill="#481A90" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.76005 9.32439C5.02627 6.00557 8.34919 4 12 4C15.651 4 18.9741 6.00576 21.2403 9.32487C21.7641 10.09 22 11.0669 22 12C22 12.9333 21.764 13.9104 21.2399 14.6756C18.9737 17.9944 15.6508 20 12 20C8.34901 20 5.02595 17.9942 2.75973 14.6751L3.406 14.2807L2.76038 14.6761C2.23609 13.9108 2 12.9335 2 12C2 11.0667 2.236 10.0896 2.76005 9.32439ZM12 5.45455C8.99087 5.45455 6.10663 7.10492 4.05227 10.1137L4.05162 10.1147C3.72688 10.5887 3.53846 11.2724 3.53846 12C3.53846 12.7276 3.72688 13.4113 4.05162 13.8853L4.05227 13.8863C6.10663 16.8951 8.99087 18.5455 12 18.5455C15.0091 18.5455 17.8934 16.8951 19.9477 13.8863L19.9484 13.8853C20.2731 13.4113 20.4615 12.7276 20.4615 12C20.4615 11.2724 20.2731 10.5887 19.9484 10.1147L19.9477 10.1137C17.8934 7.10492 15.0091 5.45455 12 5.45455Z"
                                fill="#481A90" />
                        </svg>
                    </button>

                    <button mat-icon-button color="warn" (click)="onDisable(row)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.7175 5.98993L5.98958 18.7178L5.28247 18.0107L18.0104 5.28282L18.7175 5.98993Z"
                                fill="#D93636" />
                            <circle cx="12" cy="12" r="9.25" stroke="#D93636" stroke-width="1.5" />
                        </svg>
                    </button>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20,50, 100]" showFirstLastButtons [length]="totalRows"
            showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize" (page)="pageChanged($event)">
        </mat-paginator>
        <mat-spinner *ngIf="loading"></mat-spinner>
    </div>

</section>
