import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { BlacklistService } from '../_services';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-blacklist-details',
  templateUrl: './blacklist-details.component.html',
  styleUrls: ['./blacklist-details.component.scss']
})
export class BlacklistDetailsComponent implements OnInit {

  title: string = "Add a Vehicle to Blacklist";
  id;
  details:any[];
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['plateType', 'country', 'make', 'model', 'color'];


  constructor(
    private location: Location,
    private blacklistService: BlacklistService,
    private _router: Router,
    private route: ActivatedRoute,
  ) {
    this.id = route.snapshot.paramMap.get('id');

   }

  ngOnInit(): void {
    this.getBlacklistDetails()
  }

  private getBlacklistDetails() {
    this.blacklistService.getDetails(this.id).pipe(first()).subscribe(res => {
      console.log(res.payload);
      this.details = [res.payload];
      this.dataSource = new MatTableDataSource<any>(this.details);
    })
  }

  goBack() {
    this.location.back();
  }

}
