<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <span class="on-back-arrow">
            <button mat-icon-button (click)="onBack()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.63415 19C9.44926 19 9.26437 18.9281 9.1184 18.7742L3.21165 12.5428C2.92945 12.2451 2.92945 11.7523 3.21165 11.4546L9.1184 5.22328C9.4006 4.92557 9.86769 4.92557 10.1499 5.22328C10.4321 5.52099 10.4321 6.01375 10.1499 6.31146L4.75889 11.9987L10.1499 17.686C10.4321 17.9837 10.4321 18.4764 10.1499 18.7742C10.0137 18.9281 9.81904 19 9.63415 19Z"
                        fill="#6B757E" />
                    <path
                        d="M20.2702 12.7684H3.89279C3.49382 12.7684 3.16296 12.4193 3.16296 11.9985C3.16296 11.5776 3.49382 11.2285 3.89279 11.2285H20.2702C20.6691 11.2285 21 11.5776 21 11.9985C21 12.4193 20.6691 12.7684 20.2702 12.7684Z"
                        fill="#6B757E" />
                </svg>
            </button>
        </span>
        <div class="header-title">
            <h1><strong>{{title}}</strong></h1>
        </div>

        <div class="breadcrumb-content">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.0014 13.9199C12.3481 13.9199 12.6292 14.206 12.6292 14.5589V17.115C12.6292 17.4679 12.3481 17.754 12.0014 17.754C11.6547 17.754 11.3736 17.4679 11.3736 17.115V14.5589C11.3736 14.206 11.6547 13.9199 12.0014 13.9199Z"
                    fill="#39B2E5" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.0096 3.69085C14.0098 3.69105 14.0101 3.69124 14.0103 3.69144L19.8118 8.41202C20.2417 8.7644 20.5639 9.28047 20.7611 9.80664C20.9584 10.333 21.0559 10.9367 20.9668 11.4921L20.9664 11.4945L19.8531 18.2766L19.8528 18.2785C19.6007 19.7859 18.1933 21 16.6891 21H7.31371C5.80176 21 4.40185 19.7949 4.14992 18.2778L3.03705 11.4984C3.03693 11.4977 3.03681 11.4969 3.03669 11.4962C2.94225 10.9391 3.03779 10.3339 3.23543 9.80664C3.4332 9.27902 3.75825 8.76258 4.19312 8.41034C4.19346 8.41006 4.1938 8.40979 4.19415 8.40951L9.99307 3.68247C11.1109 2.77037 12.8855 2.77187 14.0096 3.69085ZM13.2237 4.6876C12.5565 4.14177 11.4352 4.14363 10.7786 4.67953L4.97546 9.40999C4.75792 9.58599 4.54759 9.89136 4.40858 10.2622C4.26955 10.6331 4.22695 11.0026 4.27421 11.2799L4.27488 11.2838L5.388 18.0648C5.38803 18.0649 5.38797 18.0646 5.388 18.0648C5.53811 18.9672 6.41501 19.722 7.31371 19.722H16.6891C17.5787 19.722 18.4646 18.9599 18.6148 18.065C18.6148 18.0647 18.6149 18.0644 18.6149 18.064L19.7276 11.2862C19.7276 11.2858 19.7277 11.2854 19.7278 11.285C19.7723 11.0056 19.7274 10.6342 19.588 10.2622C19.4484 9.88992 19.2394 9.58406 19.025 9.40808C19.0248 9.40788 19.0245 9.40768 19.0243 9.40747L13.225 4.68865L13.2237 4.6876Z"
                    fill="#39B2E5" />
            </svg>
            <nav aria-label="breadcrumb" class="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="href" (click)="onBack()">Violations</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
                </ol>
            </nav>
        </div>
    </div>


    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col-md-6 col-sm-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Name</mat-label>
                    <input class="w-100" matInput formControlName="enName" placeholder="enter violation name">
                </mat-form-field>
                <mat-error *ngIf="myError('enName', 'required')">Name is required</mat-error>
                <mat-error *ngIf="myErrorWithoutSubmit('enName', 'unavailable')">Name is already exists</mat-error>
                <mat-error *ngIf="myError('enName', 'pattern')">Name in english accepts only arabic letters</mat-error>

            </div>
            <div class="col-md-6 col-sm-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Name in arabic</mat-label>
                    <input class="w-100" matInput formControlName="arName" placeholder="اكتب اسم المخالفة بالعربى">
                </mat-form-field>
                <mat-error *ngIf="myError('arName', 'required')">Name in arabic is required</mat-error>
                <mat-error *ngIf="myError('arName', 'pattern')">Name in arabic accepts only arabic letters</mat-error>
                <mat-error *ngIf="myErrorWithoutSubmit('arName', 'unavailable')">Name in arabic is already exists
                </mat-error>

            </div>
            <div class="col-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="enDescription" placeholder="enter violation description"
                        class="w-100" style="height: 150px;"></textarea>
                </mat-form-field>
                <mat-error *ngIf="myError('enDescription', 'required')">Description is required</mat-error>
                <mat-error *ngIf="myError('enDescription', 'pattern')">Description in english accepts only arabic
                    letters</mat-error>

            </div>
            <div class="col-12">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Description in arabic</mat-label>
                    <textarea matInput formControlName="arDescription"
                        placeholder="enter violation description in arabic" class="w-100"
                        style="height: 150px;"></textarea>
                </mat-form-field>
                <mat-error *ngIf="myError('arDescription', 'required')">Description is required</mat-error>
                <mat-error *ngIf="myError('arDescription', 'pattern')">Description in arabic accepts only arabic letters
                </mat-error>
            </div>
        </div>
        <mat-spinner *ngIf="loading"></mat-spinner>
        <section *ngIf="type!='view'" class="row g-0 justify-content-end">
            <div class="col-6 px-2 text-end">
                <button class="btn login__btn full-width" [disabled]="loading">Save</button>
            </div>
        </section>
    </form>
</section>