import { CommonModule } from '@angular/common';
import {
  NgModule,
} from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedDirectiveModule } from '../_directives/shared-directive.module';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { MatCarouselModule } from '@ngmodule/material-carousel';
import { FilterListComponent } from './filter-list/filter-list.component';
import { MultiSelectItemsFromListComponent } from './multi-select-items-from-list/multi-select-items-from-list.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    SharedDirectiveModule,
    NgxIntlTelInputModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    MatDatepickerModule,
    MatDialogModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    MatCarouselModule
  ],
  declarations: [FilterListComponent, MultiSelectItemsFromListComponent],
  exports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    SharedDirectiveModule,
    NgxIntlTelInputModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    MatDatepickerModule,
    MatDialogModule,
    MatRadioModule,
    MatExpansionModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatToolbarModule,
    MatAutocompleteModule,
    NgxMatSelectSearchModule,
    FilterListComponent,
    MultiSelectItemsFromListComponent,
    MatCarouselModule
  ]
})
export class SharedModule { }