import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // title = 'eden-tech';
  //   _opened: boolean = false;
  dark: boolean = true;
  active: boolean = true;
  events: string[] = [];
  opened: boolean = true;

  //   toggleSideBar() {
  //     console.log(this._opened);
  //     this._opened = !this._opened;
  //   }
  activatedLink(e) {
    // console.log(e);
    // this.dark = true;
    // this.active = true;
  }
}
