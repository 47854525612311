import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { DetentionRequestModel, DetentionService, ReleaseDetentionRequestModel } from '../detention.service';

@Component({
  selector: 'app-release-vehicles',
  templateUrl: './release-vehicles.component.html',
  styleUrls: ['./release-vehicles.component.scss']
})
export class ReleaseVehiclesComponent implements OnInit {

  dialogRef: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('releaseDetentionOrderDialog') releaseDetentionOrderDialog: TemplateRef<any>;

  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<DetentionRequestModel>;
  loading = false;
  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  expandedElement: any;
  displayedColumns: string[] = ['vehicleInfo', 'parkingSlot', 'date', 'actions'];
  dataList: any[];

  sortingBy: string = 'detention_order_id';
  sortingType: any = 'asc';
  operationId;
  failedMsg: string = "";
  successMsg: string = ""
  detentionOrder: any;
  releaseOrderForm: FormGroup;
  currentUserJson: any;
  statusList: any[] = [{ name: 'ACCEPTED' }, { name: 'READY_TO_RELEASE' }]
  releaseToValue: string;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _service: DetentionService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog

  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this.releaseOrderForm = new FormGroup({
      releaseTo: new FormControl(null, [
        Validators.maxLength(50),
      ]),
      name: new FormControl(null),
      desc: new FormControl(null)
    });
    this._loadList(this.operationId, 0, this.pageSize);
    this.successMsg = this._activatedRoute.snapshot.queryParams['success'];
    setTimeout(() => {
      this.successMsg = "";
      this._router.navigate(
        ['.'],
        { relativeTo: this._activatedRoute, queryParams: {} }
      );
    }, 5000);

  }

  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }

  _loadList(operationId: number, page: number, limit: number, query: string = '', sortingType: any = this.sortingType, sortingBy: string = this.sortingBy) {
    this.loading = true;
    if (sortingType === 'asc') sortingType = 1;
    else sortingType = 0;

    this._service.getDetentionRequests(operationId, page, limit, '', sortingType, sortingBy, ['READY_TO_RELEASE', 'ACCEPTED'])
      .pipe(first())
      .subscribe(
        resp => {
          console.log(resp);

          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
          this.loading = false;


        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }


  openReleaseDetentionOrderDialog(row: any) {
    this.detentionOrder = row;
    this.dialogRef = this._dialog.open(this.releaseDetentionOrderDialog,
      {
        width: "400px",
        disableClose: true,
      });
    this.dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        this._loadList(this.operationId, 0, this.pageSize);
      }
    });
  }

  releaseDetentionOrder() {
    let releasedToPerson: string;
    if (this.releaseOrderForm.value.name && this.releaseOrderForm.value.desc) {
      releasedToPerson = `other-${this.releaseOrderForm.value.name}-${this.releaseOrderForm.value.desc}`;
    }
    if (this.releaseOrderForm.value.name && !this.releaseOrderForm.value.desc) {
      releasedToPerson = `other-${this.releaseOrderForm.value.name}`;
    } else if (this.releaseOrderForm.value.desc && !this.releaseOrderForm.value.name) {
      releasedToPerson = `other-${this.releaseOrderForm.value.desc}`;
    }
    // } else if (this.releaseOrderForm.value.name && this.releaseOrderForm.value.desc) {
    //   releasedToPerson = `other-${this.releaseOrderForm.value.name}-${this.releaseOrderForm.value.desc}`;
    // }
    let params: ReleaseDetentionRequestModel = {
      workforceId: this.currentUserJson.employeeDto.id,
      releaseTo: (this.releaseToValue != 'other') ? this.releaseOrderForm.value.releaseTo : releasedToPerson,
      description: '',
      detentionOrderId: this.detentionOrder.id
    }
    console.log(params);

    this._service.releaseDetentionRequest(params)
      .pipe(first())
      .subscribe(
        resp => {
          console.log(resp);
          this.dialogRef.close(resp);
          this.releaseOrderForm.controls.value = null;;
        },
        error => {
          console.log(" err ===== ", error);
        });
  }

  applyFilter(e: any) {
    let filterValue = e.value;
    if (filterValue[0] == 0) {
      filterValue = [];
      this.statusList.forEach(element => {
        filterValue.push(element.name)
      });
    }
    this._service.getDetentionRequests(this.operationId, 0, this.pageSize, '', this.sortingType, this.sortingBy, filterValue)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
        },
        error => {
          // console.log(" err ===== ", error);
        });
  }
  applySearch(e: any) {
    const filterValue = (e.target as HTMLInputElement).value;
    console.log(filterValue);
    this._service.getDetentionRequests(this.operationId, 0, this.pageSize, filterValue, this.sortingType, this.sortingBy, ['ACCEPTED'])
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
        },
        error => {
          // console.log(" err ===== ", error);
        });
  }
  onSearchClear() {
    this.searchKey = '';
    this._loadList(this.operationId, this.currentPage + 1, this.pageSize);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.operationId, this.currentPage, this.pageSize);
  }

  announceSortChange(sortState: Sort) {
    this.sortingType = sortState.direction;
    this.sortingBy = sortState.active;
    // console.log(this.sortingType);
    // console.log(this.sortingBy);
    this._loadList(this.operationId, this.currentPage + 1, this.pageSize);
  }

  onCancelSearch(): void {
    this.onSearchClear();
  }

  navigateToSettleCitation(plateNumber: string) {
    this._router.navigateByUrl(`enforcement-citations/${plateNumber}`)

  }
}

