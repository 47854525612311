<section class="row g-0 align-items-center mb-3">
  <div class="col-12 row g-0 mb-3">
    <div class="col-md-9 col-sm-12">
      <h1><strong>Handover Equipment</strong></h1>
    </div>
  </div>

  <div class="" *ngIf="successMsg">
    <div class="alert success-alert" role="alert">
      {{ successMsg }}
    </div>
  </div>
  <div class="" *ngIf="failedMsg">
    <div class="alert danger-alert" role="alert">
      {{ failedMsg }}
    </div>
  </div>

  <!-- Search Box-->
  <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
    <input matInput placeholder="Search by workforce name or ID" autocomplete="off" [(ngModel)]="searchKey"
      (keyup)="applySearch($event)" />
    <mat-icon matPrefix>search</mat-icon>

    <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <!-- Search Box-->
  <section class="mb-5">
    <!-- List Grid -->
    <div class="mat-elevation-z8 custom-table-card mb-5">
      <mat-table
        [dataSource]="dataSource"
        matSort
        (matSortChange)="announceSortChange($event)"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Workforce</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.employeeName
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >ID</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{
            element.employeeId
          }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="custody">
          <mat-header-cell *matHeaderCellDef>Custody</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.custody ? "Yes" : "No" }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button class="actionBtn" (click)="openEquipmentHandoverDialog(row)">
              <img src="../../../assets/Icons/eye.svg" class="mx-1" alt="details" />
            </button>
            <button
              mat-icon-button
              class="actionBtn"
              (click)="onHandoverEquipment(row.employeeId,row.employeeName)"
            >
              <img
                src="../../../assets/Icons/add-equipment.svg"
                class="mx-1"
                alt="add equipment"
              />
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
          [class.expanded-row]="row.isExpanded">
        </mat-row>
      </mat-table>
      <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons
        [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
        (page)="pageChanged($event)">
      </mat-paginator>
    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </section>

  <ng-template #equipmentHandoverDialog>
    <div class="h-100">
      <div class="row mb-2">
        <div class="col-12 row g-0 px-1 align-items-center">
          <span class="d-flex justify-content-between">
            <div class="print-citation__title">
              <h4 class="fw-bold h3">Custody Details</h4>
            </div>
            <div class="cross">
              <mat-icon class="close" (click)="dialogRef.close(null)">close</mat-icon>
            </div>
          </span>
        </div>
      </div>

      <div>
        <div class="pb-1 mb-0" dialog-content>
          <div class="mat-dialog-actions d-flex justify-content-between">
            <section class="mb-5 w-100">
              <!-- List Grid -->
              <div class="mat-elevation-z8 custom-table-card mb-5">
                <mat-table
                  [dataSource]="selectedRow.equipmentList"
                  multiTemplateDataRows
                >
                  <ng-container matColumnDef="equipment">
                    <mat-header-cell *matHeaderCellDef
                      >Equipment</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">
                      {{ element.category }}</mat-cell
                    >
                  </ng-container>

                  <ng-container matColumnDef="serialNumber">
                    <mat-header-cell *matHeaderCellDef
                      >Serial No.</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{
                      element.serialNumber
                    }}</mat-cell>
                  </ng-container>

                  <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef
                      >Description</mat-header-cell
                    >
                    <mat-cell *matCellDef="let element">{{
                      element.description
                    }}</mat-cell>
                  </ng-container>

                  <mat-header-row
                    *matHeaderRowDef="equipmentTableColumns"
                  ></mat-header-row>

                  <mat-row
                    *matRowDef="let row; columns: equipmentTableColumns"
                    class="element-row"
                    [class.expanded-row]="row.isExpanded"
                  >
                  </mat-row>
                </mat-table>
              </div>
              <mat-spinner *ngIf="loading"></mat-spinner>
            </section>

            <button
              class="btn col-5 cancelBtn text-white rounded"
              mat-button
              mat-dialog-close
              (click)="dialogRef.close(null)"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>