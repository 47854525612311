<section>
    <section class="row mb-5">
        <div class="col-12 row g-0 mb-3 align-items-center">
            <span class="col-1">
                <img class="pointer back-btn" src="../../assets/Icons/arrow-left-2.svg" (click)="goBack()" />
            </span>
            <span class="col-11">
                <div class="title"> {{title}}</div>
            </span>
        </div>
    </section>

    <!-- <section>
        <div class="" *ngIf="created">
            <div class="alert success-alert" role="alert">
                {{ createdMsg }}
            </div>
        </div>
        <div class="" *ngIf="failed">
            <div class="alert danger-alert" role="alert">
                {{ createdMsg }}
            </div>
        </div>
    </section> -->

    <form class="form-group" [formGroup]="form">
        <section class="row g-0 mb-4">
            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Vehicle User</mat-label>
                    <input matInput formControlName="userName" id="vehicle-user" placeholder="enter vehicle user name">
                </mat-form-field>
                <mat-error *ngIf="myError('userName', 'required')">
                    Vehicle User is Required
                </mat-error>
            </div>

            <div class="col-6 px-2">
                <div fxLayout="row">
                    <mat-form-field class="w-50" appearance="fill">
                        <input matInput placeholder="eg:1234" formControlName="plateNumber">
                    </mat-form-field>

                    <mat-form-field class="w-50" appearance="fill">
                        <input matInput placeholder="eg:ABC" formControlName="plateText">
                    </mat-form-field>
                </div>
                <mat-error *ngIf="myError('plateNumber', 'required')">
                    Plate Number is Required
                </mat-error>
            </div>

            <div class="col-12">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" class="w-100" style="height: 150px;"
                        placeholder="enter any additional notes and/or description"></textarea>
                </mat-form-field>
                <mat-error *ngIf="myError('description', 'required')">
                    Description is Required
                </mat-error>
            </div>
            <div class="col-12 mt-5">
                <h2 class="create-whitelist__subtitle">Vehicle Details</h2>
            </div>

            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" id="country" aria-required="true">
                        <mat-option *ngFor="let country of countries" [value]="country">{{country.enName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('country', 'required')">
                    Country is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Color</mat-label>
                    <mat-select formControlName="colorId" id="colorId" aria-required="true">
                        <mat-option *ngFor="let color of colors" [value]="color.id">{{color.enName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('colorId', 'required')">
                    Color is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Model</mat-label>
                    <input matInput formControlName="model" id="model" placeholder="enter model">
                </mat-form-field>
                <mat-error *ngIf="myError('model', 'required')">
                    Model is Required
                </mat-error>
            </div>

            <div class="col-6 px-2">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Plate Type</mat-label>
                    <mat-select formControlName="type" id="type" aria-required="true">
                        <mat-option *ngFor="let type of plateType" [value]="type">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('type', 'required')">
                    Plate Type is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Make</mat-label>
                    <mat-select formControlName="makeId" id="makeId" aria-required="true">
                        <mat-option *ngFor="let make of makes" [value]="make.id">{{make.enName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('makeId', 'required')">
                    Make is Required
                </mat-error>
            </div>

            <!-- <div class="col-6">
                <app-multi-select-items-from-list [title]="'Penalties'" [id]="'id'" [name]="'enName'"
                    [itemsList]="penaltiesDataList" (selectedItems)="onSelectedPenalities($event)">
                </app-multi-select-items-from-list>

            </div> -->
        </section>

        <section class="row g-0 justify-content-end my-2">
            <div class="col-12 col-md-6 px-2 text-end">
                <button class="btn login__btn w-100" (click)="onSubmit()">Save</button>
            </div>
        </section>
    </form>
</section>