<section class="create-shift" *ngIf="!next">
    <section class="row">
        <div class="col-12 row g-0 mb-3 align-items-center">
            <span class="col-1">
                <img class="pointer back-btn" src="../../assets/Icons/arrow-left-2.svg" (click)="goBack()" />
            </span>
            <span class="col-11">
                <div class="create-shift__title"> Create new shift </div>
            </span>
        </div>
        <div class="col-12">
            <!-- <ol class="breadcrumb">
                <li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
                    [class.active]="i===breadcrumbList.length-1">
                    <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
                        {{ item.name }}
                    </a>
                    <span *ngIf="i===breadcrumbList.length-1">{{ item.name }}</span>
                </li>
            </ol> -->
        </div>
    </section>


    <form class="example-form" [formGroup]="createShiftForm">
        <section class="row g-0 mb-5">
            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Shift Label</mat-label>
                    <input matInput formControlName="shiftName" id="shift-name" placeholder="enter shift label here"
                        required>
                    <mat-error *ngIf="createShiftForm.controls.shiftName.errors">
                        Shift Name is Required
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input matInput [min]="startMinDate" type="date" formControlName="startDate"
                        (ngModelChange)="endMinDate = $event">
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Start Time</mat-label>
                    <input matInput formControlName="startTime" [owlDateTimeTrigger]="dt3" [owlDateTime]="dt3">
                    <owl-date-time [pickerType]="'timer'" #dt3></owl-date-time>
                </mat-form-field>
                <mat-error *ngIf="myError('startTime', 'required')">Start Time is required</mat-error>
                <mat-error *ngIf="checkStartTimeValidation">
                    Start Time Must be Less Than End Time
                </mat-error>
            </div>

            <div class=" col-6 px-2">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Shift Role</mat-label>
                    <mat-select formControlName="userRole" id="role" aria-required="true"
                        aria-placeholder="select role">
                        <mat-option *ngFor="let role of roles" [value]="role">{{role}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input matInput type="date" [min]="endMinDate" formControlName="endDate">
                </mat-form-field>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>End Time</mat-label>
                    <input matInput formControlName="endTime" [owlDateTimeTrigger]="dt4" [owlDateTime]="dt4">
                    <owl-date-time [pickerType]="'timer'" #dt4></owl-date-time>
                </mat-form-field>
                <mat-error *ngIf="myError('endTime', 'required')">End Time is required</mat-error>
                <mat-error *ngIf="checkStartTimeValidation">Start Time Must be Less Than End Time</mat-error>
            </div>
        </section>
    </form>
    <section class="row g-0 justify-content-end">
        <div class="col-6 px-2 text-end">
            <button class="btn login__btn full-width" (click)="createNewShift()"
                [disabled]="!createShiftForm.valid || loading">Next</button>
        </div>
    </section>
</section>

<div *ngIf="next">
    <app-assign-shift-members [shiftData]="shiftData" [freeWorkforcesList]="freeWorkforcesList"
        [supervisorsList]="supervisorsList">
    </app-assign-shift-members>
</div>
<mat-spinner *ngIf="loading"></mat-spinner>