<div mat-dialog-content class="dialog-content">
    <div id="printcontent" class="print-content">
        <div class="centered">
            <img src="/assets/images/group72-inv-logo.png" alt="Logo" class="img-print">
        </div>
        <div class="centered header-desc">
            <p>{{data.company_name_ar}}</p>
            <p>{{data.company_name_en}}</p>
            <p style="font-weight: 800;">فاتورة ضريبية مبسطة</p>
            <p style="font-weight: 800;">Vat Invoice</p>
            <p>
                <span style="font-weight: 800;">Inv No: </span>
                <span>{{data.invoiceNumber}} </span>
                <span style="font-weight: 800;"> :رقم الفاتورة</span>
            </p>
            <p>
                <span style="font-weight: 800;">العنوان: </span>
              <span>{{data.address_ar}}</span>
            </p>

            <p>
                <span style="font-weight: 800;">Address:</span>
              <span>{{data.address_en}}</span>
            </p>

            <p>
                <span style="font-weight: 800;">Date: </span>
                <span>{{formatDateTime(data.invoiceDate)}}</span>
                <span style="font-weight: 800;"> :التاريخ</span>
            </p>
            <p>
                <span style="font-weight: 800;">Cashier: </span>
                <span>{{data.cashierName}}</span>
                <span style="font-weight: 800;"> :الكاشير</span>
            </p>

            <p>
                <span style="font-weight: 800;">Vat No: </span>
              <span>{{data.tax_number}}</span>
                <span style="font-weight: 800;"> :الرقم ضريبى</span>
            </p>

            <p>
                <span style="font-weight: 800;">CR: </span>
              <span>{{data.commercial_register_number}}</span>
                <span style="font-weight: 800;"> :السجل التجارى</span>
            </p>
            <p id="copy-name">(نسخة التاجر)</p>

        </div>

        <div>
            <div class="d-flex justify-content-center row "dir='ltr'>
                <div class="col-md-6">
                  <div class="brdr w-100"></div>
                  <div class=" plateNo mx-auto py-2 d-flex justify-content-center">
                    <p class="col-md-6 col-sm-12 my-0 text-center d-flex justify-content-between">
                      <span class="fw-bold mx-1 my-0">Plate No: </span>
                      <span>{{ data.plateNumberEn }} </span>
                      <span class="fw-bold mx-1 my-0">:لوحة رقم</span>
                    </p>
                  </div>
                  <div class="brdr w-100"></div>
                </div>
            
            </div>
            <div class="row my-1 d-flex justify-content-center" dir="rtl">
                <div class="col-md-6 tableItems mx-2 d-flex justify-content-center">
                  <table class="table table-borderless mb-2 text-center">
                    <thead>
                      <tr>
                        <th>صنف</th>
                        <th>الكمية</th>
                        <th>السعر</th>
                        <th>المجموع</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ data.items[0].itemName }}</td>
                        <td>{{ data.items[0].quantity }}</td>
                        <td>{{ data.items[0].amount }}</td>
                        <td>{{ data.items[0].totalAmount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            </div>
            <div class="row bookingSummary d-flex justify-content-center">
                <div class="booking-summary-container p-2 col-md-6" dir="rtl">
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>الخصم</p>
                    <p>{{ data.totalDiscount }} ر.س</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>طريقة الدفع</p>
                    <p>{{ data.paymentMethodId }}</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>رسوم إضافية لطريقة الدفع</p>
                    <p>{{ data.deductionPaymentMethodTotalAmount }} ر.س</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p class="mx-e">المجموع الفرعي</p>
                    <p class="mx-s">{{ data.totalAmount }} ر.س</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>نسبة ضريبة القيمة المضافة</p>
                    <p>{{ data.vat * 100 }}%</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>رسوم ضريبة القيمة المضافة</p>
                    <p>{{ data.totalVATAmount }} ر.س</p>
                  </div>
                  <div class="bookingItem d-flex justify-content-between px-1 fw-bold">
                    <p>المجموع</p>
                    <p>{{ data.totalAmountWithVAT }} ر.س</p>
                  </div>
                </div>
            </div>
        </div>
        <div class="row qrCodeContainer d-flex mb-2 h-100 justify-content-center">
            <div class="col-md-6 qrCode d-flex justify-content-center h-50">
                <ngx-qrcode [elementType]="elementType" width="200" height="200" [errorCorrectionLevel]="correctionLevel"
                    [value]="value" alt="Demo QR Code" cssClass="bshadow"></ngx-qrcode>
            </div>
        </div>
    </div>
</div>

<div class="text-center footer">

    <div>
        <button class="btn btn-confirm" (click)="onConfirm('نسخة العميل')">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#481A90" />
                <path
                    d="M22.3633 11.5352H21.8711V9.63672C21.8711 8.18283 20.6883 7 19.2344 7H12.7656C11.3117 7 10.1289 8.18283 10.1289 9.63672V11.5352H9.63672C8.18283 11.5352 7 12.718 7 14.1719V18.3906C7 19.8445 8.18283 21.0273 9.63672 21.0273H10.1289V23.418C10.1289 24.2903 10.8386 25 11.7109 25H20.2891C21.1614 25 21.8711 24.2903 21.8711 23.418V21.0273H22.3633C23.8172 21.0273 25 19.8445 25 18.3906V14.1719C25 12.718 23.8172 11.5352 22.3633 11.5352ZM11.1836 9.63672C11.1836 8.76439 11.8933 8.05469 12.7656 8.05469H19.2344C20.1067 8.05469 20.8164 8.76439 20.8164 9.63672V11.5352H11.1836V9.63672ZM20.8164 23.418C20.8164 23.7087 20.5798 23.9453 20.2891 23.9453H11.7109C11.4202 23.9453 11.1836 23.7087 11.1836 23.418V18.2148H20.8164V23.418ZM23.9453 18.3906C23.9453 19.263 23.2356 19.9727 22.3633 19.9727H21.8711V18.2148H22.1875C22.4787 18.2148 22.7148 17.9787 22.7148 17.6875C22.7148 17.3963 22.4787 17.1602 22.1875 17.1602H9.8125C9.52127 17.1602 9.28516 17.3963 9.28516 17.6875C9.28516 17.9787 9.52127 18.2148 9.8125 18.2148H10.1289V19.9727H9.63672C8.76439 19.9727 8.05469 19.263 8.05469 18.3906V14.1719C8.05469 13.2995 8.76439 12.5898 9.63672 12.5898H22.3633C23.2356 12.5898 23.9453 13.2995 23.9453 14.1719V18.3906Z"
                    fill="white" />
                <path
                    d="M17.4062 19.4102H14.5938C14.3025 19.4102 14.0664 19.6463 14.0664 19.9375C14.0664 20.2287 14.3025 20.4648 14.5938 20.4648H17.4062C17.6975 20.4648 17.9336 20.2287 17.9336 19.9375C17.9336 19.6463 17.6975 19.4102 17.4062 19.4102Z"
                    fill="white" />
                <path
                    d="M17.4062 21.6602H14.5938C14.3025 21.6602 14.0664 21.8963 14.0664 22.1875C14.0664 22.4787 14.3025 22.7148 14.5938 22.7148H17.4062C17.6975 22.7148 17.9336 22.4787 17.9336 22.1875C17.9336 21.8963 17.6975 21.6602 17.4062 21.6602Z"
                    fill="white" />
                <path
                    d="M11.5 13.7852H9.8125C9.52127 13.7852 9.28516 14.0213 9.28516 14.3125C9.28516 14.6037 9.52127 14.8398 9.8125 14.8398H11.5C11.7912 14.8398 12.0273 14.6037 12.0273 14.3125C12.0273 14.0213 11.7912 13.7852 11.5 13.7852Z"
                    fill="white" />
            </svg>
            Print Customer Copy
        </button>
        <button class="btn btn-confirm" (click)="onConfirm('نسخة التاجر')">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="16" cy="16" r="16" fill="#481A90" />
                <path
                    d="M22.106 15.3351C22.2701 15.3351 22.403 15.2021 22.403 15.0381V12.688C22.403 11.8202 21.697 11.1141 20.8292 11.1141H19.8107V6.29704C19.8107 6.19664 19.76 6.10302 19.6758 6.04821C19.5918 5.9934 19.4856 5.98476 19.3937 6.02528L17.8577 6.7031L16.3217 6.02528C16.2453 5.99157 16.1583 5.99157 16.0818 6.02528L14.5458 6.7031L13.0098 6.02528C12.9179 5.98476 12.8117 5.9934 12.7277 6.04821C12.6436 6.10302 12.5928 6.19664 12.5928 6.29704V11.1142H11.5739C10.706 11.1142 10 11.8202 10 12.6881V24.4261C10 25.294 10.706 26 11.5739 26H20.8292C21.6971 26 22.4031 25.294 22.4031 24.4261V16.2263C22.4031 16.0622 22.2701 15.9292 22.1061 15.9292C21.942 15.9292 21.809 16.0622 21.809 16.2263V24.4261C21.809 24.9664 21.3695 25.406 20.8292 25.406H11.5738C11.0335 25.406 10.594 24.9664 10.594 24.4261V12.688C10.594 12.1478 11.0336 11.7082 11.5738 11.7082H12.5928V12.3705H12.2508C12.0867 12.3705 11.9538 12.5035 11.9538 12.6675C11.9538 12.8315 12.0867 12.9645 12.2508 12.9645H20.1527C20.3168 12.9645 20.4497 12.8315 20.4497 12.6675C20.4497 12.5035 20.3168 12.3705 20.1527 12.3705H19.8107V11.7082H20.8291C21.3694 11.7082 21.809 12.1478 21.809 12.688V15.0381C21.809 15.2021 21.942 15.3351 22.106 15.3351ZM13.1869 12.3705V6.75276L14.4259 7.29953C14.5023 7.33324 14.5893 7.33324 14.6657 7.29953L16.2017 6.62171L17.7378 7.29953C17.8142 7.33324 17.9012 7.33324 17.9777 7.29953L19.2166 6.75276V12.3705H13.1869Z"
                    fill="white" />
                <path
                    d="M20.5334 15.4828C20.5334 14.9344 20.0872 14.4883 19.5389 14.4883H14.4231C14.259 14.4883 14.1261 14.6213 14.1261 14.7853C14.1261 14.9494 14.259 15.0823 14.4231 15.0823H19.5388C19.7596 15.0823 19.9393 15.262 19.9393 15.4828V16.2149C19.9393 16.4357 19.7596 16.6153 19.5388 16.6153H12.8656C12.6448 16.6153 12.4652 16.4357 12.4652 16.2149V15.4828C12.4652 15.262 12.6448 15.0823 12.8656 15.0823H13.2349C13.399 15.0823 13.5319 14.9494 13.5319 14.7853C13.5319 14.6213 13.399 14.4883 13.2349 14.4883H12.8656C12.3172 14.4883 11.8711 14.9344 11.8711 15.4828V16.2149C11.8711 16.7633 12.3173 17.2094 12.8656 17.2094H19.5388C20.0872 17.2094 20.5334 16.7633 20.5334 16.2149V15.4828H20.5334Z"
                    fill="white" />
                <path
                    d="M19.5388 18.0664H12.8656C12.3172 18.0664 11.8711 18.5125 11.8711 19.0609V23.2307C11.8711 23.7791 12.3173 24.2253 12.8656 24.2253H14.8572C14.8573 24.2253 14.8574 24.2253 14.8576 24.2253C14.8577 24.2253 14.8578 24.2253 14.858 24.2253H17.5466C17.5467 24.2253 17.5469 24.2253 17.547 24.2253C17.5472 24.2253 17.5473 24.2253 17.5474 24.2253H19.5389C20.0873 24.2253 20.5334 23.7791 20.5334 23.2307V19.0609C20.5334 18.5125 20.0872 18.0664 19.5388 18.0664ZM15.1546 21.7888V20.5029H17.2499V21.7888H15.1546ZM17.2499 22.3828V23.6312H15.1546V22.3828H17.2499ZM12.4652 20.5029H14.5606V21.7888H12.4652V20.5029ZM15.1546 19.9089V18.6605H17.2499V19.9089H15.1546ZM17.844 20.5029H19.9393V21.7888H17.844V20.5029ZM19.9393 19.0609V19.9089H17.844V18.6605H19.5389C19.7596 18.6605 19.9393 18.8401 19.9393 19.0609ZM12.8656 18.6605H14.5606V19.9089H12.4652V19.0609C12.4652 18.8401 12.6448 18.6605 12.8656 18.6605ZM12.4652 23.2307V22.3828H14.5606V23.6312H12.8656C12.6448 23.6312 12.4652 23.4516 12.4652 23.2307ZM19.5388 23.6312H17.844V22.3828H19.9393V23.2307C19.9393 23.4516 19.7596 23.6312 19.5388 23.6312Z"
                    fill="white" />
                <path
                    d="M18.3524 8.16406H14.0509C13.8869 8.16406 13.7539 8.29705 13.7539 8.46109C13.7539 8.62513 13.8869 8.75812 14.0509 8.75812H18.3524C18.5165 8.75812 18.6495 8.62513 18.6495 8.46109C18.6495 8.29705 18.5165 8.16406 18.3524 8.16406Z"
                    fill="white" />
                <path
                    d="M18.3524 9.64453H14.0509C13.8869 9.64453 13.7539 9.77752 13.7539 9.94156C13.7539 10.1056 13.8869 10.2386 14.0509 10.2386H18.3524C18.5165 10.2386 18.6495 10.1056 18.6495 9.94156C18.6495 9.77752 18.5165 9.64453 18.3524 9.64453Z"
                    fill="white" />
            </svg>
            Print Provider Copy
        </button>
    </div>
    <div>
        <button class="btn btn-cancel" (click)="close()">Back to Citations</button>
    </div>
</div>
