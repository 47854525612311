<section class="row g-0 align-items-center mb-3">
  <div class="col-12 row g-0 mb-3">
    <div class="col-12 row g-0 mb-3 align-items-center">
      <span class="col-1">
        <img
          class="pointer back-btn"
          src="../../assets/Icons/arrow-left-2.svg"
          (click)="goBack()"
        />
      </span>
      <span class="col-11">
        <h1 class="fw-bold">
          Add <span *ngIf="!workforceId">New </span> Equipment
        </h1>
      </span>
    </div>
  </div>

  <div class="equipmentData row my-3" *ngIf="workforceId">
    <div class="col-md-5">
      <p class="h6">Workforce Name</p>
      <p class="h4 fw-bolder">{{ workforceName }}</p>
    </div>
    <div class="col-md-5">
      <p class="h6">Workforce ID</p>
      <p class="h4 fw-bolder">{{ workforceId }}</p>
    </div>
  </div>

  <form class="example-form" [formGroup]="equipmentForm">
    <div class="row g-0 mb-2">
      <div class="col-md-4 px-2">
        <label>Equipment Category</label>
        <mat-form-field class="full-width" appearance="fill">
          <mat-select
            formControlName="equipmentCategory"
            placeholder="Equipment Category"
            id="equipmentCategory"
            aria-required="true"
          >
            <mat-option
              *ngFor="let category of equipmentCategories"
              [value]="category.name"
              >{{ category.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="c['equipmentCategory'].hasError('required')">
            Equipment Category is Required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-4 px-2">
        <label>Serial Number</label>

        <!-- serial number in case of add new equipment -->
        <mat-form-field
          *ngIf="!workforceId"
          class="full-width"
          appearance="fill"
        >
          <input
            matInput
            placeholder="Serial Number"
            id="serialNumber"
            formControlName="serialNumber"
            required
          />
          <mat-error *ngIf="c['serialNumber']?.hasError('required')">
            Serial Number is Required
          </mat-error>
        </mat-form-field>

        <!-- serial in case of handover equipment -->
        <mat-form-field
          *ngIf="workforceId"
          class="full-width"
          appearance="fill"
        >
          <input
            matInput
            placeholder="Serial Number"
            formControlName="serialNumber"
            [matAutocomplete]="auto"
            (input)="onInputChange($event.target.value)"
            #serialInput
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              *ngFor="let item of filteredSerialNumbers"
              [value]="item"
              >{{ item }}</mat-option
            >
          </mat-autocomplete>
          <!-- <input matInput placeholder="Serial Number" formControlName="serialNumber" (keypress)="handleKeypress($event)" #serialInput hidden> -->
          <mat-error *ngIf="c['serialNumber']?.hasError('required')">
            Serial Number is Required
          </mat-error>
        </mat-form-field>
        <!-- <div  class="col-md-4 px-2 d-flex align-items-center"><img src="assets/Icons/scan.svg"  matTooltip="use external scanning device" matTooltipPosition="above" (click)="focusSerialInput(serialInput)" class="scan-icon" alt="scan serial number"></div> -->
      </div>

      <div class="col-md-4 px-4" *ngIf="!workforceId">
        <mat-radio-group formControlName="condition" class="d-block w-100 pt-5">
          <mat-radio-button class="mat-radio-label-custom" value="FIT"
            >Fit</mat-radio-button
          >
          <mat-radio-button value="BROKEN" class="mx-3"
            >Broken</mat-radio-button
          >
        </mat-radio-group>
        <mat-error *ngIf="c['condition']?.hasError('required')">
          Condition is Required
        </mat-error>
      </div>
    </div>

    <div class="row g-0 mb-3" *ngIf="workforceId">
      <label>Comment</label>
      <mat-form-field class="full-width" appearance="fill">
        <textarea
          matInput
          placeholder="Comment"
          id="comment"
          formControlName="comment"
          class="w-100"
          style="height: 150px"
          required
        ></textarea>
      </mat-form-field>
    </div>

    <div class="row g-0 mb-3" *ngIf="!workforceId">
      <label>Equipment Description</label>
      <mat-form-field class="full-width" appearance="fill">
        <textarea
          matInput
          formControlName="description"
          class="w-100"
          style="height: 150px"
          placeholder="enter equipment description"
        ></textarea>
        <mat-error *ngIf="c['description'].hasError('required')">
          Description is Required
        </mat-error>
      </mat-form-field>
    </div>

    <section class="row g-0 justify-content-end">
      <!-- Add Button -->
      <div class="col-3 px-2 text-end" *ngIf="!editing">
        <button
          class="btn login__btn full-width"
          (click)="onAddEquipment()"
          [disabled]="isAddDisabled()"
        >
          Add
        </button>
      </div>

      <!-- Update Button -->
      <div class="col-3 px-2 text-end" *ngIf="editing">
        <button
          class="btn login__btn full-width"
          (click)="updateEquipment()"
          [disabled]="isUpdateDisabled()"
        >
          Update
        </button>
      </div>
    </section>
  </form>
</section>

<section class="mb-3 mt-4" *ngIf="createdEquipments.length > 0">
  <!-- List Grid -->
  <div class="mat-elevation-z8 custom-table-card">
    <mat-table [dataSource]="createdEquipments" matSort multiTemplateDataRows>
      <ng-container matColumnDef="category">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Category</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.category }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="serialNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Serial No</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{
          element.serialNumber
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="condition" *ngIf="!workforceId">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Condition</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.condition }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header
          >Description</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.description }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="errorMsg">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let element" class="text-danger fw-bold">{{
          element.errorMsg
        }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="justify-content-center"
          >Actions</mat-header-cell
        >
        <mat-cell *matCellDef="let row" class="justify-content-center">
          <button
            mat-icon-button
            color="warn"
            [disabled]="row.deleted"
            (click)="removeEquipment(row)"
          >
            <img
              src="../../../assets/Icons/Group50-trash.svg"
              class="mx-1"
              alt=""
            />
          </button>
          <button mat-icon-button (click)="editEquipment(row)">
            <img
              src="../../../assets/Icons/edit-icon.svg"
              class="mx-1"
              alt=""
            />
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="loading">
        <mat-footer-cell *matFooterCellDef colspan="6">
          LoadingData
        </mat-footer-cell>
      </ng-container>

      <ng-container matColumnDef="noData">
        <mat-footer-cell *matFooterCellDef colspan="6">
          NoData
        </mat-footer-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        [class.expanded-row]="row.isExpanded"
      >
      </mat-row>
    </mat-table>
  </div>
</section>

<section class="row mt-2 g-0 justify-content-between">
  <div class="col-3 px-2 text-end">
    <button class="btn btn-cancel full-width" (click)="onCancel()">
      Cancel
    </button>
  </div>
  <div class="col-3 px-2 text-end">
    <button
      class="btn login__btn full-width"
      (click)="onSubmit()"
      [disabled]="createdEquipments.length <= 0"
    >
      Submit
    </button>
  </div>
</section>
