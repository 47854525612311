<h1 mat-dialog-title>Remove Workforce</h1>


<div mat-dialog-content>
    <form [formGroup]="removeForm" (ngSubmit)="onRemoveWorkforce()">
        <mat-form-field class="full-width" appearance="fill">
            <mat-label>From Date</mat-label>
            
            <input type="date" matInput [min]="startDate" [max]="endDate" formControlName="fromDate"required>
                    <mat-error *ngIf="removeForm.controls.fromDate.errors">
                        Date is Required
                    </mat-error>
        </mat-form-field>
    </form>
</div>

<div mat-dialog-actions class="row g-0 py-4 justify-content-end">
    <div class="col-6 px-2 text-end">
        <button class="btn full-width" style="background: #ACB5BE;" (click)="onCancel()"
            >Cancel</button>
    </div>
    <div class="col-6 px-2 text-end">
        <button class="btn login__btn full-width" (click)="onRemoveWorkforce()"
            [disabled]="!removeForm.valid || loading">Save</button>
    </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>
