import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { CitationService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';
import { CitationInvoiceDialog } from '../_shared/citation-invoice/citation-invoice.dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneratingInvoiceDialogComponent } from '../_shared/generating-invoice-dialog/generating-invoice-dialog.component';


@Component({
  selector: 'app-citation-control-multi',
  templateUrl: './citation-control-multi.component.html',
  styleUrls: ['./citation-control-multi.component.scss']
})
export class CitationControlMultiComponent implements OnInit, AfterViewChecked {

  loading = false;
  isGeneratingMsg = false;
  data: any = {};
  IDs;
  penalties: any = {
    citationRequests: []
  };
  total: number; // from penalties com
  panelOpenState = false;

  totals: any = [];
  penaltiesItems: any = [];
  voidReasonsList: [] = [];
  vat = 0;
  paymentMethodForm!: FormGroup
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    this.IDs = this._activatedRoute.snapshot.params.ids;
    this._loadCitation(this.IDs);
    this.paymentMethodForm = this.fb.group({
      paymentMethod: ['CASH', Validators.required]
    });
  }

  ngOnInit(): void {
    this._getVoidReasonsList();
    this._loadVat();
  }

  private _loadCitation(ids) {
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;
        },
        error => {
          console.log(" err ===== ", error);
        });
  }
  private _loadVat() {
    this._citationService.getVat()
      .pipe(first())
      .subscribe(
        resp => {
          this.vat = resp.payload / 100;
        },
        error => { });
  }
  getPenalities($event, idx) {
    // console.log("idx ============ ", idx, ", $event", $event);
    this.penaltiesItems[idx] = $event;
    // console.log("idx ============ ", idx, ", this.penaltiesItems", this.penaltiesItems);
    return $event;
  }
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
}
  getTotal($event, idx) {
    this.totals[idx] = $event;
    this.total = this.totals.reduce((a, b) => a + b);
    return this.total;
  }

  onApply() {
    this.loading = true
    // const comment = this.penalties.citationRequests[0].penaltiesRequestList.find(element => element.penActionType == 'Voided');
    // console.log("comment", comment);
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      // height: '500px',
      data: {
        title: { title: "Apply Settle & Void to citation", color: "#481A90" },
        body: ` You’re are going to settle & void actions of
        citation "${this.data[0].plateNumberEn}", please confirm `,
        btnConfirmation: { title: "Confirm", color: "#481A90" },
        btnClose: { title: "No, Cancel" },
        comment: false
      }
    });

    dialogRef.afterClosed().subscribe((confirmationDialogResult) => {
      console.log('The dialog was closed result', confirmationDialogResult);
      this.penalties = {
        citationRequests: [],
      };
      // console.log("The dialog was closed panalitiesItems", this.penaltiesItems);
      // this.penalties = this.penaltiesItems.reduce((a, b) => a.citationRequests.concat(b.citationRequests));

      this.penaltiesItems.map((item) => {
        console.log(
          'The dialog was closed penaltiesItems item.citationRequests',
          item.citationRequests
        );
        this.penalties.citationRequests =
          this.penalties.citationRequests.concat(item.citationRequests);
      });

      console.log('The dialog was closed penalties', this.penalties);

      if (confirmationDialogResult?.confirmed) {
        this.loading = true;

        this.penalties.citationRequests.map((citationRequest) => {
          citationRequest.penaltiesRequestList.map((penaltiesRequest) => {
            return penaltiesRequest.penActionType == 'Settled'
              ? (penaltiesRequest.comment = 'Settle from portal web')
              : '';
          });
        });

        const paymentMethod =
          this.paymentMethodForm.get('paymentMethod')?.value;
        this.penalties.paymentMethod = paymentMethod;
        this.penalties.settlementChannel = 'ENFORCEMENT_WEB';

        this._citationService
          .payCitationAsync(this.penalties)
          .pipe(first())
          .subscribe(
            (data) => {
              console.log('update payCitation  data =========', data);
              this.isGeneratingMsg = true;
              // data['payload'].voided;
              if (data['payload']) {
                // if all is voided not show print and redirect to citation list
                this._router.navigateByUrl(`/enforcement-citations`);
              } else {
                const msgDialogRef = this._dialog.open(
                  GeneratingInvoiceDialogComponent,
                  {}
                );
                this.isGeneratingMsg = true;
              }
            },
              (error) => {
                console.log('update payCitation err ===== ', error);
                this.loading = false;
              },
              () => {
                this.loading = false;
              }
          );
      } else {
        this.loading = false;
      }
    });

  }

  onViewInvoice(){
    this.loading = true
    this._citationService.getInvoiceByCitationId(this.IDs).subscribe(
      (data) => {
        console.log('data', data);
        this.loading = false;
        if (data?.payload) {
          const dialogRefInvoice = this._dialog.open(
            CitationInvoiceDialog,
            {
              panelClass: 'custom-mat-show-image-dialog-container',
              width: '820px',
              // height: '500px',
              data: data['payload'],
              disableClose: true,
            }
          );
      
          dialogRefInvoice.afterClosed().subscribe((result) => {
            this.loading = false;
            console.log('dialogRefInvoice result', result);
            // window.location.reload();
          });
        } else {
          const msgDialogRef = this._dialog.open(GeneratingInvoiceDialogComponent, {
          })
        }
       
      },
      (error) => {
        console.log('error', error);
        this.loading = false;
      }
    )
  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

  private _getVoidReasonsList() {
    this._citationService.getVoidReasons().subscribe(res => {
      this.voidReasonsList = res.payload;
    })
  }
}
