
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ViolationPenaliesSave } from '../_models';

@Injectable({ providedIn: 'root' })
export class ViolationPenaltiesService {
    constructor(private _http: HttpClient) {
    }
    getList(page: number, limit: number, operationId: number, sortBy: string = '', sortDirection: string = '', violationName: string = '',query: string = '', ) {
        let api = `${environment.baseURL}enforcements/OperationViolationPenalties/search?page=${page}&limit=${limit}&operationId=${operationId}&language=en`;
        if (query)
            api += `&name=${query}`;
        if (sortBy)
            api += `&sortBy=${sortBy}`;
        if (sortDirection)
            api += `&sortDirection=${sortDirection}`;
        if (violationName){
            
            api += `&violationName=${violationName}`;
        }
        
        api += `&getAllViolation=true`;
        console.log(violationName);
        return this._http.get<any>(api);
    }

    getDetails(violationId: number, operationId: number) {
        const api = `${environment.baseURL}enforcements/violation/details?violationId=${violationId}&operationId=${operationId}`;
        return this._http.get<any>(api);
    }
    update(data) {
        const api = `${environment.baseURL}enforcements/OperationViolationPenalties`;
        return this._http.put<any>(api, data);
    }
    updateV2(data) {
        const api = `${environment.baseURL}enforcements/OperationViolationPenalties/v2`;
        return this._http.put<any>(api, data);
    }
    create(data: ViolationPenaliesSave) {
        const api = `${environment.baseURL}enforcements/OperationViolationPenalties`;
        return this._http.post<any>(api, data);
    }
    delete(params: any) {
        const api = `${environment.baseURL}enforcements/OperationViolationPenalties/deleteViolation`;
        return this._http.delete<any>(api, { params });
    }
    getViolationsList() {
        let api = `${environment.baseURL}enforcements/violation`;
        return this._http.get<any>(api);
    }
    getPenaltiesList() {
        let api = `${environment.baseURL}enforcements/penalties`;
        return this._http.get<any>(api);
    }


    disableEnableViolation(id: number, status: boolean) {

        const api = `${environment.baseURL}enforcements/violation/disable-enable-violation/${id}/${status}`;

        return this._http.put(api, null);
    }
}
