import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftSchedulerService } from 'src/app/_services';

@Component({
  selector: 'app-idle-workforce-dialog',
  templateUrl: './idle-workforce-dialog.component.html',
  styleUrls: ['./idle-workforce-dialog.component.scss']
})
export class IdleWorkforceDialogComponent implements OnInit {
  startDate: any;
  endDate: any;
  endMinDate: any;
  idleForm: FormGroup;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<IdleWorkforceDialogComponent>,
    private shiftService: ShiftSchedulerService
    ) { }



  ngOnInit(): void {
    console.log(this.data.employeeId);
    console.log(this.data.shiftId);
    this.startDate = new Date(this.data.startDate) > new Date() ? new Date(this.data.startDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];
    this.endDate = new Date(this.data.endDate).toISOString().split('T')[0];


    //>start date < end date
    this.idleForm= new FormGroup({
      fromDate: new FormControl('', [Validators.required]),
      toDate: new FormControl({value:'', disabled: true}, [Validators.required]),
      fromTime: new FormControl('', [Validators.required]),
      toTime: new FormControl('', [Validators.required]),
      shiftId: new FormControl(this.data.shiftId, [Validators.required]),
      employeeId: new FormControl(this.data.employeeId, [Validators.required])
    })    
  }

  onSelectEndDate(event) {
    console.log(event);
    
    this.endMinDate = event;
    if(this.endMinDate) {
      this.idleForm.get('toDate').enable()
    }
  }

  onIdleWorkforce() {
    this.loading = true;
    this.shiftService.idleWorkforceMember(this.idleForm.value).subscribe(res => {
      console.log(res);
      this.loading = false;
      this.dialogRef.close();
      
    },
    error => {
      this.loading = false;
    }
    )
  }

  onCancel() {
    this.dialogRef.close();
  }

}
