import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DetentionRequestsComponent } from './detention-requests/detention-requests.component';
import { ReleaseVehiclesComponent } from './release-vehicles/release-vehicles.component';

const routes: Routes = [
//   { path: '', pathMatch: 'full', redirectTo: 'detention-requests' },
  { path: 'detention-requests', component: DetentionRequestsComponent },
  { path: 'detention-vehicles', component: ReleaseVehiclesComponent },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DetentionRoutingModule { }
