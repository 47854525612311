<div mat-dialog-title class="dialog-title">
  <h2></h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <p>Generating invoice in progress please try again later</p>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-button class="cancel-btn" (click)="close()">Close</button>
</mat-dialog-actions>
