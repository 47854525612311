import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CitationReportListService, SelectDropdownIdName } from '../../citation/_services';
import { statusList } from '../enums/requestStatus-constant';
import { TowReportPayloadModel, TowReportResponseModel } from '../models/towRequest-model';
import { ReportsService } from '../reports.service';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tow-requests-report',
  templateUrl: './tow-requests-report.component.html',
  styleUrls: ['./tow-requests-report.component.scss']
})
export class TowRequestsReportComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private _citationReportListService: CitationReportListService,
    private ReportsService: ReportsService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }
  filtersForm: FormGroup;
  enforcerId = '0';
  selectedWorkForceIds: number[] = [];
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredEnforcerData: any[] = [];
  protected _onDestroy = new Subject<void>();
  selectedOperationId: string = '';
  loading = false;
  operations: SelectDropdownIdName[] = [];
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  statusList: string[] = statusList.statusList;
  dataList: TowReportResponseModel[];
  dataSource: MatTableDataSource<TowReportResponseModel>;
  displayedColumns: string[] = ['vehiclePlateNumberEn', 'createdDate', 'requestStatus','evidence', 'username'];
  workforcesList: any[];
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('imgCarousel') imgCarousel: TemplateRef<any>;
  @ViewChild('exportDialog') exportDialog: TemplateRef<any>;

  ngOnInit(): void {
    this.editFormInit();
    this._loadOperationList();
    this.applyFilter();
  }

  getWorkforceByOperationId(operationId: string) {
    this.workforcesList = [];
    this.selectedWorkForceIds = [];

    const selectedOperationId = operationId ? Number(operationId) : null;
    if (selectedOperationId) {
      this.ReportsService.getWorkforcesByOperationId('TOW', selectedOperationId)
        .pipe(first())
        .subscribe(
          resp => {
            this.workforcesList = resp.payload;
            // console.log(this.workforcesList);
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.log(" err ===== ", error);
          });
    }
  }

  choseWorker(e, worker) {
    console.log(this.selectedWorkForceIds);
    const freeWorkforcesListIdx = this.workforcesList.findIndex(item => item.id === worker.id);
    this.workforcesList[freeWorkforcesListIdx].value = !this.workforcesList[freeWorkforcesListIdx].value;
    console.log('status:', this.workforcesList[freeWorkforcesListIdx].value);
    if (this.workforcesList[freeWorkforcesListIdx].value) {
      this.selectedWorkForceIds.push(worker.id);
      this.enforcerId = worker.id;
    } else {
      this.selectedWorkForceIds.splice(this.selectedWorkForceIds.indexOf(worker.id), 1);
    }

    if (this.selectedWorkForceIds.length > 1) {
      this.enforcerId = '00';
    } else if (this.selectedWorkForceIds.length === 0) {
      this.enforcerId = '0';
    } else if (this.selectedWorkForceIds.length === 1) {
      const index = this.workforcesList.findIndex(enforcer => enforcer.id = this.selectedWorkForceIds[0]);
      this.enforcerId = this.workforcesList[index].id;
    }

    // console.log('this.selectedWorkForceIds', this.selectedWorkForceIds);
    this.workforcesList.sort((a) => a.value === true ? -1 : 1);
    this.openDropdown();
  }

  openDropdown(): void {
    this.singleSelect.open();
  }

  clearEnforcer() {
    this.singleSelect.options.forEach((data: MatOption) => data.deselect());
    // console.log(this.singleSelect.options);
    this.filtersForm.get('enforceNameSearch').setValue('');
    this.filteredEnforcerData.forEach((item) => item.value = false);
    this.selectedWorkForceIds = [];
  }

  private _loadOperationList() {
    if (this.operations.length > 1)
      return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  private editFormInit(): void {
    this.filtersForm = new FormGroup({
      plateNumber: new FormControl(null),
      enforceName: new FormControl(null),
      enforceNameSearch: new FormControl(null),
      operation: new FormControl(null),
      startDate: new FormControl(null),
      endDate: new FormControl(null),
      status: new FormControl(null),
      employeeName: new FormControl(null)
    });
  }

  openExportConfirmation(): void {
    const dialogRef = this._dialog.open(this.exportDialog, {
      // width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.exportData();
      }
    });
  }

  onCloseDialog(): void {
    this._dialog.closeAll();
  }

  exportData() {
    const formValues = this.filtersForm.value
    let dateFrom, dateTo;
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const currentEndDate = new Date(new Date().setHours(23, 59, 59, 999));
    if (formValues.startDate) {
      const inputDate = new Date(formValues.startDate);
      dateFrom = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateFrom = new Date(dateFrom).toISOString();
    }
    if (formValues.endDate) {
      const inputDate = new Date(formValues.endDate);
      inputDate.setHours(23, 59, 59, 999);
      dateTo = inputDate.toISOString();
    }
    const payload = {
      operationId: this.selectedOperationId
        ? Number(this.selectedOperationId)
        : null,
      startDate: 
      // formValues.startDate  ? dateFrom :
       this.formatDate(currentDate),
      endDate:
      //  formValues.endDate ? dateTo : 
      this.formatDate(currentEndDate),
      groundingType: 'TOW',
      vehiclePlateNumberEn: formValues.plateNumber
        ? formValues.plateNumber.split(' ').join('')
        : null,
      groundingRequestStatus: formValues.status,
      workforceIds: this.selectedWorkForceIds.length
        ? this.selectedWorkForceIds
        : null,
    };
    this.ReportsService.exportTowReport(payload).subscribe({
      next: (data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Tow Report.xlsx';
        link.click();
        this.onCloseDialog()
        this.toastr.success('Tow Report Exported successfully');
      },
      error: (error) => {
        console.log('error =>', error);
      },
      complete: () => {
        console.log('Ticket retrieval completed');
      },
    });
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
  }

  onClearFilters() {
    this.filtersForm.controls.employeeName.setValue(null);
    this.filtersForm.controls.startDate.setValue(null);
    this.filtersForm.controls.endDate.setValue(null);
    this.filtersForm.controls.status.setValue(null);
    this.filtersForm.controls.operation.setValue(null);
    this.filtersForm.controls.plateNumber.setValue(null);
    this.selectedOperationId = null;
    this.enforcerId = '';
    this.selectedWorkForceIds = [];
    this.applyFilter();

  }

  applyFilter() {
    if (this.filtersForm.invalid) {
      return;
    }
    const formValues = this.filtersForm.value
    // console.log(formValues);
    this._loadList(0, this.pageSize, formValues);

  }

  private _loadList(page: number, limit: number, formValues: any) {
    this.loading = true;
    const currentDate = new Date();
    let dateFrom, dateTo;
    if (formValues.startDate) {
      const inputDate = new Date(formValues.startDate);
      dateFrom = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateFrom = new Date(dateFrom).toISOString();
    }
    if (formValues.endDate) {
      const inputDate = new Date(formValues.endDate);
      dateTo = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateTo = new Date(dateTo).toISOString();
    }
    let params: TowReportPayloadModel = {
      operationId: this.selectedOperationId ? Number(this.selectedOperationId) : null,
      startDate: formValues.startDate ? dateFrom : currentDate,
      endDate: formValues.endDate ? dateTo : currentDate,
      groundingType: 'TOW',
      vehiclePlateNumberEn: formValues.plateNumber ? formValues.plateNumber.split(' ').join('') : null,
      groundingRequestStatus: formValues.status,
      workforceIds: this.selectedWorkForceIds.length ? this.selectedWorkForceIds : null,
      pageNumber: page,
      pageSize: limit
    }
    // console.log(params);

    this.ReportsService.getTowReport(params)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log(resp);
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;

          // console.log(this.totalRows);

          this.dataSource = new MatTableDataSource<TowReportResponseModel>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });

    // console.log(this.dataList);

    this.dataSource = new MatTableDataSource<TowReportResponseModel>(this.dataList);
  }

  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const formValues = this.filtersForm.value
    console.log(formValues);
    this._loadList(this.currentPage, this.pageSize, formValues);
  }

  currentImages: string[];
  openImageCarousel(images: string[]) {
    this.currentImages = images;
    this._dialog.open(this.imgCarousel, {
      width: '50%',
      height: '95%',
    });
  }
}
