import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogConfirmation } from 'src/app/_shared/dialog-confirmation/dialog-confirmation';
import { EquipmentService } from '../equipment.service';
import { EquipmentModel } from '../models/equipmentModel';
EquipmentService;
@Component({
  selector: 'app-list-equipments',
  templateUrl: './list-equipments.component.html',
  styleUrls: ['./list-equipments.component.scss'],
})
export class ListEquipmentsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns: string[] = [
    'category',
    'serialNumber',
    'condition',
    'description',
    'actions',
  ];
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    private _router: Router,
    private EquipmentService: EquipmentService,
    private _dialog: MatDialog
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }
  currentUserJson: any;
  operationId: number;
  failedMsg: string = '';
  successMsg: string = '';
  searchKey: string = '';
  filterList: any[] = [{ name: 'FIT' }, { name: 'BROKEN' }];
  loading = false;
  sortingField: string = 'createdDate';
  sortingDirection: any = 'desc';
  dataSource: MatTableDataSource<EquipmentModel> = new MatTableDataSource();
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.loadList(
      this.operationId,
      '',
      '',
      0,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }
  onAddNewEquipment() {
    this._router.navigateByUrl(`/equipments/add-equipment`);
  }

  loadList(
    operationId: number,
    condition: string,
    searchValue: string = '',
    page: number,
    limit: number,
    sortDirection: string = this.sortingDirection,
    sortField: string = this.sortingField
  ) {
    this.loading = true;

    this.EquipmentService.getAllEquipments(
      operationId,
      condition,
      searchValue,
      page,
      limit,
      sortDirection,
      sortField
    )
      .pipe(first())
      .subscribe(
        (resp) => {
          console.log(resp);
          this.dataSource = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(' err ===== ', error);
        }
      );
  }

  applyFilter(e: any) {
    let filterValue = e.value;
    console.log(filterValue);

    if (filterValue[0] == 0) {
      this.filterList.forEach((element) => {
        filterValue.push(element.name);
      });
    }
    this.loadList(
      this.operationId,
      filterValue,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }
  onDelete(row) {
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      data: {
        title: { title: 'Are you sure?', color: '#D93636' },
        body: `You’re are going to delete equipment with Serial Number 
        “${row.serialNumber}”, please confirm`,
        btnConfirmation: { title: 'Delete', color: '#D93636' },
        btnClose: { title: 'Cancel' },
        comment: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.EquipmentService.deleteEquipment(row.id).subscribe(
          (res) => {
            if (res.success)
              this.loadList(
                this.operationId,
                '',
                '',
                0,
                this.pageSize,
                this.sortingDirection,
                this.sortingField
              );
          },
          (error) => {
            if (error.erorr.code == 400) return;
          }
        );
      }
    });
  }
  applySearch(e: any) {
    this.searchKey = (e.target as HTMLInputElement).value;
    this.loadList(
      this.operationId,
      '',
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.loadList(
      this.operationId,
      '',
      '',
      this.currentPage + 1,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadList(
      this.operationId,
      '',
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  announceSortChange(sortState: Sort) {
    this.sortingDirection = sortState.direction;
    this.sortingField = sortState.active;
    this.loadList(
      this.operationId,
      '',
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }
}
