
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class ShiftHistoryListService {
    constructor(private _http: HttpClient) {
    }
    getList(page: number, limit: number, fromDate: string, toDate: string, roleName: string) {
        let api = `${environment.baseURL}operation/shift/search?fromDate=${fromDate}&toDate=${toDate}&page=${page}&roleName=${roleName}&limit=${limit}`;
        return this._http.get<any>(api);
    }

    getRolesList(page: number, limit: number, query: string = '') {
        let api = `${environment.baseURL}operation/roles?page=${page}&size=${limit}`;
        if (query)
            api += `&roleName=${query}`;
        return this._http.get<any>(api);
    }
}
