import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
// import * as moment from 'moment-timezone';
import { ReplaySubject, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { CitationReportListService, CitationService, SelectDropdownIdName } from 'src/app/citation/_services';
import { detentionStatusList } from '../enums/detentionStatus-constant';
import { DetentionReportPayloadModel, DetentionReportResponseModel } from '../models/detentionPayload-model';
import { ReportsService } from '../reports.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-detention-report',
  templateUrl: './detention-report.component.html',
  styleUrls: ['./detention-report.component.scss']
})
export class DetentionReportComponent implements OnInit {

  constructor(
    private _dialog: MatDialog,
    private ReportsService: ReportsService,
    private _citationService: CitationService, private _citationReportListService: CitationReportListService
  ) { }
  filtersForm: FormGroup;
  detentionList: any[];
  statusValue: string;
  enforcerId = '0';
  selectedWorkForceIds: number[] = [];
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredEnforcerData: any[] = [];
  protected _onDestroy = new Subject<void>();
  selectedOperationId: string = '';
  loading = false;
  operations: SelectDropdownIdName[] = [];
  searchKey: string;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  statusList: string[] = detentionStatusList.detentionStatusList;
  dataList: DetentionReportResponseModel[];
  dataSource: MatTableDataSource<DetentionReportResponseModel>;
  displayedColumns: string[] = ['vehiclePlateNumber', 'operationName', 'detentionCenterName', 'parkingSlot', 'entranceDate', 'requestStatus'];
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  dateFromLabel: string = 'Entering date from';
  dateToLabel: string = 'Entering date to';
  @ViewChild('imgCarousel') imgCarousel: TemplateRef<any>;

  ngOnInit(): void {
    this.editFormInit();
    this._loadOperationList();
    this.applyFilter();
  }

  getDetentionByOperation(operationId: string) {
    this.detentionList = [];
    this.filtersForm.controls.detentionCenterId.setValue(null);

    const selectedOperationId = operationId ? Number(operationId) : null;
    if (selectedOperationId) {
      this.ReportsService.getDetentionByOperationId(selectedOperationId)
        .pipe(first())
        .subscribe(
          resp => {
            this.detentionList = resp.payload;
            // console.log(this.detentionList);
            this.loading = false;
          },
          error => {
            this.loading = false;
            console.log(" err ===== ", error);
          });
    }
  }

  changeDateLabelWithStatus(status: string) {
    if (status == 'PENDING') {
      this.dateFromLabel = 'Pending date from';
      this.dateToLabel = 'Pending date to'
    } else if (status == 'ACCEPTED' || status == 'READY_TO_RELEASE') {
      this.dateFromLabel = 'Entering date from';
      this.dateToLabel = 'Entering date to'
    } else if (status == 'CANCELLED') {
      this.dateFromLabel = 'Cancelled date from';
      this.dateToLabel = 'Cancelled date to'
    } else if (status == 'RELEASED') {
      this.dateFromLabel = 'Released date from';
      this.dateToLabel = 'Released date to'
    }
    //  else if (status == 'READY_TO_RELEASE') {
    //   this.dateFromLabel = 'Released date from';
    //   this.dateToLabel = 'Released date to'
    // }
  }

  private _loadOperationList() {
    if (this.operations.length > 1)
      return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }
  private editFormInit(): void {
    this.filtersForm = new FormGroup({
      detentionCenterId: new FormControl(null),
      operation: new FormControl(null),
      dateFrom: new FormControl(null),
      dateTo: new FormControl(null),
      status: new FormControl(null),
      vehiclePlateNumber: new FormControl(null),

    });
  }

  onClearFilters() {
    this.filtersForm.controls.detentionCenterId.setValue(null);
    this.filtersForm.controls.dateFrom.setValue(null);
    this.filtersForm.controls.dateTo.setValue(null);
    this.filtersForm.controls.status.setValue(null);
    this.filtersForm.controls.operation.setValue(null);
    this.filtersForm.controls.vehiclePlateNumber.setValue(null);
    this.selectedOperationId = null;
    this.detentionList = [];
    this.applyFilter();
  }

  applyFilter() {

    if (this.filtersForm.invalid) {
      const formValues = this.filtersForm.value
      // console.log(formValues);
      return;
    }

    const formValues = this.filtersForm.value
    // console.log(formValues);
    this._loadList(this.currentPage, this.pageSize, formValues);

  }

  private _loadList(page: number, limit: number, formValues: any) {
    this.loading = true;
    const currentDate = new Date();
    let dateFrom, dateTo;
    if (formValues.dateFrom) {
      const inputDate = new Date(formValues.dateFrom);
      dateFrom = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateFrom = new Date(dateFrom).toISOString();
    }
    if (formValues.dateTo) {
      const inputDate = new Date(formValues.dateTo);
      dateTo = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateTo = new Date(dateTo).toISOString();
    }
    let params: DetentionReportPayloadModel = {
      operationId: this.selectedOperationId ? Number(this.selectedOperationId) : null,
      dateFrom: formValues.dateFrom ? dateFrom : currentDate,
      dateTo: formValues.dateTo ? dateTo : currentDate,
      vehiclePlateNumber: this.searchKey ? this.searchKey.split(' ').join('') : null,
      status: formValues.status ? formValues.status : 'ACCEPTED',
      detentionCenterId: formValues.detentionCenterId == '0' ? null : formValues.detentionCenterId
    }
    // console.log(params);

    this.ReportsService.getDetentionReport(params, page, limit)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log(resp);
          this.dataList = resp.content;
          this.totalRows = resp.totalElements;

          // console.log(this.dataList);

          this.dataSource = new MatTableDataSource<DetentionReportResponseModel>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
    // this.dataList = [
    //   {
    //     vehiclePlateNumberEn: "1234ABC",
    //     createdDate: "2024-03-07T16:33:29.618394",
    //     requestStatus: "SYSTEM_CLOSED_RUNWAY",
    //     username: "46"
    //   },
    //   {
    //     vehiclePlateNumberEn: "1234ABC",
    //     createdDate: "2024-03-07T16:33:29.618394",
    //     requestStatus: "SYSTEM_CLOSED_RUNWAY",
    //     username: "46"
    //   },
    // ]
    // console.log(this.dataList);

    this.dataSource = new MatTableDataSource<DetentionReportResponseModel>(this.dataList);
  }

  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }
  onSearchClear() {
    this.searchKey = '';
    this.applyFilter();;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const formValues = this.filtersForm.value
    console.log(formValues);
    this._loadList(this.currentPage, this.pageSize, formValues);
  }

  currentImages: string[];
  openImageCarousel(images: string[]) {
    this.currentImages = images;
    this._dialog.open(this.imgCarousel, {
      width: '50%',
      height: '95%',
    });
  }
}
