import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
// import { DetentionRequestsReportComponent } from './detention-requests-report/detention-requests-report.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SharedModule } from '../_shared/shared.module';
import { DetentionReportComponent } from './detention-report/detention-report.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { ScBlacklistReportComponent } from './sc-blacklist-report/sc-blacklist-report.component';
import { TowRequestsReportComponent } from './tow-requests-report/tow-requests-report.component';
import { NotificationReportComponent } from './notification-report/notification-report.component';
import { DraggingNotificationComponent } from './dragging-notification/dragging-notification.component';
import { CarStatusReportComponent } from './car-status-report/car-status-report.component';
import { SharedDirectiveModule } from '../_directives/shared-directive.module';



@NgModule({
  declarations: [TowRequestsReportComponent, ScBlacklistReportComponent, DetentionReportComponent, NotificationReportComponent, DraggingNotificationComponent, CarStatusReportComponent],
  imports: [
    ReportsRoutingModule,
    CommonModule,
    SharedModule,
    SharedDirectiveModule,
    MatExpansionModule
  ],
  providers: [DatePipe],
})
export class ReportsModule { }
