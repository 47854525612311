<section>
    <section class="row mb-5">
        <div class="col-12 row g-0 mb-3 align-items-center">
            <span class="col-1">
                <img class="pointer back-btn" src="../../assets/Icons/arrow-left-2.svg" (click)="goBack()" />
            </span>
            <span class="col-11">
                <div class="create-whitelist__title"> {{title}}</div>
            </span>
        </div>
        <div class="col-12">
            <div class="breadcrumb-content">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.0014 13.9199C12.3481 13.9199 12.6292 14.206 12.6292 14.5589V17.115C12.6292 17.4679 12.3481 17.754 12.0014 17.754C11.6547 17.754 11.3736 17.4679 11.3736 17.115V14.5589C11.3736 14.206 11.6547 13.9199 12.0014 13.9199Z"
                        fill="#39B2E5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.0096 3.69085C14.0098 3.69105 14.0101 3.69124 14.0103 3.69144L19.8118 8.41202C20.2417 8.7644 20.5639 9.28047 20.7611 9.80664C20.9584 10.333 21.0559 10.9367 20.9668 11.4921L20.9664 11.4945L19.8531 18.2766L19.8528 18.2785C19.6007 19.7859 18.1933 21 16.6891 21H7.31371C5.80176 21 4.40185 19.7949 4.14992 18.2778L3.03705 11.4984C3.03693 11.4977 3.03681 11.4969 3.03669 11.4962C2.94225 10.9391 3.03779 10.3339 3.23543 9.80664C3.4332 9.27902 3.75825 8.76258 4.19312 8.41034C4.19346 8.41006 4.1938 8.40979 4.19415 8.40951L9.99307 3.68247C11.1109 2.77037 12.8855 2.77187 14.0096 3.69085ZM13.2237 4.6876C12.5565 4.14177 11.4352 4.14363 10.7786 4.67953L4.97546 9.40999C4.75792 9.58599 4.54759 9.89136 4.40858 10.2622C4.26955 10.6331 4.22695 11.0026 4.27421 11.2799L4.27488 11.2838L5.388 18.0648C5.38803 18.0649 5.38797 18.0646 5.388 18.0648C5.53811 18.9672 6.41501 19.722 7.31371 19.722H16.6891C17.5787 19.722 18.4646 18.9599 18.6148 18.065C18.6148 18.0647 18.6149 18.0644 18.6149 18.064L19.7276 11.2862C19.7276 11.2858 19.7277 11.2854 19.7278 11.285C19.7723 11.0056 19.7274 10.6342 19.588 10.2622C19.4484 9.88992 19.2394 9.58406 19.025 9.40808C19.0248 9.40788 19.0245 9.40768 19.0243 9.40747L13.225 4.68865L13.2237 4.6876Z"
                        fill="#39B2E5" />
                </svg>
                <nav aria-label="breadcrumb" class="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="href" (click)="goBack()">Whitelist</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </section>

    <section>
        <!-- <div class="" *ngIf="created">
            <div class="alert success-alert" role="alert">
                {{ createdMsg }}
            </div>
        </div> -->
        <div class="" *ngIf="failed">
            <div class="alert danger-alert" role="alert">
                {{ createdMsg }}
            </div>
        </div>
    </section>

    <form class="form-group" [formGroup]="Whitelist" (ngSubmit)="onCreateWhitelist()">
        <section class="row g-0 mb-4">
            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Vehicle User</mat-label>
                    <input matInput formControlName="userName" id="vehicle-user" placeholder="enter vehicle user name">
                </mat-form-field>
                <mat-error *ngIf="myError('userName', 'required')">
                    Vehicle User is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input type="date" matInput formControlName="fromDate" id="start-date" placeholder="select or enter start date">
                </mat-form-field>
                <mat-error *ngIf="myError('fromDate', 'required')">
                    Start Date is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Time From</mat-label>
                    <input type="text" matInput formControlName="timeFrom" onfocus="this.type='time'" id="time-from" placeholder="from">
                </mat-form-field>
                <mat-error *ngIf="myError('timeFrom', 'required')">
                    Time From is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="description" class="w-100" style="height: 150px;" placeholder="enter any additional notes and/or description"></textarea>
                </mat-form-field>
                <mat-error *ngIf="myError('description', 'required')">
                    Description is Required
                </mat-error>
            </div>

            <div class="col-6 px-2">
                <div fxLayout="row">
                    <mat-form-field class="w-50" appearance="fill">
                      <input matInput placeholder="eg:1234" formControlName="plateNumber">
                    </mat-form-field>
                  
                    <mat-form-field class="w-50" appearance="fill">
                      <input matInput placeholder="eg:ABC" formControlName="plateText">
                    </mat-form-field>
                  </div>
                  <mat-error *ngIf="myError('plateNumber', 'required') || myError('plateText', 'required')">
                    Plate Number is Required
                </mat-error>

                  <mat-form-field class="full-width" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input type="date" matInput formControlName="toDate" id="end-date" placeholder="select or enter end date">
                </mat-form-field>
                <mat-error *ngIf="myError('toDate', 'required')">
                    End Date is Required
                </mat-error>


                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Time To</mat-label>
                    <input type="text" matInput formControlName="timeTo" id="time-to" onfocus="this.type='time'" placeholder="to">
                </mat-form-field>
                <mat-error *ngIf="myError('timeTo', 'required')">
                    Time To is Required
                </mat-error>
            </div>

            <div class="col-12 mt-5">
                <h2 class="create-whitelist__subtitle">Vehicle Details</h2>
            </div>

            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select formControlName="country" id="country" aria-required="true">
                        <mat-option *ngFor="let country of countries" [value]="country.enName">{{country.enName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('country', 'required')">
                    Country is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Color</mat-label>
                    <mat-select formControlName="colorId" id="colorId" aria-required="true">
                        <mat-option *ngFor="let color of colors" [value]="color.id">{{color.enName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('colorId', 'required')">
                    Color is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Model</mat-label>
                    <input matInput formControlName="model" id="model" placeholder="enter model">
                </mat-form-field>
                <mat-error *ngIf="myError('model', 'required')">
                    Model is Required
                </mat-error>
            </div>

            <div class="col-6 px-2">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Plate Type</mat-label>
                    <mat-select formControlName="type" id="type" aria-required="true">
                        <mat-option *ngFor="let type of plateType" [value]="type">{{type}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('type', 'required')">
                   Plate Type is Required
                </mat-error>

                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Make</mat-label>
                    <mat-select formControlName="makeId" id="makeId" aria-required="true">
                        <mat-option *ngFor="let make of makes" [value]="make.id">{{make.enName}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-error *ngIf="myError('makeId', 'required')">
                    Make is Required
                 </mat-error>
            </div>
        </section>

        <section class="row g-0 justify-content-end my-2">
            <div class="col-12 col-md-6 px-2 text-end">
                <button class="btn login__btn w-100" type="submit">Save</button>
            </div>
        </section>
    </form>
</section>