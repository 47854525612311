import { formatDate, registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { first, map, takeUntil } from 'rxjs/operators';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';
import { CitationReportListService, CitationService, SelectDropdownIdName } from '../../citation/_services';
import { AdminService } from '../_services/admin.service';
import { WorkforceReportDialogComponent } from './workforce-report-dialog/workforce-report-dialog.component';

@Component({
  selector: 'app-workforce-report-list',
  templateUrl: './workforce-report-list.component.html',
  styleUrls: ['./workforce-report-list.component.scss'],
})
export class WorkforceReportListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('violationSelect', { static: true }) violationSelect: MatSelect;

  sortBy: string = 'id';
  sortDirection: string = 'ASC';
  selectedOperationId: string = '';
  operations: SelectDropdownIdName[] = [];
  violationId: string = '';
  enforcerId = '0';

  selectedDateRange: any[] = [];
  filteredViolationData: any[] = [];
  protected _onDestroy = new Subject<void>();

  // enforcer name  //
  filteredEnforcerData: any[] = [];
  /** list of banks filtered by search keyword */
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredViolationOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  selectedWorkForceIds: number[] = [];

  searchKey: '';
  roles: any;
  displayedColumns = [
    { title: 'Member Name' },
    { title: 'Operation Name' },
    { title: 'Total Scans', info: 'count workforce scans' },
    { title: 'Total Settlements Count', info: 'Made by the workforce member' },
    { title: 'Total Settlements Amount(without VAT)', info: 'Total amount of settlement by this workforce' },
    // { title: 'Total settlements', info: 'Settled by the workforce member' },
    { title: 'Checked In Late' },
    { title: 'Absence', info: 'The workforce member missed a shift which means they didn\’t check in at all' },
    { title: 'Total Citations' },
    {
      title: 'Settled Count',
      info: 'The citations that were added by this workforce member & Settled by him/her or by others',
    },
    {
      title: 'Settled Amount (without VAT)',
      info: 'The citations that were added by this workforce member & Settled by him/her or by others',
    },
    {
      title: 'Voided Count',
      info: 'The citations that were added by this workforce member & Voided by him/her or by others',
    },
    {
      title: 'Voided Amount (without VAT)',
      info: 'The citations that were added by this workforce member & Voided by him/her or by others',
    },
    {
      title: 'UnSettled Count',
      info: 'The citations that were added by this workforce member',
    },
    {
      title: 'UnSettled Amount (without VAT)',
      info: 'The citations that were added by this workforce member',
    },
  ];
  citationColumns = ['totalCount', 'setlled', 'voided', 'unsetlled'];
  dataSource;
  loading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  workForceReports: FormGroup;
  reportList: any;
  reportCounts: any;

  constructor(
    private _citationService: CitationService,
    private _citationReportListService: CitationReportListService,
    private _adminService: AdminService,
    private _fb: FormBuilder,
    private _dialog: MatDialog,
  ) {
    registerLocaleData(localeSr, 'sr');
  }

  ngOnInit(): void {

    this.workForceReports = this._fb.group({
      roleId: new FormControl(''),
      enforceName: new FormControl(''),
      enforceNameSearch: new FormControl(''),
      operation: new FormControl(''),
      violation: new FormControl(''),
      violationSearch: new FormControl(''),
      employeeName: new FormControl(''),
      startDate: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
      endDate: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
    });

    this._loadOperationList();
    this._loadGetEmployeesByNameOrUserNameOrId();

    this.workForceReports.get('enforceNameSearch').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredEnforcerData.filter(item =>
            (item.name.toLowerCase().indexOf(this.workForceReports.get('enforceNameSearch').value.toLowerCase()) > -1) || (item.id === this.workForceReports.get('enforceNameSearch').value)
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredEnforcerOptions.next(
          filtered
        );
      }, error => { });

    this.workForceReports.get('violationSearch').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredViolationData.filter(item =>
            item.arName.toLowerCase().indexOf(this.workForceReports.get('violationSearch').value.toLowerCase()) > -1 ||
            item.id === this.workForceReports.get('violationSearch').value
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredViolationOptions.next(
          filtered
        );
      }, error => { });

    this.getMemberRoles();
    this.getWorkforceReports();
    this._loadGetViolationsList();
  }

  reloadDropdownFilter($event, load) {
    this[load]();
  }


  openDropdown(): void {
    this.singleSelect.open();
  }


  choseWorker(e, worker) {

    const freeWorkforcesListIdx = this.filteredEnforcerData.findIndex(item => item.id === worker.id);

    this.filteredEnforcerData[freeWorkforcesListIdx].value = !this.filteredEnforcerData[freeWorkforcesListIdx].value;

    // console.log('status:', this.filteredEnforcerData[freeWorkforcesListIdx].value);

    if (this.filteredEnforcerData[freeWorkforcesListIdx].value) {

      this.selectedWorkForceIds.push(worker.id);

      this.enforcerId = worker.id;
    } else {

      this.selectedWorkForceIds.splice(this.selectedWorkForceIds.indexOf(worker.id), 1);
    }

    if (this.selectedWorkForceIds.length > 1) {
      this.enforcerId = '00';
    } else if (this.selectedWorkForceIds.length === 0) {
      this.enforcerId = '0';
    } else if (this.selectedWorkForceIds.length === 1) {
      const index = this.filteredEnforcerData.findIndex(enforcer => enforcer.id = this.selectedWorkForceIds[0]);
      this.enforcerId = this.filteredEnforcerData[index].id;
    }

    // console.log('this.selectedWorkForceIds', this.selectedWorkForceIds);

    this.filteredEnforcerData.sort((a) => a.value === true ? -1 : 1);

    this.openDropdown();

  }


  clearEnforcer() {
    this.singleSelect.options.forEach((data: MatOption) => data.deselect());
    this.workForceReports.get('enforceNameSearch').setValue('');
    this.filteredEnforcerData.forEach((item) => item.value = false);
    this.selectedWorkForceIds = [];
  }
  clearViolation() {
    this.violationSelect.options.forEach((data: MatOption) => data.deselect());
    this.violationId = '';
  }

  private _loadGetEmployeesByNameOrUserNameOrId(keyword: string = ''): void {
    this.loading = true;
    this._citationService.getEmployeesByNameOrUserNameOrId(keyword)
      .pipe(first())
      .subscribe(
        resp => {
          this.filteredEnforcerData = resp.payload.sort((a, b) => a.name > b.name ? 1 : -1);

          this.filteredEnforcerData.forEach((element) => {
            element.value = false;
          });
          this.filteredEnforcerOptions.next(
            this.filteredEnforcerData
          );
          // console.log(this.filteredEnforcerOptions);

          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  getOperationForceMember() {

    if (this.selectedOperationId !== '0') {
      this.filteredEnforcerOptions.next(
        this.filteredEnforcerData.filter(s => s.operationId === this.selectedOperationId));
      // console.log(this.filteredEnforcerOptions);

    } else {

      this.filteredEnforcerOptions.next(this.filteredEnforcerData);
      // console.log(this.filteredEnforcerOptions);

    }

    // console.log('selected operation', this.selectedOperationId);
  }

  private _loadOperationList() {
    if (this.operations.length > 1)
      return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }


  private _loadGetViolationsList(keyword: string = '') {
    if (this.filteredViolationData.length > 1)
      return false;

    this.loading = true;
    this._citationService.getViolationsList(keyword)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log(resp);

          this.filteredViolationData = resp.payload.content;
          this.filteredViolationOptions.next(
            this.filteredViolationData
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onClearFilters() {
    this.workForceReports.controls.roleId.setValue('');
    this.workForceReports.controls.violation.setValue('');
    this.workForceReports.controls.violationSearch.setValue('');
    this.workForceReports.controls.employeeName.setValue(undefined);
    this.workForceReports.controls.startDate.setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.workForceReports.controls.endDate.setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.selectedOperationId = null;
    this.enforcerId = '';
    this.selectedWorkForceIds = [];
  }

  getMemberRoles() {
    this.loading = true;
    this._adminService.getRolesIdName().subscribe((res) => {
      const all = [{ id: '', name: 'All' }];
      // this.roles = res.payload;
      this.roles = all.concat(res.payload);
      this.workForceReports.get('roleId').setValue(this.roles[0].id)
      this.loading = false;
    },
      error => {
        this.loading = false;
        console.log(" err ===== ", error);
      });
  }

  getWorkforceReports() {
    this.loading = true;
    const params: any = {
      roleId: this.workForceReports.get('roleId').value ? this.workForceReports.get('roleId').value : null,
      enforcerIds: this.selectedWorkForceIds.length > 0 ? this.selectedWorkForceIds : null,
      operationId: this.selectedOperationId ? this.selectedOperationId : null,
      violationId: this.violationId ? this.violationId : null,
      employeeName: this.workForceReports.get('employeeName').value
        ? this.workForceReports.get('employeeName').value
        : null,
      startDate: this.workForceReports.get('startDate').value,
      endDate: this.workForceReports.get('endDate').value,
      page: this.currentPage,
      limit: this.pageSize,
    };
    this._adminService.workforceReport(params).subscribe(
      (res) => {
        // console.log(res.payload);
        this.totalRows = res.payload.workforceMembers.totalElements;
        this.dataSource = res.payload.workforceMembers.content;
        this.reportCounts = res.payload;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log('err', error);
      }
    );
  }

  onOpenDialog(item, citationStatus = '') {
    // console.log(item);
    const dialogRef = this._dialog.open(WorkforceReportDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        employeeId: item.employeeId,
        fromDate: this.workForceReports.get('startDate').value,
        toDate: this.workForceReports.get('endDate').value,
        citationStatus: citationStatus,
      },
    });
    dialogRef.afterClosed().subscribe((result) => { });
    return false;
  }

  pageChanged(event: PageEvent) {
    // console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getWorkforceReports();
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    // console.log('filterValue : ' , filterValue);
    if (filterValue.length >= 1) {
      this.loading = true;
      const params: any = {
        roleId: this.workForceReports.get('roleId').value ? this.workForceReports.get('roleId').value : null,
        enforcerIds: this.selectedWorkForceIds.length > 0 ? this.selectedWorkForceIds : null,
        operationId: this.selectedOperationId ? this.selectedOperationId : null,
        violationId: this.violationId ? this.violationId : null,
        employeeName: this.workForceReports.get('employeeName').value.trim().toLowerCase() ? this.workForceReports.get('employeeName').value.trim().toLowerCase() : null,
        startDate: this.workForceReports.get('startDate').value,
        endDate: this.workForceReports.get('endDate').value,
        page: this.currentPage,
        limit: this.pageSize,
      };
      // console.log('params : ', params);
      this._adminService.workforceReport(params).subscribe(
        (res) => {
          // console.log(res);
          this.loading = false;
          this.reportList = res.payload.workforceMembers.content;
          this.totalRows = res.payload.workforceMembers.totalElements;
          this.dataSource = res.payload.workforceMembers.content;
        },
        (error) => {
          console.log('err', error);
        }
      );
    } else {
      this.getWorkforceReports();
    }
  }

  onSearchClear() {
    this.workForceReports.patchValue({ employeeName: '' });
    this.currentPage = 0;
    this.getWorkforceReports();
  }

  downloadCSV() {
    if (this.totalRows > 40000) {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: 'Attention', color: '#D93636' },
          body: 'Too Much Records',
          btnConfirmation: { hide: true, title: 'Confirm', color: '#D93636' },
          btnClose: { title: 'close' },
          comment: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => { });
      return false;
    }
    const params: any = {
      roleId: this.workForceReports.get('roleId').value
        ? this.workForceReports.get('roleId').value
        : '',
      employeeName: this.workForceReports.get('employeeName').value
        ? this.workForceReports.get('employeeName').value
        : '',
      enforcerIds: this.selectedWorkForceIds.length > 0 ? this.selectedWorkForceIds : [],
      operationId: this.selectedOperationId ? this.selectedOperationId : null,
      startDate: this.workForceReports.get('startDate').value,
      endDate: this.workForceReports.get('endDate').value,
      page: this.currentPage,
      limit: this.pageSize,
    };
    if (params['operationId'] == null) delete params['operationId']
    // console.log(params)
    this.loading = true;
    this._adminService
      .downloadCSV(params)
      .pipe(first())
      .subscribe(
        (resp) => {
          this.loading = false;
          // console.log("totalRows =========== ", this.totalRows);
          if (resp !== undefined) {
            const hiddenElement = document.createElement('a');
            hiddenElement.setAttribute(
              'href',
              `data:text/csv;charset=utf-8,%EF%BB%BF ${encodeURIComponent(resp)}`
            );
            hiddenElement.setAttribute('download', 'workforce-report.csv');
            hiddenElement.style.visibility = 'hidden';
            hiddenElement.click();
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  downloadCSVViolation() {
    if (this.totalRows > 40000) {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: 'Attention', color: '#D93636' },
          body: 'Too Much Records',
          btnConfirmation: { hide: true, title: 'Confirm', color: '#D93636' },
          btnClose: { title: 'close' },
          comment: false,
        },
      });
      dialogRef.afterClosed().subscribe((result) => { });
      return false;
    }
    const params: any = {
      roleId: this.workForceReports.get('roleId').value
        ? this.workForceReports.get('roleId').value
        : '',
      employeeName: this.workForceReports.get('employeeName').value
        ? this.workForceReports.get('employeeName').value
        : '',
      startDate: this.workForceReports.get('startDate').value,
      endDate: this.workForceReports.get('endDate').value,
      page: this.currentPage,
      limit: this.pageSize,
    };
    this.loading = true;
    this._adminService
      .downloadCSVViolation(params)
      .pipe(first())
      .subscribe(
        (resp) => {
          this.loading = false;
          // console.log("totalRows =========== ", this.totalRows);
          if (resp !== undefined) {
            const hiddenElement = document.createElement('a');
            hiddenElement.setAttribute(
              'href',
              `data:text/csv;charset=utf-8,%EF%BB%BF ${encodeURIComponent(resp)}`
            );
            hiddenElement.setAttribute('download', 'workforce-violation-report.csv');
            hiddenElement.style.visibility = 'hidden';
            hiddenElement.click();
          }
        },
        (error) => {
          this.loading = false;
        }
      );
  }
}
