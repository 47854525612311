<div *ngIf="!loading">
  <h1 mat-dialog-title>Citations distribution</h1>
  <div mat-dialog-content class="mat-typography">
    <div class="mat-elevation-z8 custom-table-card">
      <mat-table
        [dataSource]="dataSource"
        matSort
        multiTemplateDataRows
      >
        <ng-container matColumnDef="violationDescription">
          <mat-header-cell *matHeaderCellDef
            >Violation Description</mat-header-cell
          >
          <mat-cell *matCellDef="let element">
            {{ element.violationAr }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="totalNumber">
          <mat-header-cell *matHeaderCellDef>Total Number</mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.totalNum }}
          </mat-cell>
        </ng-container>
        <mat-header-row
          *matHeaderRowDef="displayedColumns; sticky: true"
        ></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>
