import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { EquipmentService } from '../equipment.service';
import { HandoverEquipment } from '../models/handoverEquipmentModel';

@Component({
  selector: 'app-reclaim-equipment',
  templateUrl: './reclaim-equipment.component.html',
  styleUrls: ['./reclaim-equipment.component.scss'],
})
export class ReclaimEquipmentComponent implements OnInit {
  failedMsg: string = '';
  successMsg: string ;
  modelFailedMsg: string ;
  searchKey: string = '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  loading = false;
  currentUserJson: any;
  operationId: any;
  reclaimEquipmentForm: FormGroup;
  displayedColumns: string[] = ['name', 'id', 'actions'];
  reclaimDialogColumns: string[] = [
    'serialNumber',
    'description',
    'condition',
    'comment',
    'checkBox',
  ];
  sortingField: string = 'createdDate';
  sortingDirection: any = 'desc';
  formSubmitted = false;
  dataSource: HandoverEquipment[];
  selectedRow: HandoverEquipment;
  dialogRef: any;
  selectedEquipments: any[] = [];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('equipmentReclaimDialog') equipmentReclaimDialog: TemplateRef<any>;
  success: boolean = false;
  // successMsg: any = '';
  err: boolean = false;
  errMsg: string = '';
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog,
    private equipmentService: EquipmentService,
    private fb: FormBuilder
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this.loadList(this.operationId, '', 0, this.pageSize, this.sortingDirection, this.sortingField);
    this.initializeForm();
  }

  initializeForm(): void {
    this.reclaimEquipmentForm = new FormGroup({
      condition: new FormControl('', Validators.required),
      comment: new FormControl(''),
    });
  }

  reclaimEquipment() {
    this.formSubmitted = true;
    if (this.reclaimEquipmentForm.invalid) {
      // this.reclaimEquipmentForm.get('condition').markAsTouched();
      return;
    } else if (this.selectedEquipments.length === 0) {
      this.modelFailedMsg = 'you must choose at least one equipment';
      return;
    }

    const condition = this.reclaimEquipmentForm.get('condition').value;
    const comment = this.reclaimEquipmentForm.get('comment').value;

    // if (this.selectedEquipments.length === 0) {
    //   this.modelFailedMsg = 'you must choose at least one equipment';
    // }
    const reclaimed = {
      employeeId: this.selectedRow.employeeId,
      equipmentList: this.selectedEquipments.map((row) => ({
        equipmentId: row.id,
        comment: comment,
        condition: condition,
      })),
    };
    this.equipmentService.reclaimEquipment(reclaimed).subscribe(
      (res) => {
        this.dialogRef.close(res)
        this.successMsg='Equipment reclaimed successfully';
        console.log('Reclaim success:', res);
        console.log(reclaimed);
      },
      (error) => {
        console.error('Reclaim error:', error);
        console.log(reclaimed);
        this.modelFailedMsg = error;
      }
    );
  }

  toggleSelection(row: any) {
    if (this.isSelected(row)) {
      this.selectedEquipments = this.selectedEquipments.filter(
        (selectedRow) => selectedRow !== row
      );
    } else {
      this.selectedEquipments.push(row);
    }

    if (this.selectedEquipments.length === 0) {
      this.modelFailedMsg = 'you must choose at least one equipment';
    }
    this.modelFailedMsg = '';
  }

  isSelected(row: any): boolean {
    return this.selectedEquipments.includes(row);
  }

  loadList(
    operationId: number,
    searchValue: string,
    page: number,
    limit: number,
    sortDirection: string = this.sortingDirection,
    sortField: string = this.sortingField
  ) {
    this.loading = true;

    this.equipmentService
      .getEmployeesToReclaim(operationId, searchValue, page, limit, sortDirection, sortField)
      .pipe(first())
      .subscribe(
        (res) => {
          console.log(res);
          this.dataSource = res.payload.content;
          this.totalRows = res.payload.totalElements;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('reclaim err', error);
        }
      );
  }

  openEquipmentReclaimDialog(row: any) {
    this.reclaimEquipmentForm.reset();

    this.selectedRow = row;
    this.dialogRef = this._dialog.open(this.equipmentReclaimDialog, {
      width: '60%',
      height: '85%',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.loadList(this.operationId, '', 0, this.pageSize, this.sortingDirection,
          this.sortingField);
      }

    });
  }

  announceSortChange(sortState: Sort) {
    this.sortingDirection = sortState.direction;
    this.sortingField = sortState.active;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  applySearch(event: any) {
    this.searchKey = (event.target as HTMLInputElement).value;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.loadList(this.operationId, '', this.currentPage, this.pageSize, this.sortingDirection, this.sortingField);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }
}