import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CacheFiltersCitationService {
    private cacheSubject: BehaviorSubject<any>;
    public cached: Observable<any>;

    constructor() {
        const cacheFiltersCitation = localStorage.getItem('cacheFiltersCitation');
        console.log('cacheFiltersCitation === ', cacheFiltersCitation);
        if (cacheFiltersCitation) {
            this.cacheSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('cacheFiltersCitation')));
            this.cached = this.cacheSubject.asObservable();
        } else {
            this.cacheSubject = new BehaviorSubject<any>('');
        }

    }

    public get cachedValue() {
        return this.cacheSubject.value;
    }

    set(data) {
        localStorage.setItem('cacheFiltersCitation', JSON.stringify(data));
        this.cacheSubject.next(data);
    }

    clear() {
        // remove cached data
        localStorage.removeItem('cacheFiltersCitation');
        this.cacheSubject.next(null);
    }
}