<section class="row g-0 align-items-center mb-3">
  <div class="col-12 row g-0 mb-3">
    <div class="col-md-9 col-sm-12">
      <h1><strong>Reclaim Equipment</strong></h1>
    </div>
  </div>

  <div class="" *ngIf="successMsg">
    <div class="alert success-alert" role="alert">
      {{ successMsg }}
    </div>
  </div>
  <div class="" *ngIf="failedMsg">
    <div class="alert danger-alert" role="alert">
      {{ failedMsg }}
    </div>
  </div>

  <!-- Search Box-->
  <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
    <input matInput placeholder="Search by workforce name or ID" autocomplete="off" [(ngModel)]="searchKey"
      (keyup)="applySearch($event)" />
    <mat-icon matPrefix>search</mat-icon>

    <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <!-- Search Box-->
  <section class="mb-5">
    <!-- List Grid -->
    <div class="mat-elevation-z8 custom-table-card mb-5">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Workforce</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.employeeName
            }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
          <mat-cell *matCellDef="let element">{{
            element.employeeId
            }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row" (click)="openEquipmentReclaimDialog(row)"
            class="justify-content-end reclaimEquipmentCell">
            <button mat-icon-button class="actionBtn">
              <img src="../../../assets/Icons/toolbox.svg" class="mx-1" alt="reclaim" />
            </button>
            <span style="color: #481a90">Reclaim Equipment</span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
          [class.expanded-row]="row.isExpanded">
        </mat-row>
      </mat-table>

      <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons
        [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
        (page)="pageChanged($event)">
      </mat-paginator>
    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>
  </section>

  <ng-template #equipmentReclaimDialog>
    <div class="h-100">
      <div class="row mb-2">
        <div class="col-12 row g-0 px-1 align-items-center">
          <span class="d-flex justify-content-between">
            <div class="print-citation__title">
              <h4 class="fw-bold h3">Reclaim Equipment</h4>
            </div>
            <div class="cross">
              <mat-icon class="close" (click)="dialogRef.close(null)">close</mat-icon>
            </div>
          </span>
        </div>
      </div>

      <div>
        <div class="pb-1 mb-0" dialog-content>

          <div class="" *ngIf="modelFailedMsg">
            <div class="alert danger-alert" role="alert">
              {{ modelFailedMsg }}
            </div>
          </div>
          <form [formGroup]="reclaimEquipmentForm" (ngSubmit)="reclaimEquipment()">
            <div class="mat-dialog-actions d-flex justify-content-between">
              <section class="mb-5 w-100">
                <!-- List Grid -->
                <div class="mat-elevation-z8 custom-table-card mb-5">
                  <mat-table [dataSource]="selectedRow.equipmentList" multiTemplateDataRows>
                    <ng-container matColumnDef="serialNumber">
                      <mat-header-cell *matHeaderCellDef>Equipment Serial</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{
                        element.serialNumber
                        }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="description">
                      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
                      <mat-cell *matCellDef="let element">{{
                        element.description
                        }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="condition">
                      <mat-header-cell *matHeaderCellDef>Condition</mat-header-cell>
                      <mat-cell *matCellDef="let element" class="conditionBtn">
                        <div>
                          <mat-radio-group [ngClass]="{
                            'text-danger':
                              reclaimEquipmentForm.get('condition').invalid &&
                              reclaimEquipmentForm.get('condition').touched &&
                              formSubmitted
                          }" formControlName="condition" class="">
                            <mat-radio-button class="mat-radio-label-custom radioIcon mx-2"
                              value="FIT">Fit</mat-radio-button>
                            <mat-radio-button class="mat-radio-label-custom radioIcon"
                              value="BROKEN">Broken</mat-radio-button>
                          </mat-radio-group>
                        </div>

                        <div class="mt-2"
                          *ngIf="reclaimEquipmentForm.get('condition').touched &&reclaimEquipmentForm.get('condition').invalid">
                          <mat-error class="fs-6"
                            *ngIf=" reclaimEquipmentForm.get('condition').hasError('required')">This
                            field is
                            required.</mat-error>
                        </div>
                        <!-- <div
                          *ngIf="
                            reclaimEquipmentForm.get('condition').invalid &&
                            reclaimEquipmentForm.get('condition').touched &&
                            formSubmitted
                          "
                          class="text-danger"
                        >
                          <mat-error class="fs-6"
                            >This field is required.</mat-error
                          >
                        </div> -->
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="comment">
                      <mat-header-cell *matHeaderCellDef>Comment</mat-header-cell>
                      <mat-cell *matCellDef="let element" class="my-1">
                        <mat-form-field hideRequiredMarker="true" class="w-100">
                          <mat-label>Comment</mat-label>
                          <input formControlName="comment" matInput />
                        </mat-form-field>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="checkBox">
                      <mat-header-cell *matHeaderCellDef></mat-header-cell>
                      <mat-cell *matCellDef="let element" class="justify-content-end"><mat-checkbox
                          (change)="toggleSelection(element)"></mat-checkbox></mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="reclaimDialogColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: reclaimDialogColumns" class="element-row"
                      [class.expanded-row]="row.isExpanded">
                    </mat-row>
                  </mat-table>
                </div>


                <mat-spinner *ngIf="loading"></mat-spinner>
              </section>
              <div class="mat-dialog-actions row w-100 d-flex justify-content-end">
                <button class="btn col-3 cancelBtn text-white rounded" mat-button mat-dialog-close
                  (click)="dialogRef.close(null)">
                  Cancel
                </button>
                <button class="btn col-4 reclaimBtn text-white rounded" type="submit" mat-button>
                  Reclaim Selected
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-template>
</section>