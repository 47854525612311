<section class="row">
    <div class="col-12 row g-0 mb-3 align-items-center">
        <span class="col-1">
            <img class="pointer back-btn" src="../../assets/Icons/arrow-left-2.svg" (click)="goBack()" />
        </span>
        <span class="col-11">
            <div class="workforce__title"> Reassign Workforce </div>
        </span>
    </div>
    <div class="col-12">
        <!-- <ol class="breadcrumb">
            <li class="breadcrumb-item" *ngFor="let item of breadcrumbList; let i = index"
                [class.active]="i===breadcrumbList.length-1">
                <a [routerLink]="item.path" *ngIf="i!==breadcrumbList.length-1">
                    {{ item.name }}
                </a>
                <span *ngIf="i===breadcrumbList.length-1">{{ item.name }}</span>
            </li>
        </ol> -->
    </div>

    <div *ngIf="success">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>
    <div *ngIf="err">
        <div class="alert danger-alert" role="alert">
            {{errMsg}}
        </div>
    </div>

    <form [formGroup]="workforceForm">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Current Operation</mat-label>
                    <mat-select formControlName="workforceCurrentOperation" id="currentOperation">
                        <!-- <mat-option value=""> select role </mat-option> -->
                        <mat-option *ngFor="let operation of operations" [value]="operation.name">{{operation.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="workforceRole" id="role" (selectionChange)="getWorkforceList()">
                        <!-- <mat-option value=""> select role </mat-option> -->
                        <mat-option *ngFor="let role of roles" [value]="role">{{role}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>

    <form [formGroup]="workforceForm">
        <div class="row">
            <div class="col-12 col-md-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>New Operation</mat-label>
                    <mat-select formControlName="workforceNewOperation" id="newOperation">
                        <!-- <mat-option value=""> select role </mat-option> -->
                        <mat-option *ngFor="let operation of operations" [value]="operation.name">{{operation.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>


    <ng-container >

    </ng-container>
    <section class="table-wrapper">
        <h3 *ngIf="workforceForm.get('workforceCurrentOperation').value && workforceForm.get('workforceNewOperation').value">
            Workforce Members From {{workforceForm.get('workforceCurrentOperation').value}} to be transfered to {{workforceForm.get('workforceNewOperation').value}}
        </h3>
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="freeWorkforcers" class="mat-elevation-z8">
                <!-- Item Column -->
                <ng-container matColumnDef="item">
                    <td mat-cell *matCellDef="let transaction"> {{transaction}} </td>
                </ng-container>

                <!-- Cost Column -->
                <ng-container matColumnDef="cost">
                    <!-- <td mat-cell *matCellDef="let transaction"> {{transaction.cost | currency}} </td> -->
                    <td mat-cell *matCellDef="let transaction">
                        <mat-checkbox class="example-margin" name="{{transaction}}"
                            (change)="setWorkforce($event.source)">
                            <!-- [checked]="allComplete" [color]="task.color"
                                name
                            [indeterminate]="someComplete()" (change)="setAll($event.checked)"> -->
                        </mat-checkbox>
                    </td>
                </ng-container>

                <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> -->
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20,50,100]" showFirstLastButtons
                aria-label="Select page of periodic elements">
            </mat-paginator>
        </div>
    </section>

    <div class="text-end">
        <button class="btn login__btn" type="submit" (click)="assignWorkforcers()"> Reassign & Save </button>
    </div>
</section>
