import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { ShowImageService } from '../../_services';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'show-images-dialog',
  templateUrl: './show-images-dialog.html',
  styleUrls: ['./show-images-dialog.scss'],
})
export class ShowImagesDialog {
  loading: boolean = false;
  currentIdx = 0;
  imagePath;
  rotationAmount = 0;
  filesTypes = ['pdf'];
  isFile: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ShowImagesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: [Number],
    private _showImageService: ShowImageService,
    private _sanitizer: DomSanitizer,
  ) {
    data.map((item) => {
      console.log("image dialog item", item);
    });
    console.log('images dialog data', data);


  }

  ngOnInit(): void {
    this._loadImage(this.data[this.currentIdx]);
  }

  onConfirm(): void {

    // {"success":true,"error":null,"code":200,"payload":{"invoiceNumber":10064,"invoiceDate":"2022-02-03T12:46:03.090594Z","totalAmount":360.0,"totalVATAmount":54.000,"vat":0.15,"totalAmountWithVAT":414.000,"paymentMethodId":"PortalWFSettlement","comment":"","source":"Enforcement Portal","items":[{"itemName":"penalty two new","amount":120.0,"totalAmount":360.0,"quantity":3}]},"serviceTime":null}

    const prtContent = document.getElementById("printcontent");
    console.log("prtContent", prtContent);
    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write('<html><head>');
    //WinPrint.document.write('<link rel="stylesheet" href="style.css">');

    WinPrint.document.write('</head><body onload="print();close();">');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('</body></html>');
    console.log("prtContent.innerHTML ", prtContent.innerHTML)
    WinPrint.document.close();
    WinPrint.focus();

  }

  onRotate(direction) {
    this.rotationAmount += direction == 'left' ? -90 : 90;

    (document.querySelector('#citationImage') as HTMLElement).style.transform = `rotate(${this.rotationAmount}deg)`;

    console.log(`rotate(${this.rotationAmount}deg)`);

  }

  private _loadImage(image) {

    if (image === undefined) return false;
    this.loading = true;
    this.isFile = false;
    if (image.bucketStorage == true) {

      if (this.filesTypes.includes(image.path.slice(-3)))
        this.isFile = true;

      this.imagePath = image.path;

      this.loading = false;
    } else {
      this._showImageService.getImage(image.id)
        .pipe(first())
        .subscribe(
          resp => {
            console.log('dialog image resp', resp);
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
              + resp.content);

            console.log("this.imagePath", this.imagePath);
            this.loading = false;
          },
          error => {
            console.log(" err ===== ", error);
            this.loading = true;
          });
    }

  }

  onDot(idx) {
    console.log('onDot', idx);
    this.currentIdx = idx;
    this._loadImage(this.data[idx]);
  }
  onPrev() {
    console.log('onPrev');
    --this.currentIdx;
    this._loadImage(this.data[this.currentIdx]);
  }
  onNext() {
    ++this.currentIdx;
    this._loadImage(this.data[this.currentIdx]);
    console.log('onNext');
  }
  close(): void {
    this.dialogRef.close();
  }

}