import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, filter, tap, catchError } from 'rxjs/operators';
import { LoginServiceService } from '../login/_services/login-service.service'

@Injectable()
export class ExpireInterceptor implements HttpInterceptor {
    constructor(private _authenticationService: LoginServiceService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            filter(event => event instanceof HttpResponse),
            catchError(error => {
                if (error.status === 401) {
                    this._authenticationService.logout();
                    const headers = error.headers;
                    console.log('headers', headers);
                    console.log('headers.session_expired', headers.get('session_expired'));
                    if (headers.get('session_expired'))
                        this._authenticationService.logout();
                }
                return throwError(error);
            })
        );
    }
}
