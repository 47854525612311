<!-- Filter Menu -->
<div class="d-flex" matSuffix>
    <button mat-button class="filter-button" (click)="selectedItems.open()">
        <mat-icon>{{icon}}</mat-icon>{{label}}
    </button>
    <mat-select panelClass="filter-list" [disableOptionCentering]="true" (selectionChange)="onSelectionChange($event)"
        #selectedItems [multiple]="multiple">
        <div class="mb-3">
            <mat-label>{{listTitle}}</mat-label>
        </div>
        <ng-container *ngIf="multiple">
            <mat-option [value]="0" class="fw-bold" (click)="toggleAllSelection(selectedItems)"> Select
                All</mat-option>
            <mat-option [value]="item[returnValue]" *ngFor="let item of list; let i = index">{{ item.name
                }}</mat-option>
        </ng-container>
        <ng-container *ngIf="!multiple">
            <mat-option [value]="item[returnValue]" (click)="onApplyFilter()"
                *ngFor="let item of list; let i = index">{{ item.name
                }}</mat-option>
        </ng-container>
        <div class="actions mt-4 d-flex justify-content-between">
            <button mat-button (click)="clearItems()">{{clearBtn}}</button>
            <button mat-button *ngIf="multiple" (click)="onApplyFilter()">{{filterBtn}}</button>
        </div>
    </mat-select>
</div>