
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class CitationReportListService {
    constructor(private _http: HttpClient) {
    }
    getList(page: number, limit: number, fromDate: string, toDate: string, operationIds: string, citationStatuses: string) {
        let api = `${environment.baseURL}enforcements/citations/searchCitations?page=${page}&limit=${limit}&operationIds=${operationIds}&citationStatuses=${citationStatuses}&fromDate=${fromDate}&toDate=${toDate}`;
        return this._http.get<any>(api);
    }

    getCitationReportList(limit: number, offset: number, operationId: string, statusList, violationId, settlementChannel, fromDate, toDate, sortBy, sortDirection) {
        let api = `${environment.baseURL}enforcements/citations/getCitationReport?offset=${offset}&limit=${limit}&operationId=${operationId}&statusList=${statusList}&violationId=${violationId}&settlementChannel=${settlementChannel}&fromDate=${fromDate}&toDate=${toDate}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
        return this._http.get<any>(api);
    }

    getOperationList() {
        let api = `${environment.baseURL}operation/operation/all`;
        return this._http.get<any>(api);
    }

    downloadCSV(params): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
        const api = `${environment.baseURL}enforcements/citations/export-citation-data-report`;
        return this._http.get<any>(api, {
            params,
            headers,
            responseType: 'text' as 'json'
        });
    }

    getAmounts(params) {
        const api = `${environment.baseURL}enforcements/citations/citations-report-amount`;
        return this._http.get<any>(api, { params });
    }
}

export interface SelectDropdownIdName {
  id: any;
  name: string;
}
