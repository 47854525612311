import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AuthorizationService } from '../_services';
import { LoginServiceService } from '../login/_services/login-service.service';
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  dark: boolean = true;
  active: boolean = true;
  events: string[] = [];
  opened: boolean = true;
  activeRoute: string = '';
  username: string = '';
  operationName = '';
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  messages: any[] = [];
  constructor(
    public router: Router,
    public AuthorizationService: AuthorizationService,
    private authenticationService: LoginServiceService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private afMessaging: AngularFireMessaging,
    private toastr: ToastrService,
    private authService: LoginServiceService

  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnInit(): void {
    this.getUser();
    this.listenMessages();
  }

  listenMessages() {
    this.afMessaging.requestPermission.subscribe(
      () => {
        this.afMessaging.messages.subscribe((message: any) => {
          this.messages.push(message);
          this.toastr.info(message?.notification?.body, message?.notification?.title, {
            timeOut: 10000,
            // progressBar: true,
            // tapToDismiss: false,
            messageClass: 'message-custom',
            titleClass: 'title-custom'
          })
        });
      })
  }
  setActivateLink(target) {
    const value = (target as HTMLElement).innerText;
    this.activeRoute = value.toLocaleLowerCase();
  }

  getActive(target) {
    const value = target.toLocaleLowerCase();
    console.log("getActive value", value);
    return (this.activeRoute == value) ? true : false;
  }
  changeLogStatus(status: string) {
    const employeeDto = JSON.parse(localStorage.getItem('currentUser')).employeeDto;
    let params = {
      "userId": employeeDto.id,
      "userStatus": status,
      "deviceType": "web-enforcement"
    }
    this.authService.changeLogStatus(params).subscribe(
      (res) => {
        console.log(res);
      });
  }
  logout() {
    this.changeLogStatus("LOGGED_OUT");
    this.checkToken();
    this.authenticationService.logout();


  }

  checkToken() {
    this.afMessaging.getToken
      .pipe(take(1))
      .subscribe(
        (token) => {
          if (token) {
            console.log('Existing FCM token:', token);
            this.afMessaging.deleteToken(token)
              .pipe(take(1))
              .subscribe(
                () => {
                  console.log('Token deleted successfully.');
                  // this.generateNewToken();
                },
                (error) => {
                  console.error('Unable to delete token:', error);
                  // Handle the error accordingly
                }
              );
          }
        },
        (error) => {
          console.error('Unable to get token:', error);
          // Handle the error accordingly
        }
      );
  }

  profile() {
    this.router.navigateByUrl(`/my-profile`);
  }

  getUser() {
    this.authenticationService.currentUser.subscribe((res) => {
      let user = res.employeeDto;
      console.log('user', user);
      // @ts-ignore
      this.username = user.name;
      // @ts-ignore
      this.operationName = user.operationName;
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
