import { Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../admin/_services/admin.service';
import { DataService } from '../data.service';

import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../_shared/dialog-confirmation/dialog-confirmation';


@Component({
  selector: 'app-role-creation',
  templateUrl: './role-creation.component.html',
  styleUrls: ['./role-creation.component.scss'],
})
export class RoleCreationComponent implements OnInit {
  @ViewChild('role') role;
  @ViewChild('actions') actions;
  roleForm: FormGroup;
  groupRoles: any;
  chosenAction: string;
  actionsList: any[] = [];
  chosenRoles: string[] = [];
  alreadyExist: boolean = false;
  displayedColumns: string[] = ['Privileges', 'Privilage'];
  displayMsg: string = '';
  succeeded: boolean = false;

  title: string = "Create new role";
  saveBtnTitle: string = "Create Role";

  loading: boolean = false;
  data: any = {};
  id;
  type;

  submitted = false;
  alertErrorMsg: string = "";
  alertSucceedMsg: string = "";

  groupRolesList: any = [];
  selectedRoles: any = [];

  isDisabledEl: boolean = false;
  isNewRoleDisabled: boolean = false;

  get f() { return this.roleForm.controls; }
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private location: Location,
    private dataService: DataService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog,

  ) {

    this.id = this._activatedRoute.snapshot.params.id;
    this.type = this._activatedRoute.snapshot.params.type;

    if (this.id === undefined) {
      this.title = 'Define New Role';
      this.saveBtnTitle = "Create Role"
    } else {
      this.saveBtnTitle = "Save Role";
      this.title = (this.type == "edit") ? `Edit Role` : `View Role`;
    }


  }

  ngOnInit(): void {
    this.generateForm();
    // load action by group to load
    this.getActionByGroupName().then(res => {
      if (this.id)// load form after loading action by group 
        this._loadFormData(this.id);
    });
  }

  private _loadFormData(id) {
    this.loading = true;
    this.dataService.getRoleDetails(id)
      .pipe(first())
      .subscribe(
        resp => {
          const roleDetailsContent = resp.payload;
          const roleActions = roleDetailsContent.roleActions;
          this.f['newRole'].setValue(roleDetailsContent.roleName);
          setTimeout(() => {
            roleActions.map(role => {
              const filteredGrpRoles = this.groupRolesList.filter((group) => {
                return group.name == role.groupName;
              });

              const foundRole = filteredGrpRoles[0].roles.find((roleItem) => {
                return roleItem.name == role.name;
              });
              foundRole.value = true;
            });
            this.loading = false;

          }, 200);
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  goBack() {
    this.location.back();
  }

  generateForm() {
    const isDisabledEl = (this.type == 'view') ? true : false;
    const isNewRoleDisabled = (isDisabledEl || this.type == "edit") ? true : false;

    this.roleForm = new FormGroup({
      newRole: new FormControl({ value: '', disabled: isNewRoleDisabled }, [Validators.required]),
    });
  }

  getActionByGroupName(): Promise<any> {
    this.dataService.getActionsByGroupName().subscribe((res) => {
      this.groupRoles = res.payload;
      Object.entries(res.payload).map((item, groupIdx) => {
        const roles = Object.entries(item[1]).map((item, roleIdx) => {
          return { groupIdx, roleIdx, "name": item[1], "value": false };
        });
        this.groupRolesList.push({ name: item[0], roles });
      });
    });

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }

  handleGroupActions(idx) {
    this.selectedRoles = this.groupRolesList[idx].roles;
  }
  handleActions() {
    this.chosenAction = this.role.selectedOptions.selected[0]?.value;
    for (const key in this.groupRoles) {
      if (key == this.chosenAction) this.chosenRoles = this.groupRoles[key];
    }
  }

  setRoleValueAction($event, element) {
    this.groupRolesList[element.groupIdx].roles[element.roleIdx].value = $event.source.checked;
  }
  setRoleAction(row) {
    const chosenIndex = this.actionsList.indexOf(row.name);
    row.checked === true
      ? this.actionsList.push({ name: row.name, groupName: this.chosenAction })
      : (this.actionsList = this.actionsList.filter(
        (listItem) => listItem.name !== row.name
      ));
  }

  createRoleActivities() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.roleForm.invalid || this.customError) return;

    this.loading = true;
    if (!this.id)
      this.dataService
        .createRoleWithAction(
          this.roleForm.controls.newRole.value,
          this._selectedRoles)
        .subscribe(
          (res) => {
            if (res.success === true) {
              this.displayMsg = res.payload;
              this.alreadyExist = false;
              this.succeeded = true;
              this.alertErrorMsg = '';
              this.alertSucceedMsg = res.payload || '';
              this.loading = false;
              const dialogRef = this._dialog.open(DialogConfirmation, {
                panelClass: 'custom-mat-show-image-dialog-container',
                width: '468px',
                data: {
                  title: { title: "Success", color: "#481A90" },
                  body: res.payload,
                  btnConfirmation: { title: "Ok", color: "#481A90" },
                  btnClose: { hide: true, title: "close" },
                  comment: false
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result)
                  this.onBack();
              });

            }
          },
          (error) => {
            this.succeeded = false;
            this.alreadyExist = true;
            this.alertErrorMsg = error.error.error.message || 'Ugh! Something went wrong!';
            this.loading = false;
          }
        );
    else {
      this.dataService
        .updateRoles(
          {
            roleName: this.roleForm.controls.newRole.value,
            actionsList: this._selectedRoles,
          }
        )
        .subscribe(
          (res) => {
            if (res.success === true) {
              this.displayMsg = res.payload;
              this.alreadyExist = false;
              this.succeeded = true;
              this.alertErrorMsg = '';
              this.alertSucceedMsg = res.payload || '';
              this.loading = false;
              const dialogRef = this._dialog.open(DialogConfirmation, {
                panelClass: 'custom-mat-show-image-dialog-container',
                width: '468px',
                data: {
                  title: { title: "Success", color: "#481A90" },
                  body: res.payload,
                  btnConfirmation: { title: "Ok", color: "#481A90" },
                  btnClose: { hide: true, title: "close" },
                  comment: false
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result)
                  this.onBack();
              });

            }
          },
          (error) => {
            this.succeeded = false;
            this.alreadyExist = true;
            this.alertErrorMsg = error.error.error.message || 'Ugh! Something went wrong!';
            this.loading = false;
          }
        );
    }

  }


  onBack(): void {
    this._router.navigateByUrl(`/workforce-roles`);
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.roleForm.controls[controlName].hasError(errorName) : false;
  }

  get customError() {
    return (this.submitted && !this.isSelectedRole) ? true : false;
  }
  get isSelectedRole() {
    let isSelected = false;
    Object.entries(this.groupRolesList).map((group) => {
      const roles = Object.entries(group[1]['roles']).map((role) => {
        if (role[1]['value']) isSelected = true;
        return role[1]['value'];
      });
    });
    return isSelected;
  }

  private get _selectedRoles() {
    let selected = [];
    Object.entries(this.groupRolesList).map((group) => {
      Object.entries(group[1]['roles']).map((role) => {
        if (role[1]['value']) selected.push({
          "name": role[1]['name'],
          "groupName": group[1]['name']
        });
        return role[1]['value'];
      });
    });
    return selected;
  }
}
