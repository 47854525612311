import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map, startWith } from 'rxjs/operators';
import { EquipmentService } from '../equipment.service';
import { EquipmentModel } from '../models/equipmentModel';
import {
  HandoverEquipment,
  equipmentIdsList,
} from '../models/handoverEquipmentModel';

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  styleUrls: ['./add-equipment.component.scss'],
})
export class AddEquipmentComponent implements OnInit {
  equipmentForm: FormGroup;
  equipmentCategories: any[] = [{ name: 'TOW_CAR' }];
  createdEquipments: EquipmentModel[] = [];
  operationId: any;
  currentUserJson: any;
  displayedColumns: string[];
  loading: boolean = false;
  workforceId: any;
  workforceName: string;
  commentsList: string[] = [];
  success: boolean = false;
  successMsg: any = '';
  err: boolean = false;
  errMsg: string = '';
  editingEquipmentId: any;
  editing = false;
  currentRowIndex: number;
  serialNumbers: string[];
  isScannerVisible = false;
  scannedSerialNumber = '';
  selectedCategory: string;
  searchTerm: string = '';
  filteredSerialNumbers: string[] = [];
  constructor(
    private _router: Router,
    private equipmentService: EquipmentService,
    private ActivatedRoute: ActivatedRoute,
    private location: Location
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this.workforceId = this.ActivatedRoute.snapshot.paramMap.get('id');
    this.workforceName = this.ActivatedRoute.snapshot.paramMap.get('name');
    this.displayedColumns = this.workforceId
      ? ['category', 'serialNumber', 'description', 'actions']
      : ['category', 'serialNumber', 'condition', 'description', 'actions'];
    console.log(this.workforceId);
    this.equipmentForm = new FormGroup({
      equipmentCategory: new FormControl(null, [Validators.required]),
      serialNumber: new FormControl(null, [
        Validators.required,
        ,
        this.serialNumberValidator.bind(this),
      ]),
      condition: new FormControl('FIT', [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      comment: new FormControl(null),
    });

   this.subscribeOnCategory()
  }

  get c(): any {
    return this.equipmentForm.controls;
  }

  subscribeOnCategory(){
    this.equipmentForm.get('equipmentCategory')?.valueChanges.subscribe((category) => {
      this.selectedCategory = category;
      this.getSerialNumbers(this.operationId, this.selectedCategory);
    });
  }

  get serialNumberControl() {
    console.log(this.equipmentForm.get('serialNumber'));

    return this.equipmentForm.controls['serialNumber'];
  }

  getSerialNumbers(operationId: number, category: string) {
    if (this.workforceId && this.c['equipmentCategory'].value) {
      this.equipmentService.getSerialNumbersByCategory(operationId, category).subscribe({
        next: (res) => {
          this.serialNumbers = res?.payload || [];
          this.filteredSerialNumbers = this.serialNumbers;
        },
        error: (err) => {
          console.log('error while fetching serial numbers : ', err);
        }
      });
    }
  }

  onInputChange(value: string): void {
    this.filteredSerialNumbers = this.filterSerialNumbers(value);
  }

  filterSerialNumbers(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.serialNumbers.filter((serialNumber) =>
      serialNumber.toLowerCase().includes(filterValue)
    );
  }

  focusSerialInput(inputElement: HTMLInputElement): void {
    inputElement.focus();
    console.log('Input field focused');
  }

  handleKeypress(event: KeyboardEvent): void {
    const inputValue = event.key;
    console.log('Key pressed:', inputValue);
    if (this.getValidSerialNumber(inputValue)) {
      this.scannedSerialNumber += inputValue;
      console.log('Valid serial number part:', this.scannedSerialNumber);
    }

    if (event.key === 'Enter') {
      console.log(
        'Enter key pressed, complete serial number:',
        this.scannedSerialNumber
      );
      this.updateSerialNumber(this.scannedSerialNumber);
      this.scannedSerialNumber = '';
    }
  }

  updateSerialNumber(scannedValue: string): void {
    console.log('Updating serial number:', scannedValue);
    // Check if the scanned value is in the list, if not add it
    if (!this.serialNumbers.includes(scannedValue)) {
      this.serialNumbers.push(scannedValue);
      console.log('Serial number added to list:', this.serialNumbers);
    }

    // Update the form control value
    this.serialNumberControl.setValue(scannedValue);
    console.log('Form control value set to:', this.serialNumberControl.value);
  }

  toggleScanner(): void {
    this.isScannerVisible = !this.isScannerVisible;
  }

  isAddDisabled(): boolean {
    if (this.workforceId) {
      return (
        !this.c['serialNumber'].value || !this.c['equipmentCategory'].value
      );
    } else {
      return (
        !this.c['serialNumber'].value || !this.c['equipmentCategory'].value || !this.c['description'].value
      );
    }
    
  
  }

  isUpdateDisabled(): boolean {
    if (this.workforceId) {
      return (
        !this.c['serialNumber'].value || !this.c['equipmentCategory'].value
      );
    } else {
      return this.equipmentForm.invalid;
    }
  }

  serialNumberValidator(control: any): { [key: string]: boolean } | null {
    const inputValue = control.value;
    if (!inputValue || this.getValidSerialNumber(inputValue)) {
      return null;
    }
    return { invalidSerialNumber: true };
  }

  updateEquipment() {
    const formValues = this.equipmentForm.value;
    // incase of handover existed equipment
    if (this.workforceId) {
      let equipment: EquipmentModel = {
        serialNumber: formValues.serialNumber,
        category: formValues.equipmentCategory,
        operationId: this.operationId,
      };
      this.commentsList.push(formValues.comment);
      this.checkEquipmentAvailability(equipment);
    } else {
      let equipment: EquipmentModel = {
        serialNumber: formValues.serialNumber,
        category: formValues.equipmentCategory,
        condition: formValues.condition,
        description: formValues.description,
        operationId: this.operationId,
      };

      this.createdEquipments[this.currentRowIndex] = equipment;
      this.createdEquipments = JSON.parse(
        JSON.stringify(this.createdEquipments)
      );
      this.resetForm();
      this.currentRowIndex = null;
    }
    this.editing = false;
  }

  editEquipment(equipment: EquipmentModel) {
    this.editing = true;
    this.currentRowIndex = this.createdEquipments.indexOf(equipment);
    this.equipmentForm.patchValue({
      equipmentCategory: equipment.category,
      serialNumber: equipment.serialNumber,
      condition: equipment.condition || 'FIT',
      description: equipment.description,
      comment: equipment.comment || null,
    });
  }

  onAddEquipment() {
    const formValues = this.equipmentForm.value;

    // incase of handover existed equipment
    if (this.workforceId) {
      let equipment: EquipmentModel = {
        serialNumber: formValues.serialNumber,
        category: formValues.equipmentCategory,
        operationId: this.operationId,
      };
      this.commentsList.push(formValues.comment);
      this.checkEquipmentAvailability(equipment);
    }
    // incase of add new equipment
    else {
      let equipment: EquipmentModel = {
        serialNumber: formValues.serialNumber,
        category: formValues.equipmentCategory,
        condition: formValues.condition,
        description: formValues.description,
        operationId: this.operationId,
      };
      this.createdEquipments.push(equipment);
      this.createdEquipments = JSON.parse(
        JSON.stringify(this.createdEquipments)
      );
    }
    this.resetForm();
  }

  resetForm() {
    this.equipmentForm.reset();
    this.equipmentForm.controls['condition'].setValue('FIT');
  }

  getValidSerialNumber(inputValue: string): boolean {
    // Check if the input is either a digit (0-9) or the plus sign (+)
    const isValid = /^[0-9+]+$/.test(inputValue);
    console.log('Input validation result for', inputValue, ':', isValid);
    return isValid;
  }

  onCancel() {
    if (this.workforceId) {
      this._router.navigateByUrl(`/equipments/handover-equipments`);
    } else {
      this._router.navigateByUrl(`/equipments/manage-equipments`);
    }
  }

  goBack() {
    this.location.back();
  }

  checkEquipmentAvailability(equipment: EquipmentModel) {
    this.equipmentService
      .checkEquipmentAvailability(equipment)
      .pipe(first())
      .subscribe(
        (resp) => {
          this.loading = false;
          //incase of check the first one
          if (
            this.currentRowIndex == undefined ||
            this.currentRowIndex == null
          ) {
            this.createdEquipments.push({
              ...resp.payload,
              comment: this.equipmentForm.value.comment,
            });
            this.createdEquipments = JSON.parse(
              JSON.stringify(this.createdEquipments)
            );
          }
          //in case of update existed row and check it
          else {
            this.createdEquipments[this.currentRowIndex] = {
              ...resp.payload,
              comment: this.equipmentForm.value.comment,
            };
            this.createdEquipments = JSON.parse(
              JSON.stringify(this.createdEquipments)
            );
            this.currentRowIndex = null;
          }
          this.success = true;
        },
        (error) => {
          this.loading = false;
          console.log(' err ===== ', error);
        }
      );
    this.resetForm();
  }

  removeEquipment(index: any) {
    let i = this.createdEquipments.indexOf(index);
    if (i === this.currentRowIndex) {
      this.resetForm();
      this.editing = false;
    }
    this.createdEquipments.splice(i, 1);
    this.createdEquipments = JSON.parse(JSON.stringify(this.createdEquipments));
  }

  alreadyExistedEquipments: any[] = [];
  onSubmit() {
    this.loading = true;
    console.log(this.createdEquipments);

    // incase of add new equipment
    if (!this.workforceId) {
      const equipmentList = this.createdEquipments;
      this.equipmentService
        .createEquipment(equipmentList)
        .pipe(first())
        .subscribe(
          (resp) => {
            // console.log(resp);
            this.loading = false;
            if (resp.payload.length) {
              this.createdEquipments = [];
              this.displayedColumns = [
                'category',
                'serialNumber',
                'condition',
                'description',
                'actions',
                'errorMsg',
              ];
              resp.payload.forEach((equipment) => {
                this.createdEquipments.push({
                  ...equipment.equipmentRequest,
                  errorMsg: equipment.errorMessage,
                });
              });
              this.createdEquipments = JSON.parse(
                JSON.stringify(this.createdEquipments)
              );

              console.log(this.createdEquipments);
            } else {
              this._router.navigateByUrl(`/equipments/manage-equipments`);
            }
            this.success = true;
          },
          (error) => {
            this.loading = false;
            console.log(' err ===== ', error);
          }
        );
    }
    // incase of handover existed equipment
    else {
      let equipmentList: equipmentIdsList[] = [];
      for (let i = 0; i < this.createdEquipments.length; i++) {
        equipmentList.push({
          equipmentId: this.createdEquipments[i].id,
          comment: this.createdEquipments[i].comment,
        });
      }

      const handoverPayload: HandoverEquipment = {
        employeeId: this.workforceId,
        equipmentList: equipmentList,
      };
      this.equipmentService
        .handoverEquipment(handoverPayload)
        .pipe(first())
        .subscribe(
          (resp) => {
            // console.log(resp);
            this.loading = false;
            this._router.navigateByUrl(`/equipments/handover-equipments`);
            // this.successMsg = resp.payload;
            // this.err = false;
            this.success = true;
          },
          (error) => {
            this.loading = false;
            console.log(' err ===== ', error);
          }
        );
    }
  }
}
