import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoiListComponent } from './moi-list/moi-list.component';

const routes: Routes = [
  { path: 'scanned-moi', component: MoiListComponent }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MoiRoutingModule { }
