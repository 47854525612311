import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { DialogConfirmation } from 'src/app/_shared/dialog-confirmation/dialog-confirmation';
import { AuthorizationService } from '../../_services/authorization.service';
import { workforcesDto } from '../_models/workforcesDto';
import { AdminService } from '../_services/admin.service';
import { ChangePasswordComponent } from './change-password/change-password.component';

@Component({
  selector: 'app-workforce-list',
  templateUrl: './workforce-list.component.html',
  styleUrls: ['./workforce-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class WorkforceListComponent implements OnInit {
  // displayedColumns = ['position', 'name', 'weight'];
  // dataSource = new ExampleDataSource();
  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  isExpansionDetailRow = (i: number, row: Object) =>
    row.hasOwnProperty('detailRow');
  expandedElement: any;
  membersList: any[];
  loading = false;
  failedMsg: string;
  successMsg: string;

  constructor(
    private sanitizer: DomSanitizer,
    private router: Router,
    private adminService: AdminService,
    private changeDetector: ChangeDetectorRef,
    private _dialog: MatDialog,
    public AuthorizationService: AuthorizationService,


  ) {
    // this.dataSource = null;
  }
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<workforcesDto>;

  // isExpansionDetailRow = (i: number, row: Object) => row.hasOwnProperty('detailRow');

  displayedColumns = ['index', 'id', 'name', 'operation', 'role', 'actions'];
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.getMembersList();
  }

  getMembersList() {
    this.loading = true;
    this.adminService
      .getEmployeesList(this.currentPage, this.pageSize, '').pipe(first())
      .subscribe((res) => {
        // console.log(res.payload);
        this.membersList = res.payload.content;
        this.totalRows = res.payload.totalElements;
        this.dataSource = new MatTableDataSource<workforcesDto>(
          this.membersList
        );
        this.loading = false;
      },
        error => {
          this.loading = false;
          console.log("err", error)
        }

      );
  }

  getImage(el) {
    if (el.isExpanded == true && !el.image && !el.isLoaded) {
      el.isLoaded = true;
      // console.log("getImage from workforce-list image el", el);
      this.loading = true;
      this.adminService.getImage(el.id)
        .pipe(first())
        .subscribe(
          resp => {
            // console.log('success dialog image resp', resp.payload);
            el.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp.payload);
            this.loading = false;
          },
          error => {
            // console.log(" dialog image err ===== ", error);
            el.image = '../../../assets/Icons/user-circle.svg';
            this.loading = false;
          });
    }

    return true;
  }

  detailExpand(el) {
    return el.isExpanded ? 'expanded' : 'collapsed';
  }

  reassignWorkforce() {
    this.router.navigateByUrl('/assign-workforce');
  }

  createNewMember() {
    this.router.navigateByUrl('/add-workforce')
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    // console.log(filterValue);

    if (filterValue.length > 0) {
      // this.dataSource.filter = filterValue.trim().toLowerCase();
      this.adminService.getEmployeesList(0, this.pageSize, filterValue.trim().toLowerCase()).pipe(first()).subscribe(
        res => {
          this.membersList = res.payload.content;
          this.totalRows = res.payload.totalElements;
          this.dataSource = new MatTableDataSource<workforcesDto>(
            this.membersList
          );
        },
        error => {
          console.log("err", error);
        });
    } else {
      this.getMembersList()
    }

  }

  onSearchClear() {
    this.searchKey = '';
    this.dataSource.filter = this.searchKey;
    this.getMembersList();
  }
  onView(row) {
    row.isExpanded = !row.isExpanded;
  }

  onEdit(row) {
    // console.log(row);
    this.router.navigateByUrl(`/update-workforce/${row.id}`);
  }

  onDelete(row) {

    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      data: {
        title: { title: "Are you sure?", color: "#D93636" },
        body: `You’re are going to delete workforce member
        “${row.name}”, please confirm`,
        btnConfirmation: { title: "Delete", color: "#D93636" },
        btnClose: { title: "Cancel" },
        comment: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loading = true;
        this.adminService.disableMember(row.id).pipe(first())
          .subscribe(
            data => {
              // console.log("data ========== ", data);
              // console.log('this.dataSource', this.dataSource);
              this.loading = false;
              // this._loadList(this.currentPage, this.pageSize);
              if (data.payload) {
                this.failed = true;
                this.failedMsg = "A simple danger alert—check it out!";
                setTimeout(() => {
                  this.failedMsg = "";
                  this.failed = false;
                }, 5000);
                const dialogRef2 = this._dialog.open(DialogConfirmation, {
                  panelClass: 'custom-mat-show-image-dialog-container',
                  width: '468px',
                  data: {
                    title: { title: "Notice", color: "#D93636" },
                    body: `This Workforce is Already on Shift [${data.payload}]`,
                    btnConfirmation: { hide: true, title: "Confirm", color: "#D93636" },
                    btnClose: { title: "close" },
                    comment: false
                  }
                });

                dialogRef2.afterClosed().subscribe(result => {
                });
              } else {
                const deletedIdx = this.dataSource.data.findIndex(item => item.id == row.id);
                this.succeeded = true;
                this.successMsg = "A simple success alert—check it out!";
                setTimeout(() => {
                  this.succeeded = false;
                  this.successMsg = "";
                }, 5000);
                // console.log('deleted idx', deletedIdx);
                // console.log('deleted this.dataSource.data', this.dataSource.data);

                if (deletedIdx)
                  this.dataSource.data[deletedIdx].deleted = true;
              }

            },
            error => {
              this.failed = true;
              this.succeeded = false;
              this.loading = false;
            });
      }
    });
  }

  onChangePassword(row) {
    const dialogRef = this._dialog.open(ChangePasswordComponent, {
      data: {
        id: row.id
      },
      width: '30%',
      height: 'auto',
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getMembersList();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
