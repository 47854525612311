import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyProfileComponent } from './my-profile/my-profile.component';

import { SharedModule } from '../_shared/shared.module';

@NgModule({
  declarations: [MyProfileComponent],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class MyAccountModule { }
