import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';
import { EquipmentModel } from './models/equipmentModel';
import { HandoverEquipment } from './models/handoverEquipmentModel';
// HandoverEquipment

// HandoverEquipment

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  currentUserJson: any;
  private equipmentUrl = 'operation/equipment';
  _token = `Bearer ${localStorage.getItem('token')}`;
  private headers = { Authorization: this._token };

  constructor(private http: HttpClient) {}

  getAllEquipments(
    operationId: number,
    condition: string,
    searchValue: string,
    page: number,
    limit: number,
    sortDirection: any = 'asc',
    sortField: string = ''
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}${this.equipmentUrl}?operationId=${operationId}&condition=${condition}&searchValue=${searchValue}&page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`,
      {
        headers: this.headers,
      }
    );
  }

  deleteEquipment(equipmentId: number): Observable<any> {
    return this.http.delete(
      `${env.baseURL}${this.equipmentUrl}/delete/${equipmentId}`,
      { headers: this.headers }
    );
  }

  createEquipment(params: EquipmentModel[]): Observable<any> {
    // console.log(params);
    return this.http.post(`${env.baseURL}operation/equipment`, params, {
      headers: { Authorization: this._token },
    });
  }

  checkEquipmentAvailability(params: EquipmentModel): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/equipment/custody/available?operationId=${params.operationId}&serialNumber=${params.serialNumber}&category=${params.category}`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  handoverEquipment(params: HandoverEquipment): Observable<any> {
    // console.log(params);
    return this.http.post(
      `${env.baseURL}operation/equipment/custody/assign/employee`,
      params,
      {
        headers: this.headers,
      }
    );
  }

  getEmployeesToHandover(
    operationId: number,
    searchValue: string,
    page: number,
    limit: number,
    sortDirection: any = 'asc',
    sortField: string = ''
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}${this.equipmentUrl}/custody/employee?operationId=${operationId}&searchValue=${searchValue}&page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`,
      { headers: this.headers }
    );
  }

  getEmployeesToReclaim(
    operationId: number,
    searchValue: string,
    page: number,
    limit: number,
    sortDirection: any = 'asc',
    sortField: string = ''
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}${this.equipmentUrl}/custody/employee/reclaim?operationId=${operationId}&searchValue=${searchValue}&page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`,
      { headers: this.headers }
    );
  }

  reclaimEquipment(equipmentData: any): Observable<any> {
    return this.http.post(
      `${env.baseURL}${this.equipmentUrl}/custody/employee/reclaim`,
      equipmentData,
      { headers: this.headers }
    );
  }

  getSerialNumbersByCategory( operationId: number, selectedCategory:string, serialSearch = ''): Observable<any> {
    return this.http.get(
      `${env.baseURL}${this.equipmentUrl}/serial-numbers-filter?operationId=${operationId}&category=${selectedCategory}&serialNumber=${serialSearch}`,
      { headers: this.headers }
    );
  }
}
