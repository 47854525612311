<section class="row g-0 align-items-center void-citation">
    <div class="col-12 row g-0">
        <h1><strong>Void Citations</strong></h1>
    </div>

    <div class="row" *ngIf="succeeded">
        <div class="alert success-alert" role="alert">
            A simple success alert—check it out!
        </div>
    </div>
    <div class="row" *ngIf="failed">
        <div class="alert danger-alert" role="alert">
            A simple danger alert—check it out!
        </div>
    </div>

    <!-- Search Box-->
    <mat-form-field appearance="fill" class="search-form-field manage-roles__search-bar">
        <input matInput placeholder="Search by citation number, car plate" autocomplete="off" [(ngModel)]="searchKey"
            (keyup.enter)="applyFilter($event)" (keyup)="setSearchKeyword($event)" />
        <mat-icon matPrefix>search</mat-icon>

        <div class="filter-by">
            <mat-checkbox class="" (ngModelChange)="onFilterHasEmailActionChange($event)"
                [(ngModel)]="filterHasEmailAction">
                Only Email Actions</mat-checkbox>

            <!-- <mat-form-field appearance="fill" class="date-filter">
                <mat-label>Filter by date</mat-label>
                <input matInput type="date" (ngModelChange)="onFilterDateChange($event)" [(ngModel)]="filterDate">
            </mat-form-field> -->
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">
                        <mat-icon>filter_list</mat-icon>
                    </span>
                </div>
                <input matInput type="date" placeholder="Filter by Date" (ngModelChange)="onFilterDateChange($event)" [(ngModel)]="filterDate">
            </div>
        </div>
        <div class="d-flex justify-content-between citation-filter-list">


        </div>


        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <!-- Search Box-->

    <!-- List Grid -->
    <div class="mat-elevation-z8 custom-table-card">
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows>
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let element ; let i = dataIndex">
                    <!-- {{paginator.pageIndex * paginator.pageSize + dataSource.filteredData.indexOf(element) + 1}} -->
                    {{paginator.pageIndex * paginator.pageSize + i + 1}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Citation No.
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.id}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="plateNumberEn">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Car Plate
                </mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.plateNumberEn}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="violationName">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Violation Name</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.violationName}}</mat-cell>
            </ng-container>

            <!-- Actions -->
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <button mat-icon-button (click)="onVoid(row)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M12 9.5854C10.5462 9.5854 9.39198 10.676 9.39198 12.0045C9.39198 13.333 10.5462 14.4236 12 14.4236C13.4537 14.4236 14.608 13.333 14.608 12.0045C14.608 10.676 13.4537 9.5854 12 9.5854ZM7.85352 12.0045C7.85352 9.85263 9.71799 8.13086 12 8.13086C14.282 8.13086 16.1464 9.85263 16.1464 12.0045C16.1464 14.1564 14.282 15.8782 12 15.8782C9.71799 15.8782 7.85352 14.1564 7.85352 12.0045Z"
                                fill="#481A90" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M2.76005 9.32439C5.02627 6.00557 8.34919 4 12 4C15.651 4 18.9741 6.00576 21.2403 9.32487C21.7641 10.09 22 11.0669 22 12C22 12.9333 21.764 13.9104 21.2399 14.6756C18.9737 17.9944 15.6508 20 12 20C8.34901 20 5.02595 17.9942 2.75973 14.6751L3.406 14.2807L2.76038 14.6761C2.23609 13.9108 2 12.9335 2 12C2 11.0667 2.236 10.0896 2.76005 9.32439ZM12 5.45455C8.99087 5.45455 6.10663 7.10492 4.05227 10.1137L4.05162 10.1147C3.72688 10.5887 3.53846 11.2724 3.53846 12C3.53846 12.7276 3.72688 13.4113 4.05162 13.8853L4.05227 13.8863C6.10663 16.8951 8.99087 18.5455 12 18.5455C15.0091 18.5455 17.8934 16.8951 19.9477 13.8863L19.9484 13.8853C20.2731 13.4113 20.4615 12.7276 20.4615 12C20.4615 11.2724 20.2731 10.5887 19.9484 10.1147L19.9477 10.1137C17.8934 7.10492 15.0091 5.45455 12 5.45455Z"
                                fill="#481A90" />
                        </svg>
                    </button>
                    <button mat-icon-button color="warn" (click)="onShowImage(row)">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M9.35022 3.50332C9.84275 3.20505 10.4361 3 11.0236 3H12.9849C13.5692 3 14.1606 3.20572 14.6514 3.5037C15.1425 3.80183 15.5918 4.22814 15.8536 4.73441L15.8557 4.73841L16.4695 5.94671C16.6026 6.19394 16.8644 6.34884 17.1389 6.34884C19.3669 6.34884 21.1358 8.18577 20.9918 10.3578L20.5465 17.272C20.4849 18.2265 20.1892 19.169 19.5105 19.8781C18.8194 20.6 17.8081 21 16.488 21H7.51197C6.19211 21 5.17885 20.6002 4.48671 19.8785C3.80675 19.1696 3.51062 18.2268 3.45338 17.2706C3.45335 17.2701 3.45332 17.2696 3.45329 17.2691L3.00817 10.3578C3.00816 10.3576 3.00819 10.358 3.00817 10.3578C2.86451 8.18603 4.63326 6.34884 6.86104 6.34884C7.13553 6.34884 7.39735 6.19395 7.53047 5.94673L8.14632 4.7344C8.40818 4.22802 8.85771 3.80158 9.35022 3.50332ZM9.29383 5.29917L8.6733 6.52071C8.32608 7.17527 7.62993 7.60465 6.86104 7.60465C5.37185 7.60465 4.19442 8.8319 4.29022 10.2766L4.2903 10.2778L4.73586 17.196C4.78161 17.964 5.01227 18.5904 5.42397 19.0196C5.82386 19.4366 6.46792 19.7442 7.51197 19.7442H16.488C17.5318 19.7442 18.1735 19.4367 18.5723 19.0201C18.9835 18.5905 19.2146 17.9633 19.2643 17.1931C19.2643 17.1931 19.2643 17.1931 19.2643 17.1931L19.7097 10.2766C19.8055 8.8319 18.6281 7.60465 17.1389 7.60465C16.37 7.60465 15.674 7.17522 15.3267 6.52066L15.3216 6.51093L14.7061 5.29918C14.5737 5.04411 14.3126 4.7763 13.9736 4.57048C13.6336 4.36405 13.2743 4.25581 12.9849 4.25581H11.0236C10.7289 4.25581 10.3672 4.36472 10.0268 4.57086C9.6872 4.77654 9.42624 5.04421 9.29383 5.29917Z"
                                fill="#39B2E5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M10.0732 8.65086C10.0732 8.30407 10.3608 8.02295 10.7156 8.02295H13.2851C13.6399 8.02295 13.9275 8.30407 13.9275 8.65086C13.9275 8.99764 13.6399 9.27876 13.2851 9.27876H10.7156C10.3608 9.27876 10.0732 8.99764 10.0732 8.65086Z"
                                fill="#39B2E5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.57422 14.3021C8.57422 12.4567 10.1123 10.9532 12.0002 10.9532C13.8881 10.9532 15.4261 12.4567 15.4261 14.3021C15.4261 16.1474 13.8881 17.6509 12.0002 17.6509C10.1123 17.6509 8.57422 16.1474 8.57422 14.3021ZM12.0002 12.209C10.8218 12.209 9.85895 13.1502 9.85895 14.3021C9.85895 15.4539 10.8218 16.3951 12.0002 16.3951C13.1785 16.3951 14.1414 15.4539 14.1414 14.3021C14.1414 13.1502 13.1785 12.209 12.0002 12.209Z"
                                fill="#39B2E5" />
                        </svg>
                    </button>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <mat-cell *matCellDef="let element" [attr.colspan]="5">
                    <div [@detailExpand]="element.isExpanded ? 'expanded' : 'collapsed'">
                        <mat-grid-list cols="4" rowHeight="50px" class="grid-layout-custom">
                            <mat-grid-tile class="justify-content-start py-2" colspan="1" rowspan="2">
                                <img class="workforce__img" src="../../../assets/images/Ellipse 4.jpg" />
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label class="collapse-content__header">Email:</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label class="collapse-content__header">Phone:</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label></mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label class="collapse-content__value">{{element.Email}}</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label class="collapse-content__value">{{element.Phone}}</mat-label>
                            </mat-grid-tile>
                            <mat-grid-tile colspan="1">
                                <mat-label></mat-label>
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="loading">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    LoadingData
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    NoData
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                [class.expanded-row]="row.isExpanded">
            </mat-row>

        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20,50, 100]" showFirstLastButtons [length]="totalRows"
            showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <!-- List Grid -->

    <mat-spinner *ngIf="loading"></mat-spinner>
</section>
