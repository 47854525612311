import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { CitationService, CitationVoidService } from '../_services';

import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-citation-void',
  templateUrl: './citation-void.component.html',
  styleUrls: ['./citation-void.component.scss']
})
export class CitationVoidComponent implements OnInit , AfterViewChecked{

  loading = false;
  data: any = {};
  ID;
  penalties: any;
  total: number; // from penalties com
  voidReasonsList: [] = [];
  files;
  source;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private citationVoidService: CitationVoidService


  ) {
    this.ID = this._activatedRoute.snapshot.params.id;
    this.source = this._activatedRoute.snapshot.queryParams['source'];
    this._loadCitation(this.ID);

  }

  ngOnInit(): void {
    // console.log(`CitationControlComponent ID ${this.ID}, data  `, this.data);
    this._getVoidReasonsList();
  }

  private _loadCitation(ids) {
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;
          console.log('resp.payload', resp.payload);

        },
        error => {
          console.log(" err ===== ", error);
        });
  }
  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
}
  getPenalities($event) {
    this.penalties = $event;
    return $event;
  }

  getFiles($event) {
    this.files = $event;
  }

  getTotal($event) {
    this.total = $event;
    return $event;
  }

  onApply() {
    this.loading = true
    this.penalties.settlementChannel = "ENFORCEMENT_WEB";
    this.penalties.paymentMethod = "CASH";
    this._citationService.payCitation(this.penalties).pipe(first())
      .subscribe(
        data => {
          console.log("update payCitation  data =========", data);
          this.loading = false;
          // this._router.navigateByUrl(`/enforcement-citations`);
          if (this.files !== undefined && this.files.length > 0) {
            const formData = new FormData();
            this.files.map(file => {
              formData.append('files', file);
            });
            this._citationService.postFiles(this.ID, formData).pipe(first())
              .subscribe(
                data => {
                  console.log("update payCitation  data =========", data);
                  this.loading = false;
                  this.navigateBack();
                },
                error => {
                  console.log("update payCitation err ===== ", error);
                  this.loading = false;
                });
          } else {
            this.navigateBack();
          }
        },
        error => {
          console.log("update payCitation err ===== ", error);
          this.loading = false;
        });
  }

  onCancel() {
    this.navigateBack();
  }

  private navigateBack() {
    const source = this._activatedRoute.snapshot.queryParams['source'];
    if (source === 'momah-report') {
      this._router.navigateByUrl('/reports/verifyVehiclesReport');
    } else {
      this._router.navigateByUrl('/enforcement-citations');
    }
  }

  private _getVoidReasonsList() {
    this._citationService.getVoidReasons().subscribe(res => {
      this.voidReasonsList = res.payload;
    })
  }


}
