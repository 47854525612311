import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { BootRequestRoutingModule } from './bootRequest-routing.module';
import { ListBootRequestsComponent } from './list-boot-requests/list-boot-requests.component';



@NgModule({
  declarations: [ListBootRequestsComponent],
  imports: [
    CommonModule,
    BootRequestRoutingModule,
    SharedModule
  ]
})
export class BootRequestModule { }
