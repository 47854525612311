// import { messaging } from '';
import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
// import { messaging } from 'src/configs/firebase.config';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { CacheFiltersCitationService } from '../../citation/_services';
import { User } from '../_models/user/user.modle';

@Injectable({
  providedIn: 'root',
})
export class LoginServiceService {
  private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this._isLoggedIn$.asObservable();

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient,
    private _router: Router,
    private _cacheFiltersCitationService: CacheFiltersCitationService,
    private afMessaging: AngularFireMessaging

  ) {
    const token = localStorage.getItem('token');
    this._isLoggedIn$.next(!!token);
    let currentUserJson = {};
    if (localStorage.getItem('currentUser')) {
      currentUserJson = JSON.parse(localStorage.getItem('currentUser'))
    }
    this.currentUserSubject = new BehaviorSubject<User>(currentUserJson);

    this.currentUser = this.currentUserSubject.asObservable();


  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getUser(token: string): User {
    return JSON.parse(atob(token.split('.')[1])) as User;
  }
  logout() {
    // remove user from local storage to log user out
    localStorage.setItem('token', '');
    localStorage.setItem('currentUser', '');
    this._cacheFiltersCitationService.clear();
    this._router.navigate(['/login']);
    this.currentUserSubject.next(null);
  }

  login(data): Observable<any> {
    const { userName, password } = data;
    return this.http
      .post(`${env.baseURL}operation/employee/login`, {
        username: userName,
        password: password,
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem('token', response.payload.token);
          this._isLoggedIn$.next(true);
          this.getUser(response.payload.token);
          localStorage.setItem('currentUser', JSON.stringify(response.payload));
          this.currentUserSubject.next(response.payload);
        })
      );
  }

  confirmEmail(mail): Observable<any> {
    return this.http.post(
      `${env.baseURL}operation/employee/forgetPassword`,
      { email: mail },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }


  resetPassword(data): Observable<any> {
    const { password } = data;
    //
    return new Observable((res) => {
      msg: 'we dont have a mail to send only pass and confirmPass';
    });
    // return this.http.post(
    //   `${env.baseURL}operation/employee/resetPassword`,
    //   {
    //     email: 'mahmoudatef925@gmail.com',
    //     password: password,
    //   },
    // );
  }

  //   {
  //     "userId": 20,
  //     "userStatus": "LOGGED_IN",
  //     "deviceType": "web-enforcement"
  // }

  resetSubject() {
    this.currentUserSubject.next(null);
  }

  sendNotificationToken(params: any): Observable<any> {
    return this.http.post(
      `${env.baseURL}notification/api/v1/addUser`, params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  changeLogStatus(params: any): Observable<any> {
    return this.http.put(
      `${env.baseURL}notification/api/v1/status`, params,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

   checkToken() {
    this.afMessaging.getToken
      .pipe(take(1))
      .subscribe(
        (token) => {
          if (token) {
            console.log('Existing FCM token:', token);
            this.afMessaging.deleteToken(token)
              .pipe(take(1))
              .subscribe(
                () => {
                  console.log('Token deleted successfully.');
                  this.generateNewToken();
                },
                (error) => {
                  console.error('Unable to delete token:', error);
                  // Handle the error accordingly
                }
              );
          } else {
            console.log('No existing FCM token found.');
            this.generateNewToken();
          }
        },
        (error) => {
          console.error('Unable to get token:', error);
          // Handle the error accordingly
        }
      );
  }

  generateNewToken() {
    this.afMessaging.requestToken
      .pipe(take(1))
      .subscribe(
        (token) => {
          console.log('New FCM token:', token);
          const employeeDto = JSON.parse(localStorage.getItem('currentUser')).employeeDto;
          let params = {
            userId: employeeDto?.id,
            token: token,
            deviceType: "web-enforcement"
          }
          this.sendNotificationToken(params).subscribe((res) => {
          });
        },
        (error) => {
          console.error('Unable to generate new token:', error);
          // Handle the error accordingly
        }
      );
  }
  
}
