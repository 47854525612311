import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AdminService } from '../admin/_services/admin.service';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../data.service';
import { ShiftSchedulerService } from '../_services';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
@Component({
  selector: 'app-create-shift',
  templateUrl: './create-shift.component.html',
  styleUrls: ['./create-shift.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateShiftComponent implements OnInit {
  private _destroyed = new Subject<void>();
  roles: String[] = [];
  operations: String[] = [];
  freeWorkforcesList: any[] = [];
  supervisorsList: any[] = [];
  next: boolean = false;
  shiftData: {
    shiftName: string;
    shiftStartDate: string;
    shiftEndDate: string;
    shiftStartTime: string;
    shiftEndTime: string;
    shiftRoleName: string;
  };

  loading: boolean = false;
  submitted: boolean = false;
  startMinDate;
  endMinDate;

  // other time validation for 12 hours ^(0?[1-9]|1[0-2]):[0-5][0-9]$
  createShiftForm = new FormGroup({
    shiftName: new FormControl('', [Validators.required]),
    userRole: new FormControl('', [Validators.required]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    startTime: new FormControl('', [
      Validators.required,
      // Validators.pattern('([01]?[0-9]|2[0-3]):[0-5][0-9]'),
    ]),
    endTime: new FormControl('', [
      Validators.required,
      // Validators.pattern('([01]?[0-9]|2[0-3]):[0-5][0-9]'),
    ]),
  });

  get f() { return this.createShiftForm.controls; }

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private http: DataService,
    private shiftService: ShiftSchedulerService,
    private changeDetector: ChangeDetectorRef,

  ) {
    this.startMinDate = new Date().toISOString().split('T')[0];
    this.endMinDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this.getOperationsList();
    this.getRolesList();
    this.getSupervisorsList();
  }


  checkTimeValidation(start, end) {
    return start.value.split(':')[0] >= end.value.split(':')[0];
  }

  getErrorMessage() {
    if (this.createShiftForm.controls.userEmail.hasError('required')) {
      return 'You must enter a value';
    }
    return this.createShiftForm.controls.userEmail.hasError('email')
      ? 'Not a valid email'
      : '';
  }

  getPhoneErrorMessage() {
    if (this.createShiftForm.controls.userPhone.hasError('required')) {
      return 'You must enter a value';
    }
    return this.createShiftForm.controls.userPhone.hasError('')
      ? 'Please enter valid phone'
      : '';
  }

  goBack() {
    this.location.back();
  }

  getOperationsList() {
    this.http.getAllOperations().subscribe((res) => {
      this.operations = res.payload;
    });
  }

  getRolesList() {
    this.http.getRoles().subscribe((res) => {
      this.roles = res.payload;
    });
  }

  nextForm() {
    this.next = true;
    this.submitted = true;
  }

  createNewShift() {
    const newStartDate = new Date(this.createShiftForm.controls.startDate.value)
      .toISOString()
      .split('T')[0];
    const newEndDate = new Date(this.createShiftForm.controls.endDate.value)
      .toISOString()
      .split('T')[0];

    console.log('newStartDate, newEndDate ==== ', newStartDate, newEndDate);

    this.shiftData = {
      shiftName: this.createShiftForm.controls.shiftName.value,
      shiftStartDate: newStartDate,
      shiftEndDate: newEndDate,
      shiftStartTime: this._timeFormat(this.createShiftForm.controls.startTime.value),
      shiftEndTime: this._timeFormat(this.createShiftForm.controls.endTime.value),
      shiftRoleName: this.createShiftForm.controls.userRole.value,
    };

    console.log('shiftData ==== ', this.shiftData);

    this.getWorkforcersList(this.shiftData);
  }

  getWorkforcersList(shiftData) {
    const startDate = shiftData.shiftStartDate;
    const endDate = shiftData.shiftEndDate;
    const startTime = shiftData.shiftStartTime;
    const endTime = shiftData.shiftEndTime;
    const roleName = shiftData.shiftRoleName;
    this.loading = true;
    this.shiftService
      .getFreeWorkforces(startDate, endDate, startTime, endTime, roleName)
      .subscribe((res) => {
        console.log("create-shift getWorkforcersList res", res);
        this.freeWorkforcesList = res.payload.sort((a, b) => a.name > b.name ? 1 : -1);
        this.nextForm();
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  getSupervisorsList() {
    this.shiftService.getSupervisors().subscribe((res) => {
      this.supervisorsList = res.payload;
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.createShiftForm.controls[controlName].hasError(errorName) : false;
  }

  private _timeFormat(d) {
    const hours = (d.getHours() < 10) ? `0${d.getHours()}` : d.getHours();
    const minutes = (d.getMinutes() < 10) ? `0${d.getMinutes()}` : d.getMinutes();
    return `${hours}:${minutes}`;
  }

  get checkStartTimeValidation() {
    if (this.f.startTime.value && this.f.endTime.value) {
      if (this.f.startDate.value && this.f.endDate.value) {
        return ((this.f.startTime.value > this.f.endTime.value) && (new Date(this.f.startDate.value) >= new Date(this.f.endDate.value)));
      }
    }
    return false;
  }
}
