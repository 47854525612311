<section class="login mx-auto">
    <img class="login__shapes ellipse-1" src="../../../assets/Icons/Ellipse 1.svg" />
    <img class="login__shapes ellipse-2" src="../../../assets/Icons/Ellipse 2.svg" />

    <section class="row g-0 login__content-container">
        <div class="col-6">
            <img class="login__logo" src="../../../assets/Icons/thakiOperationLogo.svg" alt="" />
            <section *ngIf="!resetPassSuccess">

                <h3 class="reset__title"> Reset Password </h3>
                <div class="reset__message"> Enter your registered email, and We’ll send you a
                    link to your email to reset your password. </div>

                <!-- new-pass-form -->
                <form class="example-form" [formGroup]="confirmMailForm" (ngSubmit)="submitMail()">
                    <div class="col-12">
                        <mat-form-field class="full-width" appearance="fill">
                            <mat-label>Email</mat-label>
                            <input matInput placeholder="JohnDoe@abc.com" formControlName="email">
                            <!-- <mat-hint>Errors appear instantly!</mat-hint> -->
                            <mat-error
                                *ngIf="confirmMailForm.controls.email.hasError('email') && !confirmMailForm.controls.email.hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="confirmMailForm.controls.email.hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>

                        <button type="submit" class="btn login__btn full-width mt-2">Reset Password</button>
                    </div>
                </form>
            </section>
            <section *ngIf="resetPassSuccess">
                <app-reset-message></app-reset-message>
            </section>
        </div>
    </section>
</section>