import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DetentionService {
  // operationId: any;
  currentUserJson: any;

  constructor(private http: HttpClient) {
    // if (localStorage.getItem('currentUser')) {
    //   this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
    //   this.operationId = this.currentUserJson.employeeDto.operationId;
    //   console.log(this.operationId);

    // }
  }
  _token = `Bearer ${localStorage.getItem('token')}`;

  getDetentionRequests(operationId: number, page: number, limit: number, plateNumber: string = '', sortingType: number = 1, sortingBy: string = 'detention_order_id', status: string[],): Observable<any> {  // with any status
    if (plateNumber) {
      return this.http.get(`${env.baseURL}orders/detention-orders/get-detention-orders/${operationId}?detention_order_status=${status}&page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}&plate_number=${plateNumber}`, {
        headers: { Authorization: this._token },
      });
    } else {
      return this.http.get(`${env.baseURL}orders/detention-orders/get-detention-orders/${operationId}?detention_order_status=${status}&page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}`, {
        headers: { Authorization: this._token },
      });
    }

  }

  acceptDetentionRequest(data: AcceptDetentionRequestModel): Observable<any> {
    const { detentionOrderStatus, workforceComment, slotNumber, detentionOrderId, evidences } = data;
    const formData = new FormData();
    formData.append('detentionOrderStatus', new Blob([JSON.stringify(detentionOrderStatus)], { type: "application/json" }));
    formData.append('workforceComment', new Blob([JSON.stringify(workforceComment)], { type: "application/json" }));
    formData.append('slotNumber', new Blob([JSON.stringify(Number(slotNumber))], { type: "application/json" }));
    formData.append('detentionOrderId', new Blob([JSON.stringify(detentionOrderId)], { type: "application/json" }));
    if (evidences) formData.append('evidences', evidences);

    const headers = new HttpHeaders().set('Authorization', this._token);
    return this.http.put(`${env.baseURL}orders/detention-orders/accept-order`, formData, { headers: headers });
  }

  releaseDetentionRequest(data: ReleaseDetentionRequestModel): Observable<any> {
    const { workforceId, releaseTo, description, detentionOrderId } = data;
    const formData = new FormData();
    formData.append('workforceId', new Blob([JSON.stringify(workforceId)], { type: "application/json" }));
    formData.append('releaseTo', new Blob([JSON.stringify(releaseTo)], { type: "application/json" }));
    formData.append('description', new Blob([JSON.stringify(Number(description))], { type: "application/json" }));
    formData.append('detentionOrderId', new Blob([JSON.stringify(detentionOrderId)], { type: "application/json" }));
    const headers = new HttpHeaders().set('Authorization', this._token);
    return this.http.put(`${env.baseURL}orders/detention-orders/release-order`, formData, { headers: headers });
  }

}
export interface AcceptDetentionRequestModel {
  id?: number,
  detentionOrderStatus: string,
  workforceComment: string,
  slotNumber: string,
  detentionOrderId: number,
  evidences: any
}
export interface ReleaseDetentionRequestModel {
  id?: number,
  workforceId: number,
  releaseTo: string,
  description: string,
  detentionOrderId: number
}

export interface DetentionRequestModel {
  detentionAddress: string,
  detentionCenterId: number,
  detentionLatitude: number,
  detentionLongitude: number,
  detentionName: string,
  detentionOrderStatus: string,
  detentionRequestId: number,
  groundingOrderId: number,
  id?: number,
  releaseTo?: string,
  slotNumber: string,
  createdDate?: string,
  plateNumber: string,
  model: string,
  color: string,
  make: string,
  evidences: string[],

}
