<section class="row g-0 align-items-center mb-3">
    <div class="col-12 row g-0 mb-3">
        <div class="col-md-9 col-sm-12 ">
            <h1><strong>Release Vehicles</strong></h1>
        </div>
    </div>

    <div class="" *ngIf="successMsg">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>
    <div class="" *ngIf="failedMsg">
        <div class="alert danger-alert" role="alert">
            {{ failedMsg }}
        </div>
    </div>

    <!-- Search Box-->
    <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
        <input matInput placeholder="Search by vehicle info" autocomplete="off" [(ngModel)]="searchKey"
            (keyup)="applySearch($event)" />
        <mat-icon matPrefix>search</mat-icon>
        <app-filter-list [listTitle]="'Detention Order Status'" [multiple]='true' [label]="'Filter'"
            [icon]="'filter_list'" (clearSelectedItems)="_loadList(operationId,0, pageSize);" [clearBtn]="'Clear'"
            [filterBtn]="'Filter'" [returnValue]="'name'" [list]="statusList" (applyFilter)="applyFilter($event)">
        </app-filter-list>

        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <!-- Search Box-->
    <section class="mb-5">
        <!-- List Grid -->
        <div class="mat-elevation-z8 custom-table-card mb-5">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                multiTemplateDataRows>
                <ng-container matColumnDef="index">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element ; let i = dataIndex">
                        {{paginator.pageIndex * paginator.pageSize + i + 1}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="vehicleInfo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Info</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ slicePlateNumber(element.plateNumber)}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="parkingSlot">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Parking Slot</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.slotNumber}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.createdDate | date :'short'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-icon-button class='actionBtn' (click)="openReleaseDetentionOrderDialog(row)"
                            *ngIf='row.detentionOrderStatus=="READY_TO_RELEASE"'>
                            <img src="../../../assets/Icons/line-md_confirm-circle.svg" class='mx-1 ' alt="">Release
                            Request

                        </button>
                        <div class='rounded border note border-1 px-2 py-1 cursor-pointer'
                            *ngIf='row.detentionOrderStatus=="ACCEPTED"'
                            (click)="navigateToSettleCitation(row.plateNumber)">Need settlement</div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        LoadingData
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        NoData
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                    [class.expanded-row]="row.isExpanded">
                </mat-row>

            </mat-table>
            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>

    <ng-template #releaseDetentionOrderDialog>
        <div class=" h-100">
            <div class="row mb-2">
                <div class="col-12 row g-0 px-1 align-items-center">
                    <span class="d-flex justify-content-between">

                        <div class="print-citation__title">
                            <h4 class="fw-bold h3">Release Vehicles</h4>
                        </div>
                        <div class="cross">
                            <mat-icon class="close" (click)="dialogRef.close(null)">close</mat-icon>
                        </div>
                    </span>
                </div>
            </div>

            <div>
                <div class="pb-1 mb-0" dialog-content>
                    <form [formGroup]="releaseOrderForm" (ngSubmit)="releaseDetentionOrder()">
                        <label for="releaseTo" class='px-1 mb-1'>Applicant Status</label>
                        <div class="row my-3 mb-4">
                            <mat-radio-group formControlName="releaseTo" [(ngModel)]='releaseToValue' class=''>
                                <mat-radio-button class="mat-radio-label-custom radioIcon mx-2"
                                    value="owner">Owner</mat-radio-button>
                                <mat-radio-button class="mat-radio-label-custom radioIcon"
                                    value="other">Other</mat-radio-button>
                            </mat-radio-group>
                            <!-- <p *ngIf="releaseToValue=='other'">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Obcaecati quis doloribus unde molestias provident laboriosam, ab quas aliquid sequi eveniet commodi distinctio laudantium ut quisquam, numquam in soluta quidem. Quaerat?</p> -->
                            <div *ngIf="releaseToValue=='other'" class="mt-3">
                                <div class="custom-inputs mx-0">
                                    <label for="name">
                                        Name
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100 px-0">
                                        <input matInput placeholder='Name' formControlName="name" id="name" />
                                    </mat-form-field>
                                </div>
                                <div class=" custom-inputs mx-0">
                                    <label for="desc">
                                        Description
                                    </label>
                                    <mat-form-field appearance="outline" class="w-100 px-0">
                                        <input matInput placeholder='Description' formControlName="desc" id="desc" />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="mat-dialog-actions d-flex justify-content-between">
                            <button class="btn col-5 cancelBtn text-white rounded" mat-button mat-dialog-close
                                (click)='dialogRef.close(null)'> Cancel </button>
                            <button class="btn col-5 acceptBtn text-white rounded" type="submit" mat-button>Release
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    </ng-template>