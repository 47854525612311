import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DailyMonitorList } from '../_models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { first } from 'rxjs/operators';
import { DailyMonitorListService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

import { AuthorizationService } from 'src/app/_services';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-daily-monitor-list',
  templateUrl: './daily-monitor-list.component.html',
  styleUrls: ['./daily-monitor-list.component.scss']
})
export class DailyMonitorListComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  // testing new
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;

  expandedElement: any;
  dataList: any = [{ shifts: [], employeeMap: {} }];

  sortBy: string = 'id';
  sortDirection: string = 'ASC';

  selectedDateRange: any[] = [];

  dateFilter = this._dateFormat(new Date());
  filterFromDate;
  filterToDate;

  rolesList: string[] = [];
  selectedRoleName: string = "";
  operationId;

  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  constructor(
    private _router: Router,
    private _service: DailyMonitorListService,
    private _dialog: MatDialog,
    public authorizationService: AuthorizationService,
    private sanitizer: DomSanitizer,
  ) {
    if (localStorage.getItem('currentUser')) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this._loadRolesList(0, 1000);
    this._loadList(this.operationId, this.dateFilter, this.dateFilter);
  }

  private _loadList(operationId: number, fromDate: string, toDate: string) {
    this.loading = true;
    this._service.getList(operationId, fromDate, toDate)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload;
          // this.totalRows = resp.payload.totalElements;
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadRolesList(page: number, limit: number, query: string = '') {
    this.loading = true;
    this._service.getRolesList(page, limit, query)
      .pipe(first())
      .subscribe(
        resp => {
          const respContent = resp.payload.content;
          console.log('respContent ', respContent);
          respContent.map((item) => this.rolesList.push(item.roleName));
          this.selectedRoleName = this.rolesList[0];
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  onApplyFilter() {
    // this._loadList(this.operationId, this.filterFromDate, this.filterToDate);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.operationId, this.filterFromDate, this.filterToDate);
  }

  // not used yet
  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }
  timeFormat(time: string) {
    const timeSpitted = time.split(':');
    return Number(timeSpitted[0]) > 12
      ? `${Number(timeSpitted[0]) - 12}:${timeSpitted[1]} PM`
      : `${Number(timeSpitted[0])}:${timeSpitted[1]} AM`;
  }

  get paginationTitle() {
    const d = new Date(this.dateFilter);
    //December, 22 Saturday 
    return `${this.monthNames[d.getMonth()]}, ${d.getDate()} ${this.weekday[d.getDay()]}`;

  }

  onPrevPage() {
    console.log("onPrevPage");
    const prevDay = new Date(this.dateFilter);
    prevDay.setDate(prevDay.getDate() - 1);
    console.log("onPrevPage", prevDay);
    this.dateFilter = this._dateFormat(prevDay);
    console.log("onPrevPage this.dateFilter", this.dateFilter);
    this._loadList(this.operationId, this.dateFilter, this.dateFilter);
  }

  onNextPage() {
    const nextDay = new Date(this.dateFilter);
    nextDay.setDate(nextDay.getDate() + 1);
    console.log("onNextPage", nextDay);
    this.dateFilter = this._dateFormat(nextDay);
    console.log("onNextPage this.dateFilter", this.dateFilter);
    this._loadList(this.operationId, this.dateFilter, this.dateFilter);

  }

  getEmployeeMap(id, key) {
    return this.dataList["employeeMap"][id][key];
  }

  getImage(img) {
    // console.log("image", img)
    return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + img);
  }
  onDateFilterChange($event) {
    this._loadList(this.operationId, this.dateFilter, this.dateFilter);
  }

  getShiftStatusColor(status) {
    switch (status) {
      case "ABSENT":
      case "LATE":
        return "#D93636";
      case "ASSIGNED":
      case "CHECKED_IN":
      case "CHECKED_IN_LATE":
        return "#39B2E5";
      case "CHECKED_OUT":
        return "#31CC53";

    }
  }

  getShiftStatusName(status) {
    switch (status) {
      case "ABSENT":
        return "Absent";
      case "LATE":
        return "Late";
      case "ASSIGNED":
        return "Assigned";
      case "CHECKED_IN":
        return "Checked In";
      case "CHECKED_IN_LATE":
        return "Checked In";
      case "CHECKED_OUT":
        return "Finished";
    }
  }

  showHr(status) {
    console.log("status", status, (status !== 'ABSENT'), (status !== 'LATE'), (status !== 'ABSENT' && status !== 'LATE'));
    return (status !== 'ABSENT' && status !== 'LATE');
  }

}
