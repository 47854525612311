import {Component, Inject} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {CitationService} from '../../_services';
import {first} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import {LocationDto, ShowCitationLocationDialogComponent} from '../../../citation-details-dialog/show-citation-location-dialog.component';
import {Router} from '@angular/router';
import {ShowImagesDialog} from '../show-images-dialog/show-images-dialog';

@Component({
  selector: 'app-show-citation-details-dialog',
  templateUrl: './show-citation-details-dialog.component.html',
  styleUrls: ['./show-citation-details-dialog.component.scss'],
})
export class ShowCitationDetailsDialogComponent {

  CitationDisplayedColumns: string[] = ['date', 'time', 'violationName', 'status',  'operationName', 'streetName', 'location'];
  vehicleDisplayedColumns: string[] = [ 'enPlateNumber', 'colorEnName', 'makeArName', 'type', 'countryArName'];

  penaltiesDisplayedColumns: string[] = ['penalty', 'fees', 'status', 'voidReason', 'image'];
  loading = false;
  title = 'Vehicle Information';
  CitationDataSource: MatTableDataSource<any>;
  penaltiesDataSource: MatTableDataSource<any>;
  voidImages: any[] = []
  citationImages: any[] = []
  disableImages: boolean = false

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ShowCitationDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.voidImages = this.data?.images.filter(element => element.imagePathSource == "VoidAttachment")
    this.citationImages = this.data?.images.filter(element => element.imagePathSource == "CitationEvidence")
    this._loadCitation();
  }

  disableEvidence(element) {
    return element.status == 'Executed' || (this.citationImages.length == 0 && (element.status == 'Opened' || element.status == 'Settled')) || (this.voidImages.length == 0 && element.status == 'Voided')
  }


  private _loadCitation() {

    if (this.data) {
      this.CitationDataSource = new MatTableDataSource<any>([this.data]);
      this.penaltiesDataSource = new MatTableDataSource<any>(this.data?.citationPenalties);
    }
  }

  onCitationLocation()  {

    const dialogRef = this.dialog.open(ShowCitationLocationDialogComponent, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '1300px',
      height: '700px',
      data: {latLong: [this.data?.latitude, this.data?.longitude], labelName: this.data?.plateNumberEn, id: this.data?.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.router.navigateByUrl(`/enforcement-citations`);
    });

  }

  onShowImage(element) {
    const dialogRef = this.dialog.open(ShowImagesDialog, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '960px',
      height: '700px',
      data: element?.status == "Voided"? this.voidImages: this.citationImages
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });

    //this._router.navigateByUrl(`//${row.id}`);

  }

  close(): void {
    this.dialogRef.close();
  }

}
