import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Shift } from '../_models/shift.model';
import { ShiftSchedulerService } from '../_services';
import { AdminService } from '../admin/_services/admin.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { first, map, startWith, takeUntil, filter, sample } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatDialog } from '@angular/material/dialog';
import { RemoveWorkforceDialogComponent } from './remove-workforce-dialog/remove-workforce-dialog.component';
import { IdleWorkforceDialogComponent } from './idle-workforce-dialog/idle-workforce-dialog.component';
import { ContentObserver } from '@angular/cdk/observers';

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss'],
})
export class ShiftDetailsComponent implements OnInit {
  // @ViewChild('deleteModal') deleteModal: ElementRef;
  @ViewChild('startDate') StartDate: ElementRef;
  @ViewChild('endDate') EndDate: ElementRef;
  @ViewChild('emplyeeId') emplyeeId: ElementRef;
  @ViewChildren("selectEmployee") selectEmployee: QueryList<ElementRef<HTMLSelectElement>>;

  filteredSupervisorOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  supervisorNameFilterSearchCtrl: FormControl = new FormControl('');
  dataSource = new MatTableDataSource<Shift>();
  selection = new SelectionModel<Shift>(true, []);
  editShiftTime: boolean = false;
  editWorkforce: boolean = false;
  supervisorsList: any = [];
  shiftCardDetail: any = { id: '', role: { name: '' } };
  shiftDetail: Shift[] = [];
  display: string = 'none';
  displayRemoveMemberDialog: string = 'none';
  months: any[] = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ];
  startDate: any;
  endDate: any;
  startTime: any;
  endTime: any;
  roleName: any;
  shiftName: any;
  shiftId = 0;
  success: boolean = false;
  isNotStarted: boolean = false;
  successMsg: string = '';
  failed: boolean = false;
  errMsg: string = '';
  newShiftData: object[] = [];
  employeesList: any[];
  submit: boolean = false;
  day : any ;

  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  freeEmployees: any;
  loading: boolean;
  isShiftStarted: boolean;

  protected _onDestroy = new Subject<void>();
  filteredEmployeeData;
  filtersFormCtrlEmployee = new FormControl({ value: '', disabled: true });
  employeeNameFilterSearchCtrl: FormControl = new FormControl('');

  // employeeName: string = '';
  filteredEmployeeOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  shiftForm: FormGroup;

  selectedDay: string = "";
  shiftDateTimesubmitted: boolean = false;
  shiftDateTimeForm: FormGroup;
  constructor(
    // private modalService: NgbModal,
    private router: Router,
    private shiftService: ShiftSchedulerService,
    private adminService: AdminService,
    private location: Location,
    private route: ActivatedRoute,
    private changeDetectorRefs: ChangeDetectorRef,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.loadingService();

  }

  loadingService(){

    this.reloadSupervisorOptions();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.supervisorsList.slice())),
    );
    // console.log(this.filteredOptions);
    this.createForm();
    this.employeeNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredEmployeeData.filter(item =>
            item.name.toLowerCase().indexOf(this.employeeNameFilterSearchCtrl.value.toLowerCase()) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredEmployeeOptions.next(
          filtered
        );
      }, error => { });


     this.route.queryParamMap.subscribe(params => {
       this.startDate = params.get('startDate');
       this.endDate = params.get('endDate');
       this.day = params.get('startDate');
       this.startTime = params.get('startTime');
       this.endTime = params.get('endTime');
       this.roleName = params.get('roleName');
       this.shiftName = params.get('shiftName');
       this.shiftId = +params.get('shiftId');
    })//this.route.snapshot.queryParamMap.get('startTime');

    this.getShift();
    this.getSupervisorsList();
    this.getEmployeesList();
    // this.getFreeEmployee();
    this._loadGetEmployeesByName();

    this.selectedDay = this.route.snapshot.queryParamMap.get('startDate');

    const day = new Date().getDate();
    const today = +this.selectedDay.split('-')[2];

    console.log('d', day, 'tod', today, day > today);

    this.isShiftStarted = day >= today;

    this._generateShidtDateTimeForm();

    this.supervisorNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.supervisorsList.filter(item =>
            item.name.toLowerCase().indexOf(this.supervisorNameFilterSearchCtrl.value.toLowerCase()) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredSupervisorOptions.next(
          filtered
        );
      }, error => { });
  }
  reloadSupervisorOptions(){
    this.filteredSupervisorOptions.next(
      this.supervisorsList
    );
  }

  createForm() {
    this.shiftForm = this.fb.group({
      filtersFormCtrlEmployee: this.fb.array([])
    })
  }


  public get filtersEmployee(): FormArray {
    return this.shiftForm.get('filtersFormCtrlEmployee') as FormArray
  }

  addshift() {
    let formgrop = this.fb.group({
      id: 0,
      day: this.shiftCardDetail.startDate,
      shiftStartTime: this.shiftCardDetail.startTime,
      shiftEndTime: this.shiftCardDetail.endTime,
      // status: '',

      employee: this.fb.group({ id: null, name: '' }),
      supervisor: this.fb.group({ id: null, name: '' }),
    })
    this.filtersEmployee.push(formgrop)
  }
  deleteShiftfromArray(ind) {
    this.filtersEmployee.removeAt(ind)
  }
  selectedEmployee = new Set<string>();

  selected() {
    this.selectedEmployee.clear();
    this.selectEmployee.forEach(ls => {
      const selectedVal = ls.nativeElement.value;
      if (selectedVal && selectedVal !== "undefined") this.selectedEmployee.add(selectedVal);
    });

    this.selectedEmployee.forEach(sl => {
      console.log(`selected employee -> ${sl}`);
    });
    console.log("==================================");
  }

  isSelected(employee: string) {
    return this.selectedEmployee.has(employee);
  }

  getFreeEmployee() {
    this.shiftService.getFreeWorkforces(this.startDate, this.endDate, this.startTime, this.endTime, this.roleName).subscribe((res) => {
      // console.log(res.payload);
      this.freeEmployees = res.payload;
    })
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(filterValue);

    return this.supervisorsList.filter(option => (option.name).toLowerCase().includes(filterValue));
  }

  private _loadGetEmployeesByName(keyword: string = ''): void {
    this.loading = true;
    this.shiftService.getFreeWorkforcesByName(this.startDate, this.endDate, this.startTime, this.endTime, this.roleName, keyword)
      .pipe(first())
      .subscribe(
        resp => {
          this.filteredEmployeeData = resp.payload;
          this.filteredEmployeeOptions.next(
            this.filteredEmployeeData
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);

        });
  }

  private _filterEmployee(value: string) {
    const filterValue = value;
    console.log(filterValue, "filterValue")
    if (filterValue.length >= 3) {
      this._loadGetEmployeesByName(filterValue);
    }

    return;
  }

  goBack() {
    this.location.back();
  }

  getSupervisorsList() {
    this.shiftService.getSupervisors().subscribe((res) => {
      this.supervisorsList = res.payload;
    });
  }

  getEmployeesList() {
    // console.log(this.shiftService.shiftEmployeesList);
    this.adminService.getEmployeesDetails().subscribe(res => {
      // console.log(res.payload.content);
      this.employeesList = res.payload.content
    })
  }

  getShift() {
    this.shiftService
      .getShiftDetail({
        startDate: this.startDate,
        endDate: this.endDate,
        startTime: this.startTime,
        endTime: this.endTime,
        roleName: this.roleName,
        shiftName: this.shiftName,
        shiftId: this.shiftId
      })
      .subscribe(
        (res) => {
          this.shiftCardDetail = res.payload;
          console.log("shiftDetails",res.payload)
          this.shiftCardDetail.startTime = res.payload.shiftDetails[0].shiftStartTime;
          this.shiftCardDetail.endTime = res.payload.shiftDetails[0].shiftEndTime;
          this.isNotStarted = res.payload.isNotStarted;
          this.dataSource.data = res.payload.shiftDetails;
          this.newShiftData = this.dataSource.data.map(
            ({ id, shiftEndTime, shiftStartTime, employee, supervisor }) => ({
              "shiftDetailsId": id,
              "startTime": shiftStartTime,
              "endTime": shiftEndTime,
              "employeeId": Number(employee['id']),
              "supervisorId": Number(supervisor['id'])
            }));
          res.payload.shiftDetails.forEach(element => {
            let formgrop: FormGroup = this.fb.group({})
            Object.keys(element).forEach(key => {
              if (typeof element[key] == 'object' && element[key] != null) {
                formgrop.addControl(key, this.fb.group({}))
                Object.keys(element[key]).forEach(control => {
                  (formgrop?.get(key) as FormGroup).addControl(control, this.fb.control(element[key][control]))
                })

              } else {

                formgrop.addControl(key, this.fb.control(element[key]))
              }
            })
            formgrop.addControl('filterSerach', this.fb.control(null))
            this.filtersEmployee.push(formgrop)
          });

          this._loadShiftDateTimeForm();

        },
        (error) => {
          console.log(error.message);
        }
      );
  }

  deleteShift() {
    this.display = 'block';
  }

  confirmDelete() {
    this.shiftService.deleteShift(this.shiftCardDetail.id).subscribe(
      (res) => {
        if (res.success) this.router.navigateByUrl('/shifts-schedule');
      },
      (error) => {
        this.errMsg = error.error.error.message;
        this.failed = true;
        this.cancelDelete();
      }
    );
  }

  cancelDelete() {
    this.display = 'none';
    this.displayRemoveMemberDialog = 'none';
  }

  editShiftDate() {
    this.editShiftTime = true;
    this.gShiftDateTimeForm['startTime'].enable();
    this.gShiftDateTimeForm['endTime'].enable();
    this.gShiftDateTimeForm['startDate'].enable();
    this.gShiftDateTimeForm['endDate'].enable();

  }

  handleEditWorkforce() {
    this.editShiftTime = false;
    this.editWorkforce = true;
    this.filtersFormCtrlEmployee.enable();
    // this.getShift();
    this.submit = false;
  }

  addShiftMember() {
    this.addshift()
    this.changeDetectorRefs.detectChanges();
    console.log(this.dataSource.data);
    this.submit = true;

    const data = {
      id: 0,
      day: this.shiftCardDetail.startDate,
      shiftStartTime: this.shiftCardDetail.startTime,
      shiftEndTime: this.shiftCardDetail.endTime,
      // status: '',
      employee: { id: '', name: '' },
      supervisor: { id: '', name: '' },
    }

    this.newShiftData.push({ endTime: this.shiftCardDetail.endTime, startTime: this.shiftCardDetail.startTime });
    this.dataSource.data.push(data);
    this.dataSource = new MatTableDataSource<Shift>(this.dataSource.data);

  }

  handleSaveWorkforce() {

    console.log('handleSaveWorkforce workforceShiftDetails data =======', {
      id: this.shiftCardDetail.id,
      day: this.shiftCardDetail.startDate,
      newData: this.newShiftData
    });

    this.shiftService.updateShiftDetails(
      this.shiftCardDetail.id,
      this.endDate,
      this.newShiftData
    ).pipe(first()).subscribe((res) => {
      this.successMsg = res.payload;
      this.failed = false;
      this.success = true;
      this.dataSource.data = [];
      this.newShiftData = [];
      this.createForm()
      this.getShift();
    }, (err) => {
      console.log("err :", err);
      // this.dataSource.data.pop();
      // this.newShiftData.pop();
      console.log(this.filtersEmployee);
      this.createForm()
      this.getShift();
      console.log(
        this.dataSource.data);

      // this.dataSource.data = [];
      // this.newShiftData = [];

      this.errMsg = err.error.error.message;
      this.success = false;
      this.failed = true;
    });
    this.editShiftTime = false;
    this.editWorkforce = false;
  }

  handleSaveShiftOld() {
    this.editWorkforce = false;
    const day = new Date().getDate();
    const month = new Date().getMonth();
    const today = this.StartDate.nativeElement.value.split('-')[2];
    const thisMonth = this.months.indexOf(
      this.StartDate.nativeElement.value.split('-')[1]
    );
    this.EndDate.nativeElement.value;
    if (day < today) {
      this.shiftService
        .updateShiftDate(
          this.shiftCardDetail.id,
          this.StartDate.nativeElement.value,
          this.EndDate.nativeElement.value
        )
        .subscribe(
          (res) => {
            this.successMsg = res.payload;
            this.failed = false;
            this.success = true;
          },
          (error) => {
            this.errMsg = error.error.error.message;
            this.success = false;
            this.failed = true;
          }
        );
    } else {
      this.errMsg = 'Shift already Started please pick a date';
      this.success = false;
      this.failed = true;
    }
    this.editShiftTime = false;
  }

  handleSaveShift() {

    // this.submitted = true;
    // stop here if form is invalid
    if (this.shiftDateTimeForm.invalid) return;

    this.loading = true;
    console.log('shiftDateTimeForm value on handleSaveShift  ========== ', this.shiftDateTimeForm.value);

    const data = {
      id: this.shiftCardDetail.id,
      startDate: this.startDate,
      endDate: this.gShiftDateTimeForm.endDate.value,
      startTime: this.gShiftDateTimeForm.startTime.value,
      endTime: this.gShiftDateTimeForm.endTime.value
    };

    console.log("saveData", data);
    this.editWorkforce = false;
    const day = new Date().getDate();
    const month = new Date().getMonth();
    const today = +this.selectedDay.split('-')[2];
    const thisMonth = this.months.indexOf(
      data.startDate.split('-')[1]
    );
    console.log("day < today", day, today, (day < today));
    if (day < today) {
      this.shiftService
        .updateShiftDateAndTime(data)
        .subscribe(
          (res) => {
            console.log('updateShiftDateAndTime res', res);
            this.successMsg = res.payload;
            this.failed = false;
            this.success = true;
            this.loading = false;
          },
          (error) => {
            console.log('updateShiftDateAndTime error', error);
            this.errMsg = error.error.error.message;
            this.success = false;
            this.failed = true;
            this.loading = false;
          }
        );
    } else {
      this.errMsg = 'Shift already Started please pick a date';
      this.success = false;
      this.failed = true;
      this.loading = false;
    }

    this.gShiftDateTimeForm['startTime'].disable();
    this.gShiftDateTimeForm['endTime'].disable();
    this.gShiftDateTimeForm['startDate'].disable();
    this.gShiftDateTimeForm['endDate'].disable();
    this.editShiftTime = false;

    let link = `startDate=${this.day}&endDate=${this.day}&startTime=${this.gShiftDateTimeForm.startTime.value.replace(':00','')}&endTime=${this.gShiftDateTimeForm.endTime.value.replace(':00','')}&roleName=${this.roleName}&shiftName=${this.shiftName}&shiftId=${this.shiftId}`;

    this.router.navigateByUrl(`/shift-detail?${link}`);
  }

  /** table functionality */
  displayedColumns: string[] = [
    // 'select',
    'employee',
    'supervisor',
    'shiftStartTime',
    'shiftEndTime',
    'actions'
  ];

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }


  onSelectionChange(supervisorId, idx) {
    // debugger
    console.log("supervisorId", supervisorId.value);
    console.log("onSelectionChange idx ===== ", idx);
    this.submit = true;
    this.newShiftData[idx]['supervisorId'] = Number(supervisorId.value);
    this.dataSource.data[idx]['supervisor']['name'] = supervisorId.value;
    this.filtersEmployee.controls[idx].get('supervisor.id').setValue(supervisorId.value);
  }

  deleteRow(index: number) {
    this.dataSource.data.splice(index, 1);
    this.dataSource = new MatTableDataSource<Shift>(this.dataSource.data);
    this.newShiftData.splice(index, 1);
    this.deleteShiftfromArray(index)
    const isNewData = this.dataSource.data.filter(item => item.id === 0);
    if (isNewData.length == 0)
      this.submit = false;
  }

  onSelectionEmployeeChange($event, idx) {
    // debugger
    console.log("onSelectionChange $event", $event.value);
    console.log("onSelectionChange idx ===== ", idx);

    // this.newShiftData[idx]['employeeId'] = Number($event.target.value);

    console.log(this.filteredEmployeeOptions['_events']);
    const x = this.filteredEmployeeOptions['_events'][0].filter(res => res.id == $event.value)
    // // .filter(obj=>obj.id)
    // console.log(x);
    // console.log("this.dataSource.data[idx]['employee']['name']", this.dataSource.data[idx]);
    // console.log(" this.newShiftData[idx]", this.newShiftData[idx]);

    // console.log(this.filtersEmployee.controls[idx]);

    this.filtersEmployee.controls[idx].get('employee.name').setValue(x[0]?.name)
    this.dataSource.data[idx]['employee']['name'] = x[0]?.name;
    this.dataSource.data[idx]['employee']['id'] = x[0]?.id;

    this.newShiftData[idx]['employeeId'] = x[0]?.id;

    // this.selectedEmployee.add($event.value);
    // this.filtersFormCtrlEmployee.setValue($event.value)
    // console.log(this.filtersFormCtrlEmployee.value);

    this.getFreeEmployee()

  }
  onStartTimeChange($event, idx) {
    console.log("onStartTimeChange $event", $event.target.value);
    console.log("onStartTimeChange idx ===== ", idx);
    this.newShiftData[idx]['startTime'] = $event.target.value;
  }

  onEndTimeChange($event, idx) {
    console.log("onStartTimeChange $event", $event.target.value);
    console.log("onStartTimeChange idx ===== ", idx);
    this.newShiftData[idx]['endTime'] = $event.target.value;
  }

  getDeletRowDisplay(idx) {
    // console.log(this.dataSource.data[idx].id);
    return (this.dataSource.data[idx] && this.dataSource.data[idx].id == 0 && this.editWorkforce) ? true : false;
  }

  onRemoveFromShift(employeeId,i:number) {

   console.log('members count:', this.dataSource.data.length );

    if (this.dataSource.data.length <= 1) {
      this.displayRemoveMemberDialog = 'block';
    } else {
      const dialogRef = this.dialog.open(RemoveWorkforceDialogComponent, {
        data: {
          startDate: this.shiftCardDetail.startDate,
          endDate: this.shiftCardDetail.endDate,
          shiftId: this.shiftCardDetail.id,
          employeeId: employeeId
        },
        width: '30%',
        height: 'auto'
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
/*        this.getShift();
        this.getSupervisorsList();
        this.getEmployeesList();
        this._loadGetEmployeesByName();*/
        this.loadingService();
        dialogRef.close();
      });

    }
  }

  onIdleWorkforceMember(employeeId) {
    console.log('this.shiftCardDetail  ', this.shiftCardDetail);
    const dialogRef = this.dialog.open(IdleWorkforceDialogComponent, {
      data: {
        startDate: this.shiftCardDetail.startDate,
        endDate: this.shiftCardDetail.endDate,
        shiftId: this.shiftCardDetail.id,
        employeeId: employeeId
      },
      width: '60%',
      height: 'auto',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      dialogRef.close();
    });
  }

  returnId() {
    return this.dataSource.data.some(data => data.id === 0) ? true : false;
  }

  get customValidationForAddNewWorkForce() {

    const lastRow = this.filtersEmployee.controls[this.filtersEmployee.length - 1];
    // console.log("employee",lastRow.employee['name'].length, (lastRow.employee['name'].length))
    // console.log('custom validation condation ',((lastRow.employee['name'].length && lastRow.supervisor['name'].length)?false:true));
    return (lastRow.get('employee.name').value.length && lastRow.get('supervisor.id').value) ? false : true;
  }

  // edit shift date time
  get gShiftDateTimeForm() { return this.shiftDateTimeForm.controls; }

  private _generateShidtDateTimeForm(): void {
    const disabled: boolean = (!this.editShiftTime) ? true : false;
    console.log("disabled", disabled);
    this.shiftDateTimeForm = this.fb.group({
      startDate: [{ value: '', disabled }, Validators.required],
      endDate: [{ value: '', disabled }, Validators.required],
      startTime: [{ value: '', disabled }, Validators.required],
      endTime: [{ value: '', disabled }, Validators.required],
    });
  }

  private _loadShiftDateTimeForm(): void {
    this.gShiftDateTimeForm['startDate'].setValue(this.shiftCardDetail.startDate);
    this.gShiftDateTimeForm['endDate'].setValue(this.shiftCardDetail.endDate)
    this.gShiftDateTimeForm['startTime'].setValue(this.shiftCardDetail.startTime)
    this.gShiftDateTimeForm['endTime'].setValue(this.shiftCardDetail.endTime)

  }
  public myError = (controlName: string, errorName: string) => {
    return (this.shiftDateTimesubmitted) ? this.shiftDateTimeForm.controls[controlName].hasError(errorName) : false;
  }
  // end edit shift date time
}
