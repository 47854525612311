import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CitationService } from '../_services';
import { CitationsInvoicesDialogComponent } from '../_shared/citations-invoices/citations-invoices-dialog.component';

@Component({
  selector: 'app-print-invoice',
  templateUrl: './print-invoice.component.html',
  styleUrls: ['./print-invoice.component.scss']
})
export class PrintInvoiceComponent implements OnInit {

  selectedDateRange: any[] = [];
  dataList: any;
  totalInvoicesAmount = 0;
  invoiceCount = 0;
  filterFromDate;
  filterToDate;
  loading = false;


  constructor(private router: Router,
              private service: CitationService,
              private location: Location,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }


  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }


  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }


  get validSearch() {
    return (this.selectedDateRange[0] && this.selectedDateRange[1]) ? true : false;
  }


  onPrintInvoices() {
    
    this.service.getInvoiceBetweenDate(this.filterFromDate, this.filterToDate)
      .subscribe(
        resp => {
          console.log(resp);

          this.loading = false;
          console.log('invoices', resp.payload[0]);
          this.dataList = resp.payload;
          this.invoiceCount = this.dataList?.length;

          this.getTotalInvoiceAmount(this.dataList);

          const dialogRefInvoice = this.dialog.open(CitationsInvoicesDialogComponent, {
            panelClass: 'custom-mat-show-image-dialog-container',
            width: '820px',
            // height: '500px',
            data: resp.payload,
            disableClose: true
          });

          dialogRefInvoice.afterClosed().subscribe(result => {
            console.log('dialogRefInvoice result', result);
            this.router.navigateByUrl(`/invoice-details`);
          });


        },
        error => {
          this.loading = false;
          console.log(' err ===== ', error);
        });
  }

  private getTotalInvoiceAmount(invoiceData: any[]) {
    this.totalInvoicesAmount=0;
    invoiceData.forEach((item) => {
      this.totalInvoicesAmount += item.totalAmount;
    });
  }

  goBack() {
    this.location.back();
  }

}
