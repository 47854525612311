import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { DetentionRequestModel } from 'src/app/detention/detention.service';
import { MoiServiceService } from '../moi-service.service';

@Component({
  selector: 'app-moi-list',
  templateUrl: './moi-list.component.html',
  styleUrls: ['./moi-list.component.scss']
})
export class MoiListComponent implements OnInit {


  dialogRef: any;
  @ViewChild('acceptDetentionOrderDialog') acceptDetentionOrderDialog: TemplateRef<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<DetentionRequestModel>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  expandedElement: any;
  displayedColumns: string[] = ['vehicleCountry', 'vehicleInfo', 'vehicleMake', 'vehicleColor', 'scannedDate'];
  dataList: any[];
  sortingBy: string = 'detention_order_id';
  sortingType: any = 'asc';
  failedMsg: string = "";
  successMsg: string = "";
  currentUserJson: any;
  operationId: any;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _service: MoiServiceService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this._loadList(0, this.pageSize);
    this.successMsg = this._activatedRoute.snapshot.queryParams['success'];
    setTimeout(() => {
      this.successMsg = "";
      this._router.navigate(
        ['.'],
        { relativeTo: this._activatedRoute, queryParams: {} }
      );
    }, 5000);

  }
  private _loadList(page: number, limit: number, query: string = '', sortingType: any = this.sortingType, sortingBy: string = this.sortingBy) {
    this.loading = true;
    // console.log("manage violation penalties _loadList operationId", operationId);
    if (sortingType === 'asc') sortingType = 1;
    else sortingType = 0;

    this._service.getMoiScanned(page, limit, sortingType, sortingBy)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log(resp);
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          // console.log(this.dataList);

          this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }
  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }
  applyFilter(e: any) {
    // const filterValue = e.value;
    // console.log(filterValue);
    // this._service.getMoiScanned(1, this.pageSize, '', this.sortingType, this.sortingBy)
    //   .pipe(first())
    //   .subscribe(
    //     resp => {
    //       this.dataList = resp.payload;
    //       this.totalRows = resp.payload;
    //       this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
    //     },
    //     error => {
    //       // console.log(" err ===== ", error);
    //     });
  }
  // applySearch(e: any) {
  //   const filterValue = (e.target as HTMLInputElement).value;
  //   console.log(filterValue);
  //   this._service.getMoiScanned(0, this.pageSize, this.sortingType, this.sortingBy, filterValue)
  //     .pipe(first())
  //     .subscribe(
  //       resp => {
  //         this.dataList = resp.payload;
  //         this.totalRows = resp.payload;
  //         this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
  //       },
  //       error => {
  //         // console.log(" err ===== ", error);
  //       });
  // }
  applySearch(e: any) {
    const filterValue = (e.target as HTMLInputElement).value;
    // console.log(filterValue);
    this._service.getMoiScanned(0, this.pageSize, this.sortingType, this.sortingBy, filterValue)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.length;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  onSearchClear() {
    this.searchKey = '';
    this._loadList(0, this.pageSize);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.currentPage , this.pageSize);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortingType = sortState.direction;
    this.sortingBy = sortState.active;
    // console.log(this.sortingType);
    // console.log(this.sortingBy);
    this._loadList(this.currentPage + 1, this.pageSize);
  }

  onCancelSearch(): void {
    this.onSearchClear();
  }
}