import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WhitelistService } from '../_services';

@Component({
  selector: 'app-add-period',
  templateUrl: './add-period.component.html',
  styleUrls: ['./add-period.component.scss']
})
export class AddPeriodComponent implements OnInit {

  title: string = "Add period";
  PeriodForm: FormGroup;
  submitted = false;
  id;
  details: any;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute,
    private whitelistService: WhitelistService
  ) {
    this.generatePeriodForm();
    this.id = route.snapshot.paramMap.get('id');
   }

  ngOnInit(): void {
    this.getWhitelist();
  }

  onCreatePeriod() {
    this.submitted = true;
    if(this.PeriodForm.invalid) return;

    console.log(this.PeriodForm.value);
    const params:any = {
        fromDate: this.PeriodForm.get('fromDate').value,
        toDate: this.PeriodForm.get('toDate').value,
        timeFrom: this.PeriodForm.get('timeFrom').value,
        timeTo: this.PeriodForm.get('timeTo').value,
        description: this.PeriodForm.get('description').value,

        userName: this.details.whitelistDetails[0].userName,
        country: this.details.country,
        colorId: this.details.colorId,
        makeId: this.details.makeId,
        model: this.details.model,
        type: this.details.type,
        operationId: this.details.operationId,
        plateNumberEn: this.details.plateNumberEn,
    }

    this.whitelistService.createWhitelistVehicle(params).subscribe(res => {
      console.log(res);
      if(res.success === true) {
        this._router.navigate([`/whitelist-details/${this.id}`])
      }
    })
  }

  getWhitelist() {
    this.whitelistService.getWhitelistDetails().subscribe(res => {
      this.details = res.payload.content.find(details=>details.id == this.id)
      console.log(this.details);
      
    })
  }

  generatePeriodForm() {
    this.PeriodForm = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      timeFrom: ['', Validators.required],
      timeTo: ['', Validators.required],
      description: ['', Validators.required],
    })
  }



  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.PeriodForm.controls[controlName].hasError(errorName) : false;
  }

  onCancel() {
    this._router.navigateByUrl(`/manage-whitelist`);
  }

  goBack() {
    this.location.back();
  }

}
