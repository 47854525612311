import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListBootRequestsComponent } from './list-boot-requests/list-boot-requests.component';

const routes: Routes = [
    { path: 'boot-requests', component: ListBootRequestsComponent }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BootRequestRoutingModule { }
