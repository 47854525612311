import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '../../reports.service';

@Component({
  selector: 'app-edit-vehicle-dialog',
  templateUrl: './edit-vehicle-dialog.component.html',
  styleUrls: ['./edit-vehicle-dialog.component.scss'],
})
export class EditVehicleDialogComponent implements OnInit {
  selectedRegistrationType: string | null = null;
  selectedImage: string = '';
  images: any[] = [];
  registrationTypes: any[] = [];

  constructor(
    public dialogRef: MatDialogRef<EditVehicleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportsService: ReportsService
  ) {}

  ngOnInit(): void {
    this.selectedImage = '';
    this.images = [];
    if (this.data?.citationNumber) {
      this.getEvidenceImages(this.data.citationNumber);
      this.getRegistrationTypes(this.data.citationNumber);
    }

    this.selectedRegistrationType = this.data?.registrationType || null;
  }

  getRegistrationTypes(citationId: number) {
    this.reportsService.getRegistrationTypes(citationId).subscribe({
      next: (res) => {
        this.registrationTypes = res?.payload || [];
      },
      error: (error) => {
        console.error('Error fetching registration types:', error);
      },
    });
  }

  getEvidenceImages(citationId: number) {
    this.reportsService.getCitationDetails(citationId).subscribe({
      next: (res) => {
        this.images = res?.payload?.images || [];
        console.log(this.images);
      },
      error: (error) => {
        console.error('Error fetching evidence images:', error);
      },
    });
  }

  onSend(): void {
    this.dialogRef.close({
      registrationType: this.selectedRegistrationType,
    });
  }

  onVoid(): void {
    this.dialogRef.close({
      // registrationType: this.selectedRegistrationType,
      isVoid: true,
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}
