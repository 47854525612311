import {Component, Inject, OnInit} from '@angular/core';

import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

declare const L: any;

@Component({
  selector: 'app-show-citation-location-dialog',
  templateUrl: './show-citation-location-dialog.component.html',
  styleUrls: ['./show-citation-location-dialog.component.scss'],
})
export class ShowCitationLocationDialogComponent implements OnInit {

  mapLocationURL = '';

  constructor(
    public dialogRef: MatDialogRef<ShowCitationLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LocationDto
  ) {

  }

  ngOnInit(): void {
    const latLong = this.data.latLong;

  //  this.mapLocationURL = `https://nominatim.openstreetmap.org/ui/reverse.html?lat=${latLong[0]}&lon=${latLong[1]}&zoom=13`;

    this.mapLocationURL = `https://maps.google.com/?q=${latLong[0]},${latLong[1]}`;


    console.log('mapData', this.data);
    let mymap = L.map('map').setView(latLong, 13);

    L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mymap);

    let marker = L.marker(latLong).addTo(mymap);

    if (!(this.data.labelName === undefined || this.data.labelName === '')) {
      marker.bindPopup(
        `<b>${this.data.labelName}</b><br>
`).openPopup();
    }

    /*    let popup = L.popup()
          .setLatLng(latLong)
          .setContent('I am Subrat')
          .openOn(mymap);*/
  }

  close(): void {
    this.dialogRef.close();
  }

  /*--------------------------------------------------------------------------------*/
  /*

    title = 'locationApp';

  ngOnInit() {
    if (!navigator.geolocation) {
      console.log('location is not supported');
    }
    navigator.geolocation.getCurrentPosition((position) => {
      const coords = position.coords;
     // const latLong = [coords.latitude, coords.longitude];
      const latLong = [26.28472, 50.19563];
      console.log(
        `lat: ${position.coords.latitude}, lon: ${position.coords.longitude}`
      );
      let mymap = L.map('map').setView(latLong, 13);

      L.tileLayer(
        'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoic3VicmF0MDA3IiwiYSI6ImNrYjNyMjJxYjBibnIyem55d2NhcTdzM2IifQ.-NnMzrAAlykYciP4RP9zYQ',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: -1,
          accessToken: 'your.mapbox.access.token',
        }
      ).addTo(mymap);

      let marker = L.marker(latLong).addTo(mymap);

      marker.bindPopup('<b>Hi</b>').openPopup();

      let popup = L.popup()
        .setLatLng(latLong)
        .setContent('I am Subrat')
        .openOn(mymap);
    });
    this.watchPosition();
  }

  watchPosition() {
    let desLat = 0;
    let desLon = 0;
    let id = navigator.geolocation.watchPosition(
      (position) => {
        console.log(
          `lat: ${position.coords.latitude}, lon: ${position.coords.longitude}`
        );
        if (position.coords.latitude === desLat) {
          navigator.geolocation.clearWatch(id);
        }
      },
      (err) => {
        console.log(err);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }
  */
  /*--------------------------------------------------------------------------------*/

}

//--------------------------------------
export class LocationDto {
  latLong: number[] = [];
  labelName = '';
  id: number;
}
