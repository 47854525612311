import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { EquipmentService } from '../equipment.service';
import { EmployeeWithEquipment } from '../models/employeeWithEquipmentModel';


@Component({
  selector: 'app-handover-equipment',
  templateUrl: './handover-equipment.component.html',
  styleUrls: ['./handover-equipment.component.scss'],
})
export class HandoverEquipmentComponent implements OnInit {
  failedMsg: string = '';
  successMsg: string = '';
  searchKey = '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  loading = false;
  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  expandedElement: any;
  currentUserJson: any;
  operationId: any;
  displayedColumns: string[] = ['name', 'id', 'custody', 'actions'];
  equipmentTableColumns: string[] = ['equipment', 'serialNumber', 'description']
  dataSource: EmployeeWithEquipment[];
  selectedRow: EmployeeWithEquipment;
  dialogRef: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('equipmentHandoverDialog')
  equipmentHandoverDialog: TemplateRef<any>;
  sortingField: string = 'createdDate';
  sortingDirection: any = 'desc';
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog,
    private equipmentService: EquipmentService
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this.loadList(this.operationId, '', 0, this.pageSize, this.sortingDirection, this.sortingField);
  }

  loadList(
    operationId: number,
    searchValue: string,
    page: number,
    limit: number,
    sortDirection: string = this.sortingDirection,
    sortField: string = this.sortingField
  ) {
    this.loading = true;


    this.equipmentService
      .getEmployeesToHandover(operationId, searchValue, page, limit, sortDirection, sortField)
      .pipe(first())
      .subscribe(
        (res) => {
          console.log(res);
          this.dataSource = res.payload.content;
          this.totalRows = res.payload.totalElements;
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('handover err', error);
        }
      );
  }

  openEquipmentHandoverDialog(row: any) {
    this.selectedRow = row;
    this.dialogRef = this._dialog.open(this.equipmentHandoverDialog, {
      width: '50%',
      height: '85%',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(async (res) => {
      if (res) {
        this.loadList(this.operationId, '', 0, this.pageSize, this.sortingDirection,
          this.sortingField);
      }
    });
  }

  announceSortChange(sortState: Sort) {
    this.sortingDirection = sortState.direction;
    this.sortingField = sortState.active;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  applySearch(event: any) {
    this.searchKey = (event.target as HTMLInputElement).value;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }

  onSearchClear() {
    this.searchKey = '';
    this.loadList(this.operationId, '', this.currentPage + 1, this.pageSize, this.sortingDirection,
      this.sortingField);
  }
  onHandoverEquipment(id: number, name: string) {
    this._router.navigateByUrl(`/equipments/handover-equipment/${id}/${name}`);
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.loadList(
      this.operationId,
      this.searchKey,
      this.currentPage,
      this.pageSize,
      this.sortingDirection,
      this.sortingField
    );
  }
}
