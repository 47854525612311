export class statusList {
    public static statusList: string[] = [
        'PENDING',
        'ASSIGNED',
        'ACCEPTED',
        'REJECTED',
        'TOWED',
        'UNTOW',
        'DELIVERED',
        'ABORTED',
        'WORKFORCE_ARRIVED',
        'READY_TO_UNTOW',
        'CLOSED_SETTLED',
        'SYSTEM_CLOSED_RUNWAY',
        'SYSTEM_CLOSED_TIME_CONFIG',
        'WORKFORCE_CLOSED_RUNWAY',
        'PROBLEM_HAPPENED'
    ]
}