import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestsMapService {

  constructor(private http: HttpClient) { }
  _token = `Bearer ${localStorage.getItem('token')}`;

  assignRequest(payload: any): Observable<any> {
    return this.http.put(`${env.baseURL}operation/grounding-requests/assign`, payload, {
      headers: { Authorization: this._token }
    });
  }

  getDetentionRequests(page: number, limit: number, plateNumber: string = '', sortingType: number = 1, sortingBy: string = 'detention_order_id', status: string[],): Observable<any> {  // with any status
    if (plateNumber) {
      return this.http.get(`${env.baseURL}orders/detention-orders/get-detention-orders/1?detention_order_status=${status}&page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}&plate_number=${plateNumber}`, {
        headers: { Authorization: this._token },
      });
    } else {
      return this.http.get(`${env.baseURL}orders/detention-orders/get-detention-orders/1?detention_order_status=${status}&page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}`, {
        headers: { Authorization: this._token },
      });
    }

  }
  getAllRequests(requestType: string, operationId: number, page: number, limit: number, plateNumber?: string, severity?: string): Observable<any> {
    // return this.http.get(`${env.baseURL}operation/grounding-requests?type=${requestType}&status=PENDING&operationId=1`, {
    //   headers: { Authorization: this._token }
    // });
    if (severity) {
      return this.http.get(`${env.baseURL}operation/grounding-requests?type=${requestType}&operationId=${operationId}&severity=${severity}&page=${page}&limit=${limit}`, {
        headers: { Authorization: this._token },
      });
    }
    else if (plateNumber) {
      return this.http.get(`${env.baseURL}operation/grounding-requests?type=${requestType}&operationId=${operationId}&page=${page}&limit=${limit}&vehiclePlateNumberEn=${plateNumber}`, {
        headers: { Authorization: this._token },
      });
    }
    else if (plateNumber && severity) {
      return this.http.get(`${env.baseURL}operation/grounding-requests?type=${requestType}&operationId=${operationId}&severity=${severity}&page=${page}&limit=${limit}&vehiclePlateNumberEn=${plateNumber}`, {
        headers: { Authorization: this._token },
      });
    }
    else {
      return this.http.get(`${env.baseURL}operation/grounding-requests?type=${requestType}&operationId=${operationId}&page=${page}&limit=${limit}`, {
        headers: { Authorization: this._token },
      });
    }

  }

  getAllCounts(operationId: number): Observable<any> {
    return this.http.get(`${env.baseURL}operation/grounding-requests/status-count?operationId=${operationId}`, {
      headers: { Authorization: this._token }
    });
  }

  getAssignedWorkforces(groupName: string, operationId: number): Observable<any> {
    return this.http.get(`${env.baseURL}operation/tracking/numberOfRequestsOpenedPerWorkforce/${operationId}/${groupName}`, {
      headers: { Authorization: this._token }
    });
  }

  getAvailableWorkforces(groupName: string, lat: number, lng: number, operationId: number): Observable<any> {
    return this.http.get(`${env.baseURL}operation/tracking/distanceAndNumberOfRequestsOpenedPerWorkforce/${operationId}/${groupName}/${lat}/${lng}`, {
      headers: { Authorization: this._token }
    });
  }

  getAvailableWorkforcesHavingCustody(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/tracking/availableWorkforcesHavingCustody`, {
      headers: { Authorization: this._token }
    });
  }

  getAllDetentions(): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/detention-center/V2`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  // note: we may need to remove "&selectedLocation=false" from the url
  getRequestsPrioryOrder(employeeId: number): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/grounding-requests/priorityOrder?employeeId=${employeeId}&selectedLocation=false`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  assignRequestToWorker(request:any): Observable<any> {
    return this.http.put(`${env.baseURL}operation/grounding-requests/assign/v2`, request, {
      headers: { Authorization: this._token }
    });
  }

  getAssignedByCounts(operationId: number): Observable<any> {
    return this.http.get(`${env.baseURL}operation/grounding-requests/assignedByCount?operationId=${operationId}`, {
      headers: { Authorization: this._token }
    });
  }
}
