import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { CitationList } from '../_models/citationList';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { CitationVoidService } from '../_services';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShowImagesDialog } from '../citation-list/show-images-dialog/show-images-dialog';
import { AuthorizationService } from 'src/app/_services';

@Component({
  selector: 'app-citation-void-list',
  templateUrl: './citation-void-list.component.html',
  styleUrls: ['./citation-void-list.component.scss']
})
export class CitationVoidListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  loading: boolean = false;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<CitationList>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;

  expandedElement: any;
  displayedColumns: string[] = ['index', 'id', 'plateNumberEn', 'violationName', 'actions'];
  dataList: [];
  sortBy: string = 'id';
  sortDirection: string = 'ASC';

  filterKeyword: string = '';
  filterDate;
  filterHasEmailAction: boolean = false;
  constructor(
    private _router: Router,
    private _service: CitationVoidService,
    public authorizationService: AuthorizationService,
    public dialog: MatDialog
  ) {
    this.filterDate = new Date().toISOString().split('T')[0];
  }

  ngOnInit(): void {
    this._load(0, this.pageSize, this.sortBy, this.sortDirection, this.filterDate,
      this.filterHasEmailAction,
      '');
  }

  private _load(offset: number, limit: number,
    sortBy: string, sortDirection: string,
    date: string,
    hasEmailAction,
    query: string = '',
  ) {
    this.loading = true;
    this._service.getList(offset, limit, sortBy, sortDirection, date, hasEmailAction, query)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          // console.log('resp.payload', resp.payload);
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    if (filterValue.length >= 3) {
      this.filterKeyword = filterValue;
      this._load(this.currentPage, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction, this.filterKeyword);
    }
  }

  setSearchKeyword(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    if (filterValue.length >= 3) {
      this.filterKeyword = filterValue;
    }
  }

  onSearchClear() {
    this.filterKeyword = '';
    this._load(0, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction, '');
  }

  onShowImage(row) {
    // console.log('onShowImage(row)', row);
    const dialogRef = this.dialog.open(ShowImagesDialog, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '960px',
      height: '500px',
      data: row.imagesIds
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
    //this._router.navigateByUrl(`//${row.id}`);
  }

  onVoid(row) {
    this._router.navigateByUrl(`/citation-void-void/${row.id}`);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._load(this.currentPage, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.

    //id  ,plateNumberEn, enName
    // console.log("sortState", sortState);
    this.sortBy = sortState.active;
    this.sortDirection = sortState.direction;

    this._load(this.currentPage, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction);

  }

  onFilterDateChange($event) {
    this.filterDate = $event;
    this._load(this.currentPage, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction, this.filterKeyword);

  }
  onFilterHasEmailActionChange($event) {
    this.filterHasEmailAction = $event;
    this._load(this.currentPage, this.pageSize, this.sortBy, this.sortDirection, this.filterDate, this.filterHasEmailAction, this.filterKeyword);
  }

}
