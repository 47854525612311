<div class="list-form-title list-form-title-right">{{title}}
    <button mat-icon-button *ngIf="type!='view'" class="plus-btn" (click)="add()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="11" width="16" height="2" rx="1" fill="#39B2E6" />
            <rect x="11" y="4" width="2" height="16" rx="1" fill="#39B2E6" />
        </svg>
    </button>
</div>
<mat-accordion *ngFor="let item of selected ; let idx = index" class="">
    <mat-expansion-panel class="accordion-panel accordion-right" [expanded]="selected[idx].isExpanded">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{selected[idx][name]}}
            </mat-panel-title>

            <mat-icon *ngIf="type!='view'" [hidden]="selected[idx].isExpanded" class="remove-penalty"
                (click)="remove(idx)">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M17.3135 5.09765C17.2951 5.09765 17.2674 5.09765 17.2397 5.09765C12.3559 4.60462 7.48132 4.41858 2.65292 4.9116L0.769569 5.09765C0.38182 5.13485 0.0402314 4.85579 0.00330296 4.46509C-0.0336255 4.07439 0.243338 3.73951 0.621855 3.7023L2.50521 3.51626C7.41669 3.01393 12.3928 3.20928 17.3781 3.7023C17.7567 3.73951 18.0336 4.0837 17.9967 4.46509C17.969 4.82788 17.6643 5.09765 17.3135 5.09765Z"
                        fill="#D93636" />
                    <path
                        d="M5.77317 4.15812C5.73624 4.15812 5.69931 4.15813 5.65315 4.14882C5.28387 4.08371 5.02537 3.72092 5.09 3.34883L5.2931 2.13023C5.44082 1.2372 5.64392 0 7.795 0H10.2138C12.3741 0 12.5772 1.28372 12.7157 2.13953L12.9188 3.34883C12.9835 3.73022 12.725 4.09301 12.3557 4.14882C11.9772 4.21394 11.6171 3.95347 11.5617 3.58138L11.3586 2.37208C11.2294 1.56279 11.2017 1.40465 10.2231 1.40465H7.80424C6.82563 1.40465 6.80717 1.53488 6.66869 2.36278L6.45635 3.57208C6.40096 3.91627 6.10553 4.15812 5.77317 4.15812Z"
                        fill="#D93636" />
                    <path
                        d="M11.968 19.9996H6.04103C2.81902 19.9996 2.68977 18.2042 2.58822 16.7531L1.98813 7.38566C1.96043 7.00427 2.25586 6.66938 2.63438 6.64148C3.02212 6.62287 3.34525 6.91124 3.37295 7.29264L3.97303 16.66C4.07459 18.074 4.11151 18.6042 6.04103 18.6042H11.968C13.9068 18.6042 13.9437 18.074 14.036 16.66L14.6361 7.29264C14.6638 6.91124 14.9962 6.62287 15.3747 6.64148C15.7532 6.66938 16.0486 6.99496 16.0209 7.38566L15.4209 16.7531C15.3193 18.2042 15.1901 19.9996 11.968 19.9996Z"
                        fill="#D93636" />
                    <path
                        d="M10.5372 14.8836H7.46292C7.0844 14.8836 6.77051 14.5673 6.77051 14.186C6.77051 13.8046 7.0844 13.4883 7.46292 13.4883H10.5372C10.9157 13.4883 11.2296 13.8046 11.2296 14.186C11.2296 14.5673 10.9157 14.8836 10.5372 14.8836Z"
                        fill="#D93636" />
                    <path
                        d="M11.3124 11.1629H6.69632C6.3178 11.1629 6.00391 10.8466 6.00391 10.4653C6.00391 10.0839 6.3178 9.76758 6.69632 9.76758H11.3124C11.6909 9.76758 12.0048 10.0839 12.0048 10.4653C12.0048 10.8466 11.6909 11.1629 11.3124 11.1629Z"
                        fill="#D93636" />
                </svg>
            </mat-icon>
        </mat-expansion-panel-header>
        <div class="list-form-content">
            <!-- Search Box lift-->
            <mat-form-field class="search-field">
                <input matInput placeholder="Search here" autocomplete="off" (keyup)="applyFilter($event)" />
                <span matPrefix>
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M6.5 1.37805C3.67122 1.37805 1.37805 3.67122 1.37805 6.5C1.37805 9.32878 3.67122 11.622 6.5 11.622C9.32878 11.622 11.622 9.32878 11.622 6.5C11.622 3.67122 9.32878 1.37805 6.5 1.37805ZM0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5Z"
                            fill="#6B757E" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M11.0002 11.0009C11.2045 10.7966 11.5358 10.7966 11.7402 11.0009L15.3469 14.6076C15.5513 14.8119 15.5513 15.1432 15.3469 15.3476C15.1426 15.5519 14.8113 15.5519 14.6069 15.3476L11.0002 11.7409C10.7958 11.5366 10.7958 11.2053 11.0002 11.0009Z"
                            fill="#6B757E" />
                    </svg>
                    &nbsp;
                </span>
                <span matSuffix>
                    <!-- <button mat-button matSuffix mat-icon-button aria-label="Clear"
                        *ngIf="searchKey" (click)="onSearchClear()">
                        <mat-icon>close</mat-icon>
                    </button> -->
                </span>
            </mat-form-field>
            <!-- Search Box lift-->
            <mat-selection-list #selectedList [multiple]="false" (selectionChange)="onSelect($event,idx)">
                <mat-list-option *ngFor="let item of itemsList" [value]="item">
                    {{item[name]}}
                </mat-list-option>
            </mat-selection-list>
        </div>
    </mat-expansion-panel>
</mat-accordion>