import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { DetentionRequestsComponent } from './detention-requests/detention-requests.component';
import { DetentionRoutingModule } from './detention-routing.module';
import { ReleaseVehiclesComponent } from './release-vehicles/release-vehicles.component';



@NgModule({
  declarations: [DetentionRequestsComponent, ReleaseVehiclesComponent],
  imports: [
    CommonModule,
    DetentionRoutingModule,
    SharedModule
  ]
})
export class DetentionModule { }
