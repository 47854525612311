<section class="row g-0 align-items-center">
  <div class="col-12 row g-0">
    <h1><strong>Workforce Members Reports</strong></h1>
  </div>

  <form [formGroup]="workForceReports">
    <div class="row d-flex align-items-center">
      <mat-form-field appearance="fill" class="search-form-field w-100">
        <input matInput placeholder="Search" autocomplete="off" formControlName="employeeName"
          (keyup.enter)="applyFilter($event)" />
        <mat-icon matPrefix>search</mat-icon>
        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="d-flex">
          <button mat-icon-button (click)="downloadCSV()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20.1464 3.85383C20.3916 4.09904 20.3916 4.49661 20.1464 4.74183L13.2813 11.6069C13.036 11.8522 12.6385 11.8522 12.3933 11.6069C12.148 11.3617 12.148 10.9642 12.3933 10.7189L19.2584 3.85383C19.5036 3.60862 19.9012 3.60862 20.1464 3.85383Z"
                fill="#6B757E" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.7256 3.62791C15.7256 3.28112 16.0067 3 16.3535 3H20.3721C20.7189 3 21 3.28112 21 3.62791V7.64651C21 7.9933 20.7189 8.27442 20.3721 8.27442C20.0253 8.27442 19.7442 7.9933 19.7442 7.64651V4.25581H16.3535C16.0067 4.25581 15.7256 3.97469 15.7256 3.62791Z"
                fill="#6B757E" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4.54437 4.54437C5.6616 3.42715 7.33342 3 9.48837 3H11.1628C11.5096 3 11.7907 3.28112 11.7907 3.62791C11.7907 3.97469 11.5096 4.25581 11.1628 4.25581H9.48837C7.45728 4.25581 6.19886 4.66588 5.43237 5.43237C4.66588 6.19886 4.25581 7.45728 4.25581 9.48837V14.5116C4.25581 16.5427 4.66588 17.8011 5.43237 18.5676C6.19886 19.3341 7.45728 19.7442 9.48837 19.7442H14.5116C16.5427 19.7442 17.8011 19.3341 18.5676 18.5676C19.3341 17.8011 19.7442 16.5427 19.7442 14.5116V12.8372C19.7442 12.4904 20.0253 12.2093 20.3721 12.2093C20.7189 12.2093 21 12.4904 21 12.8372V14.5116C21 16.6666 20.5729 18.3384 19.4556 19.4556C18.3384 20.5729 16.6666 21 14.5116 21H9.48837C7.33342 21 5.6616 20.5729 4.54437 19.4556C3.42715 18.3384 3 16.6666 3 14.5116V9.48837C3 7.33342 3.42715 5.6616 4.54437 4.54437Z"
                fill="#6B757E" />
            </svg>
            Export
          </button>

          <!-- <button mat-icon-button (click)="downloadCSVViolation()" style="margin-right: 139px;">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20.1464 3.85383C20.3916 4.09904 20.3916 4.49661 20.1464 4.74183L13.2813 11.6069C13.036 11.8522 12.6385 11.8522 12.3933 11.6069C12.148 11.3617 12.148 10.9642 12.3933 10.7189L19.2584 3.85383C19.5036 3.60862 19.9012 3.60862 20.1464 3.85383Z"
                fill="#6B757E" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M15.7256 3.62791C15.7256 3.28112 16.0067 3 16.3535 3H20.3721C20.7189 3 21 3.28112 21 3.62791V7.64651C21 7.9933 20.7189 8.27442 20.3721 8.27442C20.0253 8.27442 19.7442 7.9933 19.7442 7.64651V4.25581H16.3535C16.0067 4.25581 15.7256 3.97469 15.7256 3.62791Z"
                fill="#6B757E" />
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M4.54437 4.54437C5.6616 3.42715 7.33342 3 9.48837 3H11.1628C11.5096 3 11.7907 3.28112 11.7907 3.62791C11.7907 3.97469 11.5096 4.25581 11.1628 4.25581H9.48837C7.45728 4.25581 6.19886 4.66588 5.43237 5.43237C4.66588 6.19886 4.25581 7.45728 4.25581 9.48837V14.5116C4.25581 16.5427 4.66588 17.8011 5.43237 18.5676C6.19886 19.3341 7.45728 19.7442 9.48837 19.7442H14.5116C16.5427 19.7442 17.8011 19.3341 18.5676 18.5676C19.3341 17.8011 19.7442 16.5427 19.7442 14.5116V12.8372C19.7442 12.4904 20.0253 12.2093 20.3721 12.2093C20.7189 12.2093 21 12.4904 21 12.8372V14.5116C21 16.6666 20.5729 18.3384 19.4556 19.4556C18.3384 20.5729 16.6666 21 14.5116 21H9.48837C7.33342 21 5.6616 20.5729 4.54437 19.4556C3.42715 18.3384 3 16.6666 3 14.5116V9.48837C3 7.33342 3.42715 5.6616 4.54437 4.54437Z"
                fill="#6B757E" />
            </svg>
            Export violation
          </button> -->
        </div>

      </mat-form-field>


      <!-- Filters -->
      <div class="accordion-panel-div">
        <mat-accordion>
          <mat-expansion-panel class="accordion-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Filter
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="accordion-content">

              <div class="row">
                <div class="col-12">
                  <div class="row text-center">

                    <div class="col-3">
                      <mat-form-field appearance="standard">
                        <mat-label>From (Issue Date)</mat-label>
                        <input type="date" matInput placeholder="from" formControlName="startDate" />
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="standard">
                        <mat-label>To (Issue Date)</mat-label>
                        <input type="date" matInput placeholder="to" formControlName="endDate" />
                      </mat-form-field>
                    </div>
                    <div class="col-4">
                      <mat-form-field class="full-width" appearance="standard">
                        <mat-label>Role</mat-label>
                        <mat-select aria-required="true" formControlName="roleId">
                          <mat-option *ngFor="let role of roles" [value]="role.id">{{
                            role.name
                            }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                  </div>

                  <div class="row text-center">

                    <div class="col-3">

                      <mat-form-field>
                        <mat-select [(ngModel)]="enforcerId" formControlName="enforceName" placeholder="Enforcer Name"
                          #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search formControlName="enforceNameSearch" placeholderLabel="Settled By">

                            </ngx-mat-select-search>
                          </mat-option>

                          <mat-option [hidden]="selectedWorkForceIds.length >= 1" [value]="'0'">{{'All'}}</mat-option>
                          <mat-option [hidden]="selectedWorkForceIds.length <= 1" [value]="'00'">{{'More Than
                            1'}}</mat-option>

                          <mat-option *ngFor="let enforcer of filteredEnforcerOptions | async" [value]="enforcer.id">
                            <mat-checkbox [checked]="enforcer.value" [value]="enforcer?.id"
                              (change)="choseWorker($event,enforcer)">
                              {{enforcer?.name}}
                            </mat-checkbox>
                            {{enforcer.id}}-{{enforcer.name}}
                          </mat-option>
                          <div class="clear-btn" *ngIf="this.selectedWorkForceIds.length>0">
                            <button mat-raised-button (click)="clearEnforcer()">
                              Clear
                            </button>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div><!-- License Plate -->

                    <div class="col-3">
                      <mat-form-field>
                        <mat-label>Operation</mat-label>
                        <mat-select [(value)]="selectedOperationId" formControlName="operation"
                          (selectionChange)="getOperationForceMember()">
                          <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div><!-- License Plate -->
                    <!--
                    <div class="col-3">
                      <mat-form-field>
                        <mat-select formControlName="violation" [(value)]="violationId"
                                    placeholder="Violation Name" #violationSelect
                                    (click)="reloadDropdownFilter($event,'_loadGetEmployeesByNameOrUserNameOrId')">
                          <mat-option>
                            <ngx-mat-select-search formControlName="violationSearch" placeholderLabel="Violation Name"></ngx-mat-select-search>
                          </mat-option>

                          <div class="clear-btn" *ngIf="violationSelect.value">
                            <button mat-raised-button (click)="clearViolation()">Clear</button>
                          </div>

                          <mat-option *ngFor="let violation of filteredViolationOptions | async" [value]="violation.id">{{violation.id}}-{{violation.arName}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>-->

                  </div>

                  <div class="row filters-footer">
                    <div>
                      <button [disabled]="loading" class="btn search-btn" (click)="applyFilter($event)">Search</button>
                      <button class="btn filters-btn-clear" (click)="onClearFilters()">Clear</button>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- Filters -->


    </div>
  </form>

  <div class="table-responsive">
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th scope="colgroup" colspan="5">Details</th>
          <th scope="colgroup" rowspan="2" colspan="2">Attendance</th>
          <th scope="colgroup" colspan="7">Citations</th>
        </tr>
        <tr>
          <th scope="colgroup" colspan="3">
            <br />
          </th>
          <th scope="colgroup" colspan="2">
            Settlements <br />
          </th>

          <th scope="colgroup"
            matTooltip='total Count during the period from {{workForceReports.value.startDate |date}} to {{workForceReports.value.endDate |date}} '
            class="btn example-button" style="cursor: 'pointer'">
            Total count <br />
            <span>{{ reportCounts?.totalCitationsCount|number }}</span>
          </th>
          <th scope="colgroup" colspan="2"
            matTooltip='Total Settled during the period from {{workForceReports.value.startDate |date}} to {{workForceReports.value.endDate |date}} '
            style="cursor: 'pointer'">Settled <br />
            <span>{{
              reportCounts?.totalSettledAmount
              | currency: "(SAR)":"symbol":"1.0-0":"SR"
              }}
            </span>
          </th>
          <th scope="colgroup"
            matTooltip='Total Voided during the period from {{workForceReports.value.startDate |date}} to {{workForceReports.value.endDate |date}}'
            style="cursor: 'pointer'" colspan="2">
            Voided <br /><span>{{
              reportCounts?.totalVoidedAmount
              | currency: "(SAR)":"symbol":"1.0-0":"SR"
              }}</span>
          </th>
          <!--  -->
          <th scope="colgroup"
            matTooltip='Total Unsettled during the period from {{workForceReports.value.startDate |date}} to {{workForceReports.value.endDate |date}}'
            style="cursor: 'pointer'" colspan="2">
            Unsettled <br /><span>{{
              reportCounts?.totalUnSettledAmount
              | currency: "(SAR)":"symbol":"1.0-0":"SR"
              }}</span>
          </th>
          <!--  matTooltip='Total Unsettled during the period from {{workForceReports.value.startDate |date}} to {{workForceReports.value.endDate |date}} ' class="btn example-button"
        style="cursor: 'pointer'"  -->
        </tr>
        <tr>
          <th scope="col" *ngFor="let column of displayedColumns">
            <button matTooltip="{{ column.info }}" matTooltipClass="example-tooltip-red"
              aria-label="Button that shows a red tooltip" class="btn example-button"
              [ngStyle]="{ cursor: column.info ? 'pointer' : 'revert' }">
              {{ column.title }}
            </button>
            <!-- <a href=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M160 448h-32V224c0-17.69-14.33-32-32-32L32 192c-17.67 0-32 14.31-32 32s14.33 31.1 32 31.1h32v192H32c-17.67 0-32 14.31-32 32s14.33 32 32 32h128c17.67 0 32-14.31 32-32S177.7 448 160 448zM96 128c26.51 0 48-21.49 48-48S122.5 32.01 96 32.01s-48 21.49-48 48S69.49 128 96 128z"/></svg></a> -->
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataSource">
          <td>{{item?.employeeId}} - {{ item.employeeName }}</td>
          <td>{{item.operationName}}</td>
          <td>{{item.scanCount|number}}</td>
          <td>
            <button (click)="onOpenDialog(item)">{{ item.totalSettlementsCount |number}}</button>
          </td>
          <td>
            <button (click)="onOpenDialog(item)">
              {{ item.totalSettlementsFees|number }}
            </button>
          </td>
          <td>{{ item.checkedInLateCount |number}}</td>
          <td>{{ item.absenceCount|number }}</td>
          <td> <button (click)="onOpenDialog(item, 'all')">{{ item.citationsCount|number }}</button></td>
          <td>
            <button (click)="onOpenDialog(item, 'Settled')">
              {{ item.settledCitationsCount |number}}
            </button>
          </td>
          <td>{{ item.settledTotalFees |number}}</td>
          <td>
            <button (click)="onOpenDialog(item, 'Voided')">
              {{ item.voidedCitationsCount|number }}
            </button>
          </td>
          <td>{{ item.voidedTotalFees|number }}</td>
          <td>
            <button (click)="onOpenDialog(item, 'Opened')">
              {{ item.unSettledCitationsCount |number}}
            </button>
          </td>
          <td>{{ item.unSettledTotalFees|number }}</td>
        </tr>
      </tbody>
    </table>
    <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20,50, 100]" showFirstLastButtons
      [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
      (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <mat-spinner *ngIf="loading"></mat-spinner>
</section>