import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CitationList } from '../_models/citationList';

import { first, map, takeUntil } from 'rxjs/operators';
import { CitationReportListService, CitationService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { AuthorizationService } from 'src/app/_services';


interface SelectDropdownIdName {
  id: string;
  name: string;
}

@Component({
  selector: 'app-citation-report-list',
  templateUrl: './citation-report-list.component.html',
  styleUrls: ['./citation-report-list.component.scss']
})
export class CitationReportListComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('violationSelect', { static: true }) violationSelect: MatSelect;
  @ViewChild('settlementSelect', { static: true }) settlementSelect: MatSelect;
  @ViewChild('operationSelect', { static: true }) operationSelect: MatSelect;

  loading = false;

  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<CitationList>;
  filteredViolationOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  total: Number = 0;
  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = ['index', 'createdDate', 'citationNumber', 'violationCode', 'violationDesc', 'createdBy', 'licensePlate', 'licenseState', 'streetName', 'status', 'statusDateTime', 'cashierName', 'paymentAmount', 'paymentSource', 'voidedReason', 'operation',];
  dataList: [] = [];

  sortBy: string = 'createdDate';
  sortDirection: string = 'desc';

  selectedDateRange: any[] = [];
  roles = new FormControl();

  operations: SelectDropdownIdName[] = [];
  selectedOperationId: string = "";
  selectedSettlementChannel: string[] = [];
  selectedViolationId: string = "";
  filterStatusList = [{ id: 'Opened', name: 'Opened' }, { id: 'Settled', name: 'Settled' }, { id: 'Voided', name: 'Voided' }];
  filterSettlementChannel = [{ id: '', name: 'All' }, { id: 'MOTORIST', name: 'MOTORIST' }, { id: 'ENFORCEMENT_MOBILE', name: 'ENFORCEMENT_MOBILE' }, { id: 'ENFORCEMENT_WEB', name: 'ENFORCEMENT_WEB' }];

  statusList: SelectDropdownIdName[] = [
    // { id: 'All', name: 'All' },
    { id: 'Opened', name: 'Opened' },
    { id: 'Settled', name: 'Settled' },
    { id: 'Voided', name: 'Voided' }
  ];
  selectedStatus: string[] = [];
  filtersFormCtrlViolation = new FormControl();
  violationFilterSearchCtrl: FormControl = new FormControl();

  protected _onDestroy = new Subject<void>();


  filterFromDate;
  filterToDate;
  filteredViolationData: any;
  /** control for the MatSelect filter keyword */
  violationNameFilterSearchCtrl: FormControl = new FormControl();
  amounts = [
    { status: 'Voided', totalFees: 0 },
    { status: 'Settled', totalFees: 0 },
    { status: 'Opened', totalFees: 0 }
  ];

  constructor(
    private _router: Router,
    private _service: CitationReportListService,
    private _citationService: CitationService,
    private _citationReportService: CitationReportListService,
    private _dialog: MatDialog,
    public authorizationService: AuthorizationService
  ) {
  }

  ngOnInit(): void {
    this._loadOperationList();
    this._loadGetViolationsList();

    this.violationNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredViolationData.filter(item =>
            (item.arName.toLowerCase().indexOf(this.violationNameFilterSearchCtrl.value.toLowerCase()) > -1) || (item.id == this.violationNameFilterSearchCtrl.value)
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredViolationOptions.next(
          filtered
        );
      }, error => { });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.settlementSelect.options.first.select();
    });
  }

  private _loadList(page: number, limit: number, fromDate: string, toDate: string, operationIds: string, citationStatuses: string) {
    this.loading = true;
    this._service.getList(page, limit, fromDate, toDate, operationIds, citationStatuses)
      .pipe(first())
      .subscribe(
        resp => {
          console.log(resp);

          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<CitationList>(this.dataList);

          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadOperationList(): void {
    this.loading = true;
    this._service.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          console.log(resp.payload);
          const all = [{ id: '', name: 'All' }];
          this.operations = all.concat(resp.payload);
          // this.operations = resp.payload;
          this.selectedOperationId = this.operations[0].id;
          console.log('dashboard-hoem Operations', this.operations);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadGetViolationsList(keyword: string = ''): void {
    this.loading = true;

    this._citationService.getViolationsList(keyword)
      .pipe(first())
      .subscribe(
        resp => {
          const all = [{ id: '', arName: 'All' }];
          // this.filteredViolationData = resp.payload.content;
          this.filteredViolationData = all.concat(resp.payload.content);
          this.filtersFormCtrlViolation.setValue(this.filteredViolationData[0].id)

          this.filteredViolationOptions.next(
            this.filteredViolationData
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  private _getFilterSettlementChannel() {
    let channelList = "";

    if (this.selectedSettlementChannel.length !== 0) {
      channelList = this.selectedSettlementChannel.reduce(
        (previousValue, currentValue) => {
          return `${previousValue},${currentValue}`
        }
      );
    } else {
      channelList = 'MOTORIST, ENFORCEMENT_MOBILE, ENFORCEMENT_WEB';
    }

    return channelList;
  }

  clearOperation() {
    this.operationSelect.options.forEach((data: MatOption) => data.deselect());
    this.selectedOperationId = '';
    this.violationFilterSearchCtrl.setValue('')
  }

  clearViolation() {
    this.violationSelect.options.forEach((data: MatOption) => data.deselect());
    this.selectedViolationId = '';
  }
  clearSettlement() {
    this.settlementSelect.options.forEach((data: MatOption) => data.deselect());
    this.selectedSettlementChannel = [];
  }

  onApplyFilter() {
    // this._loadList(0, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedOperationId, this.selectedStatus);
    if (this.filtersFormCtrlViolation.value) this.selectedViolationId = this.filtersFormCtrlViolation.value;

    this._loadCitationReportList(this.currentPage, this.pageSize, this.selectedOperationId, this.selectedStatus, this.selectedViolationId, this.selectedSettlementChannel, this.filterFromDate, this.filterToDate, this.sortBy, this.sortDirection);
    this._loadAmounts(this.currentPage, this.pageSize, this.selectedOperationId, this.selectedStatus, this.selectedViolationId, this.selectedSettlementChannel, this.filterFromDate, this.filterToDate)

  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    // this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedOperationId, this.selectedStatus);
    this._loadCitationReportList(this.currentPage, this.pageSize, this.selectedOperationId, this.selectedStatus, this.selectedViolationId, this.selectedSettlementChannel, this.filterFromDate, this.filterToDate, this.sortBy, this.sortDirection)
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    console.log("sortState", sortState);

    this.sortBy = sortState.active;
    this.sortDirection = (this.sortDirection === 'desc') ? 'asc' : 'desc';
    // this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedOperationId, this.selectedStatus);
    this._loadCitationReportList(this.currentPage, this.pageSize, this.selectedOperationId, this.selectedStatus, this.selectedViolationId, this.selectedSettlementChannel, this.filterFromDate, this.filterToDate, this.sortBy, this.sortDirection)
  }

  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }

  onSelectionChange($event) {

  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }


  get validSearch() {
    return (this.selectedDateRange[0] && this.selectedDateRange[1]) ? true : false;
  }

  getFormatedDate(date) {
    if (!date) return ' - ';
    const d = new Date(date);
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${day} / ${month} / ${year}`;
  }

  formatDate(d) {
    if (!d) return '';
    // const spDateTime = new Date(d).toISOString().split('T');
    // return `${spDateTime[0]} ${spDateTime[1].split('.')[0]}`
    d = new Date(d);
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();

    const hours = (d.getHours() < 10) ? `0${d.getHours()}` : d.getHours();
    const minutes = (d.getMinutes() < 10) ? `0${d.getMinutes()}` : d.getMinutes();
    const seconds = (d.getSeconds() < 10) ? `0${d.getSeconds()}` : d.getSeconds();

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  }

  public downloadCSVOld() {
    const data = this.dataList.map((item) => {
      return {
        citationDate: item['createdDate'],
        plateNumberEn: item['plateNumberEn'],
        status: item['status'],
        violationName: item['violationName'],
        violationCode: item['violationId'],
        id: item['id'],
        feesAmount: item['amount (without VAT)'],
        operationArea: item['operationName'],
        enforcerName: item['enforcerName'],
        country: item['country'] ? item['country']['enName'] : '',
        settledDate: item['modifiedDate'],
        actionBy: item['actionBy'] ? item['actionBy'] : '',

      }
    });
    new ngxCsv(data, 'Citations', {
      headers: [
        'Citation Date', 'Car Plate', 'Status', 'Violation Description',
        'Violation Code', 'Citation Number', 'Fees Amount', 'Operation Area',
        'Enforcer name', 'Country', 'Settled Date', 'Settled/Voided By']
    });
  }

  public downloadCSV() {
    if (this.totalRows > 40000) {
      const dialogRef = this._dialog.open(DialogConfirmation, {
        panelClass: 'custom-mat-show-image-dialog-container',
        width: '468px',
        data: {
          title: { title: "Attention", color: "#D93636" },
          body: "Too Much Records",
          btnConfirmation: { hide: true, title: "Confirm", color: "#D93636" },
          btnClose: { title: "close" },
          comment: false
        }
      });
      dialogRef.afterClosed().subscribe(result => { });
      return false;
    }

    this.loading = true;
    const params: any = {
      limit: this.currentPage,
      offset: this.pageSize,
      operationId: this.selectedOperationId,
      statusList: this.selectedStatus,
      violationId: this.selectedViolationId,
      settlementChannel: this.selectedSettlementChannel,
      fromDate: this.filterFromDate,
      toDate: this.filterToDate
    }
    this._citationReportService.downloadCSV(params).pipe(first()).subscribe(resp => {
      this.loading = false;
      console.log(resp);

      if (resp !== undefined) {
        const hiddenElement = document.createElement('a');
        hiddenElement.setAttribute("href", `data:text/csv;charset=utf-8,%EF%BB%BF ${encodeURIComponent(resp)}`);
        hiddenElement.setAttribute("download", 'citations-report.csv');
        hiddenElement.style.visibility = 'hidden';
        hiddenElement.click();
      }

    },
      error => {
        this.loading = false;
      })
  }

  private _loadCitationReportList(limit: number, offset: number, operationId: string, statusList: string[], violationId: string, settlementChannel: string[], fromDate, toDate, sortBy: string, sortDirection: string) {
    this.loading = true;
    this._service.getCitationReportList(offset, limit, operationId, statusList, violationId, settlementChannel, fromDate, toDate, sortBy, sortDirection)
      .pipe(first())
      .subscribe(
        resp => {
          this.total = resp.payload.content.length;
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<CitationList>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadAmounts(limit: number, offset: number, operationId: string, statusList: string[], violationId: string, settlementChannel: string[], fromDate, toDate) {
    this.loading = true;
    this._service.getAmounts({ limit, offset, operationId, statusList, violationId, settlementChannel, fromDate, toDate })
      .pipe(first())
      .subscribe(
        resp => {
          this.amounts = [
            { status: 'Voided', totalFees: 0 },
            { status: 'Settled', totalFees: 0 },
            { status: 'Opened', totalFees: 0 }
          ];
          const payload = resp['payload'];
          if (payload.length) {
            payload.map(item => {
              switch (item.status) {
                case 'Voided':
                  this.amounts[0] = item;
                  break;
                case 'Settled':
                  this.amounts[1] = item;
                  break;
                case 'Opened':
                  this.amounts[2] = item;
                  break;
              }
            })
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }
}

