
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class DailyMonitorListService {
    constructor(private _http: HttpClient) {
    }
    getList(operationId: number, fromDate: string, toDate: string) {
        let api = `${environment.baseURL}operation/shift/getDailyMonitor/web?operationId=${operationId}&fromDate=${fromDate}&toDate=${toDate}`;
        return this._http.get<any>(api);
    }
    getRolesList(page: number, limit: number, query: string = '') {
        let api = `${environment.baseURL}operation/roles?page=${page}&size=${limit}`;
        if (query)
            api += `&roleName=${query}`;
        return this._http.get<any>(api);
    }

}
