<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <div class="col-3">
            <h1><strong>Shifts Report</strong></h1>
        </div>
        <div class="col-9">
            <div class="row text-center">
                <div>
                    <mat-form-field>
                        <mat-label>Role</mat-label>
                        <mat-select [(value)]="selectedRoleName">
                            <mat-option *ngFor="let role of rolesList" [value]="role">
                                {{role}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <!-- <mat-form-field>
                        <mat-label></mat-label>
                        <mat-select [formControl]="roles" multiple>
                            <mat-select-trigger>
                                {{roles.value ? roles.value[0] : ''}}
                                <span *ngIf="roles.value?.length > 1" class="example-additional-selection">
                                    (+{{roles.value.length - 1}} {{roles.value?.length === 2 ? 'other' :
                                    'others'}})
                                </span>
                            </mat-select-trigger>
                            <mat-option *ngFor="let role of rolesList" [value]="role">{{role}}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                </div>
                <div>
                    <mat-form-field>
                        <input matInput [(ngModel)]="selectedDateRange" [owlDateTime]="dt1" [selectMode]="'range'"
                            [owlDateTimeTrigger]="dt1" placeholder="Filter by Date">
                        <!-- <span [owlDateTimeTrigger]="dt1"><i class="fas fa-calendar"></i></span> -->
                        <owl-date-time [pickerType]="'calendar'" [dateTimeChange]="onSelectedDateRange()" #dt1>
                        </owl-date-time>
                    </mat-form-field>
                </div>
                <div>
                    <button [disabled]="loading || !validSearch" class="btn search-btn"
                        (click)="onApplyFilter()">Search</button>
                </div>
            </div>
        </div>
    </div>

    <!-- List Grid -->
    <div [hidden]="!dataList.length" class="mat-elevation-z8 custom-table-card">
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows>
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef>#</mat-header-cell>
                <mat-cell *matCellDef="let element ; let i = dataIndex">
                    <!-- {{paginator.pageIndex * paginator.pageSize + dataSource.filteredData.indexOf(element) + 1}} -->
                    {{paginator.pageIndex * paginator.pageSize + i + 1}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.id}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.shiftName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="role">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.roleName}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="members">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Members</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.noEmployee}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="date">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                <mat-cell *matCellDef="let element">from: {{ element.startDate}} <br />to: {{ element.endDate}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="time">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
                <mat-cell *matCellDef="let element">from: {{ element.startTime}} <br />to: {{ element.endTime}}
                </mat-cell>
            </ng-container>
            <!-- Actions -->

            <ng-container matColumnDef="loading">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    LoadingData
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    NoData
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                [class.expanded-row]="row.isExpanded">
            </mat-row>

            <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr> -->
        </mat-table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons [length]="totalRows"
            showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize" (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <!-- List Grid -->
    <mat-spinner *ngIf="loading"></mat-spinner>


    <svg class="no-data" *ngIf="!dataList.length" width="304" height="304" viewBox="0 0 304 304" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="152" cy="152" r="152" fill="#F9F7FF" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M132.307 98.667C134.346 98.667 135.999 100.146 135.999 101.971V115.189C135.999 117.014 134.346 118.493 132.307 118.493C130.268 118.493 128.615 117.014 128.615 115.189V101.971C128.615 100.146 130.268 98.667 132.307 98.667Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M171.692 98.667C173.732 98.667 175.385 100.146 175.385 101.971V115.189C175.385 117.014 173.732 118.493 171.692 118.493C169.653 118.493 168 117.014 168 115.189V101.971C168 100.146 169.653 98.667 171.692 98.667Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M106.463 133.207C106.463 131.382 108.116 129.902 110.156 129.902H193.848C195.887 129.902 197.54 131.382 197.54 133.207C197.54 135.032 195.887 136.511 193.848 136.511H110.156C108.116 136.511 106.463 135.032 106.463 133.207Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M116.244 116.65C113.09 119.962 111.385 124.952 111.385 131.6V172.4C111.385 179.048 113.09 184.038 116.244 187.35C119.353 190.614 124.396 192.8 132.308 192.8H171.692C179.604 192.8 184.647 190.614 187.756 187.35C190.91 184.038 192.615 179.048 192.615 172.4V131.6C192.615 124.952 190.91 119.962 187.756 116.65C184.647 113.386 179.604 111.2 171.692 111.2H132.308C124.396 111.2 119.353 113.386 116.244 116.65ZM110.833 111.75C115.724 106.614 122.989 104 132.308 104H171.692C181.011 104 188.276 106.614 193.167 111.75C198.013 116.838 200 123.848 200 131.6V172.4C200 180.152 198.013 187.162 193.167 192.25C188.276 197.386 181.011 200 171.692 200H132.308C122.989 200 115.724 197.386 110.833 192.25C105.987 187.162 104 180.152 104 172.4V131.6C104 123.848 105.987 116.838 110.833 111.75Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M125.333 152C125.333 149.055 127.711 146.667 130.643 146.667H130.691C133.623 146.667 136 149.055 136 152C136 154.946 133.623 157.334 130.691 157.334H130.643C127.711 157.334 125.333 154.946 125.333 152Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M125.333 173.333C125.333 170.388 127.711 168 130.643 168H130.691C133.623 168 136 170.388 136 173.333C136 176.279 133.623 178.667 130.691 178.667H130.643C127.711 178.667 125.333 176.279 125.333 173.333Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M146.667 152C146.667 149.055 149.044 146.667 151.976 146.667H152.024C154.956 146.667 157.333 149.055 157.333 152C157.333 154.946 154.956 157.334 152.024 157.334H151.976C149.044 157.334 146.667 154.946 146.667 152Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M146.667 173.333C146.667 170.388 149.044 168 151.976 168H152.024C154.956 168 157.333 170.388 157.333 173.333C157.333 176.279 154.956 178.667 152.024 178.667H151.976C149.044 178.667 146.667 176.279 146.667 173.333Z"
            fill="#481A90" />
        <path fill-rule="evenodd" clip-rule="evenodd"
            d="M168 152C168 149.055 170.377 146.667 173.309 146.667H173.357C176.29 146.667 178.667 149.055 178.667 152C178.667 154.946 176.29 157.334 173.357 157.334H173.309C170.377 157.334 168 154.946 168 152Z"
            fill="#481A90" />
    </svg>

</section>
