import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class MoiServiceService {

  
  constructor(private http: HttpClient) { }
  _token = `Bearer ${localStorage.getItem('token')}`;


  getMoiScanned(page: number, limit: number, sortingType: number = 1, sortingBy: string = '',plateNumber=''): Observable<any> {  
    if (plateNumber) {
    return this.http.get(`${env.baseURL}enforcements/citations/moi-scans?page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}&enPlateNumber=${plateNumber}`, {
        headers: { Authorization: this._token },
      });
    }else{
      return this.http.get(`${env.baseURL}enforcements/citations/moi-scans?page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}`, {
        headers: { Authorization: this._token },
      });
    }
  }

  // acceptDetentionRequest(data: AcceptDetentionRequestModel): Observable<any> {
  //   return this.http.put(`${env.baseURL}orders/detention-orders/accept-order`, data, {
  //     headers: { Authorization: this._token },
  //   });
  // }

  // releaseDetentionRequest(data: ReleaseDetentionRequestModel): Observable<any> {
  //   return this.http.put(`${env.baseURL}orders/detention-orders/update-order`, data, {
  //     headers: { Authorization: this._token },
  //   });
  // }

}
export interface MoiScannedModel {
  scannedDate:string,
  vehicleColor:string,
  vehicleCountry:string,
  vehicleMake:string
  vehiclePlateNumber:string
}