<h1 mat-dialog-title>Idle</h1>

<div mat-dialog-content>
    <form [formGroup]="idleForm">
        <section class="row g-0">
            <div class="col-6">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Start Date</mat-label>
                    <input formControlName="fromDate" type="date" matInput [min]="startDate" [max]="endDate" placeholder="select or enter start date" (ngModelChange)="onSelectEndDate($event)" required>
                    <mat-error *ngIf="idleForm.controls.fromDate.errors">
                        Start Date is Required
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>Start Time</mat-label>
                    <input type="text" matInput formControlName="fromTime" placeholder="select or enter start time" onfocus="this.type='time'"  required>
                    <mat-error *ngIf="idleForm.controls.fromTime.errors">
                        Start Time is Required
                    </mat-error>
                </mat-form-field>
            </div>
    
            <div class="col-6 px-2">
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>End Date</mat-label>
                    <input type="date" matInput [min]="endMinDate || startDate" [max]="endDate" formControlName="toDate" placeholder="select or enter end date" required>
                    <mat-error *ngIf="idleForm.controls.toDate.errors">
                        End Date is Required
                    </mat-error>
                </mat-form-field>
    
                <mat-form-field class="full-width" appearance="fill">
                    <mat-label>End Time</mat-label>
                    <input type="text" matInput formControlName="toTime" placeholder="select or enter end time" onfocus="this.type='time'" required>
                    <mat-error *ngIf="idleForm.controls.toTime.errors">
                        End Time is Required
                    </mat-error>
                </mat-form-field>
            </div>
        </section>
    </form>

    
</div>

<div mat-dialog-actions class="row g-0 py-4 justify-content-end">
    <div class="col-6 px-2 text-end">
        <button class="btn full-width" style="background: #ACB5BE;" (click)="onCancel()"
            >Cancel</button>
    </div>
    <div class="col-6 px-2 text-end">
        <button class="btn login__btn full-width" (click)="onIdleWorkforce()"
            [disabled]="!idleForm.valid || loading">Save</button>
    </div>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>
