import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { MoiListComponent } from './moi-list/moi-list.component';
import { MoiRoutingModule } from './moiList-routing.module';



@NgModule({
  declarations: [MoiListComponent],
  imports: [
    CommonModule,
    SharedModule,
    MoiRoutingModule
  ]
})
export class MoiListModule { }
