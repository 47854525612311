import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../_services';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouteGuard implements CanActivate {
  flag: boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.check(next);
  }

  check(route: ActivatedRouteSnapshot) {
    if (
      this.authorizationService.checkGroupName('Dashboard') ||
      this.authorizationService.check('Reports', 'Finance')
    ) {
      return true;
    } else {
      this.router.navigate(['workforce-roles']);
    }
  }
}
