import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';
import { AddEquipmentComponent } from './add-equipment/add-equipment.component';
import { EquipmentsRoutingModule } from './equipments-routing.module';
import { HandoverEquipmentComponent } from './handover-equipment/handover-equipment.component';
import { ListEquipmentsComponent } from './list-equipments/list-equipments.component';
import { ReclaimEquipmentComponent } from './reclaim-equipment/reclaim-equipment.component';


@NgModule({
  declarations: [ListEquipmentsComponent, AddEquipmentComponent, HandoverEquipmentComponent, ReclaimEquipmentComponent],
  imports: [
    CommonModule,EquipmentsRoutingModule,SharedModule
  ]
})
export class EquipmentsModule { }
