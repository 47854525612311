import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { CitationService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';
import { CitationInvoiceDialog } from '../_shared/citation-invoice/citation-invoice.dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneratingInvoiceDialogComponent } from '../_shared/generating-invoice-dialog/generating-invoice-dialog.component';

@Component({
  selector: 'app-citation-settle',
  templateUrl: './citation-settle.component.html',
  styleUrls: ['./citation-settle.component.scss']
})
export class CitationSettleComponent implements OnInit, AfterViewChecked {

  loading = false;
  isGeneratingMsg = false;
  data: any = {};
  ID;
  penalties: any;
  total: number; // from penalties com
  vat = 0;
  paymentMethodForm!: FormGroup
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder


  ) {
    this.ID = this._activatedRoute.snapshot.params.id;
    this._loadCitation(this.ID);
    this.paymentMethodForm = this.fb.group({
      paymentMethod: ['CASH', Validators.required]
    });
  }

  ngOnInit(): void {
    this._loadVat();
  }

  private _loadCitation(ids) {
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;

        },
        error => {
          console.log(" err ===== ", error);
        });
  }

  private _loadVat() {
    this._citationService.getVat()
      .pipe(first())
      .subscribe(
        resp => {
          this.vat = resp.payload / 100;
        },
        error => { });
  }
  getPenalities($event) {
    this.penalties = $event;
    return $event;
  }
  ngAfterViewChecked(): void {
      this.changeDetector.detectChanges();
  }

  getTotal($event) {
    this.total = $event;
    console.log(this.total);
    
    return $event;
  }

  onApply() {
    this.loading = true
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      // height: '500px',
      data: {
        title: { title: "Settle Citation", color: "#481A90" },
        body: `
        You’re are going to settle citation "${this.data[0].plateNumberEn}", please confirm settlement`,
        btnConfirmation: { title: "Settle", color: "#481A90" },
        btnClose: { title: "No, Cancel" },
        comment: false
      }
    });

    dialogRef.afterClosed().subscribe(confirmationDialogResult => {

      if (confirmationDialogResult?.confirmed) {
        this.loading = true;
        this.penalties.citationRequests.map((citationRequest) => {
          citationRequest.penaltiesRequestList.map((penaltiesRequest) => {
            return (penaltiesRequest.comment = 'Settle from portal web');
          });
        });

        const paymentMethod =
          this.paymentMethodForm.get('paymentMethod')?.value;
        this.penalties.paymentMethod = paymentMethod;
        this.penalties.settlementChannel = 'ENFORCEMENT_WEB';

        this._citationService
          .payCitationAsync(this.penalties)
          .pipe(first())
          .subscribe(
            (data) => {
              this.isGeneratingMsg = true;
              console.log('update payCitation  data =========', data);
              if (data['payload']) {
                // const dialogRefInvoice = this._dialog.open(
                //   CitationInvoiceDialog,
                //   {
                //     panelClass: 'custom-mat-show-image-dialog-container',
                //     width: '820px',
                //     // height: '500px',
                //     data: data['payload'],
                //     disableClose: true,
                //   }
                // );
                // dialogRefInvoice.afterClosed().subscribe((result) => {
                //   // this.loading = false;
                //   console.log('dialogRefInvoice result', result);
                //   window.location.reload();
                // });
              } else {
                const msgDialogRef = this._dialog.open(
                  GeneratingInvoiceDialogComponent,
                  {}
                );
                this.isGeneratingMsg = true;
              }
            },
            (error) => {
              console.log('update payCitation err ===== ', error);
              this.loading = false;
            },
            () => {
              this.loading = false;
            }
          );
      } else {
        this.loading = false;
      }
    });

  }

  onViewInvoice(){
    this.loading = true
    this._citationService.getInvoiceByCitationId(this.ID).subscribe(
      (data) => {
        console.log('data', data);
        this.loading = false;
        if (data?.payload) {
          const dialogRefInvoice = this._dialog.open(
            CitationInvoiceDialog,
            {
              panelClass: 'custom-mat-show-image-dialog-container',
              width: '820px',
              // height: '500px',
              data: data['payload'],
              disableClose: true,
            }
          );
      
          dialogRefInvoice.afterClosed().subscribe((result) => {
            this.loading = false;
            console.log('dialogRefInvoice result', result);
            // window.location.reload();
          });
        } else {
          const msgDialogRef = this._dialog.open(GeneratingInvoiceDialogComponent, {
          })
        }
       
      },
      (error) => {
        console.log('error', error);
        this.loading = false;
      }
    )
  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

}