<section class="row g-0 align-items-center pb-5">
    <div class="col-12 row g-0 mb-5">
        <div class="col-9">
            <h1><strong>Shifts Scheduler</strong></h1>
        </div>
        <div class="col-3 text-end">
            <button class="btn login__btn" (click)="createShift()">Create New Shift</button>
        </div>
    </div>
    <!-- {{rolesLeagend | json}} -->

    <ul class="legend-colors mb-5">
        <li *ngFor="let item of rolesLeagend">
            <span [style.background]="getColor(item.name)"></span>{{item.name}}</li>
    </ul>
        <full-calendar
        #fullcalendar
        *ngIf='calendarVisible'
        [options]='calendarOptions'
        (eventRender)="onEventRender($event)"
        ></full-calendar>
        <mat-spinner *ngIf="loading"></mat-spinner>
</section>