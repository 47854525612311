<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-3">
        <div class="col-md-9 col-sm-12 ">
            <h1><strong>Scanned MOI</strong></h1>
        </div>
        <!-- <div class="col-md-3 col-sm-12">
            <div class="text-center header-buttons">
                <button class="btn login__btn" *ngIf="authorizationService.check('Blacklist','Add')" (click)="onNew()">Add a Vehicle to Blacklist</button>
            </div>
        </div> -->
    </div>

    <div class="" *ngIf="successMsg">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>
    <div class="" *ngIf="failedMsg">
        <div class="alert danger-alert" role="alert">
            {{ failedMsg }}
        </div>
    </div>


    <!-- Search Box-->
    <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
        <input matInput placeholder="Search by vehicle info" autocomplete="off" [(ngModel)]="searchKey"
            (keyup)="applySearch($event)" />
        <mat-icon matPrefix>search</mat-icon>
        <!-- <app-filter-list [listTitle]="'Detention Order Status'" [label]="'Filter'" [icon]="'filter_list'" [clearBtn]="'Clear'" [filterBtn]="'Filter'" [returnValue]="'name'" [list]="statusList" (applyFilter)="applyFilter($event)">
        </app-filter-list> -->

        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <!-- Search Box-->

    <section>

        <div class="mat-elevation-z8 custom-table-card">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                multiTemplateDataRows>
                <ng-container matColumnDef="vehicleCountry">
                    <mat-header-cell *matHeaderCellDef>Vehicle Country</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.vehicleCountry}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleInfo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Info</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ slicePlateNumber(element.vehiclePlateNumber)}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="vehicleMake">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Make</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.vehicleMake }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="vehicleColor">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Color</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.vehicleColor }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="scannedDate">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Scanned Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.scannedDate | date :'short'}}</mat-cell>
                </ng-container>


                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        LoadingData
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        NoData
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                    [class.expanded-row]="row.isExpanded">
                </mat-row>

            </mat-table>
            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <!-- List Grid -->
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>

</section>