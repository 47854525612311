import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { WhitelistService } from '../_services';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

@Component({
  selector: 'app-whitelist-details',
  templateUrl: './whitelist-details.component.html',
  styleUrls: ['./whitelist-details.component.scss']
})
export class WhitelistDetailsComponent implements OnInit {

  title: string = "Add a Vehicle to Whitelist";
  id;
  details: any[];
  itemDetails: any;
  dataSource: MatTableDataSource<any>;
  whitelistDataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['plateType', 'country', 'make', 'model', 'color'];
  displayedWhitelistColumns: string[] = ['startDate', 'endDate', 'fromTime', 'toTime', 'description'];
  makeId: any;
  colorId: any;

  constructor(
    private location: Location,
    private whitelistService: WhitelistService,
    private route: ActivatedRoute,
    private _router: Router
  ) {
    this.id = route.snapshot.paramMap.get('id');
    // console.log(id);

  }

  ngOnInit(): void {
    this.getWhitelistDetails();
    
  }

  private getWhitelistDetails() {
    this.whitelistService.getWhitelistDetails().pipe(first()).subscribe(res => {
      // console.log(res);
      this.details = res.payload.content;
      this.itemDetails = this.details?.filter(res => res.id == this.id)
      console.log(this.itemDetails);
      this.dataSource = new MatTableDataSource<any>(this.itemDetails);
      this.itemDetails.forEach(details => {
        console.log(details.whitelistDetails);
        this.whitelistDataSource = new MatTableDataSource<any>(details.whitelistDetails);
      });
    })
  }
  

  public onAddPeriod() { 
    this._router.navigateByUrl(`/add-period/${this.id}`);
  }

  goBack() {
    this.location.back();
  }

}
