<!-- Slideshow container -->
<div class="slideshow-container ">
    <!-- Full-width images with number and caption text -->
    <div class="mySlides2 fade2" id="printcontent">
        <!-- <div class="numbertext">1 / 3</div> -->
        <img *ngIf="isFile==false; else elseTemplate" [src]="imagePath" style="width: 960px; height: 700px;"
            id="citationImage">
        <ng-template #elseTemplate>
            <img src="../../../../assets/Icons/pdf.png" style="width: 960px; height: 700px;">
        </ng-template>
        <!-- <div class="text">Caption Text</div> -->
        <!-- The dots/circles -->
        <div style="text-align:center;position: relative;top: -45px;">
            <span class="dot" *ngFor="let item of data; let i = index" (click)="onDot(i)"></span>
        </div>
    </div>



    <!-- Print and Download buttons -->
    <div class="example-button-container download">
        <a target="_blank" class="btn btn-primary mb-2" mat-mini-fab aria-label="Download Image" [href]="imagePath"
            download="image.png">
            <mat-icon>arrow_downward</mat-icon>
        </a>
        <a [hidden]="isFile" class="btn btn-primary mb-2" mat-mini-fab aria-label="Print Image" (click)="onConfirm()">
            <mat-icon>print</mat-icon>
        </a>
        <a [hidden]="isFile" class="btn btn-primary" mat-mini-fab aria-label="Rotate Image" (click)="onRotate('left')">
            <mat-icon>crop_rotate</mat-icon>
        </a>
    </div>

    <!-- Next and previous buttons -->
    <a class="prev" (click)="onPrev()">&#10094;</a>
    <a class="next" (click)="onNext()">&#10095;</a>
    <mat-spinner *ngIf="loading"></mat-spinner>
</div>