<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <div class="col-3">
            <h1><strong class="title">Daily Monitor</strong></h1>
        </div>
        <div class="col-9">

        </div>
    </div>

    <div class="container-fluid ">
        <!-- filter by date container -->
        <div class="row">
            <div class="col">
                <!-- <span>December 22 - 28, 2021</span>&nbsp; &nbsp; -->
                <span class="pagination-title">{{paginationTitle}}</span>
                <button mat-icon-button (click)="onPrevPage()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 12C8 11.4347 8.25123 10.8693 8.7444 10.4413L14.8113 5.17566C15.0811 4.94145 15.5278 4.94145 15.7976 5.17566C16.0675 5.40986 16.0675 5.79752 15.7976 6.03173L9.73073 11.2974C9.28409 11.685 9.28409 12.315 9.73073 12.7026L15.7976 17.9683C16.0675 18.2025 16.0675 18.5901 15.7976 18.8243C15.5278 19.0586 15.0811 19.0586 14.8113 18.8243L8.7444 13.5587C8.25123 13.1307 8 12.5653 8 12Z"
                            fill="#6B757E" />
                    </svg>
                </button>

                <button mat-icon-button (click)="onNextPage()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.0001 12C16.0001 12.5653 15.7488 13.1307 15.2557 13.5587L9.18878 18.8243C8.91893 19.0586 8.47229 19.0586 8.20245 18.8243C7.9326 18.5901 7.9326 18.2025 8.20245 17.9683L14.2693 12.7026C14.716 12.315 14.716 11.685 14.2693 11.2974L8.20245 6.03173C7.9326 5.79752 7.9326 5.40986 8.20245 5.17566C8.47229 4.94145 8.91893 4.94145 9.18878 5.17566L15.2557 10.4413C15.7488 10.8693 16.0001 11.4347 16.0001 12Z"
                            fill="#6B757E" />
                    </svg>
                </button>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Filter by Role</mat-label>
                    <mat-select [(value)]="selectedRoleName">
                        <mat-option *ngFor="let role of rolesList" [value]="role">
                            {{role}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <!-- <mat-label>Filter by Day</mat-label> -->
                    <input matInput type="date" [(ngModel)]="dateFilter" (ngModelChange)="onDateFilterChange($event)"
                        placeholder="Filter by Day">
                </mat-form-field>
            </div>
        </div>
        <br />
        <div class="row">
            <!-- {{dataList.shifts | json}} -->
            <div class="col-12 accordion-panel-div" *ngFor="let shift of dataList.shifts ; let shiftIdx = index">
                <mat-accordion>
                    <mat-expansion-panel class="accordion-panel">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{shift.name}}
                            </mat-panel-title>
                            <mat-panel-description>
                                From <span class="card-time">{{timeFormat(shift.startTime)}}</span> To <span
                                    class="card-time">{{timeFormat(shift.endTime)}}</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>

                        <div class="accordion-content">

                            <div class="row">
                                <div class="col-4" *ngFor="let detail of shift.shiftDetails ; let shiftIdx = index">
                                    <mat-card class="card">
                                        <mat-card-header>
                                            <img mat-card-avatar
                                                [src]="getImage(getEmployeeMap(detail.employeeId,'imageData'))" alt=""
                                                class="card-header-image">
                                            <mat-card-title>
                                                {{getEmployeeMap(detail.employeeId,'name')}}
                                            </mat-card-title>
                                            <mat-card-subtitle>
                                                {{getEmployeeMap(detail.employeeId,'mobNumber')}}
                                            </mat-card-subtitle>
                                        </mat-card-header>
                                        <mat-card-content>
                                            <button mat-button class="btn-status"
                                                [style.background-color]="getShiftStatusColor(detail.finalShiftStatus)">{{getShiftStatusName(detail.finalShiftStatus)}}</button>

                                            <hr *ngIf="showHr(detail.finalShiftStatus)">
                                            {{showHr(detail.finalShiftStatus)}}
                                        </mat-card-content>

                                        <mat-card-actions>
                                        </mat-card-actions>
                                    </mat-card>

                                </div>
                            </div>


                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
    </div>

    <mat-spinner *ngIf="loading"></mat-spinner>

</section>