import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ManageWhitelistComponent } from './manage-whitelist/manage-whitelist.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../_shared/shared.module';
import { WhitelistFormComponent } from './whitelist-form/whitelist-form.component';
import { WhitelistDetailsComponent } from './whitelist-details/whitelist-details.component';
import { AddPeriodComponent } from './add-period/add-period.component';



@NgModule({
  declarations: [
    ManageWhitelistComponent,
    WhitelistFormComponent,
    WhitelistDetailsComponent,
    AddPeriodComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    SharedModule
  ]
})
export class WhitelistModule { }
