export const environment = {
  production: false,
  baseURL: 'https://preprod-gateway.thaki.net/',
  firebaseConfig: {
    apiKey: "AIzaSyAnwNMG-7P4e2CZNn46h5mPYakdkE3JnTY",
    authDomain: "fir-notification-f002c.firebaseapp.com",
    projectId: "fir-notification-f002c",
    storageBucket: "fir-notification-f002c.appspot.com",
    messagingSenderId: "482473021140",
    appId: "1:482473021140:web:da405a5fda456b38173541",
    measurementId: "G-GWPVS9NHP2",
    vapidKey: "BLPC2ke4UFE8cFb1X6vegJmgGD6KwjXOEsIpQFLJjFRS05dRfMqiVzO5eLocTyTlQJqpEM-k3erFwhKehxHbMow"
  }
};
