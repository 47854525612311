import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateEssentialUserComponent } from './admin/create-essential-user/create-essential-user.component';
import { EssentialUSersComponent } from './admin/essential-users/essential-users.component';
import { ReassignWorkforceComponent } from './admin/reassign-workforce/reassign-workforce.component';
import { WorkforceListComponent } from './admin/workforce-list/workforce-list.component';
import { CitationControlComponent } from './citation/citation-control/citation-control.component';
import { CitationListComponent } from './citation/citation-list/citation-list.component';
import { CitationSettleComponent } from './citation/citation-settle/citation-settle.component';
import { CitationSettledViewComponent } from './citation/citation-settled-view/citation-settled-view.component';
import { CreateShiftComponent } from './create-shift/create-shift.component';
import { CreateWorkforceMemberComponent } from './create-workforce-member/create-workforce-member.component';
import { HomePageComponent } from './home-page/home-page.component';
import { IsAuthenticatedGuard } from './is-authenticated.guard';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { NewPasswordComponent } from './login/new-password/new-password.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { ManageRolesListComponent } from './manage-roles-list/manage-roles-list.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RoleCreationComponent } from './role-creation/role-creation.component';
import { ShiftsSchedulerComponent } from './shifts-scheduler/shifts-scheduler.component';

import { AuthorizationGroupNameGuard } from './Guards/authorization-group-name.guard';
import { BlacklistListComponent } from './blacklist/blacklist-list/blacklist-list.component';
import { CitationPenaltiesComponent } from './citation/_penalties/citation-penalties/citation-penalties.component';
import { CitationControlMultiComponent } from './citation/citation-control-multi/citation-control-multi.component';
import { CitationReportListComponent } from './citation/citation-report-list/citation-report-list.component';
import { CitationSettledCitationsComponent } from './citation/citation-settled-citations/citation-settled-citations.component';
import { CitationVoidListComponent } from './citation/citation-void-list/citation-void-list.component';
import { CitationVoidVoidComponent } from './citation/citation-void-void/citation-void-void.component';
import { CitationVoidComponent } from './citation/citation-void/citation-void.component';
import { DailyMonitorListComponent } from './daily-monitor/daily-monitor-list/daily-monitor-list.component';
import { DashboardHomeComponent } from './dashboard/dashboard-home/dashboard-home.component';
import { MyProfileComponent } from './my-account/my-profile/my-profile.component';
import { ShiftDetailsComponent } from './shift-details/shift-details.component';
import { ShiftHistoryListComponent } from './shift/shift-history-list/shift-history-list.component';
import { ManageViolationsPenaltiesFormComponent } from './violations/manage-violations-penalties-form/manage-violations-penalties-form.component';
import { ManageViolationsPenaltiesComponent } from './violations/manage-violations-penalties/manage-violations-penalties.component';
import { PenaltiesFormComponent } from './violations/penalties-form/penalties-form.component';
import { PenaltiesListComponent } from './violations/penalties-list/penalties-list.component';
import { ViolationsFormComponent } from './violations/violations-form/violations-form.component';
import { ViolationsListComponent } from './violations/violations-list/violations-list.component';
import { AddPeriodComponent } from './whitelist/add-period/add-period.component';
import { ManageWhitelistComponent } from './whitelist/manage-whitelist/manage-whitelist.component';
import { WhitelistDetailsComponent } from './whitelist/whitelist-details/whitelist-details.component';
import { WhitelistFormComponent } from './whitelist/whitelist-form/whitelist-form.component';

import { RouteGuard } from './_guards';
import { ScanDetailsComponent } from './admin/scan-details/scan-details.component';
import { WorkforceReportListComponent } from './admin/workforce-report-list/workforce-report-list.component';
import { BlacklistDetailsComponent } from './blacklist/blacklist-details/blacklist-details.component';
import { BlacklistFormComponent } from './blacklist/blacklist-form/blacklist-form.component';
import { BootRequestModule } from './boot-requets/boot-requets.module';
import { CalendarComponent } from './calendar/calendar.component';
import { CitationPrintComponent } from './citation/_shared/citation-print/citation-print.component';
import { PrintInvoiceComponent } from './citation/print-invoice/print-invoice.component';
import { DetentionModule } from './detention/detention.module';
import { EquipmentsModule } from './equipments/equipments.module';
import { MoiListModule } from './moi-list/moi-list.module';
import { ReportsModule } from './reports/reports.module';
import { ManageRequestsComponent } from './requests-map/manage-requests/manage-requests.component';

const routes: Routes = [
  { path: 'login', component: LoginPageComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'confirm-password', component: NewPasswordComponent },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'workforce-roles',
        component: ManageRolesListComponent,
      },
      {
        path: 'roles-form',
        component: RoleCreationComponent,
      },
      {
        path: 'roles-form/:type/:id',
        component: RoleCreationComponent,
      },
      {
        path: 'create-shift',
        component: CreateShiftComponent,
      },
      {
        path: 'shifts-schedule-old',
        component: ShiftsSchedulerComponent,
      },
      {
        path: 'shift-detail',
        component: ShiftDetailsComponent,
      },
      {
        path: 'essential-users',
        component: EssentialUSersComponent,
      },
      {
        path: 'create-essential-user',
        component: CreateEssentialUserComponent,
      },
      {
        path: 'update-essential-user/:id',
        component: CreateEssentialUserComponent,
      },
      {
        path: 'workforce-list',
        component: WorkforceListComponent,

      },
      {
        path: 'workforce-report-list',
        component: WorkforceReportListComponent,

      },
      {
        path: 'scan-details',
        component: ScanDetailsComponent,
      },

      {
        path: 'reports',
        loadChildren: () => ReportsModule,
      },
      {
        path: 'equipments',
        loadChildren: () => EquipmentsModule,
      },
      {
        path: 'add-workforce',
        component: CreateWorkforceMemberComponent,
        canActivate: [AuthorizationGroupNameGuard],
        data: {
          groupName: 'Workforce',
          actions: ['Add']
        }
      },
      {
        path: 'detention',
        loadChildren: () => DetentionModule,
      },
      {
        path: 'boot',
        loadChildren: () => BootRequestModule,
      },
      {
        path: 'moi',
        loadChildren: () => MoiListModule,
      },
      
      { path: 'update-workforce/:id', component: CreateWorkforceMemberComponent },
      { path: 'assign-workforce', component: ReassignWorkforceComponent },
      { path: 'enforcement-citations', component: CitationListComponent },
      { path: 'enforcement-citations/:plateNumber', component: CitationListComponent },

      { path: 'invoice-details', component: PrintInvoiceComponent },

      { path: 'citation-control/:id', component: CitationControlComponent },
      { path: 'citation-settle/:id', component: CitationSettleComponent },
      { path: 'citation-settled-view/:id', component: CitationSettledViewComponent },
      { path: 'citation-void/:id', component: CitationVoidComponent },
      { path: 'citation-penalties/:id', component: CitationPenaltiesComponent },
      { path: 'citation-control-multi/:ids', component: CitationControlMultiComponent },
      { path: 'enforcement-citations-viod', component: CitationVoidListComponent },
      { path: 'citation-void-void/:id', component: CitationVoidVoidComponent },
      { path: 'citation-settled-list', component: CitationSettledCitationsComponent },
      
      { path: 'requests-map', component: ManageRequestsComponent },

      { path: 'dashboard', component: DashboardHomeComponent, canActivate: [RouteGuard] },
      { path: 'enforcement-violations', component: ViolationsListComponent },
      { path: 'violation-form/:type/:id', component: ViolationsFormComponent },
      { path: 'violation-form', component: ViolationsFormComponent },
      { path: 'penalties-list', component: PenaltiesListComponent },
      { path: 'penalty-form', component: PenaltiesFormComponent },
      { path: 'penalty-form/:type/:id', component: PenaltiesFormComponent },
      { path: 'violation-penalties-list', component: ManageViolationsPenaltiesComponent },
      { path: 'violation-penalties-form', component: ManageViolationsPenaltiesFormComponent },
      { path: 'violation-penalties-form/:type/:id', component: ManageViolationsPenaltiesFormComponent },
      { path: 'shift-history-list', component: ShiftHistoryListComponent },
      { path: 'daily-monitor-list', component: DailyMonitorListComponent },
      { path: 'citation-report-list', component: CitationReportListComponent },
      { path: 'manage-whitelist', component: ManageWhitelistComponent },
      { path: 'whitelist-form', component: WhitelistFormComponent },
      { path: 'whitelist-details/:id', component: WhitelistDetailsComponent },
      { path: 'add-period/:id', component: AddPeriodComponent },
      { path: 'my-profile', component: MyProfileComponent },
      { path: 'enforcement-black-list', component: BlacklistListComponent },
      { path: 'blacklist-form', component: BlacklistFormComponent },
      { path: 'blacklist-form/:type/:id', component: BlacklistFormComponent },
      { path: 'blacklist-details/:id', component: BlacklistDetailsComponent },
      { path: 'shifts-schedule', component: CalendarComponent },
      { path: 'print-citation', component: CitationPrintComponent },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
