<section class="row g-0 align-items-center">
  <div class="col-12 row g-0">
    <h1><strong>SCAN DETAILS</strong></h1>
  </div>
  <!------------------------------------filter fields----------------------------------------------->
  <form [formGroup]="filterForm">
    <div class="row d-flex align-items-center">
      <!-- Filters -->
      <div class="accordion-panel-div">
        <mat-accordion>
          <mat-expansion-panel class="accordion-panel">
            <mat-expansion-panel-header>
              <mat-panel-title>Filter</mat-panel-title>
              <mat-panel-description></mat-panel-description>
            </mat-expansion-panel-header>

            <div class="accordion-content">

              <div class="row">
                <div class="col-12">
                  <div class="row text-center align-items-end">
                    <div class="col-3">
                      <mat-form-field appearance="standard">
                        <mat-label>From (Issue Date)</mat-label>
                        <input type="date" matInput placeholder="from" formControlName="startDate"/>
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field appearance="standard">
                        <mat-label>To (Issue Date)</mat-label>
                        <input type="date" matInput placeholder="to" formControlName="endDate"/>
                      </mat-form-field>
                    </div>
                    <div class="col-3">
                      <mat-form-field>
                        <mat-label>Enforcer Name</mat-label>
                        <mat-select [(value)]="enforcerName" formControlName="enforceName" placeholder="Enforcer Name" #singleSelect>
                          <mat-option>
                            <ngx-mat-select-search formControlName="enforceNameSearch" placeholderLabel="Enforcer Name">
                            </ngx-mat-select-search>
                          </mat-option>
                          <mat-option [value]="''">{{'All'}}</mat-option>
                          <mat-option *ngFor="let enforcer of filteredEnforcerOptions | async" [value]="enforcer.userName">
                            {{enforcer.id}}-{{enforcer.name}}
                          </mat-option>
                          <div class="clear-btn" *ngIf="singleSelect.value">
                            <button mat-raised-button (click)="clearEnforcer()">
                              Clear
                            </button>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="d-flex justify-content-end pb-2">
                    <div>
                      <button [disabled]="loading" class="btn search-btn me-2"
                              (click)="getWorkforceReports()">Search</button>
                      <button class="btn filters-btn-clear" (click)="onClearFilters()">Clear</button>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <!-- Filters -->

    </div>
  </form>
  <!---------------------------------------summary-------------------------------------------->

  <hr>
  <div class="row col-md-12" style="margin: 3px;padding: 2px">
    <div class="col-md-3">
      <mat-form-field style="width: fit-content;margin: 3px;padding: 2px">
        <input matInput value="{{scansCount|number}}" placeholder="SCANS COUNT" readonly style="font-size: xx-large" matTooltip="All Enforcer Scans">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field style="width: fit-content;margin: 3px;padding: 2px">
        <input matInput value="{{illegalScansCount|number}}" placeholder="ILLEGAL SCANS" readonly  matTooltip="All Enforcer Illegal Scans (PARKING WITHOUT A TICKET,PARKING WITHOUT A TICKET and Boot)"
               style="font-size: xx-large">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field style="width: fit-content;margin: 3px;padding: 2px">
        <input matInput  value="{{totalIllegalCitations|number}}" placeholder="ILLEGAL CITATION" readonly  matTooltip="All Enforcer Illegal Citations (PARKING WITHOUT A TICKET,PARKING WITHOUT A TICKET and Boot)"
               style="font-size: xx-large">
      </mat-form-field>
    </div>
    <div class="col-md-3">
      <mat-form-field style="width: fit-content;margin: 3px;padding: 2px">
        <input matInput value="{{totalCitations|number}}" placeholder="TOTAL CITATIONS " readonly style="font-size: xx-large"  matTooltip="All Enforcer Citations">
      </mat-form-field>
    </div>
  </div>
  <hr>
  <!-----------------------------------------scans details------------------------------------------>

  <div [hidden]="dataSource?.length<=0" class="mb-5">
    <div class="d-flex justify-content-center align-items-center">
      <h1 class="header-title"><strong>SCANS DETAIL </strong></h1>
    </div>
    <mat-table [dataSource]="dataSource" multiTemplateDataRows >
      <ng-container matColumnDef="index"><mat-header-cell *matHeaderCellDef>	<mat-icon>fingerprint</mat-icon></mat-header-cell>
        <mat-cell *matCellDef="let element ; let i = dataIndex">{{paginator.pageIndex * paginator.pageSize + i + 1}}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="enforcerName">
        <mat-header-cell [hidden]="enforcerName !== ''" *matHeaderCellDef> Enforcer Name</mat-header-cell>
        <mat-cell [hidden]="enforcerName !== ''" *matCellDef="let element"> {{getEnforcerName(element?.workForceId)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="plateNumber">
        <mat-header-cell *matHeaderCellDef> Plate Number</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.plateNumber}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef> Date</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.date}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="time">
        <mat-header-cell *matHeaderCellDef> TIME</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.time}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef> Location</mat-header-cell>
        <mat-cell *matCellDef="let element" ><button mat-icon-button (click)="onCitationLocation(element.latitude, element.longitude, element.plateNumber)" matTooltip="location on map" style="color: blue">
          <mat-icon>location_on</mat-icon>
        </button> </mat-cell>
      </ng-container>

      <ng-container matColumnDef="latitude">
        <mat-header-cell *matHeaderCellDef> Latitude</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.latitude}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="longitude">
        <mat-header-cell *matHeaderCellDef> Longitude</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.longitude}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="result">
        <mat-header-cell *matHeaderCellDef> Result</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element?.type}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20,50, 100]" showFirstLastButtons
                   [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                   (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <!-- List Grid -->

  <!----------------------------------------------------------------------------------->
  <mat-spinner *ngIf="loading"></mat-spinner>
</section>
