<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <h1><strong>View Citation</strong></h1>
    </div>


    <app-citation-penalties-view *ngIf="data.length" [data]="data[0]" [penActionType]="'control'"
        (penalties)="getPenalities($event)" (total)="getTotal($event)">
    </app-citation-penalties-view>

    <app-citation-penalties-total [total]="total">
    </app-citation-penalties-total>

    <div class="text-center pb-3">
        <button class="btn login__btn" style="background: #ACB5BE;" (click)="onCancel()"> Cancel </button>
        <button *ngIf="data.length" [disabled]="data[0].status !=='Settled'" class="btn login__btn" (click)="onApply()">
            View Invoice </button>
        <button *ngIf="data.length" class="btn login__btn" (click)="onPrintCitations()">Print Citations </button>
    </div>

</section>