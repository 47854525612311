import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventNonNumeric]'
})
export class PreventNonNumericDirective {

  constructor() { }

  
  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const charCode = event.charCode;
    if (charCode !== 0) {
      const char = String.fromCharCode(charCode);
      if (!/^\d$/.test(char)) {
        event.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const pasteData = event.clipboardData?.getData('text') || '';
    if (!/^\d+$/.test(pasteData)) {
      event.preventDefault();
    }
  }

}
