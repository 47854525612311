import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ShiftSchedulerService } from '../_services';

@Component({
  selector: 'app-assign-shift-members',
  templateUrl: './assign-shift-members.component.html',
  styleUrls: ['./assign-shift-members.component.scss'],
})
export class AssignShiftMembersComponent implements OnInit {
  @ViewChild("supervisorOption") supervisorOption: MatSelect
  @Input('shiftData') shiftData;
  @Input('freeWorkforcesList') freeWorkforcesList: any[] = [];
  @Input('supervisorsList') supervisorsList: any[];
  createdMsg: boolean = false;
  created: boolean = false;
  failed: boolean = false;
  activeSupervisor: any = [];
  formData: FormData;
  shiftWorkforceList: object[] = [];
  assignedShift: any;
  submitted: boolean = false;
  searchKey: '';
  filteredWorkforces: any[];
  shiftSuperVisorList: any[] = [];
  loading: boolean = false;
  suppervisorForm: FormGroup;
  public allSelected: boolean = false;
  public indeterminate: boolean = false;

  constructor(
    private shiftService: ShiftSchedulerService,
    private router: Router,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.freeWorkforcesList.forEach((element) => {
      // this.activeSupervisor.push({id:element.id,value:false});
      element.value = false;
    });
    this.filteredWorkforces = this.freeWorkforcesList;

    this.filteredSupervisorOptions.next(
      this.supervisorsList
    );

    this.supervisorNameFilterSearchCtrl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.supervisorsList.filter(item =>
            item.name.toLowerCase().indexOf(this.supervisorNameFilterSearchCtrl.value.toLowerCase()) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredSupervisorOptions.next(
          filtered
        );
      }, error => { });
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    console.log(filterValue);
    console.log('this.freeWorkforcesList befor filter  == ', this.freeWorkforcesList);
    if (filterValue.length > 0) {
      this.filteredWorkforces = this.freeWorkforcesList.filter(item =>
        item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 ||
        item.id == filterValue
      )
    }
    else {
      this.filteredWorkforces = this.freeWorkforcesList;
    }

    // const filterValue = (e.target as HTMLInputElement).value;
    // console.log('filterValue == ', filterValue);
    // console.log('this.freeWorkforcesList befor filter  == ', this.freeWorkforcesList);

    // if (filterValue.length > 0) {
    //   this.freeWorkforcesList = this.freeWorkforcesList.filter(item => item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)
    // }
    // else {
    //   this.freeWorkforcesList = this.freeWorkforcesList.filter(item => item);
    // }

    // console.log('this.freeWorkforcesList after filter  == ', this.freeWorkforcesList);


  }

  onSearchClear() {
    this.searchKey = '';
    this.filteredWorkforces = this.freeWorkforcesList;
  }

  goBack() {
    this.location.back();
  }

  choseWorker(e, i, worker) {
    i = worker.id;

    // console.log('this.freeWorkforcesList befor filter  == ', this.freeWorkforcesList);

    // console.log('choseWorker e.target.value = ', e.target.value, 'i = ', i);
    // console.log('choseWorker worker = ', worker.id);


    // console.log('choseWorker this.activeSupervisor  = ', this.activeSupervisor);
    console.log('choseWorker this.shiftWorkforceList  = ', this.shiftWorkforceList);

    const freeWorkforcesListIdx = this.freeWorkforcesList.findIndex(item => item.id == worker.id);
    console.log('freeWorkforcesListIdx', freeWorkforcesListIdx);
    this.freeWorkforcesList[freeWorkforcesListIdx].value = !this.freeWorkforcesList[freeWorkforcesListIdx].value;
    console.log('this.freeWorkforcesList[freeWorkforcesListIdx]', this.freeWorkforcesList[freeWorkforcesListIdx]);


    // this.activeSupervisor[i] = !e.target.checked;
    this.activeSupervisor[i] = !e.checked;
    if (this.activeSupervisor[i] == true) {
      const index = this.shiftWorkforceList.findIndex(
        (a: any) => a.employeeId == e.target.value
      );
      this.shiftWorkforceList.splice(index, 1);
    }
    this.assignShiftSupervisor();

    let totalSelected = this.filteredWorkforces.filter(i => i.value).length;
    if (totalSelected === 0) {
      this.allSelected = false;
      this.indeterminate = false;
    } else if (totalSelected > 0 && totalSelected < this.filteredWorkforces.length) {
      this.allSelected = false;
      this.indeterminate = true;
    } else if (totalSelected === this.filteredWorkforces.length) {
      this.allSelected = true;
      this.indeterminate = false;
    }

  }

  chosenSupervisor(e, employeeId, i) {
    let supervisorId = e.value;

    // if (this.activeSupervisor[i] === false) {
    //   this.shiftWorkforceList.push({
    //     supervisorId: supervisorId,
    //     employeeId: employeeId,
    //   });
    // }

    const freeWorkforcesListIdx = this.freeWorkforcesList.findIndex(item => item.id == employeeId);
    // console.log('freeWorkforcesListIdx', freeWorkforcesListIdx);
    this.freeWorkforcesList[freeWorkforcesListIdx].supervisorId = supervisorId;

    this.assignShiftSupervisor();

  }
  assignShiftSupervisor() {
    this.shiftSuperVisorList = [];
    this.filteredWorkforces.map(item => {
      // console.log('assignShift filteredWorkforces.item', item);
      if (item.value && (item.supervisorId && item.id)) {
        this.shiftSuperVisorList.push({
          supervisorId: item.supervisorId,
          employeeId: item.id,
        })
      }
      return item;
    });
  }

  assignShift() {
    // console.log("this.shiftData, ", this.shiftData);
    // console.log("assignShift this.shiftWorkforceList, ", this.shiftWorkforceList);

    this.assignShiftSupervisor();
    this.loading = true;

    // console.log('assignShift shiftSuperVisorList', this.shiftSuperVisorList);


    this.assignedShift = {
      ...this.shiftData,
      shiftSuperVisorList: this.shiftSuperVisorList,
    };

    // console.log("this.assignedShift, ", this.assignedShift);


    this.submitted = true;

    // stop here if form is invalid
    // if (this.shiftWorkforceList.length == 0) return;

    this.shiftService.createNewShift(this.assignedShift).subscribe((res) => {
      if (res.success === true) {
        this.location.back();
        this.loading = false;

      }
    });
  }
  get isDataNotValid() {
    // return false;
    // console.log("shiftSuperVisorList", this.shiftSuperVisorList);
    return (this.shiftSuperVisorList.length == 0) ? true : false;
  }

  // Supervisor Dropdown search list

  protected _onDestroy = new Subject<void>();

  filteredSupervisorOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  supervisorNameFilterSearchCtrl: FormControl = new FormControl('');

  public get filtersSuppervisor(): FormArray {
    return this.suppervisorForm.get('supervisorNameFilterSearchCtrl') as FormArray
  }

  selectAllSupervisor(event) {
    // console.log('selectAllSupervisor event', event.value);
    // console.log('selectAllSupervisor supervisorOption', this.supervisorOption);
    // console.log('selectAllSupervisor supervisorOption', this.supervisorOption.value);

    // console.log('selectAllSupervisor filteredWorkforces', this.filteredWorkforces);

    //freeWorkforcesList
    // console.log('selectAllSupervisor freeWorkforcesList', this.freeWorkforcesList);

    this.filteredWorkforces.map((workforce) => {
      workforce.supervisorId = event.value;
    });

    this.freeWorkforcesList.map((workforce) => {
      workforce.supervisorId = event.value;
    });

    this.assignShiftSupervisor();
    // this.supervisorNameFilterSearchCtrl.setValue(event.value);
    // console.log('filteredSupervisorOptions filteredWorkforces', this.filteredWorkforces);

    // this.filteredSupervisorOptions.filter(supervisor => supervisor.id == event.value);
  }

  isAllChecked() {
    return this.filteredWorkforces.every(worker => worker.value);
  }

  checkAll(event) {
    this.filteredWorkforces.forEach(x => x.value = event.target.checked)
  }

  toggleSelectAll(event) {
    this.allSelected = event.checked;
    console.log("toggleSelectAll this.filteredWorkforces", this.filteredWorkforces);
    console.log("toggleSelectAll event", event);

    this.filteredWorkforces.forEach(item => {
      item.value = event.checked;
    });

    this.assignShiftSupervisor();
  }

}


