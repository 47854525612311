import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from "@angular/router";
import { first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BlacklistService } from '../_services';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

@Component({
  selector: 'app-blacklist-form',
  templateUrl: './blacklist-form.component.html',
  styleUrls: ['./blacklist-form.component.scss']
})
export class BlacklistFormComponent implements OnInit {
  title: string = "Add a Vehicle to Blacklist";
  submitted = false;
  form: FormGroup;
  id;
  type;
  data: any = {};

  countries: String[] = [];
  colors: String[] = [];
  makes: String[] = [];
  plateType: String[] = ["PRIVATE", "TAXI", "TRUCK", "EMBASSY"];

  created: boolean = false;
  failed: boolean = false;
  createdMsg: string = '';
  operationId: number;

  penaltiesDataList: [] = [];
  get f() { return this.form.controls; }

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _service: BlacklistService,
    private _router: Router,

  ) {
    if (localStorage.getItem('currentUser')) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = currentUserJson.employeeDto.operationId;
      console.log(this.operationId);
    }

    this.id = this._activatedRoute.snapshot.params.id;
    this.type = this._activatedRoute.snapshot.params.type;

    if (this.id) {
      this._loadDate(this.id);
    }

    this.initForm();


  }

  ngOnInit(): void {
    this.getVehicleCountries();
    this.getVehicleColors();
    this.getVechicleMakes();
    this._loadPenaltiesData();
  }

  initForm(): void {
    this.form = this.fb.group({
      userName: ['', Validators.required],
      plateText: ['', Validators.required],
      plateNumber: ['', Validators.required],
      description: ['', Validators.required],
      country: ['', Validators.required],
      colorId: ['', Validators.required],
      model: ['', Validators.required],
      type: ['', Validators.required],
      makeId: ['', Validators.required],
    })
  }

  private _loadDate(id) {
    this._service.getDetails(id)
      .pipe(first())
      .subscribe(
        resp => {
          // this.data = resp.payload;
          // this.f['id'].setValue(this.data.id);
          // this.f['enName'].setValue(this.data.enName);
          // this.f['arName'].setValue(this.data.arName);
          // this.f['enDescription'].setValue(this.data.enDescription);
          // this.f['arDescription'].setValue(this.data.arDescription);

        },
        error => {
          console.log(" err ===== ", error);
        });
  }

  getVehicleCountries() {
    this._service.getVehicleCountries().subscribe(res => {
      this.countries = res.payload.content;
    })
  }

  getVehicleColors() {
    this._service.getVehicleColors().subscribe(res => {
      this.colors = res.payload.content;
    })
  }

  getVechicleMakes() {
    this._service.getVehicleMakes().subscribe(res => {
      this.makes = res.payload.content;
    })
  }

  onSubmit() {
    this.submitted = true;
    console.log('blacklist-form onSubmit form value === ', this.form.value);
    console.log('blacklist-form onSubmit this.form === ', this.form);
    console.log('blacklist-form onSubmit this.form.invalid === ', this.form.invalid);
    console.log('blacklist-form onSubmit this.form.get(country).value === ', this.form.get('country').value);

    if (this.form.invalid) return;

    let data: any = {
      userName: this.form.get('userName').value,
      countryName: this.form.get('country').value['enName'],
      colorId: this.form.get('colorId').value,
      makeId: this.form.get('makeId').value,
      model: this.form.get('model').value,
      description: this.form.get('description').value,
      type: this.form.get('type').value,
      // operationId: this.operationId,
      plateNumberEn: this.form.get('plateNumber').value + this.form.get('plateText').value.toUpperCase(),
      plateNumberAr: this.form.get('plateNumber').value + this.form.get('plateText').value.toUpperCase(),
      // source: "WORKFORCE",
      countryId: this.form.get('country').value['id'],
    };

    if (this.id === undefined) {

      this._service.create(data).subscribe(
        (res) => {
          console.log(res);
          if (res.success === true) {
            this.createdMsg = res.payload;
            this.failed = false;
            this.created = true;
            this._router.navigate([`/enforcement-black-list`], { queryParams: { success: res.payload } });

          }
        },
        (error) => {
          this.createdMsg = error.error.error.message;
          this.created = false;
          this.failed = true;
        }
      );
    }

  }

  goBack() {
    this.location.back();
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.form.controls[controlName].hasError(errorName) : false;
  }

  private _loadPenaltiesData() {
    this._service.getPenaltiesList()
      .pipe(first())
      .subscribe(
        resp => {
          this.penaltiesDataList = resp.payload;

        },
        error => {
          // console.log(" err ===== ", error);
        });
  }

  onSelectedPenalities($event) {
    console.log("black list onSelectedPenalities event", $event)
  }
}
