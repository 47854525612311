import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AdminService } from '../../_services/admin.service';

@Component({
  selector: 'app-workforce-report-dialog',
  templateUrl: './workforce-report-dialog.component.html',
  styleUrls: ['./workforce-report-dialog.component.scss'],
})
export class WorkforceReportDialogComponent implements OnInit {
  displayedColumns = ['violationDescription', 'totalNumber'];
  dataSource;
  details: any;
  loading = false;

  constructor(
    private _adminService: AdminService,
    public dialogRef: MatDialogRef<WorkforceReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: [Number]
  ) {}

  ngOnInit(): void {
    // console.log(this.data);
    this.getCitationCount();
  }

  getCitationCount() {
    this.loading = true;
    if (this.data['citationStatus']) {
      const params: any = {
        citationStatus: this.data['citationStatus'],
        employeeId: this.data['employeeId'],
        fromDate: this.data['fromDate'],
        toDate: this.data['toDate'],
      };

      this._adminService.citationsCount(params).subscribe(
        (res) => {
          // console.log(res);
          this.details = res.payload;
          this.dataSource = new MatTableDataSource<any>(this.details);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('err', error);
        }
      );
    } else {
      const params: any = {
        employeeId: this.data['employeeId'],
        fromDate: this.data['fromDate'],
        toDate: this.data['toDate'],
      };
      this._adminService.citationsSettlementCount(params).subscribe(
        (res) => {
          // console.log(res);
          this.details = res.payload;
          this.dataSource = new MatTableDataSource<any>(this.details);
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('err', error);
        }
      );
    }
  }
}
