import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services';
import { AcceptDetentionRequestModel, DetentionRequestModel, DetentionService } from '../detention.service';

@Component({
  selector: 'app-detention-requests',
  templateUrl: './detention-requests.component.html',
  styleUrls: ['./detention-requests.component.scss'],
})
export class DetentionRequestsComponent implements OnInit {
  dialogRef: any;
  @ViewChild('acceptDetentionOrderDialog')
  acceptDetentionOrderDialog: TemplateRef<any>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<DetentionRequestModel>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  expandedElement: any;
  displayedColumns: string[] = [
    'vehicleInfo',
    'make',
    'color',
    'evidence',
    'date',
    'actions',
  ];
  dataList: any[];
  sortingBy: string = 'detention_order_id';
  sortingType: any = 'asc';
  operationId;
  failedMsg: string = '';
  successMsg: string = '';
  detentionOrder: any;
  acceptOrderForm: FormGroup;
  currentUserJson: any;
  statusList: any[] = [
    { name: 'PENDING' },
    { name: 'ACCEPTED' },
    { name: 'READY_TO_RELEASE' },
  ];
  @ViewChild('imgCarousel') imgCarousel: TemplateRef<any>;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _service: DetentionService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog
  ) {
    if (localStorage.getItem('currentUser')) {
      this.currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = this.currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this.acceptOrderForm = new FormGroup({
      slotNumber: new FormControl(null, [Validators.maxLength(50)]),
      notes: new FormControl(null, [Validators.maxLength(50)]),
      evidences: new FormControl(''),
    });

    this._loadList(this.operationId, 0, this.pageSize);
    this.successMsg = this._activatedRoute.snapshot.queryParams['success'];
    setTimeout(() => {
      this.successMsg = '';
      this._router.navigate(['.'], {
        relativeTo: this._activatedRoute,
        queryParams: {},
      });
    }, 5000);
  }
  fileName = '';
  imageUrl: any;

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      let reader = new FileReader();

      if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append('thumbnail', file);

        reader.onloadend = () => {
          this.imageUrl = reader.result;
        };
        reader.readAsDataURL(file);
        this.acceptOrderForm.controls?.evidences?.setValue(file);
      }
    }
  }

  private _loadList(
    operationId: number,
    page: number,
    limit: number,
    query: string = '',
    sortingType: any = this.sortingType,
    sortingBy: string = this.sortingBy
  ) {
    this.loading = true;
    if (sortingType === 'asc') sortingType = 1;
    else sortingType = 0;

    this._service
      .getDetentionRequests(
        operationId,
        page,
        limit,
        '',
        sortingType,
        sortingBy,
        ['PENDING']
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          console.log(resp);
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          console.log(this.dataList);

          this.dataSource = new MatTableDataSource<DetentionRequestModel>(
            this.dataList
          );
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('err _loadList ===> ', error);
        }
      );
  }
  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }
  openAcceptDetentionOrderDialog(row: any) {
    this.detentionOrder = row;
    this.dialogRef = this._dialog.open(this.acceptDetentionOrderDialog, {
      width: '500px',
      disableClose: true,
    });
    this.dialogRef.afterClosed().subscribe(async (resp) => {
      if (resp) {
        this._loadList(this.operationId, 0, this.pageSize);
      }
    });
  }

  acceptDetentionOrder() {
    console.log(this.acceptOrderForm.value);
    let params: AcceptDetentionRequestModel = {
      detentionOrderStatus: 'ACCEPTED',
      workforceComment: this.acceptOrderForm.value.notes,
      slotNumber: this.acceptOrderForm.value.slotNumber,
      detentionOrderId: this.detentionOrder.id,
      evidences: this.acceptOrderForm.value.evidences,
    };
    console.log(params);

    this._service
      .acceptDetentionRequest(params)
      .pipe(first())
      .subscribe(
        (resp) => {
          console.log(resp);
          this.dialogRef.close(resp);
        },
        (error) => {
          console.log(' err ===== ', error);
        }
      );
  }

  applyFilter(e: any) {
    const filterValue = e.value;
    console.log(filterValue);
    this._service
      .getDetentionRequests(
        this.operationId,
        1,
        this.pageSize,
        '',
        this.sortingType,
        this.sortingBy,
        filterValue
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(
            this.dataList
          );
        },
        (error) => {
          // console.log(" err ===== ", error);
        }
      );
  }
  applySearch(e: any) {
    const filterValue = (e.target as HTMLInputElement).value;
    console.log(filterValue);
    this._service
      .getDetentionRequests(
        this.operationId,
        0,
        this.pageSize,
        filterValue,
        this.sortingType,
        this.sortingBy,
        ['PENDING']
      )
      .pipe(first())
      .subscribe(
        (resp) => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<DetentionRequestModel>(
            this.dataList
          );
        },
        (error) => {
          // console.log(" err ===== ", error);
        }
      );
  }

  onSearchClear() {
    this.searchKey = '';
    this._loadList(this.operationId, this.currentPage + 1, this.pageSize);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.operationId, this.currentPage, this.pageSize);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortingType = sortState.direction;
    this.sortingBy = sortState.active;
    this._loadList(this.operationId, this.currentPage + 1, this.pageSize);
  }

  onCancelSearch(): void {
    this.onSearchClear();
  }

  currentImages: string[];
  openImageCarousel(images: string[]) {
    this.currentImages = images;
    this._dialog.open(this.imgCarousel, {
      width: '50%',
      height: '95%',
    });
  }
}

