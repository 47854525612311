import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';
import { DetentionReportPayloadModel } from './models/detentionPayload-model';
import { sc2BlacklistReportPayloadModel } from './models/sc2Blacklist-model';
import { TowReportPayloadModel } from './models/towRequest-model';

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  constructor(private http: HttpClient) {}
  _token = `Bearer ${localStorage.getItem('token')}`;

  getTowReport(params: TowReportPayloadModel): Observable<any> {
    console.log(params);
    return this.http.post(
      `${env.baseURL}operation/grounding-requests/searchGroundingType`,
      params,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getWorkforcesByOperationId(
    groundingType: string,
    operationId: number
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/employee/getEmployeesByIdAndOperationIdAndGroundingType?groupName=${groundingType}&operationId=${operationId}`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getDetentionReport(
    params: DetentionReportPayloadModel,
    page: number,
    size: number
  ): Observable<any> {
    console.log(params);
    return this.http.post(
      `${env.baseURL}operation/detention-requests/search?page=${page}&size=${size}`,
      params,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getDetentionByOperationId(operationId: number): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/detention-center?operationId=${operationId}`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getSc2BlacklistReport(
    params: sc2BlacklistReportPayloadModel
  ): Observable<any> {
    console.log(params);
    return this.http.post(
      `${env.baseURL}operation/blacklist/advanced-search`,
      params,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getNotificationReport(params: any): Observable<any> {
    console.log(params);
    return this.http.get(`${env.baseURL}regulatory/report/notification`, {
      headers: { Authorization: this._token },
      params: params,
    });
  }

  getCarStatusReport(params: any): Observable<any> {
    console.log(params);
    return this.http.get(`${env.baseURL}regulatory/report/check-car-status`, {
      headers: { Authorization: this._token },
      params: params,
    });
  }

  exportTowReport(report: any): Observable<any> {
    return this.http.post(
      `${env.baseURL}operation/grounding-requests/search-grounding-type/reports/export-to-excel`,
      report,
      {
        headers: { Authorization: this._token },
        responseType: 'blob',
      }
    );
  }

  getMomrahReport(params: any): Observable<any> {
    return this.http.get(`${env.baseURL}enforcements/citations/momrah/search`, {
      headers: { Authorization: this._token },
      params: params,
    });
  }

  getRegistrationTypes(citationId: number): Observable<any> {
    return this.http.get(
      `${env.baseURL}enforcements/citations/${citationId}/availableRegistrationTypes`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  getCitationDetails(citationId: number): Observable<any> {
    return this.http.get(
      `${env.baseURL}enforcements/citations/citation-details/${citationId}`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  resendUnavailable(citationId: number): Observable<any> {
    return this.http.get(
      `${env.baseURL}enforcements/citations/${citationId}/momrah/unavailable`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  resendAllUnavailable(): Observable<any> {
    return this.http.get(
      `${env.baseURL}enforcements/citations/momrah/unavailable`,
      {
        headers: { Authorization: this._token },
      }
    );
  }

  updateCitation(citationId: number, data: any): Observable<any> {
    return this.http.put(
      `${env.baseURL}enforcements/citations/${citationId}/plate`,
      data,
      {
        headers: { Authorization: this._token },
      }
    );
  }
}
