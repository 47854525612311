<div *ngIf="data.length">
    <h2><strong>
            {{data[0].plateNumberEn}}
        </strong>
    </h2>
</div>

<mat-accordion *ngIf="data.length">
    <mat-expansion-panel *ngFor="let item of data; let idx = index" class="accordion-panel">
        <mat-expansion-panel-header>
            <div class="row header">
                <div class="col-6">Citation Number</div>
                <div class="col-6">Violation Name</div>

                <div class="col-6">#{{item.id}}</div>
                <div class="col-6">{{item.violationName}}</div>
            </div>
        </mat-expansion-panel-header>

        <app-citation-penalties [data]="item" [vat]="vat" [penActionType]="'control'" [isHeader]="false"
            [isComment]="true" (penalties)="getPenalities($event,idx)" (total)="getTotal($event,idx)"
            [voidReasonsList]="voidReasonsList">
        </app-citation-penalties>

    </mat-expansion-panel>

</mat-accordion>

<div>
    <p class="payment-header">Payment Method</p>
    <form [formGroup]="paymentMethodForm" class="col-3">
      <mat-radio-group formControlName="paymentMethod" class="radio-group">
        <mat-radio-button class="radio-btn" value="CASH">Cash</mat-radio-button>
        <mat-radio-button class="radio-btn" value="POS">POS</mat-radio-button>
      </mat-radio-group>
    </form>
  </div>

<app-citation-penalties-total [total]="total">
</app-citation-penalties-total>

<div class="text-center">
    <button class="btn" style="background: #ACB5BE;" (click)="onCancel()"> {{ isGeneratingMsg ?  'Back to Citation' : 'Cancel'}} </button>
    <button [disabled]="loading || data[0].status == 'Settled'" [ngClass]="isGeneratingMsg ? 'view__btn': 'login__btn'"
        class="btn login__btn" (click)="isGeneratingMsg ? onViewInvoice() : onApply()"> {{isGeneratingMsg ? "View Invoice" :
        'Apply'}}</button>
</div>