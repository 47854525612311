import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-generating-invoice-dialog',
  templateUrl: './generating-invoice-dialog.component.html',
  styleUrls: ['./generating-invoice-dialog.component.scss'],
})
export class GeneratingInvoiceDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GeneratingInvoiceDialogComponent>
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
