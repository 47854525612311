import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule, } from '@angular/core';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
// import { NgxBarcodeModule } from 'ngx-barcode';


import { CitationListComponent } from './citation-list/citation-list.component';
import { CitationSettleComponent } from './citation-settle/citation-settle.component';
import { CitationSettledViewComponent } from './citation-settled-view/citation-settled-view.component';
import { CitationVoidComponent } from './citation-void/citation-void.component';


import { CitationPenaltiesViewComponent } from './_penalties/citation-penalties-view/citation-penalties-view.component';
import { CitationPenaltiesVoidComponent } from './_penalties/citation-penalties-void/citation-penalties-void.component';
import { CitationPenaltiesComponent } from './_penalties/citation-penalties/citation-penalties.component';
import { CitationControlComponent } from './citation-control/citation-control.component';
import { ShowImagesDialog } from './citation-list/show-images-dialog/show-images-dialog';

import { CitationPenaltiesAttachsComponent } from './_penalties/citation-penalties-attachs/citation-penalties-attachs.component';
import { CitationPenaltiesHeaderComponent } from './_penalties/citation-penalties-header/citation-penalties-header.component';
import { CitationPenaltiesTotalComponent } from './_penalties/citation-penalties-total/citation-penalties-total.component';

import { CitationInvoiceDialog } from './_shared/citation-invoice/citation-invoice.dialog';
import { CitationControlMultiComponent } from './citation-control-multi/citation-control-multi.component';
import { CitationReportListComponent } from './citation-report-list/citation-report-list.component';

import { SharedModule } from '../_shared/shared.module';
import { CitationPrintComponent } from './_shared/citation-print/citation-print.component';
import { CitationSettledCitationsComponent } from './citation-settled-citations/citation-settled-citations.component';
import { CitationVoidListComponent } from './citation-void-list/citation-void-list.component';
import { CitationVoidVoidComponent } from './citation-void-void/citation-void-void.component';
import { GeneratingInvoiceDialogComponent } from './_shared/generating-invoice-dialog/generating-invoice-dialog.component';

// import { FilterListComponent } from '../_shared/filter-list/filter-list.component';
@NgModule({
  declarations: [
    CitationListComponent,
    CitationSettleComponent,
    CitationVoidComponent,
    CitationSettledViewComponent,
    ShowImagesDialog,
    CitationControlComponent,
    CitationPenaltiesComponent,
    CitationPenaltiesVoidComponent,
    CitationPenaltiesViewComponent,
    CitationPenaltiesTotalComponent,
    CitationPenaltiesAttachsComponent,
    CitationPenaltiesHeaderComponent,
    CitationInvoiceDialog,
    CitationControlMultiComponent,
    CitationReportListComponent,
    CitationVoidListComponent,
    CitationVoidVoidComponent,
    CitationSettledCitationsComponent,
    CitationPrintComponent,
    GeneratingInvoiceDialogComponent
    // FilterListComponent
  ],
  imports: [
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxQRCodeModule,
    NgxQRCodeModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class CitationModule { }
