import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ShiftHistoryList } from '../_models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { first } from 'rxjs/operators';
import { ShiftHistoryListService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

import { AuthorizationService } from 'src/app/_services';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-shift-history-list',
  templateUrl: './shift-history-list.component.html',
  styleUrls: ['./shift-history-list.component.scss']
})
export class ShiftHistoryListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  // testing new
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<ShiftHistoryList>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = ['index', 'id', 'name', 'role', 'members', 'date', 'time'];
  dataList: [] = [];

  sortBy: string = 'id';
  sortDirection: string = 'ASC';

  selectedDateRange: any[] = [];
  roles = new FormControl();

  rolesList: string[] = [];
  selectedRoleName: string = "";

  filterFromDate;
  filterToDate;
  constructor(
    private _router: Router,
    private _shiftHistoryListService: ShiftHistoryListService,
    private _dialog: MatDialog,
    public authorizationService: AuthorizationService
  ) {
  }

  ngOnInit(): void {
    this._loadRolesList(0, 1000);
  }

  private _loadList(page: number, limit: number, fromDate: string, toDate: string, roleName: string) {
    this.loading = true;
    this._shiftHistoryListService.getList(page, limit, fromDate, toDate, roleName)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<ShiftHistoryList>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  private _loadRolesList(page: number, limit: number, query: string = '') {
    this.loading = true;
    this._shiftHistoryListService.getRolesList(page, limit, query)
      .pipe(first())
      .subscribe(
        resp => {
          const respContent = resp.payload.content;
          respContent.map((item) => this.rolesList.push(item.roleName));
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }
  onApplyFilter() {
    this._loadList(0, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedRoleName);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedRoleName);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    this.sortBy = sortState.active;
    this.sortDirection = sortState.direction;
    this._loadList(this.currentPage, this.pageSize, this.filterFromDate, this.filterToDate, this.selectedRoleName);
  }

  onSelectedDateRange() {
    if (this.selectedDateRange[0] && this.selectedDateRange[1]) {
      this.filterFromDate = this._dateFormat(this.selectedDateRange[0]);
      this.filterToDate = this._dateFormat(this.selectedDateRange[1]);
    }
  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }


  get validSearch() {
    return (this.selectedRoleName && this.selectedDateRange[0] && this.selectedDateRange[1]) ? true : false;
  }
}
