import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../_services/authorization.service';
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGroupNameGuard implements CanActivate {
  flag: boolean = false;

  constructor(
    private authorizationService: AuthorizationService,
    private router: Router
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):
    Observable<boolean | UrlTree>
    | Promise<boolean
      | UrlTree>
    | boolean
    | UrlTree {
    // this.checkUserGroupName(next);
    console.log(this.checkUserGroupName(next))
    return this.checkUserGroupName(next);
  }

  checkUserGroupName(route: ActivatedRouteSnapshot) {
    let groupName = route.data.groupName;
    console.log(route);
    // debugger;
    // for (let action of route.data.actions) {
    //   console.log(action);
    //   debugger;
    //   const checkGroupName = this.authorizationService.check(groupName, action);
    //   debugger;
    //   if(checkGroupName == true) {
    //     this.flag = true;
    //     break;
    //   } else {
    //     this.flag = false;
    //     this.router.navigate(['home']);
    //   }
    //   return this.flag;
    // }
        let check = route.data.actions.find(action=>{
          console.log(action);
          // debugger;
           const checkGroupName = this.authorizationService.check(groupName, action);
           console.log(checkGroupName)
          //  debugger;
           if (checkGroupName == true) {
             this.flag =true;
             return true;
            }
           else {
             this.flag = false
            // this.router.navigate(['home']);
            return false;
          }
    // return this.flag;
        })
    console.log("check", check);

    // console.log("checkUserGroupName groupName",groupName);
    // let groupNameValue = this.authorizationService.check(groupName, actions);
    // console.log("checkUserGroupName groupNameValue =========",groupNameValue);

    if(this.flag == true) {
      return true;
    } else {
      this.router.navigate(['home']);
      return false;
    }


  }

}
