import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EditVehicleDialogComponent } from './edit-vehicle-dialog/edit-vehicle-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ReportsService } from '../reports.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { CitationService } from 'src/app/citation/_services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-verify-vehicles-report',
  templateUrl: './verify-vehicles-report.component.html',
  styleUrls: ['./verify-vehicles-report.component.scss'],
})
export class VerifyVehiclesReportComponent implements OnInit {
  sending: boolean = false;
  searchForm: FormGroup;
  statuses = [
    { name: 'All', value: 'all' },
    { name: 'Success', value: 'SUCCESS' },
    { name: 'Failed', value: 'FAILED' },
  ];
  failureReasons = [
    { name: 'All', value: 'all' },
    { name: 'Unavailable', value: 'UNAVAILABLE' },
    { name: 'Other', value: 'OTHER' },
  ];
  loading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  dataList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private datePipe: DatePipe,
    private reportsService: ReportsService,
    private citationService: CitationService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.initSearchForm();
    this.getReportData(this.currentPage, this.pageSize, this.searchForm.value);
    console.log(this.dataList);
    console.log(this.dataSource);
  }

  initSearchForm() {
    this.searchForm = this.fb.group({
      fromDate: [null],
      toDate: [null],
      status: [null],
      failureReasons: [null],
    });
  }

  getReportData(page: number, limit: number, formValues: any) {
    this.loading = true;
    let fromDate = formValues?.fromDate ? new Date(formValues.fromDate) : null;
    let toDate = formValues?.toDate ? new Date(formValues.toDate) : null;
    let toDay = new Date();

    if (toDate) {
      toDate.setHours(23, 59, 59, 999); // Set to the end of the day
    }

    const params = {
      status: formValues?.status === 'all' ? null : formValues?.status,
      failedReason:
        formValues?.failureReasons === 'all'
          ? null
          : formValues?.failureReasons,
      fromDate: fromDate ? this.formatDate(fromDate) : null,
      toDate: toDate ? this.formatDate(toDate) : this.formatDate(toDay),
      page: page,
      limit: limit,
    };

    // Filter out null or empty parameters
    const filteredParams = Object.keys(params)
      .filter(
        (key) =>
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ''
      )
      .reduce((obj, key) => {
        obj[key] = params[key];
        return obj;
      }, {});

    this.reportsService.getMomrahReport(filteredParams).subscribe({
      next: (res) => {
        this.loading = false;
        this.dataList = res?.payload?.content || [];
        this.dataSource = new MatTableDataSource<any>(this.dataList);
        this.totalRows = res?.payload?.totalElements || 0;
      },
      error: (err) => {
        this.loading = false;
        this.toastr.error(err?.error?.error?.message || 'An error occurred');
      },
    });
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  onSelectionChange(event: any, controlName: string) {
    const selectedValues = event.value;
    if (selectedValues.includes('all')) {
      this.searchForm.get(controlName).setValue(['all']);
    }
  }

  onSubmitSearch() {
    const formValues = this.searchForm?.value;
    this.getReportData(this.currentPage, this.pageSize, formValues);
  }

  openDialog(data: any): void {
    const dialogRef = this._dialog.open(EditVehicleDialogComponent, {
      width: '600px',
      data: data,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.registrationType) {
        this.updateCitation(data.citationNumber, result);
      } else if (result.isVoid) {
        console.log('void result:', result);
        this.voidCitation(data.citationNumber);
      }
    });
  }

  onResend(citationNumber: number): void {
    this.sending = true;
    this.reportsService.resendUnavailable(citationNumber).subscribe({
      next: (res) => {
        this.toastr.success(res?.payload);
        this.getReportData(
          this.currentPage,
          this.pageSize,
          this.searchForm.value
        );
        this.sending = false;
      },
      error: (err) => {
        this.toastr.error(err?.payload || 'An error occurred');
        this.sending = false;
      },
    });
  }

  onResendAllUnavailable() {
    this.sending = true;
    this.reportsService.resendAllUnavailable().subscribe({
      next: (res) => {
        this.toastr.success(res?.payload);
        this.getReportData(
          this.currentPage,
          this.pageSize,
          this.searchForm.value
        );
        this.sending = false;
      },
      error: (err) => {
        this.toastr.error(err?.payload || 'An error occurred');
        this.sending = false;
      },
    });
  }

  updateCitation(citationNumber: number, result: any) {
    this.reportsService.updateCitation(citationNumber, result).subscribe({
      next: (res) => {
        this.toastr.success(res?.payload);
        this.getReportData(
          this.currentPage,
          this.pageSize,
          this.searchForm.value
        );
      },
      error: (err) => {
        this.toastr.error(err?.payload || 'An error occurred');
      },
    });
  }

  voidCitation(citationNumber: number) {
    this.router.navigate(['/citation-void', citationNumber], {
      queryParams: { source: 'momah-report' },
    });
  }

  onClear() {
    this.searchForm?.reset();
    this.getReportData(this.currentPage, this.pageSize, this.searchForm.value);
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event?.pageSize || this.pageSize;
    this.currentPage = event?.pageIndex || 0;
    const formValues = this.searchForm?.value;
    this.getReportData(this.currentPage, this.pageSize, formValues);
  }
}
