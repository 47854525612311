import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginServiceService } from '../_services/login-service.service';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  hide: boolean = true;
  loginForm: FormGroup;
  errorActive: boolean = false;
  errMsg: string = '';
  message: any = null;
  constructor(
    private router: Router,
    private authService: LoginServiceService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
  }

  changeLogStatus(status: string) {
    const employeeDto = JSON.parse(localStorage.getItem('currentUser')).employeeDto;
    let params = {
      "userId": employeeDto.id,
      "userStatus": status,
      "deviceType": "web-enforcement"
    }
    this.authService.changeLogStatus(params).subscribe(
      (res) => {
      });
  }

  login() {
    if (this.loginForm.valid)
      this.authService.login(this.loginForm.value).subscribe(
        (res) => {
          this.authService.checkToken();
          this.changeLogStatus("LOGGED_IN")
          this.router.navigate(['/dashboard']);

        },
        (error) => {
          error.error.code == 401
            ? (this.errMsg = 'Incorrect Username or Password')
            : (this.errMsg = error.error.error.message);
          this.errorActive = true;
        }
      );
  }

  resetPassword() {
    this.router.navigateByUrl('/reset-password');
  }
}
