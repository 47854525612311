import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhitelistService {

  constructor(private _http: HttpClient) { }

  _token = `Bearer ${localStorage.getItem('token')}`;
  private requestHeader = {
    'content-Type': '',
    Authorization: 'bearer-',
  };

  getList(page: number, limit: number, query: string = '', operationId: number) {
    let api = `${environment.baseURL}enforcements/whiteListedVehicles?page=${page}&limit=${limit}&operationId=${operationId}`;
    if (query)
      api += `&searchValue=${query}`;
    return this._http.get<any>(api);
  }

  getWhitelistDetails() {
    let api = `${environment.baseURL}enforcements/whiteListedVehicles`;
    return this._http.get<any>(api);
  }

  getVehicleCountries() {
    let api = `${environment.baseURL}vehicles/countries`;
    return this._http.get<any>(api);
  }

  getVehicleMakes() {
    let api = `${environment.baseURL}vehicles/makes`;
    return this._http.get<any>(api);
  }

  getVehicleColors() {
    let api = `${environment.baseURL}vehicles/colors`;
    return this._http.get<any>(api);
  }

  createWhitelistVehicle(params): Observable<any> {
    let api = `${environment.baseURL}enforcements/whiteListedVehicles`
    return this._http.post<any>(api,params);
  }

  deleteWhitelistVehicle(params): Observable<any> {
    let api = `${environment.baseURL}enforcements/whiteListedVehicles/${params.id}`
    return this._http.delete<any>(api,params);
  }
}
