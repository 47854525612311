<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <h1><strong>Void Citation</strong></h1>
    </div>

    <app-citation-penalties-header *ngIf="data.length" [data]="data[0]">
    </app-citation-penalties-header>

    <app-citation-penalties-void *ngIf="data.length" [data]="data[0]" [penActionType]="'voided'"
        (penalties)="getPenalities($event)" (total)="getTotal($event)">
    </app-citation-penalties-void>

    <app-citation-penalties-attachs (getFiles)="getFiles($event)">
    </app-citation-penalties-attachs>
    
    <app-citation-penalties-total [total]="total">
    </app-citation-penalties-total>

    <div class="text-center">
        <button class="btn" style="background: #ACB5BE;" (click)="onCancel()"> Cancel </button>
        <button class="btn login__btn" (click)="onApply()" [disabled]="!getCustomValidation"> Void selected penalties
        </button>
    </div>
</section>