<div class="row header" *ngIf="isHeader">

    <div class="col-3 head">Citation Number</div>
    <div class="col-3 head">Car Plate</div>
    <div class="col-3 head">Violation Name</div>
    <div class="col-3 head">Citation Date</div>

    <div class="col-3">#{{data.id}}</div>
    <div class="col-3">{{data.plateNumberEn}}</div>
    <div class="col-3">{{data.violationName}}</div>
    <div class="col-3">{{formatDate(data.createdDate)}}</div>

    <div class="col-3 head">Status</div>
    <div class="col-3 head">Violation Code</div>
    <div class="col-3 head">Fees Amount</div>
    <div class="col-3 head">Operation Area</div>

    <div class="col-3">{{data.status}}</div>
    <div class="col-3">{{data.violationId}}</div>
    <div class="col-3">{{data.amount}}</div>
    <div class="col-3">{{data.operationName}}</div>
    <!---->
    <div class="col-3 head">Enforcer name</div>
    <div class="col-3 head">Country</div>
    <div class="col-3 head">Settled Date</div>
    <div class="col-3 head">Settled/Voided By</div>

    <div class="col-3">{{data.enforcerName}}</div>
    <div class="col-3">{{data.country?.enName}}</div>
    <div class="col-3">{{formatDate(data.modifiedDate)}}</div>
    <div class="col-3">{{data.actionBy}}</div>

</div>

<br />
<div class="row">
    <div class="col-3">Action Name</div>
    <div class="col-3">Status</div>
    <div class="col-3">Value</div>
    <div [hidden]="penActionType=='voided'" class="col-3">Count</div>

</div>

<form [formGroup]="saveForm">
    <div *ngFor="let penalty of data.citationPenalties; let i = index">
        <div formArrayName="penaltiesRequestList">
            <div [formGroupName]="i" class="row">
                <div class="col-3">{{penalty.operationViolationPenalty.penalty.enName}}</div>
                <div class="col-3">
                    <mat-radio-group formControlName="penActionType">
                        <mat-radio-button [hidden]="penActionType=='voided'" class="mat-radio-label-custom"
                            value="Settled">Settle</mat-radio-button>
                        <mat-radio-button [hidden]="penActionType=='settled'" value="Voided">Void</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="col-3">{{penalty.operationViolationPenalty.penalty.fees}} SAR</div>
                <div class="col-3"
                    *ngIf="penalty.operationViolationPenalty.penalty.type =='COUNTED' && getPenActionControlValue(i)=='Settled'">
                    <mat-form-field appearance="fill">
                        <input matInput type="number" placeholder="" formControlName="count" min="1">
                        <mat-error *ngIf="count.invalid">Min size: 1</mat-error>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field *ngIf="isComment && getPenActionControlValue(i)=='Voided'" appearance="fill">
                        <mat-label>Void Reason</mat-label>
                        <mat-select formControlName="reasonId" aria-required="true">
                            <mat-option *ngFor="let item of voidReasonsList" [value]="item.id">{{item.reasonEN}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-error *ngIf="myError('reasonId', 'required')">
                        Void Reason is Required
                    </mat-error>

                    <mat-form-field *ngIf="isComment && getPenActionControlValue(i)=='Voided'" style="width: 100%;"
                        appearance="fill">
                        <textarea matInput formControlName="comment" placeholder="enter void reason  here"
                            style="width: 100%;;height: 150px;"></textarea>

                    </mat-form-field>
                    <mat-error *ngIf="myError('comment', 'required')">Comment is required</mat-error>

                </div>
                <mat-divider></mat-divider>
            </div>
        </div>

    </div>
</form>
<mat-spinner *ngIf="loading"></mat-spinner>