import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LoginServiceService } from '../login/_services/login-service.service'

import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../_shared/dialog-confirmation/dialog-confirmation';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: LoginServiceService, private _dialog: MatDialog,) {
        console.log("ErrorInterceptor")
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401)
                return throwError(err);

            let isExclude: boolean = false
            const exclusionList = [
                'operation/employee/imagesbase64',
                //citation list
                'operation/operation/all',
                'vehicles/countries',
                'operation/employee/getEmployeesByNameOrUserNameOrId',
                'enforcements/violation/search/autocomplete',
                //citation list

                //shift report
                'operation/roles',
                //shift report
            ];
            exclusionList.map((url) => {
                if (err.url.includes(url)) isExclude = true;
            });

            if (isExclude)
                return throwError(err);

            let backendError = {};
            // if (err.status === 401) {
            //     // auto logout if 401 response returned from api
            //     // this.authenticationService.logout();
            //     // location.reload(true);
            // }


            backendError = (err.error) ? err.error.error : backendError = {
                httpStatus: 'Attention',
                message: 'Something wrong'
            }

            const dialogRef = this._dialog.open(DialogConfirmation, {
                panelClass: 'custom-mat-show-image-dialog-container',
                width: '468px',
                data: {
                    title: { title: backendError['httpStatus'], color: "#D93636" },
                    body: backendError['message'],
                    btnConfirmation: { hide: true, title: "Confirm", color: "#D93636" },
                    btnClose: { title: "close" },
                    comment: false
                }
            });

            dialogRef.afterClosed().subscribe(result => {
            });

            //const error = err.error.errors || err.statusText;
            return throwError(err);
        }))
    }
}