<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-3">
        <div class="col-9">
            <h1><strong>Shifts Scheduler</strong></h1>
        </div>
        <div class="col-3 text-end">
            <button class="btn login__btn" *ngIf="authorizationService.check('Shift','Add')" (click)="createShift()">Create New Shift</button>
        </div>
    </div>

    <mat-form-field class="search-form-field essential-uesrs__search-bar">
        <input matInput placeholder="Search by shift label, and/or role name" autocomplete="off" [(ngModel)]="searchKey"
            (keyup)="applyFilter()" />

        <button mat-button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>

    <div class="container-fluid filter-by-date">
        <!-- filter by date container -->
        <div class="row">
            <div class="col">
                <span>{{paginationTitle}}</span>&nbsp; &nbsp;
                <button mat-icon-button (click)="onPrevPage()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8 12C8 11.4347 8.25123 10.8693 8.7444 10.4413L14.8113 5.17566C15.0811 4.94145 15.5278 4.94145 15.7976 5.17566C16.0675 5.40986 16.0675 5.79752 15.7976 6.03173L9.73073 11.2974C9.28409 11.685 9.28409 12.315 9.73073 12.7026L15.7976 17.9683C16.0675 18.2025 16.0675 18.5901 15.7976 18.8243C15.5278 19.0586 15.0811 19.0586 14.8113 18.8243L8.7444 13.5587C8.25123 13.1307 8 12.5653 8 12Z"
                            fill="#6B757E" />
                    </svg>
                </button>

                <button mat-icon-button (click)="onNextPage()">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.0001 12C16.0001 12.5653 15.7488 13.1307 15.2557 13.5587L9.18878 18.8243C8.91893 19.0586 8.47229 19.0586 8.20245 18.8243C7.9326 18.5901 7.9326 18.2025 8.20245 17.9683L14.2693 12.7026C14.716 12.315 14.716 11.685 14.2693 11.2974L8.20245 6.03173C7.9326 5.79752 7.9326 5.40986 8.20245 5.17566C8.47229 4.94145 8.91893 4.94145 9.18878 5.17566L15.2557 10.4413C15.7488 10.8693 16.0001 11.4347 16.0001 12Z"
                            fill="#6B757E" />
                    </svg>
                </button>
            </div>
            <div class="col">
                <mat-form-field>
                    <input matInput [(ngModel)]="selectedDateRange" [owlDateTime]="dt1" [selectMode]="'range'"
                        [owlDateTimeTrigger]="dt1" placeholder="Filter by Date">
                    <!-- <span [owlDateTimeTrigger]="dt1"><i class="fas fa-calendar"></i></span> -->
                    <owl-date-time [pickerType]="'calendar'" [dateTimeChange]="onSelectedDateRange()" #dt1>
                    </owl-date-time>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field>
                    <mat-label>Week</mat-label>
                    <mat-select [value]="">
                        <mat-option>
                            Week
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <br />

    <section class="row" *ngFor="let role of roles">
        <!-- <div *ngFor="let role of roles"> -->
        <div *ngIf="role.roles.length" class="role-shifts-content">
            <div>
                <span class="role-title">
                    {{role.title}}
                </span>
            </div>
            <div class="row shift-details">
                <div class="col-4 shift-details-card" *ngFor="let shift of role.roles">
                    <app-shift-details-card [title]="shift.day" [name]="shift.name"
                        [membersCount]="shift.workforceNumber" [day]="shift.day" [shiftStartTime]="shift.shiftStartTime"
                        [shiftEndTime]="shift.shiftEndTime" [roleName]="role.title">
                    </app-shift-details-card>
                </div>
            </div>
        </div>


        <!-- </div> -->
        <!-- <div class="col-4" *ngFor="let shift of shifts">
            <app-shift-details-card [title]="shift.title" [name]="shift.name" [membersCount]="shift.membersCount"
                [time]="shift.time"></app-shift-details-card>
        </div> -->
    </section>
</section>
<mat-spinner *ngIf="loading"></mat-spinner>