<div id="map"></div>
<div class=" footer">

  <div class="row col-md-12">
    <div class="col-md-4">  <textarea cols="50" rows="1" [(ngModel)]="mapLocationURL" [cdkCopyToClipboard]="mapLocationURL"  matTooltip="copy location link"
                                      style="color: blue;border: 1px solid blue;max-width: fit-content"></textarea></div>

    <div class="col-md-3">    <a href="{{mapLocationURL}}"  target="_blank" style="color: blue">OpenStreetMap</a></div>

    <div class="col-md-5"><button class="btn btn-cancel" (click)="close()">Back to Citations</button></div>
  </div>

</div>
