<section class="row g-0 align-items-center mb-3">

    <div class="col-12 row g-0 mb-3">
        <div class="col-md-9 col-sm-12 ">
            <h1><strong>Manage Equipments</strong></h1>
        </div>
        <div class="col-3 text-end">
            <button class="btn login__btn" (click)="onAddNewEquipment()">Add New Equipment</button>
        </div>
    </div>

    <div class="" *ngIf="successMsg">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>
    <div class="" *ngIf="failedMsg">
        <div class="alert danger-alert" role="alert">
            {{ failedMsg }}
        </div>
    </div>

    <!-- Search Box-->
    <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
        <input matInput placeholder="Search by Category or Serial No" autocomplete="off" [(ngModel)]="searchKey"
            (keyup)="applySearch($event)" />
        <mat-icon matPrefix>search</mat-icon>
        <app-filter-list [listTitle]="'Condition'" [multiple]='false' [label]="'Filter'" [icon]="'filter_list'"
            (clearSelectedItems)="loadList(operationId,'', '',0, pageSize);" [clearBtn]="'Clear'" [filterBtn]="'Filter'"
            [returnValue]="'name'" [list]="filterList" (applyFilter)="applyFilter($event)">
        </app-filter-list>

        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <!-- Search Box-->

</section>


<section class="mb-5">
    <!-- List Grid -->
    <div class="mat-elevation-z8 custom-table-card mb-5">
        <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows>
            <ng-container matColumnDef="index">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element ; let i = dataIndex">
                    {{paginator.pageIndex * paginator.pageSize + i + 1}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="category">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Category</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.category}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="serialNumber">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Serial No</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.serialNumber}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="condition">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Condition</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.condition}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.description}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="actions">
                <mat-header-cell *matHeaderCellDef class="justify-content-center">Actions</mat-header-cell>
                <mat-cell *matCellDef="let row" class="justify-content-center">
                    <button title="Delete" mat-icon-button color="warn" [disabled]="row.deleted" (click)="onDelete(row)">
                        <!-- <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M18.7175 5.98993L5.98958 18.7178L5.28247 18.0107L18.0104 5.28282L18.7175 5.98993Z"
                                fill="#D93636" />
                            <circle cx="12" cy="12" r="9.25" stroke="#D93636" stroke-width="1.5" />
                        </svg> -->
                        <img src="../../../assets/Icons/Group50-trash.svg" class='mx-1 ' alt="">

                    </button>
                    <!-- <button mat-icon-button class='actionBtn' (click)="openReleaseDetentionOrderDialog(row)"
                        *ngIf='row.detentionOrderStatus=="READY_TO_RELEASE"'>
                        <img src="../../../assets/Icons/Group50-trash.svg" class='mx-1 ' alt="">

                    </button> -->

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="loading">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    LoadingData
                </mat-footer-cell>
            </ng-container>

            <ng-container matColumnDef="noData">
                <mat-footer-cell *matFooterCellDef colspan="6">
                    NoData
                </mat-footer-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

            <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                [class.expanded-row]="row.isExpanded">
            </mat-row>

        </mat-table>
        <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
            [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
            (page)="pageChanged($event)">
        </mat-paginator>
    </div>
    <mat-spinner *ngIf="loading"></mat-spinner>

</section>