import {
  NgModule,
} from '@angular/core';

import { MatInputRequiredDirective } from './mat-input-required.directive';
import { PreventNonNumericDirective } from './prevent-non-numeric.directive';

@NgModule({
  declarations: [MatInputRequiredDirective, PreventNonNumericDirective],
  exports: [MatInputRequiredDirective, PreventNonNumericDirective]
})
export class SharedDirectiveModule { }