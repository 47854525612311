import { NgModule } from '@angular/core';

import { SharedModule } from '../_shared/shared.module';

import { DailyMonitorListComponent } from './daily-monitor-list/daily-monitor-list.component';

@NgModule({
  declarations: [DailyMonitorListComponent],
  imports: [
    SharedModule
  ]
})
export class DailyMonitorModule { }
