<div class="mat-dialog-content">
    <div class="row mb-5">
        <div class="col-12 row g-0 mb-3 align-items-center">
            <span class="col-1">
                <img class="pointer back-btn" src="../../assets/Icons/arrow-left-2.svg" (click)="goBack()" />
            </span>
            <span class="col-11">
                <div class="print-citation__title"> {{title}}</div>
            </span>
        </div>
        <div class="col-12">
            <div class="breadcrumb-content">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.0014 13.9199C12.3481 13.9199 12.6292 14.206 12.6292 14.5589V17.115C12.6292 17.4679 12.3481 17.754 12.0014 17.754C11.6547 17.754 11.3736 17.4679 11.3736 17.115V14.5589C11.3736 14.206 11.6547 13.9199 12.0014 13.9199Z"
                        fill="#39B2E5" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.0096 3.69085C14.0098 3.69105 14.0101 3.69124 14.0103 3.69144L19.8118 8.41202C20.2417 8.7644 20.5639 9.28047 20.7611 9.80664C20.9584 10.333 21.0559 10.9367 20.9668 11.4921L20.9664 11.4945L19.8531 18.2766L19.8528 18.2785C19.6007 19.7859 18.1933 21 16.6891 21H7.31371C5.80176 21 4.40185 19.7949 4.14992 18.2778L3.03705 11.4984C3.03693 11.4977 3.03681 11.4969 3.03669 11.4962C2.94225 10.9391 3.03779 10.3339 3.23543 9.80664C3.4332 9.27902 3.75825 8.76258 4.19312 8.41034C4.19346 8.41006 4.1938 8.40979 4.19415 8.40951L9.99307 3.68247C11.1109 2.77037 12.8855 2.77187 14.0096 3.69085ZM13.2237 4.6876C12.5565 4.14177 11.4352 4.14363 10.7786 4.67953L4.97546 9.40999C4.75792 9.58599 4.54759 9.89136 4.40858 10.2622C4.26955 10.6331 4.22695 11.0026 4.27421 11.2799L4.27488 11.2838L5.388 18.0648C5.38803 18.0649 5.38797 18.0646 5.388 18.0648C5.53811 18.9672 6.41501 19.722 7.31371 19.722H16.6891C17.5787 19.722 18.4646 18.9599 18.6148 18.065C18.6148 18.0647 18.6149 18.0644 18.6149 18.064L19.7276 11.2862C19.7276 11.2858 19.7277 11.2854 19.7278 11.285C19.7723 11.0056 19.7274 10.6342 19.588 10.2622C19.4484 9.88992 19.2394 9.58406 19.025 9.40808C19.0248 9.40788 19.0245 9.40768 19.0243 9.40747L13.225 4.68865L13.2237 4.6876Z"
                        fill="#39B2E5" />
                </svg>
                <nav aria-label="breadcrumb" class="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a class="href" (click)="goBack()">Manage Citations</a></li>
                        <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>

    <div id="printcontent-view-citation">
        <!--print content-->
        <div class="company-info">
            <div class="info-img">
                <!-- <img class="prt-info-img" [src]="imagePath"> -->
                <ngx-barcode width="100" height="50" [bc-value]="citationData.id" [bc-display-value]="false">
                </ngx-barcode>

            </div>
            <div>
                <ul>
                    <li class="prt-company-title">شركة حلول المدن الذكية - ذكي</li>
                    <li>س: تجاري:
                        {{data?.commercial_register_number}}</li>
                    <li>رقم ضريبي:
                        {{data?.tax_number}}</li>
                </ul>
            </div>
        </div>
        <div class="card mb-5">
            <div class="card-title">
                <p>بيانات المخالفة</p>
                <p>Violation Informations</p>
            </div>
            <div class="card-body">
                <!-- <div class="card-text">
                    <ul>
                        <li>Violation No.</li>
                        <li>{{citationData?.id}}</li>
                        <li>رقم المخالفة</li>

                        <li>Violation Type</li>
                        <li>
                            {{citationData?.violationName}}
                            <br>
                            {{citationData?.violationNameAr}}
                        </li>
                        <li>نوع المخالفة</li>

                        <li>Violation Date</li>
                        <li>{{citationData?.createdDate}}</li>
                        <li>تاريخ المخالفة</li>

                        <li>Enforcer</li>
                        <li>{{citationData?.enforcerName}}</li>
                        <li>المراقب</li>
                    </ul>
                </div> -->
                <table dir="rtl" style="width: 100%;">
                    <tbody class="tr-border" style="border-top: 0px solid #e9ecef;">
                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">رقم المخالفة</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.id}}</td>
                            <td style="text-align: left;" class="prt-td-left">Violation No</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">نوع المخالفة</td>
                            <td style="text-align: center;" class="prt-td-center">
                                {{citationData?.violationName}}
                                <br>
                                {{citationData?.violationNameAr}}
                            </td>
                            <td style="text-align: left;" class="prt-td-left">Violation Type</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">تاريخ المخالفة</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.createdDate}}</td>
                            <td style="text-align: left;" class="prt-td-left">Violation Date</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">المراقب</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.enforcerName}}</td>
                            <td style="text-align: left;" class="prt-td-left">Enforcer</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">المبلغ الإجمالي الذي يجب على مالك السيارة دفعه</td>
                            <td style="text-align: center;" class="prt-td-center">{{this.data?.totalAmountWithVAT}}</td>
                            <td style="text-align: left;" class="prt-td-left">Total Amount car owner should pay</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-5">
            <div class="card-title">
                <p>بيانات المركبة</p>
                <p>Vehicle Informations</p>
            </div>
            <div class="card-body">
                <!-- <div class="card-text">
                    <ul>
                        <li *ngIf="citationData?.plateNumberEn">Licennce Plate No.</li>
                        <li>{{citationData?.plateNumberEn}}</li>
                        <li *ngIf="citationData?.plateNumberEn">رقم اللوحة</li>

                        <li *ngIf="citationData?.citationVehicleDto?.type">Vehicle Type</li>
                        <li>
                            {{citationData?.citationVehicleDto?.type}}
                        </li>
                        <li *ngIf="citationData?.citationVehicleDto?.type">نوع المركبة</li>

                        <li *ngIf="citationData?.citationVehicleDto?.colorEnName">Vehicle Color</li>
                        <li>{{citationData?.citationVehicleDto?.colorEnName}}</li>
                        <li *ngIf="citationData?.citationVehicleDto?.colorEnName">لون المركبة</li>

                    </ul>
                </div> -->
                <table dir="rtl" style="width: 100%;">
                    <tbody class="tr-border" style="border-top: 0px solid #e9ecef;">
                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">رقم اللوحة</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.plateNumberEn}}</td>
                            <td style="text-align: left;" class="prt-td-left">Licennce Plate No.</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">نوع المركبة</td>
                            <td style="text-align: center;" class="prt-td-center">
                                {{citationData?.citationVehicleDto?.type}}</td>
                            <td style="text-align: left;" class="prt-td-left">Vehicle Type</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">لون المركبة</td>
                            <td style="text-align: center;" class="prt-td-center">
                                {{citationData?.citationVehicleDto?.colorEnName}}</td>
                            <td style="text-align: left;" class="prt-td-left">Vehicle Color</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card mb-5">
            <div class="card-title">
                <p>منطقة المخالفة</p>
                <p>Citation Area</p>
            </div>
            <div class="card-body">
                <!-- <div class="card-text">
                    <ul>
                        <li *ngIf="citationData?.country.enName">Area</li>
                        <li>{{citationData?.country.enName}}</li>
                        <li *ngIf="citationData?.country.enName">المنطقة</li>

                        <li *ngIf="citationData?.streetName">Street</li>
                        <li>{{citationData?.streetName}}</li>
                        <li *ngIf="citationData?.streetName">الشارع</li>
                    </ul>
                </div> -->

                <table dir="rtl" style="width: 100%;">
                    <tbody class="tr-border" style="border-top: 0px solid #e9ecef;">
                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">المنطقة</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.country.enName}}</td>
                            <td style="text-align: left;" class="prt-td-left">Area</td>
                        </tr>

                        <tr class="tr-border" style="border-top: 0px solid #e9ecef;">
                            <td style="text-align: right;" class="prt-td-right">الشارع</td>
                            <td style="text-align: center;" class="prt-td-center">{{citationData?.streetName}}</td>
                            <td style="text-align: left;" class="prt-td-left">Street</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </div>
    <!--end print content-->
</div>

<div class=" mat-dialog-actions text-center mt-4">
    <button class="btn" style="background: #ACB5BE;" mat-button mat-dialog-close> Cancel </button>
    <button class="btn login__btn" mat-button (click)="onPrint()">Print </button>
</div>