<h1 mat-dialog-title>Change Password</h1>
<div mat-dialog-content>
    <form [formGroup]="changePasswordForm">
        <p>
            <mat-form-field appearance="standard">
                <mat-label>New Password</mat-label>
                <input [type]="showPassword ? 'password' : 'text'" matInput formControlName="password"
                    placeholder="New Password" required>
                <mat-icon matSuffix (click)="onTogglePassword()">{{showPassword ? 'visibility_off' : 'visibility'}}
                </mat-icon>

            </mat-form-field>
            <mat-error *ngIf="myError('password', 'required')">
                password is required
            </mat-error>
            <mat-error *ngIf="myError('password', 'minlength')">
                password must be at least 8 characters
            </mat-error>
        </p>
        <p>
            <mat-form-field appearance="standard">
                <mat-label>Confirm Password</mat-label>
                <input [type]="showConfirmPassword ? 'password' : 'text'" matInput formControlName="confirmPassword"
                    placeholder="Confirm Password">
                <mat-icon matSuffix (click)="onToggleConfirmPassword()">{{showConfirmPassword ?
                    'visibility_off' : 'visibility'}}</mat-icon>
            </mat-form-field>
            <mat-error *ngIf="myError('confirmPassword', 'required')">
                confirm password is required
            </mat-error>
            <mat-error *ngIf="myError('confirmPassword', 'mustMatch')">
                password doesn't match
            </mat-error>

        </p>
    </form>
</div>
<div class=" mat-dialog-actions g-0 py-4 d-flex justify-content-between">
    <button mat-button class="btn px-2 full-width login__btn" style="background: #ACB5BE;"
        (click)="onCancel()">Cancel</button>

    <button mat-button class="btn full-width login__btn" mat-dialog-close (click)="onSubmit()">Submit</button>
</div>

<mat-spinner *ngIf="loading"></mat-spinner>