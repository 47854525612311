
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class CitationSettledListService {
    constructor(private _http: HttpClient) {
    }
    getList(offset: number, limit: number, fromDate: string, toDate: string, actionBy: string[],channels:string[], operationId: number) {
        const api = `${environment.baseURL}enforcements/citations/settled-citations?offset=${offset}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&actionByMany=${actionBy}&channels=${channels}&operationId=${operationId}`;
        return this._http.get<any>(api);
    }
    getTotal(page: number, limit: number, fromDate: string, toDate: string, actionBy: string[],channels:string[], operationId: number) {
        const api = `${environment.baseURL}enforcements/citations/settled-citations-amount?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&actionByMany=${actionBy}&channels=${channels}&operationId=${operationId}`;
        return this._http.get<any>(api);
    }

    getTotalSettled(fromDate: string, toDate: string,channels:string[], operationId: number) {
        const api = `${environment.baseURL}enforcements/citations/settled-citations-total-amount?fromDate=${fromDate}&toDate=${toDate}&channels=${channels}&operationId=${operationId}`;
        return this._http.get<any>(api);
    }
    downloadCSV(params) {
        const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
        
        let api = params.actionByMany?`${environment.baseURL}enforcements/citations/export-settled-citations?offset=${params.offset}&limit=${params.limit}&fromDate=${params.fromDate}&toDate=${params.toDate}&actionByMany=${params.actionByMany}&channels=${params.channels}&operationId=${params.operationId}`:  `${environment.baseURL}enforcements/citations/export-settled-citations?offset=${params.offset}&limit=${params.limit}&fromDate=${params.fromDate}&toDate=${params.toDate}&channels=${params.channels}&operationId=${params.operationId}`;
        return this._http.get<any>(api, {
            headers,
            responseType: 'text' as 'json'
        });
    }

    exportSettledCitation(params: any) {
        const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');

        const api = `${environment.baseURL}enforcements/citations/export-settled-citations`;
        return this._http.get<any>(api, { params, headers, responseType: 'text' as 'json' });
    }

}
