<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <div class="col-3">
            <h1><strong>My Account</strong></h1>
        </div>
        <div class="col-9">
            <div class="row text-center">

            </div>
        </div>
    </div>


    <mat-spinner *ngIf="loading"></mat-spinner>


    <img class="img-profile" src="assets/Icons/user-circle.png" alt="">

    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-content">
            <div class="">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Email (Read only)</mat-label>
                    <input class="w-100" matInput formControlName="email" placeholder="enter violation name">
                </mat-form-field>
                <mat-error *ngIf="myError('email', 'required')">Email is required</mat-error>
            </div>
            <div class="">
                <mat-form-field class="w-100" appearance="fill">
                    <mat-label>Full name</mat-label>
                    <input class="w-100" matInput formControlName="name" placeholder="enter violation name">
                </mat-form-field>
                <mat-error *ngIf="myError('name', 'required')">Full name is required</mat-error>
            </div>
        </div>
        <section *ngIf="type!='view'" class="row g-0 justify-content-end">
            <div class="col-6 px-2 text-end">
                <button class="btn login__btn full-width" [disabled]="loading">Save</button>
            </div>
        </section>
    </form>

</section>