<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-5">
        <h1><strong>Settle Citation</strong></h1>
    </div>

    <app-citation-penalties-header *ngIf="data.length" [data]="data[0]">
    </app-citation-penalties-header>

    <app-citation-penalties *ngIf="data.length" [data]="data[0]" [vat]="vat" [penActionType]="'settled'"
        (penalties)="getPenalities($event)" (total)="getTotal($event)">
    </app-citation-penalties>

    <div>
        <p class="payment-header">Payment Method</p>
        <form [formGroup]="paymentMethodForm" class="col-3">
          <mat-radio-group formControlName="paymentMethod" class="radio-group">
            <mat-radio-button class="radio-btn" value="CASH">Cash</mat-radio-button>
            <mat-radio-button class="radio-btn" value="POS">POS</mat-radio-button>
          </mat-radio-group>
        </form>
      </div>

    <app-citation-penalties-total *ngIf='total' [total]="total">
    </app-citation-penalties-total>

    <div class="text-center">
        <button class="btn" style="background: #ACB5BE;" (click)="onCancel()"> {{ isGeneratingMsg ?  'Back to Citation' : 'Cancel Settlement'}} </button>
        <button [disabled]="loading || data[0].status == 'Settled'" [ngClass]="isGeneratingMsg ? 'view__btn': 'login__btn'" class="btn login__btn" (click)="isGeneratingMsg ? onViewInvoice() : onApply()"> {{isGeneratingMsg ? "View Invoice" : 'Settle Citation'}}
        </button>
    </div>

</section>