<section class="row g-0 align-items-center">
  <div class="col-12 row g-0 mb-3">
    <div class="col-md-9 col-sm-12">
      <h1 class='fw-bolder fs-2 mt-0'><strong> Tow Requests
        </strong></h1>
    </div>

  </div>

  <div
  #element
  (mousedown)="startDragging($event, false, element)"
  (mouseup)="stopDragging($event, false)"
  (mouseleave)="stopDragging($event, false)"
  (mousemove)="moveEvent($event, element)" class="row d-flex statistics justify-content-between overflow-hidden flex-nowrap gap-3" style="cursor: grab" *ngIf='countList'>
    <div class="card col-xl-2 col-lg-4 flex-shrink-0" *ngFor='let count of countList'>
      <div class="card-body p-1">
        <div class="count d-flex justify-content-between align-items-start my-2 mb-1">
          <h2 class='h1 fw-bolder'>{{count.count}}</h2>
          <div class="">
            <img [src]='count.icon' class="w-100" alt="">
          </div>
        </div>
        <p class="mb-2">{{ removeUnderScore(count.requestStatus) | titlecase }}</p>
        <p
          *ngIf="
            count.requestStatus === 'SYSTEM_CLOSED_RUNWAY' ||
            count.requestStatus === 'WORKFORCE_CLOSED_RUNWAY'
          "
          class="amount"
        >
          value: {{ count.amount }}
        </p>
      </div>
    </div>
  </div>

  <div class="requests-map mt-3 mb-5 rounded border-black border-5 shadow-lg pt-2 px-2">
    <div class="row">

      <div class="col-md-4 p-2">
        <div>
          <ng-container class='mb-5' *ngIf="!hideRequests">
            <div class=" d-flex justify-content-between px-3">
              <h4 class="mb-1 fw-bold fs-5">By severity</h4>
              <div class="position-relative py-2">
                <app-filter-list [multiple]="false" [listTitle]="'Severity Status'" [label]="'Filter'"
                  [icon]="'filter_list'" (clearSelectedItems)="clearFilter()" [clearBtn]="'Clear'"
                  [filterBtn]="'Filter'" [returnValue]="'name'" [list]="severityList"
                  (applyFilter)="applyFilter($event)">
                </app-filter-list>
              </div>

            </div>
            <div class='workforceList'>
              <ul class="p-0 mt-2 sidebarList w-100" *ngFor='let request of requestsList'>
                <li class="d-flex py-1 justify-content-between align-items-center border-bottom cursor-pointer"
                  (click)="searchMarker(requestMarkers,request.requestLatitude,request.requestLongitude,13)">
                  <div class="row w-100 d-flex align-items-center justify-content-around">
                    <div class="col-3 pe-0">
                      <img
                        [src]="request.requestSeverity=='MEDIUM_CITATION'? mediumSeverity.listImg:(request.requestSeverity=='HIGH_CITATION'? highSeverity.listImg: lowSeverity.listImg)"
                        class='w-100' alt="">
                    </div>
                    <div class="col-7 px-0">
                      <h3 class="mb-0 d-flex px-0">
                        <p class="fw-bold fs-5">{{request.groundingType |titlecase}}</p>
                        <p *ngIf="request.requestSeverity=='MEDIUM_CITATION'"
                          class="mediumCitation rounded-pill text-white h6 citationSeverity px-2 py-1 mx-2">{{
                          mediumSeverity.severity}}</p>
                        <p *ngIf="request.requestSeverity=='HIGH_CITATION'"
                          class="highCitation rounded-pill text-white px-2 h6 citationSeverity py-1 mx-2">{{ highSeverity.severity}}</p>
                        <p *ngIf="request.requestSeverity=='LOW_CITATION'"
                          class="lowCitation rounded-pill text-white px-2 py-1 h6 citationSeverity mx-2">{{ lowSeverity.severity}}</p>
                      </h3>
                      <div class='vehicle '>
                      <span class=''>{{slicePlateNumber(request.vehiclePlateNumberEn)}}.</span>
                      <span class='px-3'>{{request.vehiclePlateType|titlecase}}. </span>
                        <span class=''>{{ request?.createdDate |date:'h:mm a'}}</span>
                    </div>
                    </div>
                    <span class="col-1 dangerColor fw-bold"  *ngIf='request.requestStatus=="REJECTED"'>Rejected</span>

                  <div class="icon h-100 d-flex align-items-center col-1">
                    <img class="toogle-arrow px-1" src="../assets/Icons/arrow-right.svg" alt='' />
                  </div>
                </div>


                </li>

              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf='!hideRequests'>
            <div class="filter-requestd d-flex justify-content-between px-3 mt-3">
              <h4 class="mb-1 fw-bold fs-4">Workforces</h4>

            </div>
            <div class='workforceList'>
              <ul class="p-0 mt-3 sidebarList w-100 " *ngFor='let workforce of workforcesList'>
                <li class="d-flex py-1 px-1 align-items-center border-bottom " (click)="onWorkforceClick(workforce)">
                  <div class="user-img col-3">
                    <h3 class="fs-5 fw-bold mt-3">{{ workforce.name[0] + workforce.name[1] }} </h3>
                  </div>
                  <div class="col-7 px-0 ps-2">
                    <h3 class="mb-0 d-flex px-0">
                      <p class="fw-bolder fs-5 workforceName">{{workforce.name |titlecase}}</p>
                    </h3>
                    <p class="px-2 py-1 mx-2 h6">{{workforce.numberOfRequests}} Requests</p>
                  </div>
                  <button mat-button matTooltip="View workforce on map" type="button" class="col-2 fw-bold"
                    *ngIf='workforce.locationObject' (click)="onWorkforceClick(workforce)" matTooltipPosition="above"
                    class="outline btn-sm mr-2">
                    <i class="material-icons" matSuffix>visibility</i>
                  </button>

                </li>
              </ul>
            </div>

          </ng-container>

          <ng-container *ngIf='hideRequests'>
            <div class="filter-requestd d-flex justify-content-between px-3 mt-3">
              <h4 class="mb-1 fw-bold fs-4 me-1">Assign Workforces</h4>
              <div class="cross">
                <mat-icon class="close" matTooltip="Cancel Assign" type="button" matTooltipPosition="above"
                  (click)="cancelAssign()">close</mat-icon>
              </div>
            </div>
            <div class='workforceList'>
              <ul class="p-0 mt-3 sidebarList w-100" *ngFor='let workforce of workforceWithTracking'>
                <li class="d-flex py-1 px-1 align-items-center border-bottom cursor-pointer workforceListItem"
                  (click)="workforceClick(workforce)">
                  <div class="user-img col-3">
                    <h3 class="fs-5 fw-bold mt-3">{{ workforce.employeeDataImageDto.name[0] +
                      workforce.employeeDataImageDto.name[1] }} </h3>
                  </div>
                  <div class="col-8 px-0 ps-2">
                    <h3 class="mb-0 d-flex px-0">
                      <p class="fw-bolder fs-5">{{workforce.employeeDataImageDto.name |titlecase}}</p>
                    </h3>
                    <p class="px-2 py-1 mx-2 h6"> <span>{{workforce.trackableObjectResponse?.durationMinutes ?
                        workforce.trackableObjectResponse?.durationMinutes : 0}}
                        Min ,</span>
                      <span>{{workforce.employeeDataImageDto?.numberOfRequests}}
                        Requests</span>
                    </p>
                  </div>
                </li>
              </ul>
            </div>

          </ng-container>
        </div>

      </div>

      <div class="col-8">
        <div class="map-container w-100">
          <div class="map-frame rounded">
            <div id="map"></div>
          </div>
        </div>
        <div *ngIf="hideRequests" class='bg-secondary py-5 w-100 mt-0 border-1 rounded-4 px-4 mb-3'>
          <table class='w-100 mt-0'>
            <tr class='mt-3'>
              <th>Request Type</th>
              <th>Plate No.</th>
              <th>Vehicle Type</th>
              <th>Severity</th>
            </tr>
            
            <tr class='mt-3'>
              <td>{{selectedRequestValue.groundingType}}</td>
              <td>{{ slicePlateNumber(selectedRequestValue.vehiclePlateNumberEn) }}</td>
              <td>{{selectedRequestValue.vehiclePlateType}} </td>
              <td>{{selectedRequestValue.requestSeverity}}</td>
            </tr>
          </table>
        </div>
      </div>

      <mat-spinner *ngIf="loading"></mat-spinner>
    </div>
  </div>

</section>