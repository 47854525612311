import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShiftSchedulerService } from 'src/app/_services/shiftScheduler.service';

@Component({
  selector: 'app-remove-workforce-dialog',
  templateUrl: './remove-workforce-dialog.component.html',
  styleUrls: ['./remove-workforce-dialog.component.scss']
})
export class RemoveWorkforceDialogComponent implements OnInit {
  startDate: string;
  endDate: string;
  loading: boolean = false;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RemoveWorkforceDialogComponent>,
    private shiftService: ShiftSchedulerService
  ) { }

  ngOnInit(): void {
    this.startDate = new Date(this.data.startDate) > new Date() ? new Date(this.data.startDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0];;
    this.endDate = new Date(this.data.endDate).toISOString().split('T')[0];
  }

  removeForm = new FormGroup({
    fromDate: new FormControl('', [Validators.required]),
    shiftId: new FormControl(this.data.shiftId, [Validators.required]),
    employeeId: new FormControl(this.data.employeeId, [Validators.required])
  })

  onRemoveWorkforce() {
    this.loading = true;
    this.shiftService.removeFromShift(this.removeForm.value).subscribe(resp => {
      this.loading = false;
      console.log(resp);
      this.dialogRef.close();
    },
    error => {
      this.loading = false;
    })
  }

  onCancel() {
    this.dialogRef.close();
  }
}
