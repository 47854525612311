import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { CitationService } from '../_services';

import { MatDialog } from '@angular/material/dialog';
import { CitationInvoiceDialog } from '../_shared/citation-invoice/citation-invoice.dialog';
import { CitationPrintComponent } from '../_shared/citation-print/citation-print.component';

@Component({
  selector: 'app-citation-settled-view',
  templateUrl: './citation-settled-view.component.html',
  styleUrls: ['./citation-settled-view.component.scss']
})
export class CitationSettledViewComponent implements OnInit {

  loading = false;
  data: any = {};
  ID;
  penalties: any;
  total: number; // from penalties com
  invoiceData: any = {};

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _citationService: CitationService,
    private _dialog: MatDialog

  ) {
    this.ID = this._activatedRoute.snapshot.params.id;
    this._loadCitation(this.ID);

  }

  ngOnInit(): void {
    // console.log(`CitationControlComponent ID ${this.ID}, data  `, this.data);
  }

  private _loadCitation(ids) {
    // console.log(ids);
    
    this._citationService.getDetails(ids)
      .pipe(first())
      .subscribe(
        resp => {
          this.data = resp.payload;
          // console.log("this.data ======= ", this.data);
          this._loadInvoice(this.data[0].invoiceNumber);
        },
        error => { });
  }

  getPenalities($event) {
    this.penalties = $event;
    return $event;
  }

  getTotal($event) {
    this.total = $event;
    return $event;
  }

  onApply() {
    if (!this.invoiceData.cashierName && this.data.length) this.invoiceData.cashierName = this.data[0].actionBy;

    const dialogRefInvoice = this._dialog.open(CitationInvoiceDialog, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '820px',
      // height: '500px',
      data: this.invoiceData,
      disableClose: true
    });

    dialogRefInvoice.afterClosed().subscribe(result => {
      // console.log("dialogRefInvoice result", result);
      this._router.navigateByUrl(`/enforcement-citations`);
    });

  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

  private _loadInvoice(id) {
    if (!id) return false;
    this._citationService.getInvoice(id).pipe(first())
      .subscribe(
        data => {
          // console.log("getInvoice  data =========", data);
          this.invoiceData = data.payload;
          this.total = this.invoiceData.totalAmountWithVAT
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  onPrintCitations() {
    
    // this._router.navigateByUrl(`/print-citation`);
    const dialogRefPrint = this._dialog.open(CitationPrintComponent, {
      // panelClass: 'custom-mat-show-image-dialog-container',
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      width: '700px',
      // height: '100%',
      data: {...this.data,...this.invoiceData}
    });

    dialogRefPrint.afterClosed().subscribe(result => {
      // console.log("dialogRefPrint result", result);
      this._router.navigateByUrl(`/enforcement-citations`);
    });
  }

}
