<section class="row g-0 align-items-center">
    <div class="col-12 row g-0 mb-2">
        <span class="on-back-arrow">
            <button mat-icon-button (click)="onBack()">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.63415 19C9.44926 19 9.26437 18.9281 9.1184 18.7742L3.21165 12.5428C2.92945 12.2451 2.92945 11.7523 3.21165 11.4546L9.1184 5.22328C9.4006 4.92557 9.86769 4.92557 10.1499 5.22328C10.4321 5.52099 10.4321 6.01375 10.1499 6.31146L4.75889 11.9987L10.1499 17.686C10.4321 17.9837 10.4321 18.4764 10.1499 18.7742C10.0137 18.9281 9.81904 19 9.63415 19Z"
                        fill="#6B757E" />
                    <path
                        d="M20.2702 12.7684H3.89279C3.49382 12.7684 3.16296 12.4193 3.16296 11.9985C3.16296 11.5776 3.49382 11.2285 3.89279 11.2285H20.2702C20.6691 11.2285 21 11.5776 21 11.9985C21 12.4193 20.6691 12.7684 20.2702 12.7684Z"
                        fill="#6B757E" />
                </svg>
            </button>
        </span>
        <div class="header-title">
            <h1><strong>{{title}}</strong></h1>
        </div>

        <div class="breadcrumb-content">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.0014 13.9199C12.3481 13.9199 12.6292 14.206 12.6292 14.5589V17.115C12.6292 17.4679 12.3481 17.754 12.0014 17.754C11.6547 17.754 11.3736 17.4679 11.3736 17.115V14.5589C11.3736 14.206 11.6547 13.9199 12.0014 13.9199Z"
                    fill="#39B2E5" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M14.0096 3.69085C14.0098 3.69105 14.0101 3.69124 14.0103 3.69144L19.8118 8.41202C20.2417 8.7644 20.5639 9.28047 20.7611 9.80664C20.9584 10.333 21.0559 10.9367 20.9668 11.4921L20.9664 11.4945L19.8531 18.2766L19.8528 18.2785C19.6007 19.7859 18.1933 21 16.6891 21H7.31371C5.80176 21 4.40185 19.7949 4.14992 18.2778L3.03705 11.4984C3.03693 11.4977 3.03681 11.4969 3.03669 11.4962C2.94225 10.9391 3.03779 10.3339 3.23543 9.80664C3.4332 9.27902 3.75825 8.76258 4.19312 8.41034C4.19346 8.41006 4.1938 8.40979 4.19415 8.40951L9.99307 3.68247C11.1109 2.77037 12.8855 2.77187 14.0096 3.69085ZM13.2237 4.6876C12.5565 4.14177 11.4352 4.14363 10.7786 4.67953L4.97546 9.40999C4.75792 9.58599 4.54759 9.89136 4.40858 10.2622C4.26955 10.6331 4.22695 11.0026 4.27421 11.2799L4.27488 11.2838L5.388 18.0648C5.38803 18.0649 5.38797 18.0646 5.388 18.0648C5.53811 18.9672 6.41501 19.722 7.31371 19.722H16.6891C17.5787 19.722 18.4646 18.9599 18.6148 18.065C18.6148 18.0647 18.6149 18.0644 18.6149 18.064L19.7276 11.2862C19.7276 11.2858 19.7277 11.2854 19.7278 11.285C19.7723 11.0056 19.7274 10.6342 19.588 10.2622C19.4484 9.88992 19.2394 9.58406 19.025 9.40808C19.0248 9.40788 19.0245 9.40768 19.0243 9.40747L13.225 4.68865L13.2237 4.6876Z"
                    fill="#39B2E5" />
            </svg>
            <nav aria-label="breadcrumb" class="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a class="href" (click)="onBack()">Violations</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{title}}</li>
                </ol>
            </nav>
        </div>

    </div>

    <div class="row form-content">
        <!-- list form-->
        <div class="row list-form-header2">
            <div class="col-6">
                <div class="list-form-title">Violations</div>
                <mat-accordion>
                    <mat-expansion-panel class="accordion-panel" [expanded]="selectedViolation.id==0">
                        <mat-expansion-panel-header>
                            {{selectedViolation.enName}}
                        </mat-expansion-panel-header>

                        <div class="list-form-content">
                            <!-- Search Box right-->
                            <mat-form-field class="search-field">
                                <input matInput placeholder="Search violations here" autocomplete="off"
                                    (keyup)="applyFilterRight($event)" />
                                <span matPrefix>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.5 1.37805C3.67122 1.37805 1.37805 3.67122 1.37805 6.5C1.37805 9.32878 3.67122 11.622 6.5 11.622C9.32878 11.622 11.622 9.32878 11.622 6.5C11.622 3.67122 9.32878 1.37805 6.5 1.37805ZM0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5Z"
                                            fill="#6B757E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.0002 11.0009C11.2045 10.7966 11.5358 10.7966 11.7402 11.0009L15.3469 14.6076C15.5513 14.8119 15.5513 15.1432 15.3469 15.3476C15.1426 15.5519 14.8113 15.5519 14.6069 15.3476L11.0002 11.7409C10.7958 11.5366 10.7958 11.2053 11.0002 11.0009Z"
                                            fill="#6B757E" />
                                    </svg>
                                    &nbsp;
                                </span>
                                <span matSuffix>
                                    <!-- <button mat-button matSuffix mat-icon-button aria-label="Clear"
                                            *ngIf="searchKey" (click)="onSearchClear()">
                                            <mat-icon>close</mat-icon>
                                        </button> -->
                                </span>
                            </mat-form-field>
                            <!-- Search Box right-->
                            <mat-selection-list #selectedViolationList [multiple]="false"
                                (selectionChange)="onViolationSelect($event)">
                                <mat-list-option *ngFor="let violation of violationsDataList" [value]="violation">
                                    {{violation.enName}}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>
            <div class="col-6">
                <div class="list-form-title list-form-title-right">Penalties <span *ngIf="type!='view'"
                        class="plus-icon" (click)="addPenalty()">+</span></div>
                <mat-accordion *ngFor="let selectedPenalty of selectedPenalties ; let idx = index" class="">
                    <mat-expansion-panel class="accordion-panel accordion-right"
                        [expanded]="selectedPenalties[idx].isExpanded">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{selectedPenalties[idx].enName}}
                            </mat-panel-title>

                            <mat-icon *ngIf="type!='view'" [hidden]="selectedPenalties[idx].isExpanded"
                                class="remove-penalty" (click)="removePenalty(idx)">
                                <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.3135 5.09765C17.2951 5.09765 17.2674 5.09765 17.2397 5.09765C12.3559 4.60462 7.48132 4.41858 2.65292 4.9116L0.769569 5.09765C0.38182 5.13485 0.0402314 4.85579 0.00330296 4.46509C-0.0336255 4.07439 0.243338 3.73951 0.621855 3.7023L2.50521 3.51626C7.41669 3.01393 12.3928 3.20928 17.3781 3.7023C17.7567 3.73951 18.0336 4.0837 17.9967 4.46509C17.969 4.82788 17.6643 5.09765 17.3135 5.09765Z"
                                        fill="#D93636" />
                                    <path
                                        d="M5.77317 4.15812C5.73624 4.15812 5.69931 4.15813 5.65315 4.14882C5.28387 4.08371 5.02537 3.72092 5.09 3.34883L5.2931 2.13023C5.44082 1.2372 5.64392 0 7.795 0H10.2138C12.3741 0 12.5772 1.28372 12.7157 2.13953L12.9188 3.34883C12.9835 3.73022 12.725 4.09301 12.3557 4.14882C11.9772 4.21394 11.6171 3.95347 11.5617 3.58138L11.3586 2.37208C11.2294 1.56279 11.2017 1.40465 10.2231 1.40465H7.80424C6.82563 1.40465 6.80717 1.53488 6.66869 2.36278L6.45635 3.57208C6.40096 3.91627 6.10553 4.15812 5.77317 4.15812Z"
                                        fill="#D93636" />
                                    <path
                                        d="M11.968 19.9996H6.04103C2.81902 19.9996 2.68977 18.2042 2.58822 16.7531L1.98813 7.38566C1.96043 7.00427 2.25586 6.66938 2.63438 6.64148C3.02212 6.62287 3.34525 6.91124 3.37295 7.29264L3.97303 16.66C4.07459 18.074 4.11151 18.6042 6.04103 18.6042H11.968C13.9068 18.6042 13.9437 18.074 14.036 16.66L14.6361 7.29264C14.6638 6.91124 14.9962 6.62287 15.3747 6.64148C15.7532 6.66938 16.0486 6.99496 16.0209 7.38566L15.4209 16.7531C15.3193 18.2042 15.1901 19.9996 11.968 19.9996Z"
                                        fill="#D93636" />
                                    <path
                                        d="M10.5372 14.8836H7.46292C7.0844 14.8836 6.77051 14.5673 6.77051 14.186C6.77051 13.8046 7.0844 13.4883 7.46292 13.4883H10.5372C10.9157 13.4883 11.2296 13.8046 11.2296 14.186C11.2296 14.5673 10.9157 14.8836 10.5372 14.8836Z"
                                        fill="#D93636" />
                                    <path
                                        d="M11.3124 11.1629H6.69632C6.3178 11.1629 6.00391 10.8466 6.00391 10.4653C6.00391 10.0839 6.3178 9.76758 6.69632 9.76758H11.3124C11.6909 9.76758 12.0048 10.0839 12.0048 10.4653C12.0048 10.8466 11.6909 11.1629 11.3124 11.1629Z"
                                        fill="#D93636" />
                                </svg>
                            </mat-icon>
                        </mat-expansion-panel-header>
                        <div class="list-form-content">
                            <!-- Search Box lift-->
                            <mat-form-field class="search-field">
                                <input matInput placeholder="Search violations here" autocomplete="off"
                                    (keyup)="applyFilterRight($event)" />
                                <span matPrefix>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.5 1.37805C3.67122 1.37805 1.37805 3.67122 1.37805 6.5C1.37805 9.32878 3.67122 11.622 6.5 11.622C9.32878 11.622 11.622 9.32878 11.622 6.5C11.622 3.67122 9.32878 1.37805 6.5 1.37805ZM0.5 6.5C0.5 3.18629 3.18629 0.5 6.5 0.5C9.81371 0.5 12.5 3.18629 12.5 6.5C12.5 9.81371 9.81371 12.5 6.5 12.5C3.18629 12.5 0.5 9.81371 0.5 6.5Z"
                                            fill="#6B757E" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M11.0002 11.0009C11.2045 10.7966 11.5358 10.7966 11.7402 11.0009L15.3469 14.6076C15.5513 14.8119 15.5513 15.1432 15.3469 15.3476C15.1426 15.5519 14.8113 15.5519 14.6069 15.3476L11.0002 11.7409C10.7958 11.5366 10.7958 11.2053 11.0002 11.0009Z"
                                            fill="#6B757E" />
                                    </svg>
                                    &nbsp;
                                </span>
                                <span matSuffix>
                                    <!-- <button mat-button matSuffix mat-icon-button aria-label="Clear"
                                    *ngIf="searchKey" (click)="onSearchClear()">
                                    <mat-icon>close</mat-icon>
                                </button> -->
                                </span>
                            </mat-form-field>
                            <!-- Search Box lift-->
                            <mat-selection-list #selectedPenaltyList [multiple]="false"
                                (selectionChange)="onPenaltiesSelect($event,idx)">
                                <mat-list-option *ngFor="let penalty of penaltiesDataList" [value]="penalty">
                                    {{penalty.enName}}
                                </mat-list-option>
                            </mat-selection-list>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <!-- collection form-->
    </div>
    <section *ngIf="type!='view'" class="row g-0 justify-content-end" style="padding-top: 25px;">
        <div class="col-6 px-2 text-end">
            <button class="btn login__btn full-width" [disabled]="loading" (click)="onSubmit()">Save</button>
        </div>
    </section>

    <mat-spinner *ngIf="loading"></mat-spinner>
</section>