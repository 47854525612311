import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  // http://localhost:8085
  constructor(private http: HttpClient) { }
  _token = `Bearer ${localStorage.getItem('token')}`;
  private requestHeader = {
    'content-Type': '',
    Authorization: 'bearer-',
  };

  // getRoles() {
  //   this.http.get(`${env.baseURL}`);
  // }
  getActionsByGroupName(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/actions`, {
      headers: { Authorization: this._token },
    });
  }

  createRoleWithAction(roleName, actionList): Observable<any> {
    return this.http.post(
      `${env.baseURL}operation/roles/role-actions`,
      {
        roleName: roleName,
        actionsList: actionList,
      },
      { headers: { Authorization: this._token } }
    );
  }

  createWorkforce(user): Observable<any> {
    const { fullName, memberEmail, memberPhone, memberRole, memberImage } =
      user;
    const formData = new FormData();
    formData.append('name', fullName);
    formData.append('email', memberEmail);
    formData.append('operationName', 'Dammam');
    formData.append('roleName', memberRole);
    formData.append('phone', memberPhone.number);
    if (memberImage) formData.append('image', memberImage);

    return this.http.post(
      `${env.baseURL}operation/employee/addWorkForce`,
      formData,
      { headers: { Authorization: this._token } }
    );
  }

  updateWorkforce(data) {
    //   const { fullName, memberEmail, memberPhone, memberRole, memberImage } =
    //   data;
    // const formData = new FormData();
    // formData.append('name', fullName);
    // formData.append('phone', memberPhone.number);
    const api = `${env.baseURL}operation/employee/updateEmployeeByHead`;
    return this.http.put<any>(api, data);
  }

  getImage(id): Observable<any> {
    return this.http.get(`${env.baseURL}operation/employee/imagesbase64/${id}`);
  }

  updateImage(data, id): Observable<any> {
    const formData = new FormData();
    formData.append('image', data.image);

    const api = `${env.baseURL}operation/employee/updateImage/${id}`;

    return this.http.put<any>(api, formData);
  }

  getOperations(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/actions`);
  }

  getAllOperations(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/operation/all`, {
      headers: { Authorization: this._token },
    });
  }

  getFixedRoles(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/roles/fixed_roles`, {
      headers: { Authorization: this._token },
    });
  }

  getRoles(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/roles/roles-names`, {
      headers: { Authorization: this._token },
    });
  }

  getOperationsRolesBy(
    pageCount: number,
    limitCount: number,
    roleName: string
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/roles?page=${pageCount !== null ? pageCount : 0
      }&limit=${limitCount !== null ? limitCount : 10}&roleName=${roleName !== null ? roleName : ''
      }`,
      { headers: { Authorization: this._token } }
    );
  }

  deleteRoles(roleName): Observable<any> {
    return this.http.put(
      `${env.baseURL}operation/roles/disable?roleName=${roleName}`,
      { headers: { Authorization: this._token } }
    );
  }

  getRoleDetails(roleName: string) {
    const api = `${env.baseURL}operation/roles/role-details?roleId=${roleName}`;
    return this.http.get<any>(api);
  }

  updateRoles(data) {
    const api = `${env.baseURL}operation/roles/role-actions`;
    return this.http.put<any>(api, data);
  }
}
