import { Location } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';

import { first } from 'rxjs/operators';
import { AuthorizationService, ShiftSchedulerService } from '../_services';

@Component({
  selector: 'app-shifts-scheduler',
  templateUrl: './shifts-scheduler.component.html',
  styleUrls: ['./shifts-scheduler.component.scss'],
})
export class ShiftsSchedulerComponent implements OnInit {
  searchKey: '';

  roleShiftsData: any;
  selectedDateRange: any[] = [];
  filterStartDate: string = '';
  filterEndDate: string = '';

  roles: any[];
  loading: boolean = false;

  monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  constructor(
    private location: Location,
    private router: Router,
    private _shiftSchedulerService: ShiftSchedulerService,
    public authorizationService: AuthorizationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this._setFilterDate(null, null);
  }

  ngOnInit(): void {
    this._loadRoleShiftsData(this.filterStartDate, this.filterEndDate);
  }

  createShift() {
    this.router.navigateByUrl('/create-shift');
  }

  applyFilter() { }
  onSearchClear() {
    this.searchKey = '';
  }

  private _loadRoleShiftsData(start: string, end: string) {
    if (!start || !end) return false;
    this.loading = true;
    console.log("_loadRoleShiftsData =========== ")
    this._shiftSchedulerService.getRoleShifts(start, end)
      .pipe(first())
      .subscribe(
        resp => {
          this.roleShiftsData = resp.payload;
          // console.log('_shiftSchedulerService.getRoleShifts() resp', this.roleShiftsData);
          this.roles = Object.keys(this.roleShiftsData.content).map((key) => {
            return { title: key, roles: this.roleShiftsData.content[key] }
          });
          this.loading = false;
          console.log("this roles", this.roles);
        },
        error => {
          this.loading = false;
          // console.log(" err ===== ", error);
        });
  }

  onSelectedDateRange() {
    if (this.selectedDateRange) {
      const start = this._dateFormat(this.selectedDateRange[0]);
      const end = this._dateFormat(this.selectedDateRange[1]);
      if ((start != this.filterStartDate) || (end != this.filterEndDate)) {
        this._setFilterDate(start, end);
        this._loadRoleShiftsData(this.filterStartDate, this.filterEndDate);
      }
    }
  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  private _setFilterDate(start: string, end: string) {
    if (start && end) {
      this.filterStartDate = start;
      this.filterEndDate = end;
    } else {
      // this.filterStartDate = '2021-12-17';
      // this.filterEndDate = '2022-01-30';

      const dateAfter7Days = new Date();
      dateAfter7Days.setDate(dateAfter7Days.getDate() + 7);
      this.selectedDateRange[0] = new Date();
      this.selectedDateRange[1] = dateAfter7Days;

    }
  }

  get paginationTitle() {
    const today = new Date(this.filterStartDate);
    const dateAfter7Days = new Date(this.filterEndDate);

    const secondMonth = (today.getMonth() != dateAfter7Days.getMonth()) ? this.monthNames[dateAfter7Days.getMonth()] : '';

    return `${this.monthNames[today.getMonth()]} ${today.getDate()} - ${secondMonth}  ${dateAfter7Days.getDate()}, ${today.getFullYear()}`;

  }
  onPrevPage() {
    console.log("onPrevPage");
  }

  onNextPage() {
    console.log("onNextPage");
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
