<section class="row g-0 align-items-center mb-3">
    <div class="col-12 row g-0 mb-3">
        <div class="col-md-9 col-sm-12 ">
            <h1><strong>Detention Requests</strong></h1>
        </div>
        <!-- <div class="col-md-3 col-sm-12">
            <div class="text-center header-buttons">
                <button class="btn login__btn" *ngIf="authorizationService.check('Blacklist','Add')" (click)="onNew()">Add a Vehicle to Blacklist</button>
            </div>
        </div> -->
    </div>

    <div class="" *ngIf="successMsg">
        <div class="alert success-alert" role="alert">
            {{successMsg}}
        </div>
    </div>
    <div class="" *ngIf="failedMsg">
        <div class="alert danger-alert" role="alert">
            {{ failedMsg }}
        </div>
    </div>

    <!-- Search Box-->
    <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
        <input matInput placeholder="Search by vehicle info" autocomplete="off" [(ngModel)]="searchKey"
            (keyup)="applySearch($event)" />
        <mat-icon matPrefix>search</mat-icon>
        <!-- <app-filter-list [listTitle]="'Detention Order Status'" [label]="'Filter'" [icon]="'filter_list'" [clearBtn]="'Clear'" [filterBtn]="'Filter'" [returnValue]="'name'" [list]="statusList" (applyFilter)="applyFilter($event)">
        </app-filter-list> -->

        <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <!-- Search Box-->
    <section class="mb-5">
        <!-- List Grid -->
        <div class="mat-elevation-z8 custom-table-card mb-5">
            <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)"
                multiTemplateDataRows>
                <ng-container matColumnDef="index">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element ; let i = dataIndex">
                        {{paginator.pageIndex * paginator.pageSize + i + 1}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="vehicleInfo">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Info</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ slicePlateNumber(element?.plateNumber)}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="make">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Make</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element?.make}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="color">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle color</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element?.color}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="evidence">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Evidence</mat-header-cell>
                    <mat-cell *matCellDef="let element" class='cursor-pointer '>
                        <a (click)="openImageCarousel(element?.evidences)">{{element?.evidences?.length}}
                            Images</a>




                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="date">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element?.createdDate | date :'short'}}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-icon-button class='actionBtn' (click)="openAcceptDetentionOrderDialog(row)">
                            <img src="../../../assets/Icons/line-md_confirm-circle.svg" class='mx-1 ' alt="">Accept
                            Request
                        </button>

                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        LoadingData
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        NoData
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                    [class.expanded-row]="row.isExpanded">
                </mat-row>

            </mat-table>
            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <!-- List Grid -->
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>

    <ng-template #acceptDetentionOrderDialog>
        <div class=" h-100">
            <div class="row mb-2">
                <div class="col-12 row g-0 mb-3 px-1 align-items-center">
                    <span class="d-flex justify-content-between">
                        <div class="print-citation__title">
                            <h4 class="fw-bold h3">Accept Detention Requests</h4>
                        </div>
                        <div class="cross">
                            <mat-icon class="close" (click)="dialogRef.close(null)">close</mat-icon>
                        </div>
                    </span>
                </div>
            </div>

            <div>
                <div class="pb-1 mb-0" dialog-content>
                    <form [formGroup]="acceptOrderForm" (ngSubmit)="acceptDetentionOrder()">
                        <div class="row">
                            <label for="slotNumber" class='px-1'>Parking Slot Number</label>
                            <mat-form-field appearance="outline" class="w-100 col-12 px-0 mt-2">
                                <input matInput formControlName="slotNumber" placeholder="Parking Slot Number"
                                    id="slotNumber" />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <label for="notes" class='px-1'>Notes</label>
                            <mat-form-field appearance="outline" class="w-100 col-12 px-0 mt-2">
                                <input matInput formControlName="notes" placeholder="Notes" id="notes" />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <!-- <div class="col-12 col-md-6 px-2"> -->
                            <label class="file-upload__title"> Evidence <span class="file-upload__title-optional">
                                    (Optional) </span> </label>
                            <input type="file" class="file-input" file (change)="onFileSelected($event)" #fileUpload>

                            <!-- formControlName="memberImage" -->
                            <!-- (change)="console.log(event.target.files[0])" -->

                            <div class="file-upload row g-0">
                                <div class="displayd-image">
                                    <img [src]="imageUrl" />
                                </div>
                                <!-- <button mat-mini-fab color="primary" class="upload-btn"> -->
                                <!-- </button> -->
                                <label class="file-upload__txt pointer" (click)="fileUpload.click()">
                                    {{fileName || "Click Here To Upload Images"}}
                                </label>
                            </div>
                        </div>
                        <div class="mat-dialog-actions d-flex justify-content-between mb-2 mt-5">
                            <button class="btn col-5 cancelBtn text-white" mat-button mat-dialog-close
                                (click)='dialogRef.close(null)'> Cancel </button>
                            <button class="btn col-5 acceptBtn text-white" type="submit" mat-button>Accept </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>

    </ng-template>
    <ng-template #imgCarousel style='overflow-y: auto;padding:4px;'>
        <!-- <ngx-gallery [images]="currentImages" [options]="galleryOptions" (imageClick)="onImageClick($event)">
        </ngx-gallery> -->
        <mat-carousel timings="250ms ease-in" [autoplay]="true" interval="7000" color="primary" maxWidth="auto"
            proportion="100" [loop]="true" [hideArrows]="false" [hideIndicators]="false" [useKeyboard]="true"
            [useMouseWheel]="false" orientation="ltr">
            <mat-carousel-slide #matCarouselSlide *ngFor="let slide of currentImages; let i = index" [image]="slide"
                overlayColor="#00000040" [hideOverlay]="false"></mat-carousel-slide>
        </mat-carousel>
    </ng-template>