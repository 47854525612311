<div class="p-0 mx-0 pb-5 mb-5">
    <div class="dialog-header d-flex justify-content-between py-3">
        <h1 class="h3 fw-bold">Detention Requests Report</h1>
    </div>

    <form class="custom-inputs mt-0" [formGroup]="filtersForm" (ngSubmit)='applyFilter()'>
        <div class="row pt-4 pb-0 d-flex">
            <div class="row">
                <div class="col-md-4 custom-inputs mx-0">
                    <label> Operation </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select [(value)]="selectedOperationId" placeholder="Operation" formControlName="operation"
                            (selectionChange)="getDetentionByOperation(selectedOperationId)">
                            <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-4 custom-inputs mx-0">
                    <label> Detention </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select name="detentionCenterId" formControlName="detentionCenterId"
                            placeholder='Detention'>
                            <mat-option [value]="'0'">{{'All'}}</mat-option>
                            <mat-option *ngFor="let detention of detentionList" [value]="detention.id">{{
                                detention.detentionCenterName
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>



                <div class="col-md-4 custom-inputs mx-0">
                    <label> Status </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select name="status" [(ngModel)]="statusValue"
                            (selectionChange)="changeDateLabelWithStatus(statusValue)" formControlName="status"
                            placeholder='status'>
                            <mat-option *ngFor="let status of statusList" [value]="status">{{ status }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="row">
                <div class="col-md-3 custom-inputs mx-0">
                    <label for="dateFrom">
                        {{dateFromLabel}}
                    </label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [matDatepicker]="dateFrom" placeholder='Entering Date From'
                            formControlName="dateFrom" id="dateFrom" />
                        <mat-datepicker-toggle matIconSuffix [for]="dateFrom"></mat-datepicker-toggle>
                        <mat-datepicker #dateFrom></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-3 custom-inputs mx-0">
                    <label for="dateTo">{{dateToLabel}}</label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [min]="filtersForm.value.dateFrom" placeholder='Entering Date To'
                            [matDatepicker]="dateTo" formControlName="dateTo" id="dateTo" />
                        <mat-datepicker-toggle matIconSuffix [for]="dateTo"></mat-datepicker-toggle>
                        <mat-datepicker #dateTo></mat-datepicker>

                    </mat-form-field>
                </div>


                <div class="col-md-5">
                    <div class="d-flex row justify-content-center mt-2 pt-4">
                        <button [disabled]="loading" type='submit'
                            class="btn search-btn col-md-5 h4 text-center fw-bold text-white">Search</button>
                        <button type='button'
                            class="btn filters-btn-clear h4 text-center mx-1 col-md-5 text-white fw-bold"
                            (click)="onClearFilters()">Clear</button>
                    </div>
                </div>

            </div>
        </div>

        <!-- Search Box-->
        <div class="row">
            <mat-form-field appearance="fill" class="search-form-field blacklist-list__search-bar">
                <input matInput placeholder="Search by vehicle plate number" formControlName='vehiclePlateNumber'
                    autocomplete="off" [(ngModel)]="searchKey" (keyup)="applyFilter()" />
                <mat-icon matPrefix>search</mat-icon>


                <button matSuffix mat-button mat-icon-button aria-label="Clear" *ngIf="searchKey"
                    (click)="onSearchClear()">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </form>


    <section class="mb-5">
        <div class="mat-elevation-z8 custom-table-card">
            <mat-table [dataSource]="dataSource" multiTemplateDataRows>
                <ng-container matColumnDef="vehiclePlateNumber">
                    <mat-header-cell *matHeaderCellDef>Vehicle Plate No</mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{slicePlateNumber(element.vehiclePlateNumber)}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="operationName">
                    <mat-header-cell *matHeaderCellDef>Operation</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.operationName }}</mat-cell>

                </ng-container>
                <ng-container matColumnDef="detentionCenterName">
                    <mat-header-cell *matHeaderCellDef>Detention</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.detentionCenterName }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="parkingSlot">
                    <mat-header-cell *matHeaderCellDef>Detention Slot No</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.parkingSlot }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="entranceDate">
                    <mat-header-cell *matHeaderCellDef>Entering Date</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.entranceDate |date:'MM/dd/yyyy' }}</mat-cell>
                </ng-container>
                <!-- <ng-container matColumnDef="evidence">
                    <mat-header-cell *matHeaderCellDef> Evidence </mat-header-cell>
                    <mat-cell *matCellDef="let element" class='cursor-pointer '>
                        <a (click)="openImageCarousel(element?.evidences)">{{element?.evidences?.length}}
                            Images</a>
                    </mat-cell>
                </ng-container> -->
                <ng-container matColumnDef="requestStatus">
                    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                    <mat-cell *matCellDef="let element">{{ element.requestStatus }}</mat-cell>
                </ng-container>
                <ng-container matColumnDef="loading">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        LoadingData
                    </mat-footer-cell>
                </ng-container>

                <ng-container matColumnDef="noData">
                    <mat-footer-cell *matFooterCellDef colspan="6">
                        NoData
                    </mat-footer-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"
                    [class.expanded-row]="row.isExpanded">
                </mat-row>

            </mat-table>
            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>




</div>