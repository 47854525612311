import { formatDate, registerLocaleData } from '@angular/common';
import localeSr from '@angular/common/locales/sr';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import { Subject } from 'rxjs/internal/Subject';
import { first, map, takeUntil } from 'rxjs/operators';
import { ShowCitationLocationDialogComponent } from '../../citation-details-dialog/show-citation-location-dialog.component';
import { CitationService } from '../../citation/_services';
import { AdminService } from '../_services/admin.service';

@Component({
  selector: 'app-scan-details-report',
  templateUrl: './scan-details.component.html',
  styleUrls: ['./scan-details.component.scss'],
})
export class ScanDetailsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('violationSelect', { static: true }) violationSelect: MatSelect;
  displayedColumns: string[] = ['index', 'enforcerName', 'plateNumber', 'date', 'time', 'location', 'latitude', 'longitude', 'result'];


  sortBy: string = 'id';
  sortDirection: string = 'asc';
  enforcerName = '';

  selectedDateRange: any[] = [];
  protected _onDestroy = new Subject<void>();

  filteredEnforcerData: any[] = [];
  filteredEnforcerOptions: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  scanDataSource: MatTableDataSource<any>;

  dataSource = [];
  loading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  filterForm: FormGroup;
  scansCount = 0;
  illegalScansCount = 0;
  totalCitations = 0;
  totalIllegalCitations = 0;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private _citationService: CitationService,
    private _adminService: AdminService,
    private _fb: FormBuilder,
    private _dialog: MatDialog
  ) {
    registerLocaleData(localeSr, 'sr');
  }

  ngOnInit(): void {

    this.buildFilterForm();
    this._loadGetEmployeesByNameOrUserNameOrId();
    this.getWorkforceReports();
  }

  buildFilterForm() {

    this.filterForm = this._fb.group({
      roleId: new FormControl(''),
      enforceName: new FormControl(''),
      enforceNameSearch: new FormControl(''),
      operation: new FormControl(''),
      violation: new FormControl(''),
      violationSearch: new FormControl(''),
      employeeName: new FormControl(''),
      startDate: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
      endDate: new FormControl(formatDate(new Date(), 'yyyy-MM-dd', 'en')),
    });

    this.filterForm.get('enforceNameSearch').valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        map(search => {
          return this.filteredEnforcerData.filter(item =>
            (item.name.toLowerCase().indexOf(this.filterForm.get('enforceNameSearch').value.toLowerCase()) > -1) || (item.id === this.filterForm.get('enforceNameSearch').value)
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filtered => {
        this.filteredEnforcerOptions.next(
          filtered
        );
      }, error => {
      });

  }


  clearEnforcer() {
    this.singleSelect.options.forEach((data: MatOption) => data.deselect());
    this.filterForm.get('enforceNameSearch').setValue('');
  }

  private _loadGetEmployeesByNameOrUserNameOrId(keyword: string = ''): void {
    this.loading = true;
    this._citationService.getEmployeesByNameOrUserNameOrId(keyword)
      .pipe(first())
      .subscribe(
        resp => {
          this.filteredEnforcerData = resp.payload.sort((a, b) => a.name > b.name ? 1 : -1);
          this.filteredEnforcerOptions.next(
            this.filteredEnforcerData
          );
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

  getEnforcerName(id: number) {
    const enforcer = this.filteredEnforcerData.filter(item => item.id === id);

    if (enforcer[0]) {
      return enforcer[0].name;

    } else {
      return '';
    }
  }

  onClearFilters() {
    this.buildFilterForm();
    this.getWorkforceReports();
  }

  getWorkforceReports() {

    this.loading = true;

    this._adminService.getScanDetails(this.pageSize, this.currentPage, this.filterForm.get('startDate').value, this.filterForm.get('endDate').value,
      this.enforcerName, this.sortBy, this.sortDirection).subscribe(
        (res) => {

          // console.log(res.payload);
          const scanDetailsResponse = res.payload;
          this.scansCount = scanDetailsResponse.scanDetailsCount;
          this.illegalScansCount = scanDetailsResponse.illegalsCount;
          this.totalCitations = scanDetailsResponse.citationsCount;
          this.totalIllegalCitations = scanDetailsResponse.illegalCitationsCount;
          this.dataSource = scanDetailsResponse.scanDetails;
          this.totalRows = scanDetailsResponse.totalRows;
          this.scanDataSource = new MatTableDataSource<any>([this.dataSource]);

          // console.log('datasource:', this.dataSource);
          // console.log('  this.scanDataSource:', this.scanDataSource);

          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log('err', error);
        }
      );
  }

  pageChanged(event: PageEvent) {
    // console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getWorkforceReports();
  }

  applyFilter(e: Event) {

    this.getWorkforceReports();

  }

  onSearchClear() {
    this.filterForm.patchValue({ employeeName: '' });
    this.currentPage = 0;
    this.getWorkforceReports();
  }

  announceSortChange(sortState: Sort) {

    // console.log('sortState', sortState);
    this.sortBy = sortState.active;
    this.sortDirection = (this.sortDirection === 'desc') ? 'asc' : 'desc';

    this.getWorkforceReports();
  }


  onCitationLocation(latitude, longitude, plateNumberEn) {

    const dialogRef = this.dialog.open(ShowCitationLocationDialogComponent, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '1300px',
      height: '700px',
      data: { latLong: [latitude, longitude], labelName: plateNumberEn, id: 1 }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      this.router.navigateByUrl(`/scan-details`);
    });

  }
}
