<div class="p-0 mx-0 pb-5 mb-5">
    <div class="dialog-header d-flex justify-content-between py-3">
        <h1 class="h3 fw-bold">SC2 Blacklist Report</h1>
    </div>

    <form class="custom-inputs mt-0" [formGroup]="filtersForm" (ngSubmit)='applyFilter()'>
        <div class="row pt-4 pb-0 d-flex">
            <div class="row">
                <div class="col-md-3 custom-inputs mx-0">
                    <label> Operation </label>
                    <mat-form-field appearance="outline" class="w-100 px-0">
                        <mat-select [(value)]="selectedOperationId" placeholder="Choose Operation"
                            formControlName="operation">
                            <mat-option *ngFor="let operation of operations" [value]="operation.id">{{operation.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-md-3 custom-inputs mx-0">
                    <label for="dateFrom">
                        Start Date
                    </label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [matDatepicker]="dateFrom" placeholder='dd/mm/yyyy' formControlName="dateFrom"
                            id="dateFrom" />
                        <mat-datepicker-toggle matIconSuffix [for]="dateFrom"></mat-datepicker-toggle>
                        <mat-datepicker #dateFrom></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-md-3 custom-inputs mx-0">
                    <label for="dateTo">End Date</label>
                    <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
                        <input matInput [min]="filtersForm.value.dateFrom" placeholder='dd/mm/yyyy'
                            [matDatepicker]="dateTo" formControlName="dateTo" id="dateTo" />
                        <mat-datepicker-toggle matIconSuffix [for]="dateTo"></mat-datepicker-toggle>
                        <mat-datepicker #dateTo></mat-datepicker>

                    </mat-form-field>
                </div>

                <div class="col-md-3 custom-inputs mx-0 pt-1">
                    <p> Date Criteria </p>
                    <div class='mt-4 row w-100'>
                        <mat-radio-group formControlName="dateCriteria" class='d-flex justify-content-between'>
                            <mat-radio-button class="mat-radio-label-custom" value="creation">Citation
                                Creation</mat-radio-button>
                            <mat-radio-button class="mat-radio-label-custom px-3" value="closure">Citation
                                Closure</mat-radio-button>
                        </mat-radio-group>
                    </div>

                </div>
                <div class="row d-flex justify-content-end">
                    <div class="col-md-5">
                        <div class="d-flex row pt-3">
                            <button [disabled]="loading" type='submit'
                                class="btn search-btn col-md-5 h4 text-center fw-bold text-white">Search</button>
                            <button type='button'
                                class="btn filters-btn-clear h4 text-center mx-1 col-md-5 text-white fw-bold"
                                (click)="onClearFilters()">Clear</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form>

    <section class="mb-5">
        <div class="mat-elevation-z8 custom-table-card">
            <table class="col-12 rounded bordered-table mt-3">
                <thead class="rounded tableHead">
                    <tr class="text-white">
                        <th class="py-3 px-4" style="width: 25%;">Vehicle Plate Number</th>
                        <th class="py-3 px-4" style="width: 25%;">Request Type</th>
                        <th class="py-3 px-4" style="width: 20%;"></th>
                    </tr>
                </thead>
                <tbody class="">
                    <ng-container *ngFor="let data of dataList; let i = index">
                        <tr class=" cursor-pointer" [ngClass]='isAccordionOpen[i]?"":"last-row"'
                            (click)="toggleAccordion(i)">
                            <td class="py-2 px-4">{{ data.vehiclePlateNumberEn }}</td>
                            <td class="py-2 px-4">{{ data.requestType }}</td>
                            <td class="py-2 px-4">
                                <button mat-icon-button class="btnColor fw-bold h3">
                                    <mat-icon>{{ isAccordionOpen[i]
                                        ?'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
                                </button>
                            </td>

                        </tr>
                        <ng-container class="">
                            <tr class="accordion-row w-100 " [ngClass]='isAccordionOpen[i]?"last-row":""'>

                                <td colspan='3' class='w-100'>
                                    <div class="accordion-content w-100 mx-4" [hidden]="!isAccordionOpen[i]">
                                        <table class="w-100 py-1 tableRow mb-2">
                                            <thead>
                                                <tr>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Citation Number
                                                    </th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Violation</th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Total Citation</th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Penalty Request
                                                        Type
                                                    </th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Insertion Date</th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Status</th>
                                                    <th class="btnColor fw-bold fs-6 pt-3 pb-2 px-3">Closure Date</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of data.blackListDetailsSearchCriteriaResponses">
                                                    <td class="fs-6 py-2 px-3">{{ item.citationNumber }}</td>
                                                    <td class="fs-6 py-2 px-3">{{ item.violation }}</td>
                                                    <td class="fs-6 py-2 px-3">{{item.totalCitation}} {{
                                                        (item.totalCitation!=null) ? 'SAR':''}} </td>
                                                    <td class="fs-6 py-2 px-3">{{ item.penaltyRequestType }}</td>
                                                    <td class="fs-6 py-2 px-3">{{ item.insertionDate |date:'MM/dd/yyyy'
                                                        }}</td>
                                                    <td class="fs-6 py-2 px-3">{{ item.status }}</td>
                                                    <td class="fs-6 py-2 px-3">{{ item.closureDate |date:'MM/dd/yyyy' }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container>
                </tbody>
            </table>

            <mat-paginator class="mat-paginator-sticky" [pageSizeOptions]="[5, 10, 20, 50,100]" showFirstLastButtons
                [length]="totalRows" showFirstLastButtons [pageIndex]="currentPage" [pageSize]="pageSize"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>
        <mat-spinner *ngIf="loading"></mat-spinner>

    </section>




</div>