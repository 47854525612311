import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { AdminService } from '../../_services/admin.service';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  showPassword: boolean = true;
  showConfirmPassword: boolean = true;

  submitted = false;
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: FormBuilder,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]

    }, { validator: MustMatch('password', 'confirmPassword') })
  }

  onTogglePassword() {
    this.showPassword = !this.showPassword;
  }
  onToggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }
  onSubmit() {
    this.submitted = true;
    if (!this.changePasswordForm.valid) return;
    this.loading = true;
    const params: any = {
      id: this.data.id,
      password: this.changePasswordForm.controls.password.value,
    }
    this.adminService.changePassword(params).subscribe(resp => {
      this.loading = false;
      this.dialogRef.close();
    },
      error => {
        this.loading = false;
      }
    )
  }

  onCancel() {
    this.dialogRef.close();
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.changePasswordForm.controls[controlName].hasError(errorName) : false;
  }


}

// custom validator to check that two fields match

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }
    // set error on matchingControl if validation fails

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  }

}