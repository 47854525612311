<section class="login mx-auto">
    <img class="login__shapes ellipse-1" src="../../../assets/Icons/Ellipse 1.svg" />
    <img class="login__shapes ellipse-2" src="../../../assets/Icons/Ellipse 2.svg" />

    <section class="row g-0 login__content-container">
        <div class="col-6">
            <img class="login__logo" src="../../../assets/Icons/thakiOperationLogo.svg" alt="" />
            <h3 class="login__title"> Create New Password </h3>

            <!-- new-pass-form -->
            <form class="example-form" [formGroup]="newPasswordForm" (ngSubmit)="confirmPass()">
                <div class="col-12">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Password</mat-label>
                        <input matInput placeholder="enter your username" name="password" id="pass"
                            formControlName="password" [type]="hidePassword? 'password': 'text'">
                        <i class="material-icons" matSuffix (click)="hidePassword = !hidePassword">{{hidePassword?
                            'visibility':
                            'visibility_off'}}</i>
                        <mat-error *ngIf="newPasswordForm.controls.password.errors">
                            Password is required
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field class="full-width" appearance="fill">
                        <mat-label>Confirm Password</mat-label>
                        <input matInput type="password" name="confirmPass" id="confirmPass"
                            formControlName="confirmPassword" placeholder="enter your password"
                            [type]="hideConfirmPassword? 'password': 'text'">
                        <i class="material-icons" matSuffix
                            (click)="hideConfirmPassword = !hideConfirmPassword">{{hideConfirmPassword? 'visibility':
                            'visibility_off'}}</i>
                        <mat-error *ngIf="newPasswordForm.controls.confirmPassword.errors.confirmPasswordValidator">
                            Passsword and Confirm Password didn't match.
                        </mat-error>
                    </mat-form-field>
                </div>

                <button type="submit" class="btn login__btn full-width mt-2">Confirm</button>
            </form>
        </div>
    </section>
</section>