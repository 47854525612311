
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class BlacklistService {
    constructor(private _http: HttpClient) {
    }
    getList(page: number, limit: number, query: string = '', sortingType: number = 1, sortingBy: string = 'id') {
        let api = `${environment.baseURL}enforcements/blackListedVehicles?page=${page}&size=${limit}&sortingType=${sortingType}&sortingBy=${sortingBy}`;
        if (query)
            api += `&keyword=${query}`;
        return this._http.get<any>(api);
    }
    
    getDetails(id: number) {
        const api = `${environment.baseURL}enforcements/blackListedVehicles/${id}`;
        return this._http.get<any>(api);
    }
    update(data) {
        // const api = `${environment.baseURL}enforcements/penalties/edit`;
        // return this._http.put<any>(api, data);
    }
    create(data) {
        const api = `${environment.baseURL}enforcements/blackListedVehicles`;
        return this._http.post<any>(api, data);
    }
    delete(id: number) {
        const api = `${environment.baseURL}enforcements/blackListedVehicles/${id}`;
        return this._http.delete<any>(api);
    }

    getVehicleCountries() {
        let api = `${environment.baseURL}vehicles/countries`;
        return this._http.get<any>(api);
    }

    getVehicleMakes() {
        let api = `${environment.baseURL}vehicles/makes`;
        return this._http.get<any>(api);
    }

    getVehicleColors() {
        let api = `${environment.baseURL}vehicles/colors`;
        return this._http.get<any>(api);
    }

    getPenaltiesList() {
        let api = `${environment.baseURL}enforcements/penalties`;
        return this._http.get<any>(api);
    }

}
