import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { AuthorizationService } from 'src/app/_services';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  submitted = false;
  loading = false;

  form: FormGroup;
  get f() { return this.form.controls; }
  type: string = 'view';

  currentUser;
  constructor(
    private _formBuilder: FormBuilder,
    public AuthorizationService: AuthorizationService
  ) {
    this.currentUser = this.AuthorizationService.getCurrentUser().employeeDto;
  }

  ngOnInit(): void {
    console.log();
    this.form = this._formBuilder.group({
      id: [],
      email: [
        { value: this.currentUser.email, disabled: (this.type == 'view') ? true : false },
        [
          Validators.required,

        ]
      ],
      name: [
        { value: this.currentUser.name, disabled: (this.type == 'view') ? true : false },
        [
          Validators.required,

        ]
      ],
    });
  }


  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.form.controls[controlName].hasError(errorName) : false;
  }

  onSubmit() {

    // this.submitted = true;
    // // stop here if form is invalid
    // if (this.form.invalid) return;

    // this.loading = true;


  }
}
