import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { CitationReportListService, SelectDropdownIdName } from 'src/app/citation/_services';
import { sc2BlacklistReportPayloadModel, sc2BlacklistReportResponseModel } from '../models/sc2Blacklist-model';
import { ReportsService } from '../reports.service';
ReportsService
@Component({
  selector: 'app-sc-blacklist-report',
  templateUrl: './sc-blacklist-report.component.html',
  styleUrls: ['./sc-blacklist-report.component.scss']
})
export class ScBlacklistReportComponent implements OnInit {

  constructor(private ReportsService: ReportsService, private _citationReportListService: CitationReportListService
  ) { }
  filtersForm: FormGroup;
  selectedOperationId: string = '';
  loading = false;
  operations: SelectDropdownIdName[] = [];
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataList: sc2BlacklistReportResponseModel[];
  dataSource: MatTableDataSource<sc2BlacklistReportResponseModel>;
  isExpansion: boolean = true;
  isAccordionOpen: boolean[] = [];

  ngOnInit(): void {
    this.editFormInit();
    this._loadOperationList();
    this.applyFilter();
  }

  private _loadOperationList() {
    if (this.operations.length > 1)
      return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }
  private editFormInit(): void {
    this.filtersForm = new FormGroup({
      dateCriteria: new FormControl(null),
      operation: new FormControl(null),
      dateFrom: new FormControl(null),
      dateTo: new FormControl(null),
      searchValue: new FormControl(null),
    });
  }
  onClearFilters() {
    this.filtersForm.controls.dateCriteria.setValue(null);
    this.filtersForm.controls.dateFrom.setValue(null);
    this.filtersForm.controls.dateTo.setValue(null);
    this.filtersForm.controls.searchValue.setValue(null);
    this.filtersForm.controls.operation.setValue(null);
    // this.filtersForm.controls.vehiclePlateNumber.setValue(null);
    this.selectedOperationId = null;
    this.applyFilter();
  }

  applyFilter() {
    if (this.filtersForm.invalid) {
      const formValues = this.filtersForm.value
      // console.log(formValues);
      return;
    }
    this.isAccordionOpen = [];
    const formValues = this.filtersForm.value
    // console.log(formValues);
    this._loadList(this.currentPage, this.pageSize, formValues);
  }
  private _loadList(page: number, limit: number, formValues: any) {
    this.loading = true;
    const currentDate = new Date();
    let dateFrom, dateTo;
    if (formValues.dateFrom) {
      const inputDate = new Date(formValues.dateFrom);
      dateFrom = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateFrom = new Date(dateFrom).toISOString();
    }
    if (formValues.dateTo) {
      const inputDate = new Date(formValues.dateTo);
      dateTo = Date.UTC(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
      dateTo = new Date(dateTo).toISOString();
    }

    let params: sc2BlacklistReportPayloadModel = {
      operationId: this.selectedOperationId ? Number(this.selectedOperationId) : null,
      startDate: formValues.dateFrom ? dateFrom : currentDate,
      endDate: formValues.dateTo ? dateTo : currentDate,
      isCitationCreation: formValues.dateCriteria == 'creation' ? true : false,
      isCitationClosure: formValues.dateCriteria == 'closure' ? true : false,
      pageSize: limit,
      pageNumber: page
    }

    this.ReportsService.getSc2BlacklistReport(params)
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<sc2BlacklistReportResponseModel>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  slicePlateNumber(plateNumber: string) {
    return `${plateNumber.slice(0, 4)} ${plateNumber.slice(4, 7)}`;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const formValues = this.filtersForm.value
    console.log(formValues);
    this._loadList(this.currentPage, this.pageSize, formValues);
  }

  toggleAccordion(index: number) {
    this.isAccordionOpen[index] = !this.isAccordionOpen[index];
  }


}

