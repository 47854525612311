<!-- Dialog header -->
<div class="dialog-title">
  <h3 mat-dialog-title>Update Vehicle</h3>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <!-- Evidence images section -->
  <section *ngIf="images?.length">
    <h3 class="fw-bold">Evidences</h3>
    <div class="evidence-container">
      <div class="evidence-preview">
        <img
          [src]="selectedImage || images[0]?.path"
          alt="Evidence"
          class="main-image"
          (error)="$event.target.style.display = 'none'"
        />
      </div>
      <div class="evidence-thumbnails">
        <img
          *ngFor="let img of images"
          [src]="img?.path"
          alt="Thumbnail"
          class="thumbnail"
          (click)="selectedImage = img?.path"
          [class.active]="selectedImage === img?.path"
          (error)="$event.target.style.display = 'none'"
        />
      </div>
    </div>
  </section>

  <!-- Plate number section -->
  <section class="mt-3">
    <h3 class="fw-bold">Plate Number</h3>
    <p>{{ data?.enPlateNumber }}</p>
  </section>

  <!-- Registration type selection section -->
  <section class="mt-3">
    <h3 class="fw-bold">
      Registration Type <span class="text-danger">*</span>
    </h3>
    <mat-form-field appearance="fill" class="dropdown-field">
      <mat-label>Registration Type</mat-label>
      <mat-select [(ngModel)]="selectedRegistrationType">
        <mat-option
          *ngFor="let type of registrationTypes"
          [value]="type?.typeCode"
        >
          <span
            class="registration-type-indicator-dd"
            [ngStyle]="{ 'background-color': '#' + type?.colorCode }"
          ></span>
          {{ type?.typeEN }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
</div>

<!-- Dialog action buttons -->
<div mat-dialog-actions>
  <button class="btn clear-btn fw-bold" (click)="onCancel()">Cancel</button>
  <button
    *ngIf="data?.citationStatus === 'Opened'"
    class="btn void-btn fw-bold"
    (click)="onVoid()"
  >
    Void
  </button>
  <button
    [disabled]="!selectedRegistrationType"
    class="btn submit-btn fw-bold"
    (click)="onSend()"
  >
    Send
  </button>
</div>
