import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PhoneNumberUtil } from 'google-libphonenumber';

import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input';
import { first } from 'rxjs/operators';
import { DialogConfirmation } from '../_shared/dialog-confirmation/dialog-confirmation';
import { AdminService } from '../admin/_services/admin.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-create-workforce-member',
  templateUrl: './create-workforce-member.component.html',
  styleUrls: ['./create-workforce-member.component.scss'],
})
export class CreateWorkforceMemberComponent implements OnInit {
  roles: string[] = [];
  Member: FormGroup;
  fileName = '';
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.SaudiArabia, CountryISO.Egypt];
  created: boolean = false;
  failed: boolean = false;
  createdMsg: string = '';
  id;
  title: string = "Create workforce member ";
  submitted = false;
  loading: boolean = false;
  details: any;
  memberImage: any;
  imageUrl: any;

  mobileNumValidMsg!: boolean;
  get f() { return this.Member.controls; }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.SaudiArabia, CountryISO.Egypt];
  }

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private http: DataService,
    private adminService: AdminService,
    private _activatedRoute: ActivatedRoute,
    private _dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer
  ) {
    this.id = this._activatedRoute.snapshot.params.id;
    if (this.id) {
      this._loadDate(this.id);
    }
  }

  ngOnInit(): void {
    this.generateWorkforceForm();
    this.getMemberRoles();
    if (this.id === undefined) {
      this.title = 'Create workforce member'
    } else {
      this.title = 'Edit workforce member';
    }
  }


  goBack() {
    this.location.back();
  }

  getMemberRoles() {
    this.adminService.getRoles().subscribe((res) => {
      // console.log(res);
      this.roles = res.payload
    });
  }

  generateWorkforceForm() {
    this.Member = this.fb.group({
      fullName: ['', Validators.required],
      memberEmail: [{ value: '', disabled: this.id ? true : false }, [Validators.required, Validators.email]],
      memberPhone: [{ number: '', countryCode: '', dialCode: '' }, [Validators.required]],
      memberRole: ['', Validators.required],
      memberImage: [''],
    });
  }

  validateMobileNumber(mobileNumber: any) {
    let isValid!: boolean;
    this.mobileNumValidMsg = false;

    if (mobileNumber) {
      let { internationalNumber, number, dialCode } = mobileNumber;

      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parseAndKeepRawInput(
        internationalNumber,
        'US'
      );
      if (number.match(/^(057|57)[459]\d{6}$/)) {
        (dialCode == "+966") ? isValid = true : isValid = false;
      }
      else {
        isValid = phoneUtil.isValidNumber(phoneNumber);
      }

      if (isValid) {
        this.mobileNumValidMsg = false;
      } else {
        this.mobileNumValidMsg = true;
      }
    }
    else {
      isValid = false;
    }

    if (this.mobileNumValidMsg) {
      const errors = { 'invalidPhoneNumber': true };
      this.Member.controls.memberPhone.setErrors(errors);
    }
    return isValid;
  }

  onFileSelected(event) {
    if (event.target.files.length > 0) {
      const file: File = event.target.files[0];
      let reader = new FileReader();

      if (file) {
        this.fileName = file.name;
        const formData = new FormData();
        formData.append('thumbnail', file);

        reader.onloadend = () => {
          this.imageUrl = reader.result;
        }
        reader.readAsDataURL(file);

        this.Member.controls.memberImage.setValue(file);
      }
    }
  }

  getErrorMessage() {
    if (this.Member.controls.memberEmail.hasError('required')) {
      return 'You must enter a value';
    }
    return this.Member.controls.memberEmail.hasError('email')
      ? 'Not a valid email'
      : '';
  }

  getPhoneErrorMessage() {
    if (this.Member.controls.memberPhone.hasError('required')) {
      return 'You must enter a value';
    }
    return this.Member.controls.memberPhone.hasError('')
      ? 'Please enter valid phone'
      : '';
  }
  private _loadDate(id) {
    this.loading = true;

    this.adminService.getEmployeeDetails(id).subscribe(res => {
      // console.log(res.payload);
      this.details = res.payload;
      this.f['fullName'].setValue(this.details.name);
      this.f['memberEmail'].setValue(this.details.email);
      this.f['memberPhone'].setValue(this.details.phone);
      this.f['memberRole'].setValue(this.details.role.name);
      this.cd.detectChanges();
      this.loading = false;

    }),
      this.http.getImage(this.id).pipe(first()).subscribe(res => {
        // console.log(res);
        this.memberImage = res.payload;
        if (res.payload) {
          // this.f['memberImage'].patchValue(this.memberImage);
          this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + res.payload);
        }
        this.loading = false;

      })

  }
  createMember() {
    this.submitted = true;
    if (this.Member.invalid) return;

    if (this.Member.value['image'] == '')
      delete this.Member.value['image'];


    this.loading = true;
    const validPhone = this.validateMobileNumber(this.Member.value['memberPhone']);
    if (validPhone) {
      if (this.id === undefined) {

        console.log("create workforce this.Member.value", this.Member.value);
        this.http.createWorkforce(this.Member.value).subscribe(
          (res) => {
            if (res.success === true) {
              this.createdMsg = res.payload;
              this.failed = false;
              this.created = true;
              this.loading = false;
              const dialogRef = this._dialog.open(DialogConfirmation, {
                panelClass: 'custom-mat-show-image-dialog-container',
                width: '468px',
                data: {
                  title: { title: "Success", color: "#481A90" },
                  body: res.payload,
                  btnConfirmation: { title: "Ok", color: "#481A90" },
                  btnClose: { hide: true, title: "close" },
                  comment: false
                }
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result)
                  this.goBack();
              });
            }
          },
          (error) => {
            this.createdMsg = (error.error) ? error.error.error.message : 'Something went wrong';
            this.created = false;
            this.failed = true;
            this.loading = false;
          }
        );
      } else {

        // const formData = new FormData();
        // formData.append('excel', this.Member.get());
        const imageParam = { image: this.Member.get('memberImage').value ? this.Member.get('memberImage').value : '' }

        // console.log('imageParam ', imageParam);
        if (imageParam.image)
          this.http.updateImage(imageParam, this.details.id).pipe(first()).subscribe(data => {
            // console.log("update workforce image", data);
          });
        const data = {
          name: this.f.fullName.value,
          phone: this.f.memberPhone.value.number,
          username: this.details.username,
          id: this.details.id,
          roleName: this.f.memberRole.value,
          operationId: this.details.operationId,
          operationName: this.details.operationName
        };

        this.http.updateWorkforce(data).pipe(first()).subscribe(
          data => {
            // console.log("Update workforce", data);
            const dialogRef = this._dialog.open(DialogConfirmation, {
              panelClass: 'custom-mat-show-image-dialog-container',
              width: '468px',
              data: {
                title: { title: "Success", color: "#481A90" },
                body: data.payload,
                btnConfirmation: { title: "Ok", color: "#481A90" },
                btnClose: { hide: true, title: "close" },
                comment: false
              }
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result)
                this.goBack();
            });
          },
          error => {
            this.loading = false;
          }
        )
      }
    }

  }
}
