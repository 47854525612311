import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ErrorInterceptor, ExpireInterceptor, JwtInterceptor } from './_interceptors';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateWorkforceMemberComponent } from './create-workforce-member/create-workforce-member.component';
import { DataService } from './data.service';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginModule } from './login/login.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { RoleCreationComponent } from './role-creation/role-creation.component';

// import { MatDatepicker } from '@angular/material/datepicker';
// import { AngularFireModule } from '@angular/fire';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { ChartsModule } from 'ng2-charts';
import { DialogConfirmation } from './_shared/dialog-confirmation/dialog-confirmation';
import { AssignShiftMembersComponent } from './assign-shift-members/assign-shift-members.component';
import { CitationModule } from './citation/citation.module';
import { CreateShiftComponent } from './create-shift/create-shift.component';
import { DailyMonitorModule } from './daily-monitor/daily-monitor.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ManageRolesListComponent } from './manage-roles-list/manage-roles-list.component';
import { ShiftDetailsCardComponent } from './shift-details-card/shift-details-card.component';
import { ShiftDetailsComponent } from './shift-details/shift-details.component';
import { ShiftModule } from './shift/shift.module';
import { ShiftsSchedulerComponent } from './shifts-scheduler/shifts-scheduler.component';
import { ViolationsModule } from './violations/violations.module';

import { BlacklistModule } from './blacklist/blacklist.module';
import { MyAccountModule } from './my-account/my-account.module';

// import { MatInputRequiredDirective } from './_directives/mat-input-required.directive';
// import { SharedDirectiveModule } fro./_directives/shared-directive.moduleule';
import { WhitelistModule } from './whitelist/whitelist.module';
// import { FilterListComponent } from './_shared/filter-list/filter-list.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!
import timeGrigPlugin from '@fullcalendar/timegrid'; // a plugin!
import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './_shared/shared.module';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AngularFireModule } from '@angular/fire';
// import { MatDrawerModule } from '@angular/material/drawer';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { environment } from '../environments/environment';
import { BootRequestModule } from './boot-requets/boot-requets.module';
import { CalendarComponent } from './calendar/calendar.component';
import { ShowCitationLocationDialogComponent } from './citation-details-dialog/show-citation-location-dialog.component';
import { CitationsInvoicesDialogComponent } from './citation/_shared/citations-invoices/citations-invoices-dialog.component';
import { ShowCitationDetailsDialogComponent } from './citation/citation-list/citation-details-dialog/show-citation-details-dialog.component';
import { PrintInvoiceComponent } from './citation/print-invoice/print-invoice.component';
import { ManageRequestsComponent } from './requests-map/manage-requests/manage-requests.component';
import { IdleWorkforceDialogComponent } from './shift-details/idle-workforce-dialog/idle-workforce-dialog.component';
import { RemoveWorkforceDialogComponent } from './shift-details/remove-workforce-dialog/remove-workforce-dialog.component';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGrigPlugin,
  interactionPlugin
]);

// import { initializeApp } from "firebase/app";
// initializeApp(environment.firebaseConfig);
@NgModule({
  declarations: [
    ManageRequestsComponent,
    AppComponent,
    RoleCreationComponent,
    CreateWorkforceMemberComponent,
    PageNotFoundComponent,
    HomePageComponent,
    ShiftsSchedulerComponent,
    CreateShiftComponent,
    ShiftDetailsCardComponent,
    ManageRolesListComponent,
    ShiftDetailsComponent,
    AssignShiftMembersComponent,
    DialogConfirmation,
    CalendarComponent,
    RemoveWorkforceDialogComponent,
    IdleWorkforceDialogComponent,
    ShowCitationDetailsDialogComponent,
    ShowCitationLocationDialogComponent,
    CitationsInvoicesDialogComponent,
    PrintInvoiceComponent
  ],
  imports: [
    MatCarouselModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    ToastrModule.forRoot(), // ToastrModule added
    BootRequestModule,
    BrowserModule,
    AppRoutingModule,
    AdminModule,
    LoginModule,
    CitationModule,
    DashboardModule,
    ViolationsModule,
    ShiftModule,
    DailyMonitorModule,
    MyAccountModule,
    BlacklistModule,
    MatIconModule,
    MatSortModule,
    MatTableModule,
    // SidebarModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxIntlTelInputModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatNativeDateModule,
    MatListModule,
    MatGridListModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatExpansionModule,
    NgxQRCodeModule,
    MatCardModule,
    ChartsModule,
    MatProgressSpinnerModule,
    WhitelistModule,
    MatMenuModule,
    MatToolbarModule,
    SharedModule,
    FullCalendarModule,
    ScrollingModule,
    ClipboardModule
  ],
  // exports: [MatInputRequiredDirective],
  providers: [
    DataService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExpireInterceptor, multi: true },


  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
