import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEquipmentComponent } from './add-equipment/add-equipment.component';
import { HandoverEquipmentComponent } from './handover-equipment/handover-equipment.component';
import { ListEquipmentsComponent } from './list-equipments/list-equipments.component';
import { ReclaimEquipmentComponent } from './reclaim-equipment/reclaim-equipment.component';
const routes: Routes = [
    { path: 'manage-equipments', component: ListEquipmentsComponent },
    { path: 'handover-equipment/:id/:name', component: AddEquipmentComponent },
    { path: 'add-equipment', component: AddEquipmentComponent },
    { path: 'handover-equipments', component: HandoverEquipmentComponent },
    { path: 'reclaim-equipments', component: ReclaimEquipmentComponent }



];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EquipmentsRoutingModule { }
