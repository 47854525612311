import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShiftSchedulerService {
  shiftEmployeesList: any[] = [];

  constructor(private _http: HttpClient) {
  }

  getRoleShifts(startDate: string, endDate: string) {
    return this._http.get<any>(
      `${environment.baseURL}operation/roles/shifts?startDate=${startDate}&endDate=${endDate}`);
  }

  setShiftEmployeesList(list) {
    this.shiftEmployeesList = list;
  }

  getShiftEmployeesList() {
    return this.shiftEmployeesList;
  }

  getFreeWorkforces(
    startDate,
    endDate,
    startTime,
    endTime,
    roleName
  ): Observable<any> {
    return this._http.get(
      `${environment.baseURL}operation/employee/getFreeWorkForces?startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}&roleName=${roleName}`
    );
  }

  getFreeWorkforcesByName(startDate,
    endDate,
    startTime,
    endTime,
    roleName,
    employeeName): Observable<any> {
    return this._http.get(
      `${environment.baseURL}operation/employee/getFreeWorkForces?startDate=${startDate}&endDate=${endDate}&startTime=${startTime}&endTime=${endTime}&roleName=${roleName}&employeeName=${employeeName}`
    );
  }

  getSupervisors(): Observable<any> {
    return this._http.get(
      `${environment.baseURL}operation/employee/getSupervisors`
    );
  }

  createNewShift(shiftData): Observable<any> {
    const {
      shiftName,
      shiftStartDate,
      shiftEndDate,
      shiftStartTime,
      shiftEndTime,
      shiftRoleName,
      shiftSuperVisorList,
    } = shiftData;

    return this._http.post(
      `${environment.baseURL}operation/shift/`,
      {
        name: shiftName,
        startDate: `${shiftStartDate}`,
        endDate: `${shiftEndDate}`,
        startTime: shiftStartTime,
        endTime: shiftEndTime,
        roleName: shiftRoleName,
        superVisorList: shiftSuperVisorList,
      }
    );
  }

  getShiftDetail(request): Observable<any> {
    const { startDate, endDate, startTime, endTime, roleName, shiftName, shiftId } = request;
    const api = `${environment.baseURL}operation/shift/getShiftToUpdate?startDate=${startDate}&startTime=${startTime.trim()}&endDate=${endDate}&endTime=${endTime}&roleName=${roleName}&shiftName=${shiftName}&shiftId=${shiftId}`;
    return this._http.get(api);
  }

  deleteShift(shiftId): Observable<any> {
    return this._http.delete(
      `${environment.baseURL}operation/shift/${shiftId}`
    );
  }

  updateShiftDate(ID, Starting, Ending): Observable<any> {
    return this._http.put(
      `${environment.baseURL}operation/shift/`,
      {
        id: ID,
        startDate: Starting,
        endDate: Ending,
      }
    );
  }

  updateShiftDetails(ID, Starting, ShiftDetails): Observable<any> {
    return this._http.put(
      `${environment.baseURL}operation/shift/updateShiftDetails`,
      {
        shiftId: ID,
        day: Starting,
        workforceShiftDetails: ShiftDetails,
      }
    );
  }

  idleWorkforceMember(shiftData): Observable<any> {
    return this._http.put(
      `${environment.baseURL}operation/shift/workforce/idle`, shiftData
    );
  }

  removeFromShift(shiftData): Observable<any> {
    return this._http.put(
      `${environment.baseURL}operation/shift/workforce/removeFromShift`, shiftData
    );
  }

  updateShiftDateAndTime(params): Observable<any> {
    return this._http.put(`${environment.baseURL}operation/shift/updateShiftDateOrTime`, params);
  }
}


