import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { notificationReportResponseModel } from '../models/sc2Blacklist-model';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-dragging-notification',
  templateUrl: './dragging-notification.component.html',
  styleUrls: ['./dragging-notification.component.scss'],

})
export class DraggingNotificationComponent implements OnInit {
  filtersForm: FormGroup;
  loading = false;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataList: notificationReportResponseModel[];
  dataSource: MatTableDataSource<notificationReportResponseModel>;

  constructor(
    private reportsService: ReportsService,
    private fb: FormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.filtersFormInit();
    this.applyFilter();
  }

  filtersFormInit() {
    this.filtersForm = this.fb.group({
      citationId: [],
      isSent: [true],
      // notificationType:[''],
      fromDate: [null],
      toDate: [null],
      plateNumber: [],
    });
  }

  applyFilter() {
    const formValues = this.filtersForm.value;
    this.getDataList(this.currentPage, this.pageSize, formValues);
  }

  numbersOnlyValidator(event: any): void {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    }
  }

  getDataList(page: number, limit: number, formValues: any) {
    this.loading = true;
    let fromDate = formValues.fromDate ? new Date(formValues.fromDate) : null;
    let toDate = formValues.toDate ? new Date(formValues.toDate) : null;
    
    if (toDate) {
      toDate.setHours(23, 59, 59, 999); // Set to the end of the day
    }
    const params = {
      citationId: formValues.citationId,
      isSent: formValues.isSent,
      plateNumber: formValues.plateNumber,
      notificationType: 'DRAGGING',
      fromDate: fromDate ? this.formatDate(fromDate) : null,
    toDate: toDate ? this.formatDate(toDate) : null,
      page: page,
      limit: limit,
    };

 // Filter out null or empty parameters
 const filteredParams = Object.keys(params)
 .filter(key => params[key] !== null && params[key] !== undefined && params[key] !== '')
 .reduce((obj, key) => {
   obj[key] = params[key];
   return obj;
 }, {});

this.reportsService.getNotificationReport(filteredParams).subscribe({
 next: (res) => {
   this.dataList = res?.content;
   this.totalRows = res?.totalElements;
   this.dataSource = new MatTableDataSource<notificationReportResponseModel>(this.dataList);
   this.loading = false;
 },
 error: (err) => {
   this.loading = false;
   console.log('notification reports fetching data error', err);
 },
});
  }

  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss');
  }

  onClearFilter() {
    this.filtersForm.reset({
      isSent: false //when reset make it false as it's default value is true
    });
  }

  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    const formValues = this.filtersForm.value;
    console.log(formValues);
    this.getDataList(this.currentPage, this.pageSize, formValues);
  }
}
