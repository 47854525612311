import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router'
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { Calendar, EventInput } from '@fullcalendar/core';
import { INITIAL_EVENTS, createEventId } from './event-utils';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import Tooltip from 'tooltip.js';

import * as moment from 'moment';
import { ShiftSchedulerService } from '../_services/shiftScheduler.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class CalendarComponent implements OnInit {

  tooltip: Tooltip;

  @ViewChild('fullcalendar') calendarComponent: FullCalendarComponent;
  today = new Date();
  firstDayOfMonth = this._dateFormat(new Date(this.today.getFullYear(), this.today.getMonth(), 1));
  lastDayOfMonth = this._dateFormat(new Date(this.today.getFullYear(), this.today.getMonth() + 1, 0));
  // firstDayOfMonth;
  // lastDayOfMonth;

  loading: boolean = false;
  events: any;
  calendarApi: any;
  groupRolesList: any = [];
  constructor(
    private _shiftSchedulerService: ShiftSchedulerService,
    private router: Router) {
    const name = Calendar.name;
  }

  dataList = [];

  rolesLeagend = [];
  colors = [
    '#E87A00', '#045174', '#145DA0', '#0C2D48', '#590C69', '#D89C60'
  ];

  ngOnInit(): void {
    this._loadRoleShiftsData(this.firstDayOfMonth, this.lastDayOfMonth);
  }

  private _dateFormat(d) {
    const day = (d.getDate() < 10) ? `0${d.getDate()}` : d.getDate();
    const month = ((d.getMonth() + 1) < 10) ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  }

  private _loadRoleShiftsData(start: string, end: string): any {
    if (!start || !end) return false;

    this.loading = true;
    this._shiftSchedulerService.getRoleShifts(start, end).subscribe(
      (data: any) => {
        this.loading = false;
        this.dataList = [];
        this.rolesLeagend = [];
        Object.entries(data.payload.content).map((item, idx) => {
          console.log("item, idx ", item, idx);
          let shifts: any = item[1];

          const data = shifts.map(shift => {
            shift['roleName'] = item[0];
            return shift;
          });

          this.rolesLeagend.push({
            name: item[0], color: this.colors[idx]
          });

          console.log("rolesLeagend  ", this.rolesLeagend);


          // console.log("data ", data);
          // item[1]['roleName'] = item[0];
          this.dataList = this.dataList.concat(data);
        });

        console.log('_loadRoleShiftsData this.dataList === ', this.dataList);

        if (this.dataList.length) {
          this.calendarOptions.events = this.dataList.map(
            evt => {
              console.log(evt.roleName);

              return {
                date: evt.day,
                title: evt.name.concat(" (" + evt.workforceNumber + ")"),
                data: evt,
                roleName: evt.roleName,
                start: evt.day + "T" + evt.shiftStartTime,
                end: evt.day + "T" + evt.shiftEndTime,
                background: this.getColor(evt.roleName)


              }
            }
          )


        }

      }, (error) => {
        this.loading = false;
      }
    )
  }

  calendarVisible = true;
  calendarOptions: CalendarOptions = {
    plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin],
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      // right: 'dayGridMonth',
      right: 'dayGridMonth,timeGridWeek,timeGridDay'
    },
    initialDate: new Date().toISOString(),
    initialView: 'dayGridMonth',
    // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: true,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: false,
    firstDay: 2,
    displayEventEnd: true,
    displayEventTime: true,
    nextDayThreshold: '00:00',
    allDaySlot: false,
    forceEventDuration: true,
    eventTimeFormat: { // TimeGrid views. '7:00'
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false
    },
    // select: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this),
    eventColor: '#6c757d',
    // eventDisplay: 'auto',
    // eventTextColor: '#FFFFFF',
    datesSet: (dateInfo => {
      {
        console.log('dateInfo', dateInfo);

        const firstDayOfMonth:any = dateInfo.view.currentStart;
        const lastDayOfMonth:any = dateInfo.view.currentEnd;
        lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);

        this._loadRoleShiftsData(this._dateFormat(firstDayOfMonth), this._dateFormat(lastDayOfMonth));


        // // lastDayOfMonth = moment(this.lastDayOfMonth).format('YYYY-MM-DD')
        // console.log(lastDayOfMonth);
      }
    }),

    // eventDataTransform: function( eventData ) {  console.log(eventData.title)},
    events: [],
    // dateClick: this._loadRoleShiftsData.bind(this), // this line
    eventContent: function (info) {
      // console.log(info.event.title);
    },
    eventMouseEnter: this.onEventRender.bind(this),
    eventDidMount: function (info) {
      console.log(info.el.title);
      info.el.title = '';
      if (info.event.extendedProps.background) {
        info.el.style.background = info.event.extendedProps.background;
      }
    },

    viewWillUnmount: function (info) {
      let self = this;
      console.log(info);

      let firstDayOfMonth = info.view.currentStart;
      let lastDayOfMonth = info.view.currentEnd
      lastDayOfMonth.setDate(lastDayOfMonth.getDate() - 1);
      console.log("firstDayOfMonth", firstDayOfMonth);
      console.log("lastDayOfMonth", lastDayOfMonth);

      // this._loadRoleShiftsData(this._dateFormat(firstDayOfMonth), this._dateFormat(lastDayOfMonth));

    },
    customButtons: {
      prev: { // this overrides the prev button
        text: 'PREV',
        click: () => {
          const calendarApi = this.calendarComponent.getApi();

          let firstDayOfMonth = new Date(calendarApi.view.currentStart.getFullYear(), calendarApi.view.currentStart.getMonth() - 1, 1);
          let lastDayOfMonth = new Date(calendarApi.view.currentEnd.getFullYear(), calendarApi.view.currentEnd.getMonth() - 1, 0, 0)
          console.log("firstDayOfMonth", firstDayOfMonth);
          console.log("lastDayOfMonth", lastDayOfMonth);

          console.log("calendarApi.view.currentEnd ", calendarApi.view.currentEnd);
          // this._loadRoleShiftsData(this._dateFormat(firstDayOfMonth), this._dateFormat(lastDayOfMonth));
          calendarApi.prev();
        }
      },
      next: { // this overrides the next button
        text: 'NEXT',
        click: () => {
          const calendarApi = this.calendarComponent.getApi();

          let firstDayOfMonth = new Date(calendarApi.view.currentStart.getFullYear(), calendarApi.view.currentStart.getMonth() + 1, 1)
          let lastDayOfMonth = new Date(calendarApi.view.currentEnd.getFullYear(), calendarApi.view.currentEnd.getMonth() + 1, 0)

          console.log("firstDayOfMonth", firstDayOfMonth);
          console.log("lastDayOfMonth", lastDayOfMonth);

          // this._loadRoleShiftsData(this._dateFormat(firstDayOfMonth), this._dateFormat(lastDayOfMonth));
          calendarApi.next();
        }
      },
      today: {
        text: 'Today',
        click: () => {
          const calendarApi = this.calendarComponent.getApi();
          let firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
          let lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
          this._loadRoleShiftsData(this._dateFormat(firstDayOfMonth), this._dateFormat(lastDayOfMonth));

        }
      }
    },
  };

  onEventRender(info: any) {
    console.log('onEventRender', info.el);
    this.tooltip = new Tooltip(info.el, {
      title: info.event.title,
      placement: 'top-end',
      trigger: 'hover',
      container: 'body',
      // delay: { "show": 500, "hide": 300 }
    });
  }
  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const title = prompt('Please enter a new title for your event');
    const calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay
      });
    }
  }

  handleEventClick(clickInfo: EventClickArg) {
    const params = clickInfo.event._def.extendedProps;
    console.log('handleEventClick click on shift params', params);
    this.tooltip.dispose();
    let link = `startDate=${params.data.day}&endDate=${params.data.day}&startTime=${params.data.shiftStartTime}&endTime=${params.data.shiftEndTime}&roleName=${params.roleName}&shiftName=${params.data.name}&shiftId=${params.data.shiftId}`;
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove();
    // }
    console.log('clickInfo.event ==== ', clickInfo);
    this.router.navigateByUrl(`/shift-detail?${link}`);

  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
    console.log(events);

  }

  createShift() {
    this.router.navigateByUrl('/create-shift');
  }

  getColor(roleName: string) {
    const legend = this.rolesLeagend.filter((item) => item.name == roleName);
    // return (legend.length) ? legend[0]['color'] : '#000';
    return (legend[0].color) ? legend[0]['color'] : '#000';
  }
}
