import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-multi-select-items-from-list',
  templateUrl: './multi-select-items-from-list.component.html',
  styleUrls: ['./multi-select-items-from-list.component.scss']
})
export class MultiSelectItemsFromListComponent implements OnInit {
  @Input() title: string = 'list';
  @Input() itemsList: [] = [];
  @Input() id: string = 'id';
  @Input() name: string = 'name';

  @Output() selectedItems = new EventEmitter<any>();

  type;
  selected: any;

  constructor() { }

  ngOnInit(): void {
    this.selected = [this._defaultNewSelected];
  }

  private get _defaultNewSelected() {
    const model = {
      isExpanded: true
    };
    model[this.id] = 0;
    model[this.name] = 'Select';
    return model;
  }
  onSelect($event, idx) {
    this.selected[idx] = $event.option._value;
    this.selected[idx].isExpanded = false;
    // console.log('this.selected', this.selected);
    this.selectedItems.emit(this.selected);
  }

  add() {
    this.selected.push(this._defaultNewSelected);
    // console.log('addPenalty this.selected', this.selected);
  }
  remove(idx) {
    this.selected.splice(idx, 1);
    this.selectedItems.emit(this.selected);
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    // if (filterValue.length >= 3) {
    // }
  }


}
