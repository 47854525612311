import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {
  @ViewChild("selectedItems") selectedItems: MatSelect;
  @Input() multiple: boolean;
  @Input() listTitle: string = '';
  @Input() clearBtn: string = '';
  @Input() filterBtn: string = '';
  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() returnValue: string = 'name';
  @Input() list: [];
  @Output() clearSelectedItems = new EventEmitter<any>();
  @Output() applyFilter = new EventEmitter<any>();
  // @Output() selectionChange = new EventEmitter<any>();

  selected: any = [];
  constructor() { }

  ngOnInit(): void {
    // this.toggleAllSelection(this.selectedItems);
    // this.selectedItems.options.forEach((item: MatOption) => item.select());
    // this.selected.value=['id', 'operationArea'];
  }

  // onFilter(event) {
  //   this.selectionChange.emit(event)
  // }
  onSelectionChange($event) {
    this.selected = $event;
    // console.log("onSelectionChange event",$event);
    // console.log("this.selected", this.selected);


  }
  clearItems() {
    // console.log("clearItems event",event);
    this.selectedItems.value = null;
    this.selected = [];
    this.clearSelectedItems.emit();
  }

  toggleAllSelection(matSelect: MatSelect) {
    // console.log('filter matSelect',matSelect)

    const isSelected: boolean = matSelect.options
      // The "Select All" item has the value 0
      .filter((item: MatOption) => item.value === 0)
      // Get the selected property (this tells us whether Select All is selected or not)
      .map((item: MatOption) => item.selected)[0];
    // Get the first element (there should only be 1 option with the value 0 in the select)

    if (isSelected) {
      matSelect.options.forEach((item: MatOption) => item.select());
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  onApplyFilter() {
    // console.log(this.selected);

    this.applyFilter.emit(this.selected);
    this.selectedItems.close();
  }

}
