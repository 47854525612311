import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ViolationPenaliesList } from '../_models';

import { first } from 'rxjs/operators';
import { ViolationPenaltiesService } from '../_services';

// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';

@Component({
  selector: 'app-manage-violations-penalties',
  templateUrl: './manage-violations-penalties.component.html',
  styleUrls: ['./manage-violations-penalties.component.scss']
})

export class ManageViolationsPenaltiesComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  searchKey: ''='';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<ViolationPenaliesList>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = ['index', 'id', 'enName', 'fees', 'actions'];
  dataList: [];

  sortBy: string = 'id';
  sortDirection: string = 'ASC';
  operationId
  constructor(
    private _router: Router,
    private _violationPenaltiesService: ViolationPenaltiesService,
    private _dialog: MatDialog
  ) {
    if (localStorage.getItem('currentUser')) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this._loadList(0, this.pageSize, this.sortBy, this.sortDirection);
  }

  private _loadList(page: number, limit: number, sortBy: string = '', sortDirection: string = '', violationName: string = this.searchKey, query: string = '') {
    this.loading = true;


    // console.log("manage violation penalties _loadList operationId", operationId);

    this._violationPenaltiesService.getList(page, limit, this.operationId, sortBy, sortDirection,violationName)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log('Violation Penalties Component');
          
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<ViolationPenaliesList>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  applyFilter() {
    const filterValue = this.searchKey;
    if (filterValue.length >= 3) {
      this._violationPenaltiesService.getList(0, this.pageSize, this.operationId,'','', filterValue)
        .pipe(first())
        .subscribe(
          resp => {
            console.log(resp);
            
            this.dataList = resp.payload.content;
            this.totalRows = resp.payload.totalElements;
            this.dataSource = new MatTableDataSource<ViolationPenaliesList>(this.dataList);
          },
          error => {
            console.log(" err ===== ", error);
          });
    } else {
      this._loadList(this.currentPage, this.pageSize);
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this._loadList(this.currentPage, this.pageSize);
  }

  onView(row) {
    this._router.navigateByUrl(`/violation-penalties-form/view/${row.id}`);
  }

  onEdit(row) {
    this._router.navigateByUrl(`/violation-penalties-form/edit/${row.id}`);
  }

  onToggleViolation(row){
    console.log("row",row);
    this._violationPenaltiesService.disableEnableViolation(row.id , row.enabled).subscribe(res => console.log(res));
  }

  onDeactive(row) {
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      // height: '500px',
      data: {
        title: { title: "Are you sure?", color: "#D93636" },
        body: `You’re are going to disable violation penalty
        “${row.enName}”, please confirm`,
        btnConfirmation: { title: "Disable", color: "#D93636" },
        btnClose: { title: "Cancel" },
        comment: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("row", row);
        const data: any = {
          operationId: this.operationId,
          violationId: row.id,
          comment: "aaa"
        }
        this._violationPenaltiesService.delete(data).pipe(first())
          .subscribe(
            data => {
              this.loading = false;
              this._loadList(this.currentPage, this.pageSize);

            },
            error => {
              this.loading = false;
            });
      }
    });
  }

  pageChanged(event: PageEvent) {
    console.log(event);
    
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.currentPage, this.pageSize);
    // this.applyFilter();
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    this.sortBy = sortState.active;
    this.sortDirection = sortState.direction;
    this._loadList(this.currentPage, this.pageSize);
  }

  onCancelSearch(): void {
    this.onSearchClear();
  }

  onNew() {
    this._router.navigateByUrl(`/violation-penalties-form`);
  }

}
