import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-citation-penalties-header',
  templateUrl: './citation-penalties-header.component.html',
  styleUrls: ['./citation-penalties-header.component.scss']
})
export class CitationPenaltiesHeaderComponent implements OnInit {
  @Input() data

  constructor() { }

  ngOnInit(): void {
  }


  formatDate(d) {
    if (!d) return 'N/A';
    return new Date(d).toISOString().split('T')[0]
  }

}
