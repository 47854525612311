import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/_services/authorization.service';
import { DialogConfirmation } from 'src/app/_shared/dialog-confirmation/dialog-confirmation';
import { CitationReportListService, SelectDropdownIdName } from '../../citation/_services';
import { WhitelistModel } from '../_models/whitelist.model';
import { WhitelistService } from '../_services';

@Component({
  selector: 'app-manage-whitelist',
  templateUrl: './manage-whitelist.component.html',
  styleUrls: ['./manage-whitelist.component.scss']
})
export class ManageWhitelistComponent implements OnInit {
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  whiteList: [];
  currentPage = 0;
  loading = false;
  dataSource: MatTableDataSource<WhitelistModel>;

  displayedColumns: string[] = ['id', 'vehicleUser', 'carPlate', 'plateType', 'actions'];
  failedMsg: string = "";
  successMsg: string = "";
  selectedOperationId = 0;
  operations: SelectDropdownIdName[] = [];
filterValue:string;
  constructor(
    private whitelistService: WhitelistService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog,
    private _router: Router,
    private _citationReportListService: CitationReportListService,
    private _activatedRoute: ActivatedRoute,
    ) { }

  ngOnInit(): void {
    this.loadOperationList();
    this.getWhitelist();

    this.successMsg = this._activatedRoute.snapshot.queryParams['success'];
    setTimeout(() => {
      this.successMsg = "";
      this._router.navigate(
        ['.'],
        { relativeTo: this._activatedRoute, queryParams: { } }
      );
    }, 5000);
  }

  public getWhitelist() {
    this.loading = true;
    this.whitelistService.getList(this.currentPage, this.pageSize, '', this.selectedOperationId)
    .pipe(first())
    .subscribe(
      resp => {
        console.log(resp);
        this.whiteList = resp.payload.content;
        this.totalRows = resp.payload.totalElements;
        this.dataSource = new MatTableDataSource<WhitelistModel>(this.whiteList);
        console.log(this.dataSource);
        this.loading = false;
      },
      error => {
        this.loading = false;
        console.log("err", error);
      }
    )
  }


  applyFilter() {
    const filterValue:string=this.searchKey;
    // console.log(filterValue);
    if (filterValue) {
      this.whitelistService.getList(this.currentPage, this.pageSize, filterValue.trim(), this.selectedOperationId).pipe(first()).subscribe(res => {
        this.whiteList = res.payload.content;
        this.totalRows = res.payload.totalElements;
        this.dataSource = new MatTableDataSource<WhitelistModel>(this.whiteList);
      },
        error => {
          console.log("err", error);
        });
    } else {
      this.getWhitelist();
    }
  }

  onSearchClear() {
    this.searchKey = '';
    this.dataSource.filter = this.searchKey;
    this.getWhitelist();
  }

  pageChanged(event: PageEvent) {
    // console.log(event);
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.applyFilter();
  }

  onDisable(row) {
    const params:any = {
      id: row.id
    }
    console.log('delete', row);
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      data: {
        title: { title: "Disable Vehicle", color: "#D93636" },
        body: `Are you sure you want to disable this vehicle
        from the whitelist?`,
        btnConfirmation: { title: "Confirm", color: "#D93636" },
        btnClose: { title: "No, Cancel" },
        comment: false
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.loading = true;
        this.whitelistService.deleteWhitelistVehicle(params).pipe(first()).subscribe(res=> {
          console.log(res);
          this.loading = false;
          this.getWhitelist();
        },
        error => {
          this.loading = false
        }
        )
      }
    });
  }

  onView(row) {
    console.log(row);
    this._router.navigateByUrl(`whitelist-details/${row.id}`);
  }

  onAddWhitelist() {
    this._router.navigateByUrl(`/whitelist-form`);
  }

  private loadOperationList() {
    if (this.operations.length > 1)
      return false;

    this.loading = true;
    this.operations = [{ id: 0, name: 'All' }];

    this._citationReportListService.getOperationList()
      .pipe(first())
      .subscribe(
        resp => {
          this.operations = this.operations.concat(resp.payload);
          this.loading = false;
        },
        error => {
          this.loading = false;
        });
  }

}
