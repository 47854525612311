import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  creationSuccessStatus: boolean = false;
  creationFailStatus: boolean = false;
  constructor(private http: HttpClient) { }
  _token = `Bearer ${localStorage.getItem('token')}`;

  getFixedRoles(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/roles/fixed_roles`, {
      headers: { Authorization: this._token },
    });
  }

  getRoles(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/roles/roles-names`, {
      headers: { Authorization: this._token },
    });
  }

  getRolesIdName(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/roles/rolesNameId`);
  }

  getAllOperations(): Observable<any> {
    return this.http.get(`${env.baseURL}operation/operation/all`, {
      headers: { Authorization: this._token },
    });
  }

  assignEssentialRole(user): Observable<any> {
    const { fullName, userEmail, userPhone, userOperation, userRole } = user;
    let formData: any = new FormData();
    formData.append('name', fullName);
    formData.append('email', userEmail);
    formData.append('phone', userPhone.number);
    formData.append('operationName', userOperation);
    formData.append('roleName', userRole);
    // formData.append('image', null);
    // {
    return this.http.post(`${env.baseURL}operation/employee`, formData, {
      headers: { Authorization: this._token },
    });
  }

  setUserCreationStatus(status) {
    if (status === true) {
      this.creationFailStatus = false;
      this.creationSuccessStatus = true;
    } else {
      this.creationSuccessStatus = false;
      this.creationFailStatus = true;
    }
  }

  getUserCreationSuccess() {
    return this.creationSuccessStatus;
  }
  getUserCreationFailed() {
    return this.creationFailStatus;
  }

  getEssentialUsers(
    employeeName: string,
    pageCount: number,
    limitCount: number
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/employee/search?${employeeName !== undefined
        ? `keyword=${employeeName !== null && employeeName !== undefined
          ? `${employeeName}&`
          : ''
        }`
        : ''
      }page=${pageCount !== null ? pageCount : 0}&limit=${limitCount !== null ? limitCount : 10
      }`,
      { headers: { Authorization: this._token } }
    );
  }

  getEssentialUsersDetails() {
    const api = `${env.baseURL}operation/employee/search?`;
    return this.http.get<any>(api);
  }



  getAvailableWorkforceList(operationName, roleName, today): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/employee/freeWithOpRole?roleName=${roleName}&operationName=${operationName}&&today=${today}`,
      //today=2021-12-27
      { headers: { Authorization: this._token } }
    );
  }

  reassignWorkforcers(newOperation, employeesNames): Observable<any> {
    return this.http.put(
      `${env.baseURL}operation/employee/reassignOperation`,
      {
        newOperationName: newOperation,
        employeesUserNames: employeesNames,
      },
      { headers: { Authorization: this._token } }
    );
  }

  getEmployeesList(
    pageCount: number,
    limitCount: number,
    employeeName: string
  ): Observable<any> {
    return this.http.get(
      `${env.baseURL}operation/employee?page=${pageCount !== null ? pageCount : 0
      }&limit=${limitCount !== null ? limitCount : 10}&employeeName=${employeeName !== null ? employeeName : ''
      }`,
      { headers: { Authorization: this._token } }
    );
  }

  getEmployeesDetails() {
    const api = `${env.baseURL}operation/employee`;
    return this.http.get<any>(api);
  }

  getEmployeeDetails(id) {
    const api = `${env.baseURL}operation/employee/workforce/${id}`;
    return this.http.get<any>(api);
  }

  updateEmployee(): Observable<any> {
    return this.http.put(
      `${env.baseURL}operation/employee/updateEmployeeByHead`,
      { headers: { Authorization: this._token } }
    );
  }
  getImage(id: number) {
    const api = `${env.baseURL}operation/employee/imagesbase64/${id}`;
    return this.http.get<any>(api);
  }

  disableMember(id: number) {
    const api = `${env.baseURL}operation/shift/disableMember/${id}`
    return this.http.get<any>(api);
  }
  // this.http.put(`${env.baseURL}operation/employee/freeWithOpRole`)

  getScanDetails(limit: number, offset: number, fromDate, toDate, enforceId: string, sortBy, sortDirection) {
    const api = `${env.baseURL}enforcements/scanDetails/workForceSummary?offset=${offset}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&enforcerId=${enforceId}&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    return this.http.get<any>(api);
  }


  changePassword(params): Observable<any> {
    return this.http.put(
      `${env.baseURL}operation/employee/changePasswordRequestByHead`, params
    );
  }

  workforceReport(params): Observable<any> {
    return this.http.post(
      `${env.baseURL}enforcements/citations/workforce-member-report`, params
    )
  }

  downloadCSV(params): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
    // let headers = new Headers();
    // headers.append('Content-Type', 'text/html; charset=utf-8');
    const options = { params, headers, responseType: 'text' as 'json' };
    const api = `${env.baseURL}enforcements/citations/export-workforce-member-report`;
    return this.http.get<any>(api, options);
  }
  downloadCSVViolation(params): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');
    // let headers = new Headers();
    // headers.append('Content-Type', 'text/html; charset=utf-8');
    const options = { params, headers, responseType: 'text' as 'json' };
    const api = `${env.baseURL}enforcements/citations/export-workforce-report-violations-details`;
    return this.http.get<any>(api, options);
  }

  citationsCount(params):Observable<any> {
    return this.http.post(`${env.baseURL}enforcements/citations/citations-count-group-by-violation`, params);
  }

  citationsSettlementCount(params): Observable<any> {
    return this.http.post(`${env.baseURL}enforcements/citations/citations-settlement-count-group-by-violation`, params);
  }
}
