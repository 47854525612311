<!-- Slideshow container -->
<div class="slideshow-container ">
  <cdk-virtual-scroll-viewport [itemSize]="100" class="example-viewport">
  <div class="print-content" >
    <!------------------------------------------------------------------------------------------------------------------->

    <div class="vehicle-details mb-5" style="width: 1000px;margin-right: 10px">
      <div class="row ">
        <h1 class="header-title" style="margin-left: 400px"><strong>Citation Information</strong></h1>
      </div>
      <mat-table [dataSource]="CitationDataSource" multiTemplateDataRows >

        <!-- Plate Type Column -->
        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef> Date</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.createdDate|date:'M/d/yyyy'}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="time">
          <mat-header-cell *matHeaderCellDef> TIME</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.createdDate|date:' h:mm:ss a'}} </mat-cell>
        </ng-container>

        <!-- Plate Type Column -->
        <ng-container matColumnDef="violationName">
          <mat-header-cell *matHeaderCellDef> violation Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.violationName}} </mat-cell>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.status}} </mat-cell>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="operationName">
          <mat-header-cell *matHeaderCellDef> Operation name </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.operationName}} </mat-cell>
        </ng-container>

        <!-- Make Column -->
        <ng-container matColumnDef="streetName">
          <mat-header-cell *matHeaderCellDef> Street Name</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element.streetName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef> Location</mat-header-cell>
          <mat-cell *matCellDef="let element" ><button mat-icon-button (click)="onCitationLocation()" matTooltip="location on map" style="color: blue">
            <mat-icon>location_on</mat-icon>
          </button> </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="CitationDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: CitationDisplayedColumns;"></mat-row>

      </mat-table>
    </div>
    <!----------------------------------------------------------------------------------->

    <div class="vehicle-details mb-5" style="width: 1000px">
      <div class="row ">
        <h1 class="header-title" style="margin-left: 400px"><strong>{{title}}</strong></h1>
      </div>
      <mat-table [dataSource]="CitationDataSource" multiTemplateDataRows >

        <!-- Plate Type Column -->
        <ng-container matColumnDef="enPlateNumber">
          <mat-header-cell *matHeaderCellDef> Plate Number</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.citationVehicleDto?.enPlateNumber}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="colorEnName">
          <mat-header-cell *matHeaderCellDef> Color</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.citationVehicleDto?.colorEnName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="makeArName">
          <mat-header-cell *matHeaderCellDef> make</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.citationVehicleDto?.makeEnName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.citationVehicleDto?.type}} </mat-cell>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="countryArName">
          <mat-header-cell *matHeaderCellDef> Country </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.citationVehicleDto?.countryEnName}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="vehicleDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: vehicleDisplayedColumns;"></mat-row>

      </mat-table>
    </div>

    <!------------------------------------------------------------------------------>
    <div class="mb-5" style="width: 1000px">
      <div class="row ">
        <h1 class="header-title" style="margin-left: 400px"><strong>Penalties Information</strong></h1>
      </div>
      <mat-table [dataSource]="penaltiesDataSource" multiTemplateDataRows >

        <!-- Plate Type Column -->
        <ng-container matColumnDef="penalty">
          <mat-header-cell *matHeaderCellDef> Penalty </mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.operationViolationPenalty?.penalty?.enName}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="fees">
          <mat-header-cell *matHeaderCellDef> Fees</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.operationViolationPenalty?.penalty?.fees}} </mat-cell>
        </ng-container>
        <!-- Model Column -->
        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.status}} </mat-cell>
        </ng-container>
        <!-- Model Column -->
        <ng-container matColumnDef="voidReason">
          <mat-header-cell *matHeaderCellDef> Void Reason</mat-header-cell>
          <mat-cell *matCellDef="let element"> {{element?.reason?.reasonAR}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="image">
          <mat-header-cell *matHeaderCellDef> Evidence</mat-header-cell>
          <mat-cell *matCellDef="let element" ><button mat-icon-button (click)="onShowImage(element)" [hidden]="disableEvidence(element)" matTooltip="void evidence" style="color: blue">
            <mat-icon >movie_creation</mat-icon>
          </button> </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="penaltiesDisplayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: penaltiesDisplayedColumns;"></mat-row>

      </mat-table>
    </div>
    <!--------------------------------------------------------------->

  </div>
  </cdk-virtual-scroll-viewport>

</div>
<div class=" footer">
  <button class="btn btn-cancel" (click)="close()">Back to Citations</button>
</div>
<mat-spinner *ngIf="loading"></mat-spinner>
