
import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';


@Injectable({ providedIn: 'root' })
export class CitationService {
    // private _http: HttpClient;
    // private _token: string = "";
    constructor(handler: HttpBackend, private _http: HttpClient) {
        // this._http = new HttpClient(handler);
        // this._token = `Bearer ${localStorage.getItem('token')}`;
    }
    getList(
        offset: number,
        limit: number,
        sortBy: string,
        sortDirection: string,
        status = '',
        query: string = '',
        enforcerUserName: string = '',
        citationNumber: string = '',
        street: string = '',
        plateNumber: string = '',
        operationId: string = '',
        countryId: string = '',
        fromDate: string = '',
        toDate: string = '',
        violationId: string = '',
        selectedWorkforceOptId: string = ''
    ) {
        let api = `${environment.baseURL}enforcements/citations/unpaidCitations?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection.toUpperCase()}`;
        if (query)
            api += `&query=${query}`;
        if (status)
            api += `&statusList=${status}`;

        if (enforcerUserName)
            switch (selectedWorkforceOptId) {
                case 'actionBy':
                    api += `&actionBy=${enforcerUserName}`;
                    break;
                case 'enforcerName':
                    api += `&enforcerUserName=${enforcerUserName}`;
                    break;
            }

        if (citationNumber)
            api += `&citationNumber=${citationNumber}`;
        if (street)
            api += `&street=${street}`;

        if (plateNumber)
            api += `&plateNumber=${plateNumber}`;

        if (operationId && operationId !== '0')
            api += `&operationId=${operationId}`;
        if (countryId)
            api += `&countryId=${countryId}`;

        if (fromDate)
            api += `&fromDate=${fromDate}`;

        if (toDate)
            api += `&toDate=${toDate}`;

        console.log('service violationId', violationId);
        if (violationId)
            api += `&violationId=${violationId}`;


        console.log('CitationService getList api', api);
        return this._http.get<any>(api);
    }

    downloadCSV(
        offset: number,
        limit: number,
        sortBy: string,
        sortDirection: string,
        status = '',
        query: string = '',
        enforcerUserName: string = '',
        citationNumber: string = '',
        street: string = '',
        plateNumber: string = '',
        operationId: string = '',
        countryId: string = '',
        fromDate: string = '',
        toDate: string = '',
        violationId: string = '',
        selectedWorkforceOptId: string = ''
    ) {
        const headers = new HttpHeaders().set('Content-Type', 'text/html; charset=utf-8');

        // return this.http.get<any>(`${environment.apiUrl}/admin/static_pages/aboutus`, { headers , responseType: 'text' as 'json'});


        let api = `${environment.baseURL}enforcements/citations/export?offset=${offset}&limit=${limit}&sortBy=${sortBy}&sortDirection=${sortDirection.toUpperCase()}`;
        if (query)
            api += `&query=${query}`;
        if (status)
            api += `&statusList=${status}`;

        if (enforcerUserName)
            switch (selectedWorkforceOptId) {
                case 'actionBy':
                    api += `&actionBy=${enforcerUserName}`;
                    break;
                case 'enforcerName':
                    api += `&enforcerUserName=${enforcerUserName}`;
                    break;
            }

        // if (enforcerUserName)
        //     api += `&enforcerUserName=${enforcerUserName}`;
        if (citationNumber)
            api += `&citationNumber=${citationNumber}`;
        if (street)
            api += `&street=${street}`;

        if (plateNumber)
            api += `&plateNumber=${plateNumber}`;

        if (operationId && operationId !== '0')
            api += `&operationId=${operationId}`;
        if (countryId)
            api += `&countryId=${countryId}`;

        if (fromDate)
            api += `&fromDate=${fromDate}`;

        if (toDate)
            api += `&toDate=${toDate}`;

        console.log('service violationId', violationId);
        if (violationId)
            api += `&violationId=${violationId}`;


        console.log('CitationService getList api', api);
        return this._http.get<any>(api, { headers, responseType: 'text' as 'json' });
    }
    getDetails(ids: string) {
        const api = `${environment.baseURL}enforcements/citations/citationsDetails?citationIds=${ids}`;
        return this._http.get<any>(api);
    }

    payCitation(data) {
        return this._http.put(`${environment.baseURL}enforcements/citations/payCitations`, data);
    }

    payCitationAsync(data) {
        return this._http.put(`${environment.baseURL}enforcements/citations/payCitationsAsync`, data);
    }

    getInvoiceByCitationId(citationId: number) {
        const api = `${environment.baseURL}enforcements/citations/getInvoiceByCitationId?citation-id=${citationId}`;
        return this._http.get<any>(api);
    }

    getVehicleCountries() {
        let api = `${environment.baseURL}vehicles/countries`;
        return this._http.get<any>(api);
    }
    getVoidReasons() {
        let api = `${environment.baseURL}enforcements/reasons/VOID`;
        return this._http.get<any>(api);
    }

    getEmployeesByNameOrUserNameOrId(keyword = '') {
        let api = `${environment.baseURL}operation/employee/getEmployeesByNameOrUserNameOrId`;
        if (keyword)
            api += `?keyword=${keyword}`
        return this._http.get<any>(api);
    }

    getViolationsList(keyword = '') {
        let api = `${environment.baseURL}enforcements/violation/search/autocomplete`;
        if (keyword)
            api += `?keyword=${keyword}`
        return this._http.get<any>(api);
    }

    getInvoice(id) {
        let api = `${environment.baseURL}invoices/${id}`;
        return this._http.get<any>(api);
    }

    getInvoiceBetweenDate(fromDate: string, toDate: string) {
        const api = `${environment.baseURL}invoices/invoices-between-date?fromDate=${fromDate}&toDate=${toDate}`;
        return this._http.get<any>(api);
    }


    getVat() {
        const api = `${environment.baseURL}operation/SystemParameters/vat`;
        return this._http.get<any>(api);
    }

    postFiles(citationId, files) {
        const api = `${environment.baseURL}enforcements/citations/evidences/upload/${citationId}/Voided`;
        return this._http.post<any>(api, files);
    }
    getCitationCount(citationId: number) {
        const api = `${environment.baseURL}orders/detention-orders/detention-days?citationId=${citationId}`;
        return this._http.get<any>(api);
    }

}
