import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WhitelistService } from '../_services';
import { WhitelistSaveModel } from '../_models/whitelistSave.model';

@Component({
  selector: 'app-whitelist-form',
  templateUrl: './whitelist-form.component.html',
  styleUrls: ['./whitelist-form.component.scss']
})
export class WhitelistFormComponent implements OnInit {

  title: string = "Add a Vehicle to Whitelist";
  submitted = false;
  Whitelist: FormGroup;
  id;
  countries: String[] = [];
  colors: String[] = [];
  makes: String[] = [];
  plateType: String[] = ["PRIVATE", "TAXI", "TRUCK", "EMBASSY"];

  created: boolean = false;
  failed: boolean = false;
  createdMsg: string = '';
  operationId: number;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private whitelistService: WhitelistService,
    private _router: Router,
  ) {
    if (localStorage.getItem('currentUser')) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = currentUserJson.employeeDto.operationId;
      console.log(this.operationId);
    }

    this.id = this._activatedRoute.snapshot.params.id;
    console.log("id :   " + this.id)
    this.generateWhitelistForm();


  }

  ngOnInit(): void {
    this.getVehicleCountries();
    this.getVehicleColors();
    this.getVechicleMakes();
  }

  generateWhitelistForm() {
    this.Whitelist = this.fb.group({
      userName: ['', Validators.required],
      plateText: ['', Validators.required],
      plateNumber: ['', Validators.required],
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      timeFrom: ['', Validators.required],
      timeTo: ['', Validators.required],
      description: ['', Validators.required],
      country: ['', Validators.required],
      colorId: ['', Validators.required],
      model: ['', Validators.required],
      type: ['', Validators.required],
      makeId: ['', Validators.required],
    })
  }

  getVehicleCountries() {
    this.whitelistService.getVehicleCountries().subscribe(res => {
      console.log("Vehicle Countries", res.payload.content);
      this.countries = res.payload.content;
    })
  }

  getVehicleColors() {
    this.whitelistService.getVehicleColors().subscribe(res => {
      console.log("Vehicle Colors :", res);
      this.colors = res.payload.content;
    })
  }

  getVechicleMakes() {
    this.whitelistService.getVehicleMakes().subscribe(res => {
      console.log("Vehicle Makes :", res);
      this.makes = res.payload.content;

    })
  }

  onCreateWhitelist() {
    this.submitted = true;
    if (this.Whitelist.invalid) return;

    console.log(this.Whitelist.value);

    if (this.id === undefined) {
      const params: WhitelistSaveModel = {
        userName: this.Whitelist.get('userName').value,
        country: this.Whitelist.get('country').value,
        fromDate: this.Whitelist.get('fromDate').value,
        toDate: this.Whitelist.get('toDate').value,
        timeFrom: this.Whitelist.get('timeFrom').value,
        timeTo: this.Whitelist.get('timeTo').value,
        colorId: this.Whitelist.get('colorId').value,
        makeId: this.Whitelist.get('makeId').value,
        model: this.Whitelist.get('model').value,
        description: this.Whitelist.get('description').value,
        type: this.Whitelist.get('type').value,
        operationId: this.operationId,
        plateNumberEn: this.Whitelist.get('plateNumber').value + this.Whitelist.get('plateText').value,
      };

      this.whitelistService.createWhitelistVehicle(params).subscribe(
        (res) => {
          console.log(res);
          if (res.success === true) {
            this.createdMsg = res.payload;
            this.failed = false;
            this.created = true;
            this._router.navigate([`/manage-whitelist`], { queryParams: { success: res.payload ? res.payload : "Vehicle has been added to whitelist successfully" } })
          }
        },
        (error) => {
          this.createdMsg = error.error.error.message;
          this.created = false;
          this.failed = true;
        }
      );
    }

  }

  goBack() {
    this.location.back();
  }

  public myError = (controlName: string, errorName: string) => {
    return (this.submitted) ? this.Whitelist.controls[controlName].hasError(errorName) : false;
  }

}
