import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BlacklistList } from '../_models';

import { first } from 'rxjs/operators';
import { BlacklistService } from '../_services';

// import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { AuthorizationService } from 'src/app/_services';
import { DialogConfirmation } from '../../_shared/dialog-confirmation/dialog-confirmation';


@Component({
  selector: 'app-blacklist-list',
  templateUrl: './blacklist-list.component.html',
  styleUrls: ['./blacklist-list.component.scss']
})
export class BlacklistListComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  loading = false;
  searchKey: '';
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  dataSource: MatTableDataSource<BlacklistList>;

  succeeded: boolean = false;
  failed: boolean = false;
  isTableExpanded = false;
  // isExpansionDetailRow = (i: number, row: Object) =>
  //   row.hasOwnProperty('detailRow');
  expandedElement: any;
  displayedColumns: string[] = ['index', 'userName', 'plateNumberEn', 'type', 'actions'];
  dataList: [];

  sortingBy: string = 'id';
  sortingType: any = 'asc';
  operationId;
  // success: boolean = false;
  failedMsg: string = "";
  successMsg: string = ""
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _service: BlacklistService,
    public authorizationService: AuthorizationService,
    private _dialog: MatDialog
  ) {
    if (localStorage.getItem('currentUser')) {
      const currentUserJson = JSON.parse(localStorage.getItem('currentUser'));
      this.operationId = currentUserJson.employeeDto.operationId;
    }
  }

  ngOnInit(): void {
    this._loadList(1, this.pageSize);
    console.log("this._activatedRoute.snapshot.paramMap.get('success')", this._activatedRoute.snapshot.queryParams)
    this.successMsg = this._activatedRoute.snapshot.queryParams['success'];
    setTimeout(() => {
      this.successMsg = "";
      this._router.navigate(
        ['.'],
        { relativeTo: this._activatedRoute, queryParams: {} }
      );
    }, 5000);

  }

  private _loadList(page: number, limit: number, query: string = '', sortingType: any = this.sortingType, sortingBy: string = this.sortingBy) {
    this.loading = true;
    // console.log("manage violation penalties _loadList operationId", operationId);
    if(sortingType==='asc') sortingType=1;
    else sortingType=0;
    // console.log(sortingType);
    // console.log(sortingBy);


    this._service.getList(page, limit, '', sortingType, sortingBy)
      .pipe(first())
      .subscribe(
        resp => {
          // console.log(resp);

          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<BlacklistList>(this.dataList);
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(" err ===== ", error);
        });
  }

  applyFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value;
    this._service.getList(1, this.pageSize, filterValue.trim().toLowerCase())
      .pipe(first())
      .subscribe(
        resp => {
          this.dataList = resp.payload.content;
          this.totalRows = resp.payload.totalElements;
          this.dataSource = new MatTableDataSource<BlacklistList>(this.dataList);
        },
        error => {
          // console.log(" err ===== ", error);
        });
  }

  onSearchClear() {
    this.searchKey = '';
    this._loadList(this.currentPage + 1, this.pageSize);
  }

  onView(row) {
    this._router.navigateByUrl(`/blacklist-details/${row.id}`);
  }

  onEdit(row) {
    this._router.navigateByUrl(`/blacklist-form/edit/${row.id}`);
  }

  onDeactive(row) {
    const dialogRef = this._dialog.open(DialogConfirmation, {
      panelClass: 'custom-mat-show-image-dialog-container',
      width: '468px',
      // height: '500px',
      data: {
        title: { title: "Disable Vehicle", color: "#D93636" },
        body: `Are you sure you want to disable this vehicle
        from the blacklist?`,
        btnConfirmation: { title: "Confirm", color: "#D93636" },
        btnClose: { title: "No, Cancel" },
        comment: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("row", row.id)
        this._service.delete(row.id).pipe(first())
          .subscribe(
            data => {
              this.loading = false;
              this._loadList(this.currentPage + 1, this.pageSize);
              this.failedMsg = data.payload;
              setTimeout(() => this.failedMsg = "", 5000);

            },
            error => {
              this.loading = false;
            });
      }
    });
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this._loadList(this.currentPage + 1, this.pageSize);
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    // console.log(sortState);

    this.sortingType = sortState.direction;
    this.sortingBy = sortState.active;
    // console.log(this.sortingType);
    // console.log(this.sortingBy);
    this._loadList(this.currentPage+1 , this.pageSize);
  }

  onCancelSearch(): void {
    this.onSearchClear();
  }

  onNew() {
    this._router.navigateByUrl(`/blacklist-form`);
  }

}
