import { NgModule } from '@angular/core';
import { SharedModule } from '../_shared/shared.module';

import { BlacklistListComponent } from './blacklist-list/blacklist-list.component';
import { BlacklistFormComponent } from './blacklist-form/blacklist-form.component';
import { BlacklistDetailsComponent } from './blacklist-details/blacklist-details.component';



@NgModule({
  declarations: [
    BlacklistListComponent, 
    BlacklistFormComponent,
    BlacklistDetailsComponent
  ],
  imports: [
    SharedModule
  ]
})
export class BlacklistModule { }
