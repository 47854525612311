<div>
  <div class="d-flex justify-content-between align-items-center">
    <h1 class="fw-bolder">Verify Vehicle with MOMAH</h1>
    <button
      type="button"
      [disabled]="sending"
      (click)="onResendAllUnavailable()"
      class="send-all-btn"
      aria-label="Send all unavailable vehicles"
    >
      Send All Unavailable
    </button>
  </div>

  <!-- Search Form Section -->
  <div class="search-form mt-4">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmitSearch()">
      <div class="row">
        <!-- Start Date Field -->
        <div class="col-md-3">
          <label for="fromDate">Start Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [matDatepicker]="fromDate"
              placeholder="dd/mm/yyyy"
              formControlName="fromDate"
              id="fromDate"
              aria-label="Start date"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="fromDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- End Date Field -->
        <div class="col-md-3">
          <label for="toDate">End Date</label>
          <mat-form-field appearance="outline" class="w-100 px-0 datePicker">
            <input
              matInput
              [min]="searchForm?.value?.fromDate"
              placeholder="dd/mm/yyyy"
              [matDatepicker]="toDate"
              formControlName="toDate"
              id="toDate"
              aria-label="End date"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="toDate"
            ></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
        </div>

        <!-- Status -->
        <div class="col-md-3">
          <label for="status">Status</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <mat-select
              formControlName="status"
              id="status"
              placeholder="Choose status"
              aria-label="Select status"
            >
              <mat-option
                *ngFor="let status of statuses"
                [value]="status.value"
                >{{ status.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Failure Reason -->
        <div class="col-md-3">
          <label for="failureReasons">Failure Reasons</label>
          <mat-form-field appearance="outline" class="w-100 px-0">
            <mat-select
              formControlName="failureReasons"
              id="failureReasons"
              placeholder="Choose failure reasons"
              aria-label="Select failure reasons" 
            >
              <mat-option
                *ngFor="let reason of failureReasons"
                [value]="reason.value"
                >{{ reason.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Form Action Buttons -->
      <div class="mt-3 d-flex justify-content-end">
        <button
          class="btn search-btn px-5 h4 text-center fw-bold text-white"
          type="submit"
          aria-label="Search"
        >
          Search
        </button>
        <button
          class="btn filters-btn-clear h4 text-center mx-1 px-5 text-white fw-bold"
          (click)="onClear()"
          type="button"
          aria-label="Clear filters"
        >
          Clear
        </button>
      </div>
    </form>
  </div>

  <!-- Data Table Section -->
  <section class="mb-5">
    <div class="mat-elevation-z8 custom-table-card">
      <table class="col-12 rounded bordered-table mt-3">
        <thead class="rounded tableHead">
          <tr class="text-white">
            <th class="py-3 px-4">Vehicle plate</th>
            <th class="py-3 px-4 border-start">Citation Number</th>
            <th class="py-3 px-4 border-start">Citation Status</th>
            <th class="py-3 px-4 border-start">Date</th>
            <th class="py-3 px-4 border-start">Registration Type</th>
            <th class="py-3 px-4 border-start">Status</th>
            <th class="py-3 px-4 border-start">Failed Reason</th>
            <th class="py-3 px-4 border-start">Actions</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of dataList; let i = index">
            <tr>
              <td class="fs-6 py-2 px-3">{{ data?.enPlateNumber }}</td>
              <td class="fs-6 py-2 px-3">{{ data?.citationNumber }}</td>
              <td class="fs-6 py-2 px-3">
                <span
                  [ngClass]="{
                    'status-success': data?.citationStatus === 'Settled',
                    'status-pending': data?.citationStatus === 'Voided',
                    'status-fail': data?.citationStatus === 'Opened'
                  }"
                  >{{ data?.citationStatus }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                {{ data?.citationDate }}
              </td>

              <td class="fs-6 py-2 px-3">
                <div class="d-flex align-items-center">
                  <span
                    class="registration-type-indicator"
                    [ngStyle]="{
                      'background-color': '#' + data?.registrationTypeColorCode
                    }"
                    role="presentation"
                  ></span>
                  <span class="ms-2 text-nowrap text-truncate">{{
                    data?.registrationTypeNameEn
                  }}</span>
                </div>
              </td>

              <td class="fs-6 py-2 px-3">
                <span
                  [ngClass]="
                    data?.status === 'SUCCESS'
                      ? 'status-success'
                      : 'status-fail'
                  "
                  >{{ data?.status }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                <span
                  *ngIf="data?.failedReason"
                  [ngClass]="{
                    'status-pending': data?.failedReason === 'UNAVAILABLE',
                    'status-other': data?.failedReason !== 'UNAVAILABLE'
                  }"
                  >{{ data?.failedReason }}</span
                >
              </td>

              <td class="fs-6 py-2 px-3">
                <button
                  *ngIf="
                    data?.status === 'FAILED' &&
                    data?.failedReason !== 'UNAVAILABLE'
                  "
                  mat-icon-button
                  color="primary"
                  (click)="openDialog(data)"
                  title="Edit"
                  aria-label="Edit"
                >
                  <img src="assets/Icons/edit-icon.svg" alt="edit" />
                </button>
                <button
                  *ngIf="
                    data?.status === 'FAILED' &&
                    data?.failedReason === 'UNAVAILABLE'
                  "
                  mat-icon-button
                  color="accent"
                  (click)="onResend(data?.citationNumber)"
                  title="Resend"
                  aria-label="Resend"
                >
                  <img src="assets/Icons/send.svg" alt="send" />
                </button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>

      <!-- Pagination -->
      <mat-paginator
        class="mat-paginator-sticky"
        [pageSizeOptions]="[5, 10, 20, 50, 100]"
        showFirstLastButtons
        [length]="totalRows"
        showFirstLastButtons
        [pageIndex]="currentPage"
        [pageSize]="pageSize"
        (page)="onPageChange($event)"
        aria-label="Select page"
      >
      </mat-paginator>
    </div>

    <!-- Loading Spinner -->
    <mat-spinner *ngIf="loading"></mat-spinner>
  </section>
</div>
