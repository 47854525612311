<div class="row header">

    <div class="col-3 head">Citation Number</div>
    <div class="col-3 head">Car Plate</div>
    <div class="col-3 head">Violation Name</div>
    <div class="col-3 head">Citation Date</div>

    <div class="col-3">#{{data.id}}</div>
    <div class="col-3">{{data.plateNumberEn}}</div>
    <div class="col-3">{{data.violationName}}</div>
    <div class="col-3">{{formatDate(data.createdDate)}}</div>

    <div class="col-3 head">Status</div>
    <div class="col-3 head">Violation Code</div>
    <div class="col-3 head">Fees Amount</div>
    <div class="col-3 head">Operation Area</div>

    <div class="col-3">{{data.status}}</div>
    <div class="col-3">{{data.violationId}}</div>
    <div class="col-3">{{data.amount}}</div>
    <div class="col-3">{{data.operationName}}</div>
    <!---->
    <div class="col-3 head">Enforcer name</div>
    <div class="col-3 head">Country</div>
    <div class="col-3 head">Settled Date</div>
    <div class="col-3 head">Settled/Voided By</div>

    <div class="col-3">{{data.enforcerName}}</div>
    <div class="col-3">{{data.country?.enName}}</div>
    <div class="col-3">{{formatDate(data.modifiedDate)}}</div>
    <div class="col-3">{{data.actionBy}}</div>

</div>
