import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

import { Location } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { ShowImageService } from '../../_services';
// import * as html2canvas from "html2canvas";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-citation-print',
  templateUrl: './citation-print.component.html',
  styleUrls: ['./citation-print.component.scss']
})
export class CitationPrintComponent implements OnInit {
  @ViewChild("screen") screen: ElementRef;
  @ViewChild("canvas") canvas: ElementRef;
  @ViewChild("downloadLink") downloadLink: ElementRef;

  title = "Print Citation";
  citationData: any;
  imagePath: any;
  screenshoot: any;
  window: any;
  constructor(
    private location: Location,
    public dialogRef: MatDialogRef<CitationPrintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _showImageService: ShowImageService,
    private _sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.citationData = this.data[0];
    console.log(this.data);
    
    console.log("CitationPrintComponent citationData", this.citationData);
    // this._loadImage(this.citationData);

  }

  onCancel() {
    this._router.navigateByUrl(`/enforcement-citations`);
  }

  onPrint() {
    // const prtContent = document.getElementById("printcontent-view-citation");
    const prtContent = document.getElementById("printcontent-view-citation");
    console.log('onPrint prtContent ======== ', prtContent);


    const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    WinPrint.document.write(`
    <!DOCTYPE html>
    <html><head>
    <style>
    @import url('https://fonts.googleapis.com/css?family=Cairo&display=swap');

      * {
          font-size: 10px;
          font-family: "Cairo";
          font-weight:400;
      }
      p {
        font-family: "CAIRO";
      }

      .company-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 22px;
      }
      
      .company-info ul {
        text-align: right;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;
      }
      .company-info ul li {
        font-weight: 700;
        font-size: 10px;
        margin-bottom: 10px;
      }
      
      .info-img {
        width: 100px;
        height: 100px;
      }
      .info-img img {
        width: 100px;
        height: 100px;
        height: inherit;
        object-fit: cover;
      }

      .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border-radius: 0.25rem;
        width: 100%;
        margin: 0 auto;
        border: 1px solid #3257a2;
        padding-top: 5px;
        margin-bottom:25px;
      }
      
      .card-title {
        background: #051027 !important;
        bottom: 20px;
        position: relative;
        width: 60%;
        margin: 0 auto;
        border-radius: 4px;
        padding: 6px 0px 0px 0px;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        top:-21px;
        font-size: 10px;
        font-family: "Cairo";
        font-weight:400;
        text-align: center;
        align-content: center;
      }

      .card-title p {
          font-family: "Cairo";
          font-size: 14px;
          font-weight: 700;
          color: white;
          margin: 0px !important;
      }

      .card-body {
        flex: 1 1 auto;
      }
      .card-body ul {
        list-style: none;
        flex-wrap: wrap;
        margin-bottom: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        place-items: center;
        padding-inline-start: 0;
      }
      
      .card-body ul li {
        font-weight: 600;
        margin-bottom: 15px;
      }
      
      td {
        font-size: 10px;
        font-family: "Cairo";
        font-weight: 600;
      }

      .prt-td-left{
        padding-left: 10px;
      }

      .prt-td-center{
        padding:0px;
      }

      .prt-td-right{
        padding-right: 10px;
      }
      
    </style>
    </head>
    `);

    WinPrint.document.write('<body onload="print();close();">');
    // WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write(`${prtContent.innerHTML}</body></html>`);
    WinPrint.document.close();

    return false;

    html2canvas(prtContent).then(canvas => {
      var imgData = canvas.toDataURL("image/png", 1.0);
      console.log("prtContent view citation canvas", canvas);
      console.log("prtContent view citation imgData", imgData);
      this.screenshoot = imgData;
      // this.AddImagesResource(imgData);
      // document.body.appendChild(canvas);
      console.log("prtContent view citation", prtContent);
      // const imgSan = this._sanitizer.bypassSecurityTrustResourceUrl(this.screenshoot);
      const imgContent = `<img style="width:300px; -webkit-filter: grayscale(100%); filter: grayscale(100%);" src="${this.screenshoot}">`;

      const WinPrint = this.window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
      WinPrint.document.write('<html><head>');
      WinPrint.document.write(`<style>
  
        @media print {
          .hidden-print,
          .hidden-print * {
              display: none !important;
          }
          body {color:black;}
        }

      </style>`);
      WinPrint.document.write('</head><body onload="print();close();">');
      WinPrint.document.write(imgContent);
      WinPrint.document.write('</body></html>');
      WinPrint.document.close();

    });



  }

  goBack() {
    this.location.back();
  }

  private _loadImage(image) {

    if (image === undefined) return false;

    if (image.bucketStorage == true) {
      this.imagePath = image.path;
    } else {
      this._showImageService.getImage(image.id)
        .pipe(first())
        .subscribe(
          resp => {
            // console.log('dialog image resp', resp);
            this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
              + resp.content);

            // console.log("this.imagePath", this.imagePath);
          },
          error => {
            console.log(" err ===== ", error);
          });
    }

  }

}
