<div class="content">
    <div>
        <h3>Attachments</h3>
    </div>

    <!-- <form>
        <div class="form-group">
            <fieldset class="form-group">
                {{imageError}}
                <div class="custom-file fileInputProfileWrap">
                    <input type="file" (change)="fileChangeEvent($event)" class="fileInputProfile">
                    <div class="img-space">
                        <ng-container *ngIf="isImageSaved; else elseTemplate">
                            <img [src]="cardImageBase64" />
                        </ng-container>
                        <ng-template #elseTemplate>

                            <img src="" class="img-responsive">
                        </ng-template>
                    </div>
                </div>
            </fieldset>
        </div>
        <a class="btn btn-danger" (click)="removeImage()" *ngIf="isImageSaved">Remove</a>
    </form> -->

    <div [formGroup]="form">

        <mat-grid-list cols="4" rowHeight="1:1">
            <mat-grid-tile formArrayName="files" *ngFor="let item of f; let i = index;">
              <div class="col-md-12">
                <div [formGroupName]="i" class="row box-border">
                    <fieldset class="form-group">
                        <div *ngIf="imageError[i]">{{imageError[i]}}</div>
                        <input formControlName="file" type="file" class="input-file" (change)="onFileChange($event,i)">
                        <div class="img-preview">
                            <ng-container *ngIf="imgBase64Paths[i]; else elseTemplate">
                                <img [src]="imgBase64Paths[i]" />
                            </ng-container>
                            <ng-template #elseTemplate>
                                <div class="btn-plus">+</div>
                            </ng-template>
                        </div>
                    </fieldset>
                </div>
              <div *ngIf="imgBase64Paths[i]" class="row"><button class="btn btn-delete"  (click)="onDeleteImage(i)">delete</button></div>
              </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>
